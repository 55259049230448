import { FC } from "react"
import { Trans, useTranslation } from "react-i18next"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { Box, Container } from "@mui/material"

import { UButton, UText } from "../../../components"
import { List, ListItem } from "../../../components/mui.components"

const UDesignApp: FC = () => {
  const { VITE_UDESIGNAPP } = import.meta.env
  const { t } = useTranslation("common")

  const getUDesignApp = () => {
    window.open(VITE_UDESIGNAPP, "_self")
  }

  return (
    <>
      <Container maxWidth="md">
        <UText
          variant="h4"
          component="div"
          sxProp={{
            textAlign: "center",
            color: "text.primary",
            fontSize: "36px",
            fontWeight: 600,
            pb: 8,
            pt: 6,
          }}
        >
          {t("uDesignApp.header")}
        </UText>
        <Box
          sx={{ border: "1px solid", borderColor: "divider", padding: "16px" }}
        >
          <UText variant="h6" sxProp={{ fontWeight: 600 }}>
            {t("uDesignApp.appInstallation.title")}
          </UText>
          <Box sx={{ marginBottom: "20px" }}>
            <Box sx={{ paddingTop: "20px" }}>
              <UText
                variant="subtitle2"
                sxProp={{ fontWeight: 700, color: "text.secondary" }}
              >
                {t("uDesignApp.appInstallation.diagnosticHeading")}
              </UText>
            </Box>
            <Box>
              <UText
                component="div"
                variant="body2"
                sxProp={{ color: "text.secondary" }}
              >
                {t("uDesignApp.appInstallation.diagnosticDescription1")}
              </UText>
              <UText
                component="div"
                variant="body2"
                sxProp={{ color: "text.secondary", mt: 3 }}
              >
                {t("uDesignApp.appInstallation.diagnosticDescription2")}
              </UText>
              <UText
                component="div"
                variant="body2"
                sxProp={{ color: "text.secondary", mt: 3 }}
              >
                {t("uDesignApp.appInstallation.diagnosticDescription3")}
              </UText>
              <UText
                component="div"
                variant="body2"
                sxProp={{ color: "text.secondary", mt: 3 }}
              >
                {t("uDesignApp.appInstallation.diagnosticDescription4")}
              </UText>
            </Box>
          </Box>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
              width: "283px",
              backgroundColor: "divider",
              borderRadius: "4px",
              padding: "16px 15px 16px 15px",
            }}
          >
            <UButton
              sxProp={{
                "&:hover": {
                  backgroundColor: "success.light",
                },
                height: "52px",
                backgroundColor: "success.light",
                border: "1px solid",
                borderColor: "success.light",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: 500,
                textTransform: "capitalize",
              }}
              onClickHandler={getUDesignApp}
              variant={"contained"}
              btnText="Download uDesign 9.0.0"
              startIcon={<CloudDownloadIcon />}
            />
          </Box>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "text.secondary",
                marginBottom: "6px",
              }}
            >
              {t("uDesignApp.appInstallation.minimumSystemRequirements")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.windows")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.processor")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.processorNote")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.ram")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.hardDrive")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.display")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.appInstallation.tested")}
            </UText>
          </Box>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "text.secondary",
                marginBottom: "6px",
              }}
            >
              {t("uDesignApp.aboutUDesign.title")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.version")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.Di")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.inc")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.trademark")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.Manufacturer")}
            </UText>
            <UText
              component="div"
              variant="caption"
              sxProp={{ color: "text.secondary" }}
            >
              {" "}
              <Trans components={{ strong: <strong /> }}>
                {"uDesignApp.aboutUDesign.consultUDesign"}
              </Trans>
            </UText>
          </Box>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="caption"
              component="div"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.australiaSponsor")}
            </UText>
            <UText
              variant="caption"
              component="div"
              sxProp={{ color: "text.secondary" }}
            >
              {t("uDesignApp.aboutUDesign.sponsorAddress")}
            </UText>
          </Box>
        </Box>
      </Container>

      <Container maxWidth="md" sx={{ mt: "48px", pb: "48px" }}>
        <Box
          sx={{ border: "1px solid", borderColor: "divider", padding: "16px" }}
        >
          <UText variant="h6" sxProp={{ fontWeight: 600 }}>
            {t("uDesignApp.releaseNotes.title")}
          </UText>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "info.main",
                marginBottom: "6px",
              }}
            >
              {t("uDesignApp.releaseNotes.newFeaturesTreatmentPlanningHeading")}
            </UText>
            <UText
              variant="body2"
              sxProp={{
                color: "text.secondary",
                marginBottom: "16px",
                mt: "0px",
              }}
            >
              <List
                sx={{
                  listStyleType: "decimal",
                  listStylePosition: "outside",
                  pl: "22px",
                }}
              >
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent1")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent2")}
                  <List
                    sx={{
                      listStyleType: "circle",
                      listStylePosition: "outside",
                      pl: "22px",
                    }}
                  >
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.ponticEnhancements1")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.ponticEnhancements2")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.ponticEnhancements3")}
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent3")}
                  <List
                    sx={{
                      listStyleType: "circle",
                      listStylePosition: "outside",
                      pl: "22px",
                    }}
                  >
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.eruptionEnhancements1")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.eruptionEnhancements2")}
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent4")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent5")}
                  <List
                    sx={{
                      listStyleType: "circle",
                      listStylePosition: "outside",
                      pl: "22px",
                    }}
                  >
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.digitalEnhancements1")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.digitalEnhancements2")}
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent6")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent7")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent8")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureContent9")}
                </ListItem>
              </List>
            </UText>
          </Box>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "info.main",
                marginBottom: "6px",
              }}
            >
              {t("uDesignApp.releaseNotes.newFeatureCheckoutHeading")}
            </UText>
            <UText
              variant="body2"
              sxProp={{
                color: "text.secondary",
                marginBottom: "16px",
                mt: "0px",
              }}
            >
              <List
                sx={{
                  listStyleType: "decimal",
                  listStylePosition: "outside",
                  pl: "22px",
                }}
              >
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent1")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent2")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent3")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent4")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent5")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent6")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.newFeatureCheckoutContent7")}
                </ListItem>
              </List>
            </UText>
          </Box>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "info.main",
                marginBottom: "6px",
              }}
            >
              {t("uDesignApp.releaseNotes.removedItems")}
            </UText>
            <UText
              variant="body2"
              sxProp={{
                color: "text.secondary",
                marginBottom: "16px",
                mt: "0px",
              }}
            >
              <List
                sx={{
                  listStyleType: "decimal",
                  listStylePosition: "outside",
                  pl: "22px",
                }}
              >
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.removedItemsContent1")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.removedItemsContent2")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.removedItemsContent3")}
                </ListItem>
              </List>
            </UText>
          </Box>

          <Box>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "info.main",
                marginBottom: "16px",
              }}
            >
              {t("uDesignApp.releaseNotes.bugFixesHeading")}
            </UText>
          </Box>

          <Box sx={{ pt: "12px" }}>
            <UText
              variant="subtitle2"
              sxProp={{
                fontWeight: 700,
                color: "info.main",
                marginBottom: "6px",
              }}
            >
              {t("uDesignApp.releaseNotes.futureProducts")}
            </UText>
            <UText
              variant="body2"
              sxProp={{
                color: "text.secondary",
                marginBottom: "16px",
                mt: "0px",
              }}
            >
              <List
                sx={{
                  listStyleType: "disc",
                  listStylePosition: "outside",
                  pl: "22px",
                }}
              >
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.futureProductsContent1")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.futureProductsContent2")}
                </ListItem>
                <ListItem sx={{ display: "list-item", p: 0 }}>
                  {t("uDesignApp.releaseNotes.futureProductsContent3")}
                  <List
                    sx={{
                      listStyleType: "circle",
                      listStylePosition: "outside",
                      pl: "22px",
                    }}
                  >
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements1")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements2")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements3")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements4")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements5")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements6")}
                    </ListItem>
                    <ListItem sx={{ display: "list-item", p: 0 }}>
                      {t("uDesignApp.releaseNotes.workflowEnhancements7")}
                    </ListItem>
                  </List>
                </ListItem>
              </List>
            </UText>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default UDesignApp
