import { FC, MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import CheckIcon from "@mui/icons-material/Check"
import FilterListIcon from "@mui/icons-material/FilterList"
import { isEqual, uniq } from "lodash"

import TrackIcon from "../../../../../assets/svgs/track.svg"
import TrackWhiteIcon from "../../../../../assets/svgs/track-white.svg"
import { UButton, UText } from "../../../../../components"
import {
  Badge,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  SelectChangeEvent,
  styled,
  ToggleButton,
  ToggleButtonGroup,
} from "../../../../../components/mui.components"
import { useAppSelector } from "../../../../../core/app/hooks"
import { PatientFilterQueryParams } from "../../../../../core/app/slices/patients/patient.types"
import { RootState } from "../../../../../core/app/store"
import { STATUS } from "../../../config/status.config"
import { STATUS_UPDATED } from "../../filter"

import SelectCheckBox from "./selectCheckBox"
import UActionButton from "./UActionButton"

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
  },
}))

const UTableFilter: FC<{
  onFilterUpdate?: (fields: PatientFilterQueryParams) => void
  filterQuery: PatientFilterQueryParams
  onClearAllFilter?: () => void
  actionNeededCount: number
}> = ({ onFilterUpdate, filterQuery, onClearAllFilter, actionNeededCount }) => {
  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )
  const dispatch = useDispatch()
  const [filterQueryParams, setFilterQueryParams] =
    useState<PatientFilterQueryParams>({})
  const { user } = useAppSelector((state: RootState) => state.userService)
  const { t } = useTranslation()
  const [menuOpen, setMenuOpen] = useState<null | HTMLElement>(null)
  const open = Boolean(menuOpen)
  const [actionButtons, setActionButtons] = useState<string[]>([])
  const [badgeCount, setBadgeCount] = useState<number>()
  const [isShowArchivedPatients, setIsShowArchivedPatients] = useState(false)
  const [statusUpdatedFilter, setStatusUpdatedFilter] = useState("")
  const [assigners, setAssigners] = useState([])
  const [trackImage, setTrackImage] = useState<string>("")
  const [status, setStatus] = useState<any[]>([])
  const [selectedAssigners, setSelectedAssigners] = useState<
    string[] | number[]
  >([])
  const [selectedStatus, setSelectedStatus] = useState<string[]>([])
  const [isStatusHasValue, setIsStatusHasValue] = useState(false)
  const [isAssigneeHasValue, setIsAssigneeHasValue] = useState(false)
  const numConditionsSatisfied = [
    isShowArchivedPatients,
    statusUpdatedFilter,
    isAssigneeHasValue,
    isStatusHasValue,
  ].filter(Boolean).length

  const removeFilterDuplicates = (arr) => {
    if (arr && arr.length > 0) {
      return arr.filter((item, index) => arr.indexOf(item) === index)
    }
  }

  useEffect(() => {
    setBadgeCount(actionNeededCount)
  }, [actionNeededCount])

  useEffect(() => {
    filterQuery.assigned_to = removeFilterDuplicates(filterQuery.assigned_to)
    setFilterQueryParams(filterQuery)
    if (filterQuery) {
      const q = []
      if (filterQuery.track_order === 1) {
        q.push("trackOrders")
      }
      if (filterQuery.assigned_to && filterQuery.assigned_to.length > 0) {
        if (filterQuery.assigned_to.indexOf(+user.user_id) > -1) {
          q.push("assignedToMe")
        }
        setSelectedAssigners(uniq(filterQuery.assigned_to))
      } else {
        setSelectedAssigners([])
      }
      if (filterQuery.action_needed && filterQuery.action_needed === 1) {
        q.push("actionNeeded")
      }
      setActionButtons([...q])
      if (filterQuery.status && filterQuery.status.length > 0) {
        setSelectedStatus([...filterQuery.status])
      } else {
        setSelectedStatus([])
      }
      setIsShowArchivedPatients(filterQuery.show_archived_patients === 1)

      setStatusUpdatedFilter(filterQuery.status_updated)
    }
  }, [filterQuery])

  useEffect(() => {
    if (!isShowArchivedPatients) {
      const status = STATUS.filter(
        (status) => status.key !== "ARCHIVED" && !status.isDisableDropdownShow,
      )
      setStatus(status)
    } else {
      setStatus(STATUS)
    }
  }, [dispatch, isShowArchivedPatients])

  useEffect(() => {
    //TODO: TBD with stephane and kasi
    // if (doctorList.length > 0) {
    //   doctorList = doctorList.filter((d) => d.id !== +user.user_id)
    // }
    setAssigners(doctorList)
  }, [doctorList])

  useEffect(() => {
    if (
      !isEqual(filterQueryParams, filterQuery) &&
      Object.keys(filterQueryParams).length
    ) {
      onFilterUpdate({
        ...filterQueryParams,
      })
    }
  }, [filterQueryParams])

  useEffect(() => {
    setTrackImage(
      actionButtons.includes("trackOrders") ? TrackWhiteIcon : TrackIcon,
    )
  }, [actionButtons])

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedAssigners(uniq(event.target.value))
    setIsAssigneeHasValue(event.target.value.length > 0)
  }

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(uniq(event.target.value))
    setIsStatusHasValue(event.target.value.length > 0)
  }

  const handleStatusUpdated = (event: SelectChangeEvent) => {
    setStatusUpdatedFilter(event.target.value)
  }

  const handleCheckboxChange = () => {
    setIsShowArchivedPatients(!isShowArchivedPatients)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpen(event.currentTarget)
  }

  const handleActionButtonClick = (
    event: MouseEvent<HTMLElement>,
    newButtons: string[],
  ) => {
    setActionButtons([...newButtons])
    const userIds = [...selectedAssigners]
    if (newButtons.includes("assignedToMe")) {
      userIds.push(+user.user_id)
    } else {
      const idx = userIds.indexOf(+user.user_id)
      if (idx > -1) {
        userIds.splice(idx, 1)
      }
    }
    const obj = {
      ...filterQueryParams,
      track_order: newButtons.includes("trackOrders") ? 1 : 0,
      action_needed: newButtons.includes("actionNeeded") ? 1 : 0,
      assigned_to: userIds,
    }
    setFilterQueryParams(obj)
  }

  const onClickFilter = () => {
    setMenuOpen(null)
    //clearAll()
    const userIds = [...selectedAssigners]
    if (
      actionButtons.includes("assignedToMe") &&
      userIds.indexOf(+user.user_id) === -1
    ) {
      userIds.push(+user.user_id)
    }
    const fieldObj = {
      ...filterQueryParams,
      status: selectedStatus,
      assigned_to: userIds,
      status_updated: statusUpdatedFilter,
      show_archived_patients: isShowArchivedPatients ? 1 : 0,
    }
    setFilterQueryParams(fieldObj)
  }

  const renderStatusUpdatedValue = () => {
    return !statusUpdatedFilter
      ? "Select"
      : STATUS_UPDATED.find((s) => s.key === statusUpdatedFilter).displayText
  }

  const clearSelectedStatus = () => setSelectedStatus([])

  const clearSelectedAssignees = () => setSelectedAssigners([])

  const clearAll = () => {
    setSelectedAssigners([])
    setSelectedStatus([])
    setStatusUpdatedFilter("")
    setIsShowArchivedPatients(false)
    setIsAssigneeHasValue(false)
    setIsStatusHasValue(false)
    onClearAllFilter()
  }

  const handleClose = () => {
    setMenuOpen(null)
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={7}
      sx={{
        display: "flex",
        direction: "row",
        alignItems: "center",
        justifyContent: { lg: "end", md: "end", sm: "space-between" },
        flexWrap: { md: "nowrap" },
        gap: { xs: 1 },
      }}
    >
      <ToggleButtonGroup
        data-testid={"actionButtons"}
        sx={{
          gap: 1,
          flexWrap: { xs: "wrap", sm: "nowrap" },
          flexGrow: { sm: 1 },
          justifyContent: { sm: "space-between" },
        }}
        value={actionButtons}
        onChange={handleActionButtonClick}
      >
        <ToggleButton
          sx={{ border: "none", p: 0, flexGrow: { sm: 1 } }}
          value={"actionNeeded"}
        >
          <Badge
            data-testid={"actionNeededBadge"}
            badgeContent={badgeCount}
            sx={{
              flexGrow: 1,
              "> .MuiBadge-badge": {
                marginRight: badgeCount?.toString().length >= 3 && "8px",
              },
            }}
          >
            <UActionButton
              btnText={t("patientList.actionNeeded")}
              variant={
                actionButtons.includes("actionNeeded")
                  ? "contained"
                  : "outlined"
              }
              startIcon={<CheckIcon />}
              bgColor={
                actionButtons.includes("actionNeeded")
                  ? "success.main"
                  : "inherit"
              }
              isActive={actionButtons.includes("actionNeeded")}
            />
          </Badge>
        </ToggleButton>
        <ToggleButton
          sx={{
            border: "none",
            p: 0,
            whiteSpace: "nowrap",
            flexGrow: { sm: 1 },
          }}
          value={"assignedToMe"}
        >
          <UActionButton
            btnText={t("patientList.assignedToMe")}
            variant={
              actionButtons.includes("assignedToMe") ? "contained" : "outlined"
            }
            startIcon={<AccountCircleIcon />}
            bgColor={
              actionButtons.includes("assignedToMe")
                ? "text.primary"
                : "inherit"
            }
            isActive={actionButtons.includes("assignedToMe")}
          />
        </ToggleButton>
        <ToggleButton
          sx={{
            border: "none",
            p: 0,
            flexGrow: { sm: 1 },
            "&:hover .MuiButton-startIcon": {
              filter: actionButtons.includes("trackOrders")
                ? ""
                : "invert(100%)",
            },
          }}
          value={"trackOrders"}
        >
          <UActionButton
            btnText={t("patientList.trackOrders")}
            variant={
              actionButtons.includes("trackOrders") ? "contained" : "outlined"
            }
            startIcon={
              <img
                // role="menuitem"
                // onMouseOver={() => {
                //   setTrackImage(
                //     actionButtons.includes("trackOrders")
                //       ? TrackWhiteIcon
                //       : TrackIcon,
                //   )
                // }}
                // onFocus={() => {
                //   console.log("")
                // }}
                color={"#fff"}
                alt={"TRACK ORDERS"}
                src={trackImage}
              />
            }
            bgColor={
              actionButtons.includes("trackOrders") ? "text.primary" : "inherit"
            }
            isActive={actionButtons.includes("trackOrders")}
          />
        </ToggleButton>
      </ToggleButtonGroup>
      <IconButton
        data-testid={"patientListFilterBtn"}
        id="u-filter-button"
        aria-controls={open ? "u-filter-button-content" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        size="medium"
        onClick={handleClick}
        sx={{
          padding: "4px",
          backgroundColor: open ? "rgba(0, 0, 0, 0.12)" : "",
        }}
      >
        <FilterListIcon sx={{ height: "24px", width: "24px" }} />
      </IconButton>
      <StyledMenu
        id="u-filter-button-content"
        aria-labelledby="u-filter-button"
        anchorEl={menuOpen}
        open={open}
        sx={{
          ".MuiList-padding": {
            width: 320,
            padding: "16px !important",
          },
        }}
        onClose={handleClose}
      >
        <Grid container flexDirection={"column"} gap={1}>
          <Grid item>
            <FormControl sx={{ width: "100%" }}>
              <Grid sx={{ alignItems: "center" }} container>
                <Grid item xs={10}>
                  <UText variant="subtitle2" color="text.secondary">
                    {t("filter.assignedTo")}
                  </UText>
                </Grid>
                <Grid item xs={2} sx={{ fontSize: 12, textAlign: "right" }}>
                  {selectedAssigners.length > 0 && (
                    <Link
                      sx={{ cursor: "pointer", letterSpacing: "0.40px" }}
                      onClick={clearSelectedAssignees}
                    >
                      {t("button.clear")}
                    </Link>
                  )}
                </Grid>
              </Grid>
              <SelectCheckBox
                dataTestId={"doctorsFilter"}
                sxProps={{
                  m: 1,
                  height: 56,
                  ".MuiSelect-select": {
                    padding: "16px 12px",
                  },
                }}
                selectedCheckBox={selectedAssigners}
                handleChange={handleChange}
                menuList={assigners}
                listLabel="Doctors"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ width: "100%" }}>
              <Grid sx={{ alignItems: "center" }} container>
                <Grid item xs={10}>
                  <UText variant="subtitle2" color="text.secondary">
                    {t("filter.status")}
                  </UText>
                </Grid>
                <Grid item xs={2} sx={{ fontSize: 12, textAlign: "right" }}>
                  {selectedStatus.length > 0 && (
                    <Link
                      sx={{ cursor: "pointer", letterSpacing: "0.40px" }}
                      onClick={clearSelectedStatus}
                    >
                      {t("button.clear")}
                    </Link>
                  )}
                </Grid>
              </Grid>
              <SelectCheckBox
                dataTestId={"statusFilter"}
                sxProps={{
                  m: 1,
                  height: 56,
                  ".MuiSelect-select": {
                    padding: "16px 12px",
                  },
                }}
                selectedCheckBox={selectedStatus}
                handleChange={handleStatusChange}
                menuList={status}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl sx={{ width: "100%" }}>
              <UText variant="subtitle2" color="text.secondary">
                {t("filter.statusUpdated")}
              </UText>
              <Select
                data-testid={"statusUpdatedFilter"}
                value={statusUpdatedFilter || ""}
                onChange={handleStatusUpdated}
                sx={{
                  ".MuiInputBase-input": {
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "24px",
                    color: "text.secondary",
                    padding: "16px 12px",
                  },
                  height: 56,
                  m: 1,
                }}
                displayEmpty
                renderValue={renderStatusUpdatedValue}
              >
                {STATUS_UPDATED.map(({ displayText, key }) => (
                  <MenuItem sx={{ fontSize: 16 }} key={key} value={key}>
                    <UText variant="subtitle1" color="text.primary">
                      {displayText}
                    </UText>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControlLabel
              sx={{
                ml: 0,
                ".MuiTypography-root": {
                  fontSize: "16px",
                  color: "text.primary",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
              control={
                <Checkbox
                  checked={isShowArchivedPatients}
                  onChange={handleCheckboxChange}
                />
              }
              label={t("filter.archivedPatients")}
            />
          </Grid>
          <Grid container>
            <Grid
              item
              xs={6}
              sx={{
                width: "100%",
                textAlign: "center",
                fontSize: 14,
              }}
            >
              <Button
                onClick={clearAll}
                variant="text"
                disabled={numConditionsSatisfied < 2}
              >
                {t("button.clearAll")}
              </Button>
            </Grid>
            <Grid item xs={6} sx={{ p: 0.5 }}>
              <UButton
                sxProp={{ width: "100%", letterSpacing: "0.4px" }}
                variant={"contained"}
                btnType={"button"}
                btnText={t("button.filter")}
                onClickHandler={onClickFilter}
              />
            </Grid>
          </Grid>
        </Grid>
      </StyledMenu>
    </Grid>
  )
}

export default UTableFilter
