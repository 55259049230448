import { useEffect, useState } from "react"

import {
  Stack,
  Paper,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Divider,
} from "@mui/material"

import StartCaseIcon from "@mui/icons-material/StartOutlined"
import ChairsideIcon from "@mui/icons-material/QuickreplyOutlined"
import QuickPlanIcon from "@mui/icons-material/DocumentScannerOutlined"
import QuickStagingIcon from "@mui/icons-material/RectangleOutlined"
import QuickAuxIcon from "@mui/icons-material/AttachmentOutlined"
import ReviewModIcon from "@mui/icons-material/TouchAppOutlined"
import WeSetupIcon from "@mui/icons-material/Settings"
import WeStagingIcon from "@mui/icons-material/RouteOutlined"
import WeAttIcon from "@mui/icons-material/AttachmentOutlined"
import RxIcon from "@mui/icons-material/BookOutlined"
import OrderIcon from "@mui/icons-material/AddShoppingCartOutlined"
import WeTestIcon from "@mui/icons-material/PestControl"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setCurRightPage } from "../udTreatSlice"
import { useTheme } from "@mui/material/styles"

export function RightToolBar(props: any) {
  const theme = useTheme()
  const { curCaseMode, curRightPage } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [toolName, SetToolName] = useState("")
  const dispatch = useAppDispatch()
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    SetToolName(nextTool)
    dispatch(setCurRightPage(nextTool))
  }
  useEffect(() => {
    SetToolName("")
    dispatch(setCurRightPage(""))
  }, [curCaseMode])
  const ChairsideBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="Chairside Setup" placement="left">
          <ToggleButton value="Chairside Setup">
            <ChairsideIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const SmartRxBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="Smart Rx" placement="left">
          <ToggleButton value="SmartRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const UDPlanBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Divider sx={{ height: "2px", backgroundColor: "darkcyan" }} />
        <Tooltip title="UD Setup" placement="left">
          <ToggleButton value="UDSetup">
            <QuickPlanIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Staging" placement="left">
          <ToggleButton value="UDStaging">
            <QuickStagingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Auxillary" placement="left">
          <ToggleButton value="UDAux">
            <QuickAuxIcon />
          </ToggleButton>
        </Tooltip>
        <Divider sx={{ height: "2px", backgroundColor: "darkcyan" }} />
        {/* 
        <Tooltip title="Smart Rx" placement="left">
          <ToggleButton value="SmartRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>*/}
        <Tooltip title="Retainer Rx" placement="left">
          <ToggleButton value="RetainerRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Quick Rx" placement="left">
          <ToggleButton value="QuickRx">
            <RxIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Order" placement="left">
          <ToggleButton value="Order">
            <OrderIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const ReviewModBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <ToggleButtonGroup
          orientation="vertical"
          value={toolName}
          exclusive
          onChange={handleChange}
        >
          <Tooltip title="Smart Rx" placement="left">
            <ToggleButton value="SmartRx">
              <RxIcon />
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        <Tooltip title="Review & Mod." placement="left">
          <ToggleButton value="ReviewMod">
            <ReviewModIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Order" placement="left">
          <ToggleButton value="Order">
            <OrderIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const WeDesignBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="UD Setup" placement="left">
          <ToggleButton value="UDSetup">
            <QuickPlanIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Staging" placement="left">
          <ToggleButton value="UDStaging">
            <QuickStagingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="UD Auxillary" placement="left">
          <ToggleButton value="UDAux">
            <QuickAuxIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeSetup" placement="left">
          <ToggleButton value="WeSetup">
            <WeSetupIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeStaging" placement="left">
          <ToggleButton value="WeStaging">
            <WeStagingIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeAux" placement="left">
          <ToggleButton value="WeAux">
            <WeAttIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="WeTest" placement="left">
          <ToggleButton value="WeTest">
            <WeTestIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  return (
    <Paper
      sx={{
        right: 28,
        top: 104,
        backgroundColor: theme.transBkColor.light,
        maxHeight: `calc(100% - 114px)`,
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "1px",
        },
        position: "absolute",
      }}
      elevation={6}
    >
      <Stack direction="column">
        {curCaseMode == "NewCase" ? (
          <></>
        ) : curCaseMode == "UDPlan" ? (
          <UDPlanBtns />
        ) : curCaseMode == "UDModi" ? (
          <ReviewModBtns />
        ) : curCaseMode == "UDView" ? (
          <></>
        ) : curCaseMode == "WeDesign" ? (
          <WeDesignBtns />
        ) : (
          <></>
        )}
      </Stack>
    </Paper>
  )
}
