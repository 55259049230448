import moment from "moment-timezone"

const dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  useGrouping: true,
  maximumFractionDigits: 0,
})

export const dateFormat = (
  value: string,
  outformat = "MM/DD/YYYY",
  inputFormat: string | null = null,
  dayCount?: number,
) => {
  if (!value || value === "-") {
    return ""
  }
  if (inputFormat && !dayCount) {
    return moment(value, inputFormat).format(outformat)
  } else if (dayCount) {
    const date = moment(value)
    const newDate = date.add(dayCount, "days")
    return moment(newDate, inputFormat).format(outformat)
  } else {
    return moment(value).format(outformat)
  }
}

export const formatCurrency = (
  value: number,
  currency?: string,
  countryCode?: string,
) => {
  const lanCode = countryCode ? "en-" + countryCode?.toLowerCase() : "en-us"
  const currencyValue = currency ? currency : "USD"
  const dollar = Intl.NumberFormat(lanCode, {
    style: "currency",
    currency: currencyValue,
    useGrouping: false,
    // eneble decimal  if no zero value in the  decimal and without round decimal
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    // eneble decimal  if no zero value in the  decimal and round decimal
    // minimumSignificantDigits: 2,
    // maximumSignificantDigits: 2,
  })
  return dollar.format(value)
}

export const convertMBtoKB = (mb: number) => {
  return mb * 1024 // 1 MB = 1024 KB
}

export const checkIfObjectHasKeys = (obj: object) => {
  return Object.keys(obj).length
}

export const parseToObject = (value: any) => {
  let parsedValue = value

  while (typeof parsedValue === "string") {
    try {
      parsedValue = JSON.parse(parsedValue)
    } catch (error) {
      return null
    }
  }

  if (typeof parsedValue === "object" && parsedValue !== null) {
    return parsedValue
  } else {
    return null
  }
}

export const convertSummaryValue = (
  value: string,
  suffix: string,
  prefix = "",
) => {
  return prefix + value + suffix
}

export const formatAddress = (obj) => {
  let address = obj?.line_1
  if (obj?.line_2) {
    address = address.concat(", ", obj?.line_2)
  }
  if (obj?.city) {
    address = address.concat(", ", obj?.city)
  }
  if (obj?.state) {
    address = address.concat(", ", obj?.state)
  }
  if (obj?.countryName) {
    address = address.concat(", ", obj?.countryName)
  }
  if (obj?.zipCode) {
    address = address.concat(", ", obj.zipCode)
  }
  return address
}
