import { createAsyncThunk } from "@reduxjs/toolkit"

import { OrderQueryparams } from "../../../../core/app/slices/patients/patient.types"
import { QUERY_PARAMS_STORAGE_KEY_ORDER } from "../../../../modules/Patient/PatientList/patientTable/table.config"
import { IAlignerOrderPayload } from "../../../model/interface/AlignerOrder"
import { IShippingAddressTo } from "../../../model/interface/IShippingAddress"
import store from "../../store"
import { clinicalRequestBasicType } from "../clinical/clinical.types"

import {
  createAlternateShippingAddress,
  getActiveOrders,
  getMaterials,
  getOrdersByCase,
  getPackageDetails,
  getQuotationService,
  getShippingAddress,
  getStageDetails,
  placeAlignerOrder,
  placeManufacturingOrder,
  updateOrderStatus,
} from "./order.service"

export const updateOrder = createAsyncThunk(
  "orderService/updateOrderStatus",
  async ({
    caseId,
    type,
    upper,
    lower,
  }: {
    caseId: string
    type: string
    upper: string
    lower: string
  }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      const payload = {
        case_id: caseId,
        order_type: type,
      }
      payload["upper_type"] = upper || ""
      payload["lower_type"] = lower || ""
      return updateOrderStatus(orgId, payload)
    } catch (err) {
      return err
    }
  },
)

export const getOrdersByCaseId = createAsyncThunk(
  "orderService/getOrdersByCaseId",
  async (caseId: number, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return getOrdersByCase(orgId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getQuotation = createAsyncThunk(
  "orderService/getQuotation",
  async (payload: any, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return getQuotationService(orgId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const placeAlignerOrders = createAsyncThunk(
  "orderService/placeAlignerOrders",
  async (payload: IAlignerOrderPayload, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return placeAlignerOrder(orgId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const placeManufacturingOrders = createAsyncThunk(
  "orderService/placeManufacturingOrders",
  async (
    {
      orderId,
      payload,
    }: {
      orderId: string
      payload: {
        sub_orders: number[]
        status: number
        txplan_name: string
        txplan_subname: string
      }
    },
    { rejectWithValue },
  ) => {
    const orgId = store.getState().userService.user.current_orgId
    try {
      return placeManufacturingOrder(orgId, orderId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getShippingAddressByCase = createAsyncThunk(
  "orderService/getShippingAddressByCase",
  async () => {
    const orgId = store.getState()?.userService?.user?.current_orgId
    const userId = store.getState().userService.user.user_id
    try {
      return getShippingAddress(orgId, userId)
    } catch (err) {
      return err
    }
  },
)

export const getPackageDetail = createAsyncThunk(
  "orderService/getPackageDetail",
  async () => {
    const orgId = store.getState()?.userService?.user?.current_orgId
    try {
      return getPackageDetails(orgId)
    } catch (err) {
      return err
    }
  },
)

export const getStageDetailApi = createAsyncThunk(
  "clinicalService/getStageDetails",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await getStageDetails({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const createAlternateAddress = createAsyncThunk(
  "orderService/createAlternateAddress",
  async (payload: IShippingAddressTo, { rejectWithValue }) => {
    const orgId = store.getState().userService.user.current_orgId
    const userId = store.getState().userService.user.user_id
    try {
      return createAlternateShippingAddress(orgId, userId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getMaterialsAPI = createAsyncThunk(
  "clinicalService/getMaterial",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await getMaterials({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getActiveOrdersAPI = createAsyncThunk(
  "orderService/getActiveOrders",
  async (tableQueryParams: OrderQueryparams, { rejectWithValue, getState }) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      localStorage.setItem(
        QUERY_PARAMS_STORAGE_KEY_ORDER,
        JSON.stringify(tableQueryParams),
      )
      return await getActiveOrders(orgId, tableQueryParams)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
