import { ChangeEvent, FC, ReactNode, useState } from "react"
import React, { RefObject } from "react"

import { SxProps } from "../../mui.components"
import { TextField } from "../../mui.components"

export interface IFormInput {
  inputLabel: string
  helperText?: string | null
  fieldName: string
  endAdornmentIcon?: ReactNode | null
  fieldType?: "text" | "password"
  onInputchange?: (value: string) => void
  onBlurChange?: () => void
  onFocusChange?: () => void
  value?: string
  hasError?: boolean
  placeholder?: string
  inputRef?: RefObject<HTMLInputElement>
  sxProp?: SxProps
  multiline?: boolean
  maxRows?: number
  disabled?: boolean
}

const UTextField: FC<IFormInput> = ({
  fieldType,
  fieldName,
  inputLabel,
  placeholder,
  endAdornmentIcon,
  onInputchange,
  onBlurChange,
  onFocusChange,
  hasError,
  value,
  inputRef,
  sxProp,
  multiline,
  maxRows,
  disabled,
}) => {
  const [shrinkLabel, setShrinkLabel] = useState(false)
  return (
    <TextField
      sx={sxProp}
      error={hasError}
      fullWidth
      inputRef={inputRef}
      type={fieldType}
      id={fieldName}
      label={inputLabel}
      name={fieldName}
      placeholder={placeholder}
      value={value}
      multiline={multiline}
      maxRows={maxRows}
      disabled={disabled}
      onBlur={(e) => {
        onBlurChange && e.target.value !== "" && onBlurChange()
      }}
      onFocus={(e) => {
        onFocusChange && e.target.value !== "" && onFocusChange()
      }}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        onInputchange && onInputchange(e.target.value)
      }}
      onAnimationStart={(e) => {
        if (e) {
          if (e.animationName === "mui-auto-fill") {
            setShrinkLabel(true)
          }
          if (e.animationName === "mui-auto-fill-cancel") {
            setShrinkLabel(false)
          }
        }
      }}
      InputProps={{
        endAdornment: endAdornmentIcon,
      }}
      InputLabelProps={
        placeholder || value || shrinkLabel ? { shrink: true } : {}
      }
    />
  )
}

UTextField.defaultProps = {
  endAdornmentIcon: null,
  onInputchange: undefined,
  fieldType: "text",
  hasError: false,
}

export default UTextField
