import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import Slider from "react-slick"

import stl from "../../assets/images/cube-outline.svg"
import zoomOut from "../../assets/svgs/arrow-expand-all.svg"
import { UImagePreview } from "../../components"
import { Box, IconButton } from "../../components/mui.components"
import Preview from "../../components/Preview/preview"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { getCaseById } from "../../core/app/slices/case"
import { fetchFileZips } from "../../core/app/slices/clinical/clinicalThunkApi"
import { downloadPhotographs } from "../../core/app/slices/records/photograph"
import {
  FileItem,
  PhotoObject,
} from "../../core/app/slices/records/photograph/photograph.type"
import { setDownloadStatus } from "../../core/app/slices/records/photograph/photographSlice"
import { downloadXray } from "../../core/app/slices/records/xrays"
import { RootState } from "../../core/app/store"
import { getFiles } from "../../core/utils/commonMethod"
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "../../ui-component/mui.icons"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./photoList.scss"

interface ArrowProps {
  onClick: () => void
  currentSlide: number
  slideCount: number
}

const NextArrow: FC<ArrowProps> = ({ onClick, currentSlide, slideCount }) => {
  return (
    <Box component={"div"} className="next-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide + 3 === slideCount}
        sx={{
          position: "absolute",
          right: "21px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowRight
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

const PrevArrow: FC<ArrowProps> = ({ onClick, currentSlide }) => {
  return (
    <Box component={"div"} className="prev-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide === 0}
        sx={{
          position: "absolute",
          left: "34px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowLeft
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

interface PhotoListProps {
  setSelectedPreview?: (value: string) => void
  isRefinement?: boolean
  upperArchType?: number
  lowerArchType?: number
}

const PhotoList: FC<PhotoListProps> = ({
  setSelectedPreview,
  isRefinement,
  upperArchType,
  lowerArchType,
}) => {
  const { t } = useTranslation("common")
  const [prevImage, setPrevImage] = useState<File[]>([])
  const [selectedImage, setSelectedImage] = useState<string>("")
  const [isStlPreview, setIsstlPreview] = useState<boolean>(false)
  const [currentImage, setCurrentImage] = useState<number>(0)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const { zipList, zipNames } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { compositePhoto, individualPhotos, additionalPhotos, fileList } =
    useAppSelector((state: RootState) => state.PhotosService)
  const { pano, ceph, otherXrays } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const handleImageClick = async (image: string, index: number) => {
    if (index === 0) {
      setIsstlPreview(true)
    } else {
      setIsstlPreview(false)
      const response = await fetch(image)
      const blob = await response.blob()
      const file = new File([blob], `Image`, { type: blob.type })
      setPrevImage([file])
      setSelectedImage(image)
    }
    setCurrentImage(index)
  }

  useEffect(() => {
    setIsstlPreview(true)
    if (isRefinement && caseDetail.id + "" !== caseId) {
      dispatch(getCaseById({ patientId, caseId }))
    }
  }, [])

  useEffect(() => {
    zipNames?.length &&
      dispatch(
        fetchFileZips({
          patientId,
          caseId,
          zipNames,
        }),
      )
  }, [zipNames])

  // useEffect(() => {
  //   console.log('here')
  //   dispatch(fetchFileList({ patientId, caseId }))
  //   if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  // }, [dispatch])

  useEffect(() => {
    let downloadTotal = 0
    const downloadFiles = (
      item: FileItem,
      totalFilesToDownload: number,
      attachmentType: string,
    ) => {
      const { file_name, original_file_name, sequence, photo_position } = item

      dispatch(setDownloadStatus("pending"))

      const fn =
        attachmentType === "photographs"
          ? downloadPhotographs({
              patientId: patientId,
              caseId: caseId,
              fileName: file_name,
              photographType: file_name.includes("composite")
                ? "composite"
                : file_name.includes("individual")
                ? "individual"
                : "others",
              original_file_name: original_file_name,
              sequence: sequence,
              position: photo_position,
            })
          : downloadXray({
              patientId: patientId,
              caseId: caseId,
              fileName: file_name,
              XrayType: file_name.includes("pano")
                ? "pano"
                : file_name.includes("ceph")
                ? "ceph"
                : "others",
              original_file_name: original_file_name,
            })

      dispatch(fn).then((res) => {
        downloadTotal++
        if (downloadTotal === totalFilesToDownload) {
          dispatch(setDownloadStatus("succeeded"))
        }
      })
    }

    if (fileList.length) {
      if ((compositePhoto.length || individualPhotos.length) && pano.length)
        return

      let photos = []
      let refinementPhotos = []
      let xrays = []
      let refinementXrays = []
      if (!(compositePhoto.length && individualPhotos.length)) {
        const photoGraphType = ["composite", "individual", "others"]
        photoGraphType.map((type) => {
          photos = [
            ...photos,
            ...getFiles(fileList, "photographs", false, type),
          ]
          refinementPhotos = [
            ...refinementPhotos,
            ...getFiles(
              fileList,
              "photographs",
              true,
              type,
              caseDetail.refinementRound + 1,
            ),
          ]
        })
      }
      if (!pano.length) {
        const radioGraphType = ["pano", "ceph", "others"]
        radioGraphType.map((type) => {
          xrays = [...xrays, ...getFiles(fileList, "radiograph", false, type)]
          refinementXrays = [
            ...refinementXrays,
            ...getFiles(
              fileList,
              "radiograph",
              true,
              type,
              caseDetail.refinementRound + 1,
            ),
          ]
        })
      }
      let photosToDownload = []
      let xraysToDownload = []
      if (!isRefinement) {
        photosToDownload = photos
        xraysToDownload = xrays
      } else {
        photosToDownload = photosToDownload.concat(
          refinementPhotos.length ? refinementPhotos : photos,
        )
        xraysToDownload = xraysToDownload.concat(
          refinementXrays.length ? refinementXrays : xrays,
        )
      }
      photosToDownload.map((file) =>
        downloadFiles(file, photosToDownload.length, "photographs"),
      )
      xraysToDownload.map((file) =>
        downloadFiles(file, xraysToDownload.length, "radiograph"),
      )
    }
  }, [fileList])

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })

  return (
    <Box>
      <Box
        className="preview"
        sx={{ position: "relative", width: "466px", height: "466px" }}
      >
        <Preview
          zips={zipList}
          upperStl={upperStl}
          lowerStl={lowerStl}
          setUpperStl={setUpperStl}
          setLowerStl={setLowerStl}
          sx={{ display: isStlPreview ? "" : "none" }}
          upperArchType={upperArchType}
          lowerArchType={lowerArchType}
        />

        {!isStlPreview && (
          <>
            <UImagePreview
              imageHeight={466}
              imageWidth={466}
              files={prevImage.map((img) => {
                return {
                  src: URL.createObjectURL(img),
                  name: img.name,
                }
              })}
            />
            <IconButton
              onClick={() => setSelectedPreview(selectedImage)}
              className="zoom"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                backgroundColor: "#EEE",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "4px",
                zIndex: 1,
                display: "none",
                padding: "7px",
              }}
            >
              <Box
                component={"img"}
                src={zoomOut}
                alt={"zoomout"}
                loading="lazy"
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </>
        )}
      </Box>

      <Box id="slick" component={"div"} sx={{ paddingLeft: "18px", mt: 2 }}>
        <Slider {...settings}>
          {[
            stl,
            ...compositePhoto,
            ...individualPhotos,
            ...additionalPhotos,
            ...ceph,
            ...pano,
            ...otherXrays,
          ].map((file: string | PhotoObject, index) =>
            index === 0 ? (
              <Box
                className={"slick-slider"}
                key={index}
                sx={{ p: "8px", m: 0 }}
                onClick={() => handleImageClick(file, index)}
              >
                <Box
                  component={"div"}
                  id="imagecontainer"
                  sx={{
                    borderRadius: "4px",
                    outline:
                      currentImage === index
                        ? "3px solid #1E88E5"
                        : "3px solid transparent",
                  }}
                >
                  <Box
                    id="imagebox"
                    sx={{
                      width: "120px",
                      height: "120px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#455A64",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      style={{
                        height: "48px",
                        width: "48px",
                      }}
                      src={file}
                      alt={"stl"}
                    />
                    <Box component={"div"} className="preview-text">
                      {t("uassist.stlPreview")}
                    </Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                className={"slick-slider"}
                key={index}
                sx={{ p: "5px", m: 0 }}
              >
                <img
                  style={{
                    borderRadius: "4px",
                    border: "3px solid",
                    borderColor:
                      currentImage === index ? "#1E88E5" : "transparent",
                  }}
                  src={file.src}
                  alt={file.name}
                  onClick={() => handleImageClick(file.src, index)}
                />
              </Box>
            ),
          )}
        </Slider>
      </Box>
    </Box>
  )
}

export default PhotoList
