import { CASE_STATUS } from "../../modules/Patient/config/status.config"

import Deserialize from "./interface/Deserialize"
import {
  IExistingPatientList,
  INewPatientData,
  IPatient,
  IPatientList,
} from "./interface/IPatient"

export default class Patient implements IPatient, Deserialize {
  id = null
  firstName = ""
  lastName = ""
  dob = ""
  address = ""
  street = ""
  city = ""
  state = ""
  zipCode = ""
  email = ""
  phoneNumber = ""
  countrycode = ""

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.firstName
      this.lastName = input.lastName
      this.dob = input.dob
      this.address = input.address
      this.street = input.street
      this.city = input.city
      this.state = input.state
      this.zipCode = input.zipCode
      this.email = input.email
      this.phoneNumber = input.phoneNumber
      this.countrycode = input.countryCode
    }
    return this
  }
}

export class PatientList implements IPatientList, Deserialize {
  order: {
    id: undefined
    statusId: undefined
    statusName: ""
    trackingNumber: undefined
  }
  caseId: undefined
  orgId: ""
  caseDisposition: ""
  updatedDate: ""
  assignedTo: 0
  user: { id: ""; firstName: ""; lastName: ""; preference: "" }
  patientId: undefined
  firstName: ""
  lastName: ""
  status: ""
  dob: ""

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.case_id) {
        this.caseId = input.case_id
      }
      this.orgId = input.org_id
      this.updatedDate = input.updated_date
      this.caseDisposition = input.case_disposition
        ? input.case_disposition
        : CASE_STATUS.FROM_UDESIGN

      if (input.assigned_to && input.assigned_to != 0 && input.user) {
        this.assignedTo = input.assigned_to
        this.user = {
          id: input.user.id,
          firstName: input.user.first_name,
          lastName: input.user.last_name,
          preference: input.user.preference,
        }
      }
      if (input.patient_id && input.patient) {
        this.patientId = input.patient_id
        this.firstName = input.patient.first_name
        this.lastName = input.patient.last_name
        this.status = input.patient.status
        this.dob = input.patient.dob
      }
      if (input.orders && input.orders.length > 0) {
        this.order = {
          id: input.orders[0].id,
          statusId: input.orders[0].status_id,
          statusName: input.orders[0].status_name.type_name,
          trackingNumber: input.orders[0].tracking_no,
        }
      }
    }
    return this
  }
}
export class PatientData implements INewPatientData {
  id = ""
  firstName = ""
  lastName = ""
  email = ""
  dob = ""
  notes = ""
  country = ""
  phone = ""
  status = ""
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Deserialize(input: any): this {
    if (input) {
      if (input.id) {
        this.id = input.id
      }
      this.firstName = input.first_name
      this.lastName = input.last_name
      this.email = input.email
      this.dob = input.patient_dob
      this.notes = input.ref_notes
      this.country = input.country
      this.phone = input.phone
      this.status = input.status
    }
    return this
  }
}

export class ExistingPatientList implements IExistingPatientList, Deserialize {
  id: string
  firstName: string
  lastName: string
  orgId: string

  Deserialize(input: any): this {
    if (input) {
      this.id = input.id
      this.firstName = input.first_name
      this.lastName = input.last_name
      this.orgId = input.org_id
    }
    return this
  }
}
