import { useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import EditFilled from "@mui/icons-material/Edit"
import ExpandMoreFilled from "@mui/icons-material/ExpandMore"
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material"

import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import EditPatientInfoForm from "@/modules/Patient/Overview/components/EditPatientInfoForm"
import CaseStatusAction from "@/modules/Patient/Overview/components/CaseStatusAction"

const EditUser = ({ open, handleClose, userName }) => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: "#E4E8EA" }}>
        <Typography variant="h6">{userName}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <EditPatientInfoForm
          patientData={patientData}
          handleClose={handleClose}
        ></EditPatientInfoForm>
      </DialogContent>
      <DialogActions sx={{ background: "#E4E8EA" }}>
        <Button onClick={handleClose} size="large">
          CANCEL
        </Button>
        <Button variant="contained" type="submit" form="userinfo" size="large">
          SAVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export const PatientNameID = ({
  firstName,
  lastName,
  caseId,
  planName = "Initial Plan",
  creatDate = "1/8/24",
}) => {
  const [hoverAvatar, sethoverAvatar] = useState(false)
  const [openEditUser, setopenEditUser] = useState(false)
  return (
    <Box sx={{ display: "flex", justifyItems: "center", alignItems: "center" }}>
      <Avatar
        variant="circular"
        sx={{
          bgcolor: "#FFF",
          width: 72,
          height: 72,
          fontSize: "32px",
          color: "rgba(0, 0, 0, 0.60)",
          fontweight: "500",
          position: "relative",
        }}
        onMouseOver={() => {
          sethoverAvatar(true)
        }}
        onMouseLeave={() => {
          sethoverAvatar(false)
        }}
      >
        {firstName.substring(0, 1) + lastName.substring(0, 1)}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={hoverAvatar}
          onClick={() => {
            setopenEditUser(true)
          }}
        >
          <IconButton size="medium">
            <EditFilled sx={{ color: "#FFF" }}></EditFilled>
          </IconButton>
        </Backdrop>
      </Avatar>

      <Box
        sx={{
          marginLeft: "12px",
          height: "54px",
        }}
      >
        <Tooltip
          arrow
          title="Notes: Patient referred by Dr. Newey. This is a long tooltip to show what it would look like if the user enters a lot of text regarding the patient"
          placement="right"
          followCursor
        >
          <Typography
            variant="caption"
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "0.46px",
              textTransform: "uppercase",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {firstName + " " + lastName}
          </Typography>
        </Tooltip>

        <Box>
          <Typography
            variant="caption"
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "0.46px",
              textTransform: "uppercase",
            }}
          >
            {planName + ` #` + caseId + " - " + creatDate}
          </Typography>
          <IconButton aria-label="more" sx={{ width: 28, height: 28 }}>
            <ExpandMoreFilled sx={{ color: "rgba(255, 255, 255, 1)" }} />
          </IconButton>
        </Box>
      </Box>
      <EditUser
        open={openEditUser}
        handleClose={() => setopenEditUser(false)}
        userName={firstName + " " + lastName}
      ></EditUser>
    </Box>
  )
}
