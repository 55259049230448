import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"

import doubleExclamationMarkIcon from "../../../../assets/images/Double-Exclamation-Mark.png"
import rightArrowIcon from "../../../../assets/images/Right-Arrow.png"
import { UButton, UText } from "../../../../components"
import {
  Alert,
  Box,
  Link,
  List,
  ListItem,
} from "../../../../components/mui.components"

const RefinementNote: FC = () => {
  const { t } = useTranslation()
  const [importantNotes, setImportantNotes] = useState([])
  const [refinementNotes, setRefinementNotes] = useState([])
  const [openLearnMore, setOpenLearnMore] = useState(false)

  const generateAlertPoints = (value: string, number: number) => {
    const notes: { id: string; label: string }[] = []
    for (const index of Array(number).keys()) {
      notes.push({
        id: index.toString(),
        label: t(`bundle.alert.${value}.${index}`),
      })
    }
    return notes
  }

  useEffect(() => {
    setImportantNotes(generateAlertPoints("importantNotes", 4))
    setRefinementNotes(generateAlertPoints("refinementNotes", 3))
  }, [])

  return (
    <Alert
      sx={{
        ".MuiAlert-message": {
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        },
        ".MuiAlert-icon": {
          color: "#29B6F6",
        },
        mb: 2,
        border: "1px solid #29B6F6",
      }}
      severity={"info"}
      variant={"outlined"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mb: "4px",
        }}
      >
        <UText
          sxProp={{
            fontWeight: 700,
            color: "#014361",
          }}
          variant={"body2"}
        >
          {t("bundle.alert.newPatient.title")}
        </UText>
        <UText
          sxProp={{
            color: "#014361",
          }}
          variant={"body2"}
        >
          {t("bundle.alert.newPatient.content")}
        </UText>
        <br />
        <UText
          sxProp={{
            fontWeight: 700,
            color: "#014361",
          }}
          variant={"body2"}
        >
          <Trans
            components={{
              icon: (
                <img
                  alt="exclamation-icon"
                  style={{
                    marginLeft: "3px",
                    verticalAlign: "middle",
                    marginBottom: "2px",
                  }}
                  width={"14px"}
                  height={"14px"}
                  src={doubleExclamationMarkIcon}
                />
              ),
            }}
          >
            {t("bundle.alert.refinement.title")}
          </Trans>
        </UText>
        <UText
          sxProp={{
            color: "#014361",
          }}
          variant={"body2"}
        >
          <Trans
            components={{
              rightArrowIcon: (
                <img
                  alt="right-arrow"
                  style={{
                    verticalAlign: "middle",
                    marginBottom: "2px",
                  }}
                  width={"14px"}
                  height={"14px"}
                  src={rightArrowIcon}
                />
              ),
            }}
          >
            {t("bundle.alert.refinement.content1")}
          </Trans>
        </UText>
        <UText
          sxProp={{
            color: "#014361",
          }}
          variant={"body2"}
        >
          {t("bundle.alert.refinement.content2")}
        </UText>
        <List
          disablePadding
          sx={{
            listStyleType: "disc",
            listStylePosition: "outside",
            pl: "22px",
          }}
        >
          {refinementNotes?.map((note) => {
            return (
              <ListItem
                key={note?.id}
                sx={{
                  display: "list-item",
                  p: 0,
                }}
              >
                <UText
                  sxProp={{
                    color: "#014361",
                  }}
                  variant={"body2"}
                >
                  <Trans
                    components={{
                      text: (
                        <span
                          style={{
                            color: "#0288D1",
                          }}
                        ></span>
                      ),
                      VideoLink: (
                        <Link
                          href={
                            "https://vimeo.com/896982546/b35cb703c3?share=copy"
                          }
                          sx={{
                            color: "info.main",
                          }}
                          target={"_blank"}
                        />
                      ),
                      NewTabIcon: (
                        <OpenInNewIcon
                          sx={{
                            width: "20px",
                            height: "20px",
                            verticalAlign: "sub",
                          }}
                        />
                      ),
                    }}
                  >
                    {note?.label}
                  </Trans>
                </UText>
              </ListItem>
            )
          })}
        </List>
        <UText
          sxProp={{
            color: "#014361",
          }}
          variant={"body2"}
        >
          {t("bundle.alert.refinement.content3")}
        </UText>
      </Box>
      <Box sx={{ mb: "4px" }}>
        <UButton
          sxProp={{
            fontSize: "13px",
            lineHeight: "169.231%",
            letterSpacing: "0.46px",
            height: "30px",
            color: "primary.main",
          }}
          size={"small"}
          variant={"text"}
          btnText={t("bundle.alert.learnMoreBtn")}
          onClickHandler={() => {
            setOpenLearnMore(!openLearnMore)
          }}
          endIcon={openLearnMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        />
      </Box>
      <Box
        sx={{
          transition:
            "height 0.4s ease-out, opacity 0.4s ease-in, visibility 0.4s ease-in",
          opacity: openLearnMore ? 1 : 0,
          visibility: openLearnMore ? "visible" : "hidden",
          height: openLearnMore ? "100%" : 0,
        }}
      >
        <UText
          sxProp={{
            fontWeight: 700,
          }}
          variant={"body2"}
        >
          {t("bundle.alert.importantTitle")}
        </UText>
        <List
          disablePadding
          sx={{
            listStyleType: "disc",
            listStylePosition: "outside",
            pl: "22px",
          }}
        >
          {importantNotes?.map((note) => {
            return (
              <ListItem
                key={note?.id}
                sx={{
                  display: "list-item",
                  p: 0,
                }}
              >
                <UText
                  sxProp={{
                    color: "#014361",
                  }}
                  variant={"body2"}
                >
                  {note?.label}
                </UText>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Alert>
  )
}

export default RefinementNote
