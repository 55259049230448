import {
  OrderQueryparams,
  OrgQueryparams,
  PatientQueryparams,
} from "../../../../core/app/slices/patients/patient.types"

export const defaultQuryParams: PatientQueryparams = {
  page_no: 1,
  per_page: 20,
  sort_field: "case_disposition",
  sort_type: "desc",
  action: "filter",
}

export const defaultOrderParams: OrderQueryparams = {
  page: 1,
  page_size: 10,
  sort_type: "desc",
  sort_by: "status",
  search_key: "",
}

export const defaultOrgParams: OrgQueryparams = {
  page: 1,
  page_size: 10,
  sort_type: "desc",
  sort_by: "id",
}

export const QUERY_PARAMS_STORAGE_KEY = "patientListQueryParams"
export const QUERY_PARAMS_STORAGE_KEY_ORDER = "orderListQueryParams"

export const ORDERED_STATUS = {
  DELIVERED: "DELIVERED",
  SHIPPED: "SHIPPED",
}

const commonStyles = {
  fontWeight: "bold !important",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}

export const tableData = {
  columnDef: [
    {
      name: "Last Name",
      id: "last_name",
      dataKey: "lastName",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 160,
        },
      },
      visible: true,
    },
    {
      name: "First Name",
      id: "first_name",
      dataKey: "firstName",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 129,
        },
      },
      visible: true,
    },
    {
      name: "Assigned To",
      id: "assigned_to",
      dataKey: "assignedTo",
      visible: true,
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 247,
        },
      },
    },
    {
      name: "Status",
      id: "case_disposition",
      sorty_by: "case_disposition",
      dataKey: "status",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 260,
        },
      },
      visible: true,
    },
    {
      name: "Status Updated",
      id: "update_date",
      dataKey: "updatedDate",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 200,
        },
      },
      visible: true,
    },
    {
      name: "Case #",
      id: "id",
      dataKey: "case",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 112,
        },
      },
      visible: true,
    },
  ],
}

export const orderTableData = {
  columnDef: [
    {
      name: "Order #",
      id: "order_no_2",
      dataKey: "orderNumber",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 159,
        },
      },
      visible: true,
    },
    {
      name: "Last",
      id: "case__patient_last_name",
      dataKey: "last",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 160,
        },
      },
      visible: true,
    },
    {
      name: "First",
      id: "case__patient_first_name",
      dataKey: "first",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 129,
        },
      },
      visible: true,
    },
    {
      name: "Order Date",
      id: "order_date",
      dataKey: "orderDate",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 139,
        },
      },
      visible: true,
    },
    {
      name: "Order Details",
      id: "orderDetails",
      dataKey: "orderDetails",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 159,
        },
      },
      visible: true,
    },
    {
      name: "Order Type",
      id: "pricing_option",
      dataKey: "pricingOption",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 160,
        },
      },
      visible: true,
    },
    // {
    //   name: "uAssist",
    //   id: "uAssist",
    //   dataKey: "uAssist",
    //   cell: {
    //     style: {
    //       ...commonStyles,
    //       maxWidth: 100,
    //     },
    //   },
    //   visible: true,
    // },
    {
      name: "Status",
      id: "status",
      dataKey: "orderShippingStatus",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 260,
        },
      },
      visible: true,
    },
    {
      name: "Shipping",
      id: "shipping_method_id",
      dataKey: "shipmentTrackingNo",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 129,
        },
      },
      visible: true,
    },
  ],
}

export const orgTableData = {
  columnDef: [
    {
      name: "Org Id",
      id: "org_id",
      dataKey: "org_id",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 160,
        },
      },
      visible: true,
    },
    {
      name: "Name",
      id: "name",
      dataKey: "name",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 260,
        },
      },
      visible: true,
    },
    {
      name: "Account Owner",
      id: "account_owner",
      dataKey: "account_owner",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 260,
        },
      },
      visible: true,
    },
    {
      name: "Organization Type",
      id: "org_type",
      dataKey: "org_type",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 200,
        },
      },
      visible: true,
    },
    {
      name: "Patient List",
      id: "patient_list",
      dataKey: "patient_list",
      cell: {
        style: {
          ...commonStyles,
          maxWidth: 200,
        },
      },
      visible: true,
    },
  ],
}

export const STLTable = {
  columnDef: [
    {
      name: "Date",
      id: "date",
      dataKey: "date",
    },
    {
      name: "Description",
      id: "desc",
      dataKey: "desc",
    },
    {
      name: "Units",
      id: "qty",
      dataKey: "qty",
    },
    {
      name: "Price",
      id: "total",
      dataKey: "total",
    },
  ],
}

export const UsmileOrderTable = {
  columnDef: [
    {
      name: "Order number",
      id: "order_number",
      dataKey: "order_number",
    },
    {
      name: "Date",
      id: "date",
      dataKey: "date",
    },
    {
      name: "Description",
      id: "desc",
      dataKey: "desc",
    },
    {
      name: "Price",
      id: "price",
      dataKey: "price",
    },
    {
      name: "Units",
      id: "qty",
      dataKey: "qty",
    },
    {
      name: "Total",
      id: "total",
      dataKey: "total",
      isCurrency: true,
    },
  ],
}

export const ShippingTable = {
  columnDef: [
    {
      name: "Order",
      id: "order_number",
      dataKey: "order_number",
    },
    {
      name: "Date",
      id: "date",
      dataKey: "date",
    },
    {
      name: "Description",
      id: "desc",
      dataKey: "desc",
    },
    {
      name: "Total",
      id: "total",
      dataKey: "total",
      isCurrency: true,
    },
  ],
}

export const CreditTable = {
  columnDef: [
    {
      name: "Order",
      id: "order_id",
      dataKey: "order_id",
    },
    {
      name: "Name",
      id: "name",
      dataKey: "name",
    },
    {
      name: "Description",
      id: "desc",
      dataKey: "desc",
    },
    {
      name: "Total",
      id: "total",
      dataKey: "total",
      isCurrency: true,
    },
  ],
}
