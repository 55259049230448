import { ISort } from "../PatientList/components/UTable/table"
interface ITableConfig {
  columndef: { [key: string]: unknown }[]
  defaultSort: ISort
  tableData?: any //Temporary
  title: string
}

export const CASE_OVERVIEW_TABLE: ITableConfig = {
  title: "caseOverviewTitle",
  columndef: [
    {
      name: "Plan",
      id: "casePlan",
      dataKey: "casePlan",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 260,
        },
      },
      visible: true,
    },
    {
      name: "Remaining Aligners To Order",
      id: "alignersToOrder",
      dataKey: "alignersToOrder",
      cell: {
        style: {
          fontWeight: "bold !important",
          width: 264,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
    {
      name: "Created",
      id: "createDate",
      dataKey: "createDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          width: 140,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
    {
      name: "Status",
      id: "caseStatus",
      dataKey: "caseStatus",
      cell: {
        style: {
          fontWeight: "bold !important",
          width: 200,
          textOverflow: "ellipsis",
          overflow: "hidden",
        },
      },
      visible: true,
    },
  ],
  defaultSort: { direction: "desc", key: "created" } as {
    key: string
    direction: "asc" | "desc"
  },
  tableData: [
    {
      casePlan: "Plan 1",
      alignersToOrder: [
        {
          colorCode: "info.light",
          title: "Upper",
          prefix: { label: "Aligners", total: 15, completed: 5 },
          suffix: { label: "Templates", total: 1, completed: 0 },
        },
        {
          colorCode: "info.light",
          title: "Lower",
          prefix: { label: "Aligners", total: 15, completed: 5 },
          suffix: { label: "Templates", total: 1, completed: 0 },
        },
      ],
      created: "4/21/2023",
      caseStatus: {
        status: {
          key: "REVIEW_PLAN",
          displayText: "Review Plan",
          style: {
            color: "rgba(255, 255, 255, 1)",
            backgroundColor: "success.main",
            variant: "filled",
          },
        },
        date: "4/21/2023",
      },
    },
  ],
}

export const BUNDLE_OVERVIEW_TABLE: ITableConfig = {
  title: "bundlesOverviewTitle",
  columndef: [
    {
      name: "Bundle Type",
      id: "bundleType",
      dataKey: "bundleType",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 260,
        },
      },
      visible: true,
    },
    {
      name: "Remaining Bundle Allotment",
      id: "remainingBundleAllotment",
      dataKey: "remainingBundleAllotment",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 264,
        },
      },
      visible: true,
    },
    {
      name: "Purchased",
      id: "purchaseDate",
      dataKey: "purchaseDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Expires",
      id: "expiryDate",
      dataKey: "expiryDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: "99%",
        },
      },
      visible: true,
    },
  ],
  defaultSort: { direction: "desc", key: "purchaseDate" } as {
    key: string
    direction: "asc" | "desc"
  },
  tableData: [
    {
      bundleType: "Mild Bundle",
      remainingBundleAllotment: [
        {
          colorCode: "secondary.main",
          prefix: { label: "Aligners", total: 24, completed: 4 },
        },
        {
          colorCode: "secondary.main",
          prefix: { label: "Templates", total: 2, completed: 0 },
        },
        {
          colorCode: "secondary.main",
          prefix: { label: "Retainers", total: 2, completed: 2 },
        },
      ],
      purchaseDate: "4/22/2023",
      expiryDate: "4/22/2026",
    },
  ],
}

export const ORDER_OVERVIEW_TABLE: ITableConfig = {
  title: "ordersOverviewTitle",
  columndef: [
    {
      name: "Treatment Plan",
      id: "treatmentPlan",
      dataKey: "treatmentPlan",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 260,
        },
      },
      visible: true,
    },
    {
      name: "Order",
      id: "order",
      dataKey: "order",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 264,
        },
      },
      visible: true,
    },
    {
      name: "Order Type",
      id: "orderType",
      dataKey: "orderType",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Order Date",
      id: "orderDate",
      dataKey: "orderDate",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 140,
        },
      },
      visible: true,
    },
    {
      name: "Status",
      id: "orderStatus",
      dataKey: "orderStatus",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 200,
        },
      },
      visible: true,
    },
    {
      name: "Order #",
      id: "orderId",
      dataKey: "orderId",
      cell: {
        style: {
          fontWeight: "bold !important",
          textOverflow: "ellipsis",
          overflow: "hidden",
          width: 115,
        },
      },
      visible: true,
    },
  ],
  defaultSort: { direction: "desc", key: "purchaseDate" } as {
    key: string
    direction: "asc" | "desc"
  },
}
