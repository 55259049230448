import { FC, useEffect } from "react"
import { Outlet, useLocation, useParams } from "react-router-dom"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import UploadSharpIcon from "@mui/icons-material/UploadSharp"

import { Box, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setBannerArray } from "../../../core/app/slices/banners/bannerSlice"
import { fetchBannerData } from "../../../core/app/slices/banners/bannerThunkApi"
import { getBundleByCaseId } from "../../../core/app/slices/bundle/bundleThunkApi"
import { getCaseByPatient } from "../../../core/app/slices/case"
import { updateCaseStatus } from "../../../core/app/slices/case/caseThunkApi"
import { getOrdersByCaseId } from "../../../core/app/slices/order/orderThunkApi"
import { fetchFilesList } from "../../../core/app/slices/records/photograph/photographThunkApi"
import { resetProfilePhoto } from "../../../core/app/slices/records/xrays/xraysSlice"
import { downloadProfilePhoto } from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"
import { checkIfObjectHasKeys } from "../../../core/utils/formatters"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import { CASE_STATUS } from "../../Patient/config/status.config"
import { getAttentionBannerButton, getInfoBanner } from "../Overview/banner"
import Banner from "../Overview/components/banner"

import EditHeader from "./components/EditPatientHeader"
import PatientInfoHeader from "./components/PatientInfoHeader"

const Overview: FC = () => {
  const { caseList } = useAppSelector((state: RootState) => state.caseService)
  const { fileList } = useAppSelector((state: RootState) => state.PhotosService)
  const { user } = useAppSelector((state: RootState) => state.userService)

  const { bannerData } = useAppSelector(
    (state: RootState) => state.bannerService,
  )
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useMyNavigation()
  const { patientId } = useParams()

  useEffect(() => {
    localStorage.removeItem("routeFrom")
  }, [dispatch])

  const caseId = caseList[0]?.id
  useEffect(() => {
    if (!caseId) return
    dispatch(fetchFilesList({ patientId, caseId }))
  }, [caseId])

  useEffect(() => {
    if (!fileList) return
    dispatch(resetProfilePhoto())
    const profileFileName = fileList.find(
      (file) => file.attachment_type === "avatar",
    )
    if (profileFileName?.file_name.includes(patientId)) {
      dispatch(
        downloadProfilePhoto({
          patientId: patientId,
          fileName: profileFileName?.file_name,
          orgId: user.current_orgId,
        }),
      )
    }
  }, [fileList])

  useEffect(() => {
    if (
      caseList?.length &&
      (caseList[0].caseDisposition === CASE_STATUS.ATTENTION_NEEDED ||
        caseList[0].caseDisposition ===
          CASE_STATUS.ATTENTION_NEEDED_REFINEMENT ||
        caseList[0].caseDisposition === CASE_STATUS.REJECTED)
    ) {
      dispatch(
        fetchBannerData({ patientId, caseId: caseList[0].id.toString() }),
      ).then((data) => {
        if (
          data?.payload?.warning_subject &&
          CASE_STATUS.REJECTED !== caseList[0].caseDisposition
        ) {
          dispatch(
            setBannerArray(
              getAttentionBannerButton(data.payload.warning_subject).routeTo,
            ),
          )
        }
      })
    }
  }, [dispatch, caseList])

  const caseDispositionValue = (caseStatus) => {
    if (
      caseStatus === CASE_STATUS.DELIVERED ||
      caseStatus === CASE_STATUS.SHIPPED
    ) {
      return CASE_STATUS.TREATMENT_IN_PROGRESS
    }
    return caseStatus === CASE_STATUS.TREATMENT_IN_PROGRESS
      ? CASE_STATUS.TREATMENT_COMPLETE
      : ""
  }

  const handleBannerRouter = (severity: string) => {
    const caseStatus = getInfoBanner(caseList[0].caseDisposition)?.status
    if (severity === "info") {
      dispatch(
        updateCaseStatus({
          patientId: patientData.id,
          caseId: caseList[0].id.toString(),
          payload: {
            case_disposition: caseDispositionValue(caseStatus),
          },
        }),
      ).then((res) => {
        if (res && res.payload && res.payload.response_code === 200) {
          dispatch(getCaseByPatient({ patientId: patientData.id }))
          dispatch(getOrdersByCaseId(caseList[0].id))
          dispatch(getBundleByCaseId(caseList[0].id))
        }
      })
    } else {
      if (
        !getAttentionBannerButton(bannerData?.warning_subject).routeTo.length
      ) {
        navigate(`/clinical/patient/${patientData.id}/case/${caseId}/treat`)
      } else {
        navigate(
          `/overview/patient/${patientId}/case/${caseId}/attentionBanner`,
        )
      }
    }
  }

  return (
    <>
      <Grid
        container
        direction={"column"}
        spacing={0}
        justifyContent={"center"}
        sx={{ mt: 4 }}
      >
        <Grid item>
          {!location?.pathname.includes("/settings") ? (
            <PatientInfoHeader
              status={caseList[0]?.case_disposition}
              patientData={patientData}
              caseDetail={!!caseList[0] && caseList[0]}
            />
          ) : (
            <EditHeader />
          )}
        </Grid>
      </Grid>

      {!!(
        (caseList[0]?.caseDisposition === "DELIVERED" ||
          (caseList[0]?.caseDisposition === "SHIPPED" &&
            (caseList[0].isInitialuAssist || caseList[0].isRefinementReady)) ||
          caseList[0]?.caseDisposition === "TREATMENT_IN_PROGRESS") &&
        !location?.pathname.includes("/settings")
      ) && (
        <Box sx={{ mb: checkIfObjectHasKeys(bannerData) ? 2 : 0 }}>
          {getInfoBanner(caseList[0].caseDisposition) && (
            <Banner
              severity="info"
              buttonText={getInfoBanner(caseList[0].caseDisposition).buttonTxt}
              bannerTitle={getInfoBanner(caseList[0].caseDisposition).message}
              handleBannerRouter={() => handleBannerRouter("info")}
            />
          )}
        </Box>
      )}

      {!!(
        checkIfObjectHasKeys(bannerData) > 0 &&
        !location.pathname.includes(ROUTE_ATTENTION_BANNER.smartRxFormBanner) &&
        caseList?.length &&
        (caseList[0].caseDisposition === CASE_STATUS.ATTENTION_NEEDED ||
          caseList[0].caseDisposition ===
            CASE_STATUS.ATTENTION_NEEDED_REFINEMENT) &&
        getAttentionBannerButton(bannerData?.warning_subject)
      ) && (
        <Banner
          severity="warning"
          buttonText={
            getAttentionBannerButton(bannerData?.warning_subject).buttonTxt
          }
          buttonIcon={
            getAttentionBannerButton(bannerData?.warning_subject).buttonIcon ===
            "upload" ? (
              <UploadSharpIcon />
            ) : (
              <LaunchOutlinedIcon />
            )
          }
          bannerTitle={bannerData?.warning_subject}
          bannerContent={bannerData?.content}
          handleBannerRouter={() => handleBannerRouter("warning")}
          buttonWidth={
            getAttentionBannerButton(bannerData?.warning_subject)?.width
          }
          contentTitle={
            getAttentionBannerButton(bannerData?.warning_subject)?.contentTitle
          }
        />
      )}

      <Outlet />
    </>
  )
}

export default Overview
