import { FC, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { useLocation, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import CloseIcon from "@mui/icons-material/Close"
import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  View,
} from "@react-pdf/renderer"
import { caseManagement } from "@/gluelayer"
import html2canvas from "html2canvas"
import { PDFDocument } from "pdf-lib"

import { ULoading, UText } from "@/components/index"
import { Box, Button, Grid } from "@/components/mui.components"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { postMessageData } from "@/core/app/slices/banners/bannerThunkApi"
import { getCaseByPatient } from "@/core/app/slices/case/caseThunkApi"
import { resetAction } from "@/core/app/slices/clinical/clinicalSlice"
import { updateOrder } from "@/core/app/slices/order/orderThunkApi"
import { downloadDentalChart } from "@/core/app/slices/records/xrays/xraysThunkApi"
import {
  fetchFormByCaseIdWithMergeData,
  saveUassistSummary,
} from "@/core/app/slices/uassist/formThunkApi"
import { RootState } from "@/core/app/store"
import { checkIfObjectHasKeys, parseToObject } from "@/core/utils/formatters"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "@/routes/status.route.config"
import JsonFormBuilder from "@/rx-form/builder/JsonFormBuilder"
import {
  generateRfSummaryData,
  generateRfSummarySchema,
  generateRfSummaryUiSchema,
  generateSummaryData,
  generateSummarySchema,
  generateSummaryUiSchema,
} from "@/rx-form/Json/Summary"

import { NoUassistPopup } from "./NoUassistPopup"

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    opacity: 1,
  },
})

const addPagesToMergedPDF = async (mergedPdfDoc, pdfDoc, pageIndices) => {
  for (const index of pageIndices) {
    const [pdfPage] = await mergedPdfDoc.copyPages(pdfDoc, [index])
    mergedPdfDoc.addPage(pdfPage)
  }
}

const exportComponentAsPDF = (
  componentRef: React.RefObject<HTMLDivElement>,
  dispatch: any,
  location: any,
  archesToTreat: any,
  t: any,
  navigate: any,
  dentalChart: Blob,
  payload: {
    patientId: string
    caseId: string
  },
  isRefinement: boolean,
  isRetainer: boolean,
  rxJsonForm: any,
  setIsPdfLoading: (o: boolean) => void,
) => {
  if (!componentRef.current) {
    console.warn("the component which is exported to PDF is null !")
    setIsPdfLoading(false)
    return
  }

  // Now you have the Blob object, and you can use it as needed.
  const width = componentRef.current?.clientWidth
  const height = componentRef.current?.clientHeight

  const pages = Math.ceil(height / 792) // 792 is the height of a standard letter-sized page in points (11 inches).
  const imgPromises = []

  for (let i = 0; i < pages; i++) {
    const startY = i * 792
    const endY = Math.min((i + 1) * 792, height)

    const imgPromise = html2canvas(componentRef.current, {
      y: startY,
      height: endY - startY,
    }).then((canvas) => {
      return canvas.toDataURL("image/png")
    })

    imgPromises.push(imgPromise)
  }

  Promise.all(imgPromises).then((imgDataArray) => {
    const MakePDFComponent = () => (
      <Document>
        {imgDataArray.map((imgData, i) => (
          <Page key={i} style={styles.page} size="letter">
            <View>
              <Image
                style={{ width, height: "auto" }}
                source={imgData}
                cache={true}
              />
            </View>
          </Page>
        ))}
      </Document>
    )

    pdf(MakePDFComponent())
      .toBlob()
      .then(async (blob) => {
        if (isRefinement) {
          // const link = document.createElement("a")
          // link.href = URL.createObjectURL(blob)
          // link.download = "refinement.pdf"
          // link.click()
          const formData = new FormData()
          formData.append(
            "attachment",
            new File([blob], `refinement.pdf`, {
              type: "application/pdf",
            }),
          )
          formData.append("is_refinement", "1")
          dispatch(
            saveUassistSummary({
              patientId: payload.patientId,
              caseId: payload.caseId,
              payload: formData,
              isRefinement,
            }),
          ).then((res: any) => {
            const errorMessage = t("uassist.uAssistForm.uassistSubmitFailed")
            setIsPdfLoading(false)
            if (res.payload.status === "Success") {
              navigate(
                `/overview/patient/${payload.patientId}/case/${payload.caseId}/refinement/confirmation`,
              )
            } else {
              dispatch(
                setAlert({
                  message: errorMessage,
                  isError: true,
                }),
              )
            }
          })
        } else {
          const pdfDoc1 = await PDFDocument.load(await blob.arrayBuffer())
          const pdfDoc2 = await PDFDocument.load(
            await dentalChart.arrayBuffer(),
          )

          const mergedPdfDoc = await PDFDocument.create()

          const pageIndices1 = Array.from(
            { length: pdfDoc1.getPageCount() },
            (_, i) => i,
          )
          const pageIndices2 = Array.from(
            { length: pdfDoc2.getPageCount() },
            (_, i) => i,
          )

          // Copy and add pages from pdfDoc1 and pdfDoc2
          await addPagesToMergedPDF(mergedPdfDoc, pdfDoc1, pageIndices1)
          await addPagesToMergedPDF(mergedPdfDoc, pdfDoc2, pageIndices2)
          // Save the merged PDF
          const mergedPdfBlob = new Blob([await mergedPdfDoc.save()], {
            type: "application/pdf",
          })

          // Use this block to download summary to file manager
          // const link = document.createElement("a")
          // link.href = URL.createObjectURL(mergedPdfBlob)
          // link.download = "summary.pdf"
          // link.click()

          // Create form data and upload to api
          let pdfName = ""
          isRetainer
            ? (pdfName = "retainer-summary.pdf")
            : (pdfName = "summary.pdf")
          const formData = new FormData()
          formData.append(
            "attachment",
            new File([mergedPdfBlob], pdfName, {
              type: "application/pdf",
            }),
          )
          dispatch(
            saveUassistSummary({
              patientId: payload.patientId,
              caseId: payload.caseId,
              payload: formData,
              isRefinement,
              isRetainer,
            }),
          ).then((res: any) => {
            setIsPdfLoading(false)
            const errorMessage = t("uassist.uAssistForm.uassistSubmitFailed")
            if (res.payload.status === "Success") {
              if (
                location.pathname.includes(
                  `${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
                ) ||
                location.pathname.includes(
                  `${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
                )
              ) {
                dispatch(
                  postMessageData({
                    patientId: payload.patientId,
                    caseId: payload.caseId.toString(),
                    payload: {
                      message: "Rx Form updated",
                    },
                  }),
                ).then((res) => {
                  if (res && res.payload && res.payload.status === "Success") {
                    dispatch(getCaseByPatient({ patientId: payload.patientId }))
                    navigate(`/overview/patient/${payload.patientId}/`)
                  }
                })
              } else {
                let upper = ""
                let lower = ""
                let bracket_removal = ""

                if (isRetainer) {
                  lower = rxJsonForm.data.arches_to_treat.includes("lower")
                    ? "R"
                    : ""
                  upper = rxJsonForm.data.arches_to_treat.includes("upper")
                    ? "R"
                    : ""
                  bracket_removal =
                    rxJsonForm.data.bracket_removal?.value?.includes("yes")
                } else {
                  upper = archesToTreat.upper
                    ? archesToTreat.upper.aligner_type === "Aligner"
                      ? "A"
                      : "R"
                    : ""

                  lower = archesToTreat.lower
                    ? archesToTreat.lower.aligner_type === "Aligner"
                      ? "A"
                      : "R"
                    : ""
                }

                dispatch(
                  updateOrder({
                    caseId: payload.caseId,
                    type: isRetainer
                      ? bracket_removal
                        ? "Bracket Removal Uassist"
                        : "Retainer Uassist"
                      : "uAssist",
                    upper: upper,
                    lower: lower,
                  }),
                ).then((result: any) => {
                  setIsPdfLoading(false)
                  if (result.payload.status === "Success") {
                    navigate(
                      `/uassist/patient/${payload.patientId}/case/${payload.caseId}/summary/confirmation`,
                    )
                  } else {
                    dispatch(
                      setAlert({
                        message: errorMessage,
                        isError: true,
                      }),
                    )
                  }
                })
              }
            } else {
              setIsPdfLoading(false)
              dispatch(
                setAlert({
                  message: errorMessage,
                  isError: true,
                }),
              )
            }
          })
        }
      })
  })
}

const Summary: FC<{
  isPreview?: boolean
  hideSummary?: () => void
  isRefinement?: boolean
  isRetainer?: boolean
}> = ({ isPreview, hideSummary, isRefinement, isRetainer }) => {
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [uiSchema, setUiSchema] = useState({})
  const [showNoUassistAlert, setShowUNoUassistAlert] = useState<boolean>(false)
  const { caseId, patientId } = useParams()
  const { rxJsonForm, rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { clinicalSettings } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { dentalChart } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  const { VITE_PRESCRIPTION_ID, VITE_REFINEMENT, VITE_RETAINER } = import.meta
    .env
  const contentToPDF = useRef(null)
  const [isPdfLoading, setIsPdfLoading] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0 })
    setUiSchema({})
    if (isRetainer && rxJsonForm?.id === VITE_RETAINER) {
      dispatch(downloadDentalChart({ patientId, caseId }))
      return
    }
    dispatch(
      fetchFormByCaseIdWithMergeData({
        formId: isRefinement
          ? VITE_REFINEMENT
          : isRetainer
          ? VITE_RETAINER
          : VITE_PRESCRIPTION_ID,
        caseId: caseId,
        getSavedData: true,
      }),
    ).then((res: any) => {
      if (isRefinement || isRetainer) return
      const tempFormId = parseToObject(res.payload.default_data)
        .smart_rx_template_id.id
      if (tempFormId) {
        dispatch(
          fetchFormByCaseIdWithMergeData({
            formId: tempFormId,
            caseId: caseId,
            getSavedData: true,
          }),
        )
      }
    })
    if (!isRefinement) dispatch(downloadDentalChart({ patientId, caseId }))
  }, [])

  const patientDetail = [
    {
      label: "Patient Name",
      value: patientData?.firstName + " " + patientData?.lastName,
    },
    {
      label: "Patient Id",
      value: patientId,
    },
    {
      label: "Case Id",
      value: caseId,
    },
  ]

  // useEffect(() => {
  //   if (!formSubmitted) return
  //   postSettingTozips()
  // }, [formSubmitted])

  useEffect(() => {
    if (checkIfObjectHasKeys(rxJsonForm.uischema)) {
      const uiSchema = {
        ...rxJsonForm.uischema,
        elements: [...rxJsonForm.uischema.elements].filter(
          (element: any) => element.label !== "Additional Instructions",
        ),
      }
      setUiSchema({ ...uiSchema })
    }
  }, [rxJsonForm])

  useEffect(() => {
    if (
      (isRefinement || isRetainer) &&
      checkIfObjectHasKeys(rxJsonForm.data) &&
      checkIfObjectHasKeys(uiSchema)
    ) {
      setTimeout(() => {
        exportComponentAsPDF(
          contentToPDF,
          dispatch,
          location,
          rxPrescription.data.arches_to_treat,
          t,
          navigate,
          dentalChart,
          {
            patientId: patientId,
            caseId: caseId,
          },
          isRefinement,
          isRetainer,
          rxJsonForm,
          setIsPdfLoading,
        )
      }, 1500)
    }
  }, [uiSchema])

  const navigateBack = () => {
    if (
      location.pathname.includes(
        `${ROUTE_ATTENTION_BANNER.attentionBanner}/SummaryView`,
      )
    ) {
      navigate(
        `/overview/patient/${patientId}/case/${caseId}${ROUTE_ATTENTION_BANNER.attentionBanner}/RxForm`,
      )
    } else {
      navigate(`/uassist/patient/${patientId}/case/${caseId}/prescription`)
    }
  }

  const postSettingTozips = () => {
    caseManagement.saveJsonDataToZip(
      JSON.stringify(clinicalSettings),
      "uassist_team_settings.json",
      "Setting Data2",
    )
  }

  useEffect(() => {
    if (isPdfLoading) {
      if (caseDetail && caseDetail.uassist_progress === "NO_UASSIST") {
        setShowUNoUassistAlert(true)
      } else {
        exportComponentAsPDF(
          contentToPDF,
          dispatch,
          location,
          rxPrescription.data.arches_to_treat,
          t,
          navigate,
          dentalChart,
          {
            patientId: patientId,
            caseId: caseId,
          },
          isRefinement,
          isRetainer,
          rxJsonForm,
          setIsPdfLoading,
        )
      }
    }
  }, [isPdfLoading])

  return (
    <>
      <ULoading isLoading={isPdfLoading && !showNoUassistAlert} />
      <NoUassistPopup
        showPopup={showNoUassistAlert}
        setShowPopup={setShowUNoUassistAlert}
        setIsPdfLoading={setIsPdfLoading}
      />
      <Grid
        container
        flexDirection={"column"}
        sx={{
          backgroundColor: "background.default",
          borderRadius: 2,
          minHeight: "615px",
          padding: isPreview ? "0 40px 40px" : 5,
          justifyContent: !isPreview && "space-between",
        }}
      >
        {isPreview && (
          <Box
            component={"div"}
            sx={{
              display: "flex",
              justifyContent: "end",
              height: "64px",
              position: "sticky",
              top: 0,
              alignItems: "center",
              zIndex: 1000,
              backgroundColor: "white",
              marginRight: "-24px",
            }}
          >
            <CloseIcon
              fontSize={"medium"}
              sx={{
                color: "rgba(0, 0, 0, 0.56)",
                fontSize: "24px",
                cursor: "pointer",
              }}
              onClick={hideSummary}
            />
          </Box>
        )}
        <Box component={"div"}>
          <UText
            variant={"h4"}
            component={"div"}
            sxProp={{ textAlign: "center" }}
          >
            <Trans components={{ newLine: <br /> }}>
              {isRefinement
                ? "uassist.refinement.title"
                : isRetainer
                ? "uassist.retainer.title"
                : "uassist.summary.title"}
            </Trans>
          </UText>
          {!isPreview && (
            <Box sx={{ height: "24px" }}>
              <UText
                component={"div"}
                variant={"body2"}
                sxProp={{ textAlign: "center", color: "rgb(0,0,0,0.6)" }}
              >
                {t("uassist.summary.summarydesc")}
              </UText>
            </Box>
          )}
        </Box>
        {!!(
          checkIfObjectHasKeys(rxJsonForm.schema) &&
          checkIfObjectHasKeys(uiSchema) &&
          checkIfObjectHasKeys(rxJsonForm.data) &&
          (isRefinement ||
            isRetainer ||
            (checkIfObjectHasKeys(rxPrescription.schema) &&
              checkIfObjectHasKeys(rxPrescription.uischema) &&
              checkIfObjectHasKeys(rxPrescription.data)))
        ) && (
          <Box sx={{ pt: "4px", mt: 1 }}>
            <JsonFormBuilder
              schema={
                isRefinement || isRetainer
                  ? generateRfSummarySchema(rxJsonForm.schema)
                  : generateSummarySchema(
                      rxPrescription.schema,
                      rxJsonForm.schema,
                    )
              }
              uischema={
                isRefinement || isRetainer
                  ? generateRfSummaryUiSchema(rxJsonForm.uischema)
                  : generateSummaryUiSchema(
                      rxPrescription.uischema,
                      rxPrescription.data,
                      uiSchema,
                      rxJsonForm.data,
                      false,
                    )
              }
              defaultData={
                isRefinement || isRetainer
                  ? generateRfSummaryData({ ...rxJsonForm.data })
                  : generateSummaryData({
                      ...rxPrescription.data,
                      ...rxJsonForm.data,
                    })
              }
            />
          </Box>
        )}
        {!isPreview && (
          <Box
            component={"div"}
            sx={{
              justifyContent: "space-between",
              display: "flex",
              mt: "28px",
            }}
          >
            <Button
              variant="outlined"
              onClick={navigateBack}
              sx={{ width: "96px", height: "36px" }}
            >
              <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
              {t("button.back")}
            </Button>
            <Box>
              <Button
                variant={"text"}
                onClick={() =>
                  dispatch(
                    setAlert({
                      message: t("uassist.uAssistForm.uassistCaseSaved"),
                    }),
                  )
                }
                sx={{
                  minWidth: "auto",
                  height: "auto",
                  width: "auto",
                  lineHeight: "24px",
                  marginRight: "16px",
                }}
              >
                {t("button.save")}
              </Button>
              <Button
                variant={"contained"}
                sx={{ width: "196px", height: "36px" }}
                onClick={() => {
                  setIsPdfLoading(true)
                }}
              >
                {t("button.submittoUassist")}
                <ChevronRightIcon
                  fontSize={"small"}
                  sx={{ height: "20px", width: "20px", marginLeft: "8px" }}
                />
              </Button>
            </Box>
          </Box>
        )}
      </Grid>
      {!isPreview && (
        <Grid>
          <Box
            component={"div"}
            ref={contentToPDF}
            sx={{ zIndex: -1, position: "fixed" }}
          >
            <Box component={"div"}>
              <UText
                variant={"h4"}
                component={"div"}
                sxProp={{ textAlign: "center" }}
              >
                <Trans components={{ newLine: <br /> }}>
                  {"uassist.summary.title"}
                </Trans>
              </UText>
            </Box>
            {patientDetail.map((data) => {
              return (
                <Box
                  sx={{
                    py: 1,
                    mb: "4px",
                    display: "flex",
                    flexDirection: "row",
                    pl: 1,
                  }}
                  key={data.label}
                >
                  <UText
                    variant={"body2"}
                    sxProp={{
                      width: 200,
                      color: "rgba(0, 0, 0, 0.60)",
                    }}
                  >
                    {data.label}
                  </UText>
                  <Box>
                    <UText
                      component={"div"}
                      variant={"subtitle2"}
                      sxProp={{
                        ml: 1,
                        lineHeight: "16px",
                        color: "text.primary",
                      }}
                    >
                      {data.value}
                    </UText>
                  </Box>
                </Box>
              )
            })}
            {!!(
              checkIfObjectHasKeys(rxJsonForm.schema) &&
              checkIfObjectHasKeys(uiSchema) &&
              checkIfObjectHasKeys(rxJsonForm.data) &&
              (isRefinement ||
                isRetainer ||
                (checkIfObjectHasKeys(rxPrescription.schema) &&
                  checkIfObjectHasKeys(rxPrescription.uischema) &&
                  checkIfObjectHasKeys(rxPrescription.data)))
            ) && (
              <Box sx={{ pt: "4px", mt: 1 }}>
                <JsonFormBuilder
                  schema={
                    isRefinement || isRetainer
                      ? generateRfSummarySchema(rxJsonForm.schema)
                      : generateSummarySchema(
                          rxPrescription.schema,
                          rxJsonForm.schema,
                        )
                  }
                  uischema={
                    isRefinement || isRetainer
                      ? generateRfSummaryUiSchema(rxJsonForm.uischema)
                      : generateSummaryUiSchema(
                          rxPrescription.uischema,
                          rxPrescription.data,
                          uiSchema,
                          rxJsonForm.data,
                          true,
                        )
                  }
                  defaultData={
                    isRefinement || isRetainer
                      ? generateRfSummaryData({ ...rxJsonForm.data })
                      : generateSummaryData({
                          ...rxPrescription.data,
                          ...rxJsonForm.data,
                        })
                  }
                />
              </Box>
            )}
          </Box>
        </Grid>
      )}
    </>
  )
}

export default Summary
