import { useEffect, useState, useRef } from "react"
import { useParams } from "react-router-dom"

import { Button, Divider, Stack, TextField, Typography } from "@mui/material"
import { Box, Toolbar, IconButton, Tooltip } from "@mui/material"
import SendIcon from "@mui/icons-material/SendOutlined"
import SendIconUAssist from "@mui/icons-material/SendToMobileOutlined"

import { RootState } from "@/core/app/store"
import { useAppDispatch, useAppSelector, useDeepEffect } from "@/core/app/hooks"
import moment from "moment-timezone"

import {
  clinicalRequestBasicType,
  messageBody,
} from "@/core/app/slices/clinical/clinical.types"
import {
  getCaseInfoApi,
  getMessageApi,
  postMessageApi,
  postModiApi,
  updateMessageApi,
} from "@/core/app/slices/clinical/clinicalThunkApi"

import { LeftPage } from "../left/LeftPage"
import { updateCurrentOrgId } from "@/core/app/slices/user/userSlice"

export const UDMessage = ({ onClose }) => {
  const scrollRef = useRef(null)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [holdplace, setHoldplace] = useState("")

  const { user_id, isCustomerSupport, my_orgId, current_orgId } =
    useAppSelector((state: RootState) => state.userService.user)

  const { messageList, historyList } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const getCurrentTimeByTimeZoom = (time) => {
    if (!time) return
    const current_time = moment
      .tz(time, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("MM/DD/YY hh:mm A")
    return current_time
  }

  const OneMessage = ({ messageInfo }) => {
    const LeftMessage = () => {
      return (
        <Box sx={{ width: "100%" }} justifyContent={"left"}>
          <Box sx={{ width: "66%", display: "flex" }} justifyContent={"left"}>
            <Stack sx={{ width: "100%" }}>
              <Stack direction={"row"}>
                <Typography sx={{ width: "20%" }} variant="body2">
                  {messageInfo.firstname + " " + messageInfo.lastname}
                </Typography>
                <Typography
                  sx={{ width: "80%", display: "flex" }}
                  variant="body2"
                  justifyContent={"center"}
                >
                  {getCurrentTimeByTimeZoom(messageInfo.time)}
                </Typography>
              </Stack>
              <Typography
                sx={{ width: "100%", backgroundColor: "lightgrey" }}
                variant="body1"
              >
                {messageInfo.message.text}
              </Typography>
              <Box sx={{ height: "10px" }} />
            </Stack>
          </Box>
          <Box sx={{ width: "34%" }} />
        </Box>
      )
    }
    const RightMessage = () => {
      return (
        <Box sx={{ width: "100%", display: "flex" }} justifyContent={"right"}>
          <Box sx={{ width: "34%" }} />
          <Box sx={{ width: "66%", display: "flex" }} justifyContent={"right"}>
            <Stack sx={{ width: "100%" }}>
              <Typography
                sx={{ width: "100%", display: "flex" }}
                variant="body2"
                justifyContent={"right"}
              >
                {getCurrentTimeByTimeZoom(messageInfo.time)}
              </Typography>
              <Typography
                sx={{ width: "100%", backgroundColor: "lightblue" }}
                variant="body1"
              >
                {messageInfo.message.text}
              </Typography>
              <Box sx={{ height: "10px" }} />
            </Stack>
          </Box>
        </Box>
      )
    }
    return messageInfo.role === "UASSIST USER" ? (
      <LeftMessage />
    ) : (
      <RightMessage />
    )
  }

  useEffect(() => {
    dispatch(getMessageApi({ patientId, caseId }))
  }, [])

  useEffect(() => {
    if (messageList.length === 0 && historyList.length === 0) {
      setHoldplace("Write your comments to submit the modification request.")
      return
    }
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [messageList, historyList])

  const PostMeesageBox = () => {
    const [myMsg, setMyMsg] = useState("")
    const onClickSendMsg = () => {
      console.log("Send message: " + myMsg)
      if (!myMsg || myMsg === "") return
      if (isCustomerSupport) {
        dispatch(updateCurrentOrgId(my_orgId))
        setTimeout(() => {
          dispatch(
            postMessageApi({
              patientId,
              caseId,
              txName: "Plan 1",
              content: myMsg,
              cb: () => {
                dispatch(getMessageApi({ patientId, caseId }))
              },
            }),
          )
          setTimeout(() => {
            // switch back to current org Id
            dispatch(updateCurrentOrgId(current_orgId))
          }, 500)
        }, 500)
      } else {
        dispatch(
          postMessageApi({
            patientId,
            caseId,
            txName: "Plan 1",
            content: myMsg,
            cb: () => {
              dispatch(getMessageApi({ patientId, caseId }))
            },
          }),
        )
      }
      setMyMsg("")
      const txName = { name: "Hello", id: "100" }
      dispatch(getMessageApi({ patientId, caseId }))
    }
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Toolbar>
          <TextField
            sx={{ flexGrow: 1 }}
            label={
              isCustomerSupport ? "My message to Dr." : "My message to uassit"
            }
            id="outlined-size-small"
            value={myMsg}
            size="small"
            onChange={(e) => {
              setMyMsg(e.target.value)
            }}
          />
          <Tooltip title="Send message">
            <IconButton
              size="small"
              edge="end"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={onClickSendMsg}
            >
              <SendIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Box>
    )
  }

  const MessageListBox = () => {
    return (
      <>
        <Stack
          sx={{ maxHeight: "400px", overflow: "auto" }}
          direction={"column"}
          position="relative"
        >
          {messageList
            .slice(0)
            .reverse()
            .map((messageInfo, index) => (
              <OneMessage key={index} messageInfo={messageInfo} />
            ))}
          <Box ref={scrollRef} />
        </Stack>
      </>
    )
  }

  return (
    <LeftPage
      sx={{ Width: "320px", position: "relative" }}
      title="uAssist"
      onClose={onClose}
      content={<MessageListBox />}
      actions={<PostMeesageBox />}
    />
  )
}
