import { useEffect, useState,useMemo,memo } from "react"
import * as React from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import oktaAuthClient from "@/core/config/okta.config"
import {
  Alert,
  Backdrop,
  Box,
  ImageList,
  ImageListItem,
  Tab,
  Tabs,
  Typography,
  IconButton,
  Button,
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@mui/material"
import HighlightOffIcon  from "@mui/icons-material/HighlightOffOutlined";
import { filter, find, isEmpty } from "lodash"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import {
  getS3FileUrlAPI,
  uploadPhotographs,
  downloadPhotosfromS3,
  uploadPhotosToS3
} from "@/core/app/slices/records/photograph/photographThunkApi"
import { RootState } from "@/core/app/store"
import { getAccessToken } from "@/core/config/okta.config"
import { UFileBox } from "@/ui-component"

import { LeftPage } from "./LeftPage"
import { PhotosViewer } from "./PhotosViewer"
import * as v2Apis from "@/core/app/slices/v2/v2.service"


const NormalPhotoPosType = [ 'composite', 'front_smile', 'front_normal', 'lateral_photo', 'upper', 'lower', 'front', 'left', 'right']
const XRaysPhotoPosType = ['x_lateral', 'x_panorama']

const typeNameToShowName = {
  'composite':'Composite',
  'front_smile':'Smile',
  'front_normal':'Front',
  'lateral_photo':'Profile',
  'upper':'Upper Occlusal',
  'lower':'Lower Occlusal',
  'front':'Anterior',
  'left':'Left',
  'right':'Right',
  'x_lateral':'Ceph',
  'x_panorama':'Pano',
};

import { compressImageByCanvas } from "./utils"
import { set } from "react-hook-form"

let ws
let PhotoThumbMap:Record<string,string>={};
let ImageUrlMap:Record<string,string> = {}
const getFileUrl = (file:File)=>{
  if (!ImageUrlMap[file.name]) {
    ImageUrlMap[file.name] = `${URL.createObjectURL(file)}`
  }
  return ImageUrlMap[file.name];
}

const dragItemStyle = (isDragging, draggableStyle) => ({
  height: 120,
  width: 120,
  ...draggableStyle,
});

/**
 * Explaination
 * 0. all photos infomation will save into a file 'type.json'
 * 1. this component will download type.json by calling getPhotoListFromS3 first. to check if there has photos.
 * 2. there are three types of photo: 'additional , xray ,nomarlphoto'. 'additional' express the photo has not a type.'
 * 'nomarlphoto' includes type of [ 'composite', 'front_smile', 'front_normal', 'lateral_photo', 'upper', 'lower', 'front', 'left', 'right']
 * 'xray' includes type of ['x_lateral', 'x_panorama']
 * 3. save. one of upper photos changed will trigger save action for 'type.json' automatically. the photos and their thumbs are saving in remote when uploading new photos.in the meanwhile the 'type.json' is also saved.
 *   save actions is through function 'saveToJson'. it will update json info and upload to server.
 * 4. thumbs. each photo has thumbs.it will show thumbs instead raw photo in photolist.
 */

const HoveringComponent = ({provided, file, setopenZoom, index, onDelete, type, additional}:any) => {
  const [isHovering, setIsHovering] = useState(false)
  return (
    <ImageListItem
      key={file?.name}
      sx={{
        padding: 0.5,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        sx={{
          width: 90,
          height: 90,
          borderRadius: "4px",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden"
        }}
        component={"div"}
        onMouseEnter={(e)=>{
          setIsHovering(true)
        }}
        onMouseLeave={(e)=>{
          setIsHovering(false)
        }}
      >
        <img
          srcSet={getFileUrl(file)}
          src={getFileUrl(file)}
          alt={type}
          loading="lazy"
          width={89}
          height={89}
          onClick={() => {
            setopenZoom({
              open: true,
              index,
              datas: additional,
            })
          }}
          style={{ objectFit: "contain" }}
        /> 
        {((isHovering && file)?
          <IconButton  sx={{position:'absolute',right:'1px',top:'1px',color:"#333"}} aria-label="delete" onClick={() => {
              onDelete(index, true)
            }} 
          >
            <HighlightOffIcon/>
          </IconButton>:<></>
        )}
      </Box> 
    </ImageListItem>
  )
}

/**
   * 被拖拽区域:Additional Photos
   * @returns
   */
const DroppableComponent = memo((props:any) => {

  const {setopenZoom,additional,onDelete, onFilesAdd} = props
  const dropItemStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#CCCCCC" : "white",
  })
  const { t } = useTranslation("common")

  return (
    <>
      <ImageList
        component={"div"}
        sx={{
          width: 440,
          height: 135,
          margin: "0 42px",
          display: "flex",
          overflowX: "auto",
        }}
      >
        <ImageListItem
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component={"div"}
            sx={{
              width: 90,
              height: 90,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UFileBox
              boxSize={{
                height: 90,
                width: 90,
                padding: 0,
                margin: 0,
              }}
              allowedFileExtensions={["png", "jpg", "jpeg", "bmp"]}
              fileAcceptType={"image/*"}
              id="photo"
              selectedFile={
                onFilesAdd
              }
              fileLimit={10000}
              isRequired={false}
              isMultiple={true}
              isIcon={false}
              messages={{
                uploadButton: t("+"),
                fileNote: t("records.file.filenote", {
                  fileTypes: "JPG, PNG, BMP",
                  fileSize: 5,
                }),
              }}
            />
          </Box>
        </ImageListItem>
        <Droppable 
          droppableId={"additionaldrop"} 
          key={"additionaldropkey"} 
          direction="horizontal"
        >
          {(provided, snapshot) => {
            return(
              <ImageList
                {...provided.droppableProps}
                ref={provided.innerRef}
                component={"div"}
                sx={{
                  ...dropItemStyle(snapshot.isDraggingOver),
                  width: 300,
                  height: 100,
                  margin: "16px 10px",
                  display: "flex",
                  overflowX: "auto",
                }}
              >
                
                {additional.map(({ type, file }, index) => (
                  <Draggable
                    key={file.name + index}
                    draggableId={"addiondrag" + index}
                    index={index}
                  >
                    {(provided, snapshot) => {
                      return (
                        <HoveringComponent 
                          provided={provided} 
                          setopenZoom={setopenZoom} 
                          type={type}
                          index={index}
                          file={file}
                          onDelete={onDelete}
                          additional={additional}
                        ></HoveringComponent>
                      )
                    }}
                  </Draggable> 
                ))}
                {provided.placeholder}
              </ImageList>
            )
          }}
        </Droppable>
      </ImageList>
    </>
  )
})

const DraggableComponent = memo(({ file, index, type,onClick,onDelete }:any) => {

  const [isHovering, setIsHovering] = useState(false); 
  return (
    <Box
      component={"div"}
      sx={{
        width: 120,
        height: 120,
        borderRadius: "4px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden"
      }}
      onMouseEnter={(e)=>{
        setIsHovering(true)
      }}
      onMouseLeave={(e)=>{
        setIsHovering(false)
      }}
      >
      {file ? (
        <Draggable
          draggableId={"draggable" + index}
          index={index}
        >
          {(provided,snapshot) => {
            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={dragItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                <img
                  srcSet={getFileUrl(file)}
                  src={getFileUrl(file)}
                  alt={type}
                  loading="lazy"
                  width={119}
                  height={119}
                  onClick={onClick}
                  style={{ objectFit: "contain" }}
                />
              </div>
            )
          }}
        </Draggable>
      ) : (   
        <Box
          sx={{
            width: 120,
            height: 120,
            background:
              "linear-gradient(135deg, #E0E0E0 0%, #FFF 30.31%, #E0E0E0 100%), linear-gradient(135deg, #E3E3E3 0%, #FEFEFE 32%, #E4E4E4 67.5%)",
          }}
        ></Box>
      )}
      {
        (isHovering && file)?(
          <IconButton  sx={{position:'absolute',right:'10px',top:'35px',color:"#333"}} aria-label="delete" onClick={onDelete
          } >
            <HighlightOffIcon/>
          </IconButton>
        ):(<></>
        )
      }
    </Box>
  )
})

const DroppableImage = memo(({index,file,type,onClick,onDelete}:any)=>{

  const getDropItemStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#CCCCCC" : "white",
    display: "flex",
    alignItems: "center",
  })
  const nIndex = index;
  const nFile = file;
  const nType = type;
  return <Droppable droppableId={"typedrop" + nIndex}>
  {(provided, snapshot) => (
    <ImageListItem
      component={"div"}
      {...provided.droppableProps}
      ref={provided.innerRef}
      key={nFile.name}
      sx={{
        ...getDropItemStyle(snapshot.isDraggingOver),
        padding: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          textAlign: "center",
          color: "rgba(0, 0, 0, 0.87)",
          marginBottom: "8px",
          width: 120,
        }}
      >
        {nType}
      </Typography>

      <DraggableComponent
        file={nFile}
        index={nIndex}
        type={nType}
        onClick={onClick}
        onDelete={onDelete}
      />
    </ImageListItem>
  )}
</Droppable>
})

const reorder = (list, startIndex, endIndex) => {
  const result = [...list]
  result.splice(startIndex, 1, {
    file: list[endIndex].file,
    filename: list[endIndex].filename,
    type: list[startIndex].type,
  })
  result.splice(endIndex, 1, {
    file: list[startIndex].file,
    filename: list[startIndex].filename,
    type: list[endIndex].type,
  })
  return result
}

const ImageListComponent = memo((props:any) => {

  const {setopenZoom,openZoom,tab,settab,photos,xRays,additional,onDragEnd,onFilesAdd,onDelete,downloadPhotosOrGetFromCache} = props

  const handleChange = (event: React.SyntheticEvent, newValue: 0 | 1) => {
    settab(newValue)
  }

  return (
    <Box
      sx={{
        width: 482,
        background: "#FFF",
        borderRadius: "8px",
        margin: 0,
      }}
    >
      {openZoom.open && (
        <Backdrop
          sx={{
            color: "#ffff",
            position: "absolute",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            borderRadius:"8px"
          }}
          open={openZoom.open}
        >
          <PhotosViewer
            images={openZoom.datas}
            setopenZoom={setopenZoom}
            index={openZoom.index}
            findFileNameFromThumbs = {findFileNameFromThumbs}
            downloadPhotosOrGetFromCache = {downloadPhotosOrGetFromCache}

          />
        </Backdrop>
      )}
      <Tabs
        value={tab}
        onChange={handleChange}
        sx={{ height: 42, minHeight: 42 }}
      >
        <Tab label="PHOTOS" />
        <Tab label="X-RAYS" />
      </Tabs>

      <DragDropContext
        sx={{
          background: "#F1F",
        }}
        onDragStart={() => {
          // TODO
        }}
        onDragUpdate={() => {
          // TODO
        }}
        onDragEnd={
          onDragEnd
        }
        >
        <Box component={"div"}>
          <ImageList
            key={"ImageList"}
            cols={3}
            rowHeight={166}
            sx={{
              width: 482,
              padding: "0 37px",
              margin: 0,
              overflow: "hidden",
            }}
          >
            {<></>}
            
            {(tab === 0 ? photos : xRays).map(({ type, file }, index) => {
              return (
                <DroppableImage 
                key={`ImageContainer_${index}`} 
                type={type} 
                file={file} 
                index={index} 
                onClick={
                  () => {
                  const _data = tab === 0 ? photos : xRays;
                  setopenZoom({
                    open: true,
                    index: index,
                    datas: _data,
                  })
                  }
                }  
                onDelete={()=>onDelete(index)}
                />
              )
            })}
          </ImageList>
          {/* Additional photos */}
          {/* <DroppableComponent additional={additional} setopenZoom={setopenZoom} 
          onFilesAdd={onFilesAdd}/> */}
          <Typography
            variant="subtitle2"
            color={"rgba(0, 0, 0, 0.87)"}
            width={"100%"}
            textAlign="center"
          >
            Additional Photos
          </Typography>
          {/* <ImageList
            cols={3}
            rowHeight={166}
            sx={{
              width: 482,
              padding: "0 37px",
              margin: 0,
              overflow: "hidden",
            }}
          > */}
            {/* {additional.map(({ type, file }, index) => ( */}
              <DroppableComponent 
              key={`AddImageContainer_`}
              // type={type} 
              additional={additional}
              onFilesAdd={onFilesAdd
              } 
              // index={index} 
              onDelete={onDelete} 
              setopenZoom={setopenZoom}
              ></DroppableComponent>
            {/* ))} */}
            
            
          {/* </ImageList> */}
          </Box>
            </DragDropContext>
          </Box>
  )
})

type IPhotosJSON = Record<string, string> & {
  thumbs?: Record<string, string>;
}



/**
 * PhotoThumbMap
 * key:raw filename, value: thumbs filename
 * @param thumbsName 
 * @returns 
 */
function findFileNameFromThumbs(thumbsName:string){
  return Object.keys(PhotoThumbMap).find(key=>{
    return PhotoThumbMap[key] === thumbsName;
  })
}

export function UDRecord(props) {
  const dispatch = useAppDispatch()
  const [additional, setadditional] = useState([])

  const [openZoom, setopenZoom] = useState({
    open: false,
    index: null,
    datas: [],
  })
  const [isLoading, setisLoading] = useState(true)
  const { patientId, caseId } = useParams()
  const [isAI, setisAI] = useState<{
    open: boolean
    upload?: { file: File; filename: string; type: string }[]
    params?: { category: string; filename: string }[]
  }>({
    open: false,
    upload: [],
    params: [],
  })
  const [isShowMessageBox,setIsShowMessageBox] = useState<{isShow:boolean,onOk?:()=>void,onCancel?:()=>void}>({isShow:false})
  const [isAlert, setAlert] = useState("")
  const [isSave,setIsSave] = useState(false);
  // const [photoTypeJson, setphotoTypeJson] = useState<File>(undefined)
  const { t } = useTranslation("common")
  const { photosCache } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const [tab, settab] = React.useState<1 | 0>(0)
  const [isNoData,setIsNoData] = useState(true);
  const [isAILoading,setIsAIloading] = useState(false);
  // const [AIResult, setAIResult] = useState({})
  const [photos, setphotos] = useState([
    { type: "Front", file: "" },
    { type: "Smile", file: "" },
    { type: "Profile", file: "" },
    { type: "Upper Occlusal", file: "" },
    { type: "Composite", file: "" },
    { type: "Lower Occlusal", file: "" },
    { type: "Left", file: "" },
    { type: "Anterior", file: "" },
    { type: "Right", file: "" },
  ])
  const [xRays, setxRays] = useState([
    { type: "Ceph", file: "" },
    { type: "Pano", file: "" },
  ])
  const {
    user: { current_orgId },
  } = useAppSelector((state: RootState) => state.userService)
  const { VITE_WEBSOCKET_URL } = import.meta.env

  useEffect(()=>{
    ImageUrlMap={};
    PhotoThumbMap={};
    getPhotoListFromS3({caseId})
    return () => {
      ws && ws.close()
    }
  },[])

  useEffect(()=>{
    if (isSave === true) {
      saveToJson();
      setIsSave(false);
    }
  },[isSave])
  
  useEffect(() => {
    const closeAI = find([...photos, ...xRays], (o) => {
      return !o.file
    })
    if (isEmpty(closeAI)) {
      setisAI({ open: false })
      return
    }
    if (isEmpty(additional)) {
      setisAI({ open: false })
    } else {

      const allDatas = [...additional,...photos,...xRays];

      // use the raw filename to request Cloud AI calculation.
      const fileList: { rawFileName: string,file:File }[] =
      allDatas.map((item) => {
          const rawFilename = findFileNameFromThumbs(item.file.name);
          if (item.file && rawFilename) {
            return {  rawFileName: rawFilename,file:item.file};
          }
          return undefined;
        }).filter(item=>item!==undefined);

      // prepare the parameters for request
      const params: { category: string; filename: string }[] = fileList.map(item=>{
        return {category: "photographs",filename:item.rawFileName}
      })

      const upload:any = fileList.map((item) => {
        if (item.file) {
          return { file: item.file, filename: item.rawFileName };
        }
        return undefined;
      }).filter(item=>item!==undefined);
      console.log('setisAI:::',{ open: true, upload, params })
      setisAI({ open: true, upload, params })
    }
  }, [additional, photos, xRays])

  useEffect(() => {
    if (isAlert) {
      setTimeout(() => {
        setAlert("")
      }, 3000)
    }
  }, [isAlert])

  const showAlert = ()=>{
    const handleClose = ()=>{
      setIsShowMessageBox({isShow:false});
    }

    return isShowMessageBox.isShow &&<Box 
    id="PhotosMessageBox" 
    sx={{
      position:'absolute',
      background:'#000a',
      width:'100%',
      height:'100%',
      borderRadius:'8px',
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      }}>
        <Paper sx={{width:'80%'}}>
          <Box sx={{width:'100%',height:'100%',padding:'10px'}}>
            <Typography variant="h6" component="div">
              Are you sure you want to delete this photo?
            </Typography>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Do not show again" />
            </FormGroup>
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
              <Button sx={{margin:'0px 5px'}} variant="outlined" onClick={handleClose}>CANCEL</Button>
              <Button sx={{margin:'0px 5px'}} variant="contained" onClick={()=>{isShowMessageBox.onOk&&isShowMessageBox.onOk();}}>YES</Button>
            </Box>

          </Box>
        </Paper>
    </Box>
  }

  const uploadTypeJson =async ({ AIresult }) => {
    console.log('upload typeJSON',AIresult)

    const blob = new Blob([JSON.stringify(AIresult)], {
      type: "application/json",
    })
    const file = new File([blob], "type", {
      type: "contentType",
      lastModified: Date.now(),
    })

    await dispatch(uploadPhotosToS3({caseId,files:{"type.json":file}}))
  }

  const downloadPhotosOrGetFromCache= async (filenames:string[])=>{
    let needDownloadFilenames = [];
    const retFileList:Record<string,File>= {}

    // find which need download?
    const cache = photosCache[caseId]
    if (cache) {
      for (let fileName of filenames) {
        if (cache[fileName]) {
          continue
        }else{
          needDownloadFilenames.push(fileName);
        }
      }
    }else{
      needDownloadFilenames = [...filenames]
    }

    // download if need
    const needDownloadPhotos:v2Apis.IPhotoInfo[] = []
    for (const key of needDownloadFilenames) {
      needDownloadPhotos.push({
        category:"photographs",
        filename:key
      })
    }

    let downloadFileList;
    if (needDownloadPhotos.length>0) {
      const ret = await dispatch(downloadPhotosfromS3({caseId,photos:needDownloadPhotos}))
      downloadFileList = ret.payload as Record<string,{data:File,status:string}>;
    }

    // get the files from cache or result of download.
    // filename <-> file
    for (let name of filenames) {
      if (cache && cache[name]) {
        retFileList[name] = cache[name]
      }else if(downloadFileList && downloadFileList[name]){
        retFileList[name] = downloadFileList[name]
      }
    }
    
    return retFileList;
  }

  const setPhotosFromJson = async(typeJson:Record<string,string>)=>{
    if (typeJson) {
      console.log('SetPhotosFromJSON:',typeJson)
      //const jsonObj = JSON.parse(typeJson)
      const jsonObj = typeJson;
      const keys = Object.keys(jsonObj).filter(item=>item!=='thumbs');
      // download thumbs instead of raw photos.
      const thumbsName = keys.map(key=>{
        return PhotoThumbMap[key]
      })

      const fileList = await downloadPhotosOrGetFromCache(thumbsName) as Record<string,File>;

      // distribute by type
      const additonalFiles:{file}[] = []
      const xrayFiles:{file,type}[] = []
      const photosFiles:{file,type}[] = []
      for (const key of keys) {
        const type = jsonObj[key];
        const thumbName = PhotoThumbMap[key];

        if (!fileList[thumbName]) {
          continue;
        }

        // thumbs[key] = await compressImageByCanvas(fileList[thumbName] as any, { 
        //   quality: 0.8, 
        //   width: 120 
        // })

        if (NormalPhotoPosType.includes(type)) {
          let showType = typeNameToShowName[type]??""
          const photo = photos.find((item)=>{
            return item.type === showType
          })
          photo.file = fileList[thumbName] as any;
          photosFiles.push({file:fileList[thumbName],type:showType});
        }else if (XRaysPhotoPosType.includes(type)) {
          let showType = typeNameToShowName[type]??""
          const xRaysCfg =  xRays.find((item)=>{
            return item.type === showType
          })
          
          xRaysCfg.file = fileList[thumbName] as any;
          xrayFiles.push({file:fileList[thumbName],type:showType});
        }else{
          if (fileList[thumbName]) {
            additonalFiles.push({file:fileList[thumbName]});
          }
        }
      }

      console.log("set photos...",additonalFiles,photosFiles,xrayFiles)
      if (additonalFiles) {
        setadditional([...additonalFiles]);
      }

      if (photosFiles) {
        for (let one of photosFiles) {
          const target = photos.find(item=>{
            return item.type === one.type
          })
          if (target) {
            target.file = one.file;
          } 
        }
        setphotos([...photos])
      }

      if (xrayFiles) {
        for (let one of photosFiles) {
          const target = xRays.find(item=>{
            return item.type === one.type
          })
          if (target) {
            target.file = one.file;
          } 
        }
        setxRays([...xRays])
      }
    }
  }

  const saveToJson = async()=>{
    const result:Record<string,any> = {}
    const photosKeys = Object.keys(photos);
    for (const key of photosKeys) {
      if (photos[key].file instanceof Blob) {
        const filename = findFileNameFromThumbs(photos[key].file.name);
        const type = photos[key].type;
        let ret = null;
        switch(type){
          case 'Front':
            ret='front_normal'
            break;
          case 'Smile':
            ret='front_smile'
            break;
          case 'Profile':
            ret='lateral_photo'
            break;
          case 'Upper Occlusal':
            ret='upper'
            break;
          case 'Composite':
            ret='composite'
            break;
          case 'Lower Occlusal':
            ret='lower'
            break;
          case 'Left':
            ret='left'
            break;
          case 'Anterior':
            ret='front'
            break;
          case 'Right':
            ret='right'
            break;
        }
        result[filename] = ret ? ret : "";
      }
    }
    const xraysKeys = Object.keys(xRays);
    for (const key of xraysKeys) {
      if (xRays[key].file instanceof Blob) {
        const filename = findFileNameFromThumbs(xRays[key].file.name);
        const type = xRays[key].type;
        let ret = null;
        switch(type){
          case 'Ceph':
            ret='x_lateral'
            break;
          case 'Pano':
            ret='x_panorama'
            break;
        }
        result[filename] = ret ? ret : "";
      }
    }

   
    const additionalKeys = Object.keys(additional);
    for (const key of additionalKeys) {
      if (additional[key].file instanceof Blob) {
        const filename = findFileNameFromThumbs(additional[key].file.name);
        result[filename] = additional[key].type ? additional[key].type : "";
      }
    }

    // save thumbs
    result['thumbs'] = PhotoThumbMap;

    await uploadTypeJson({AIresult:result});
  }


  /**
   * first upload and create typejson
   * Here will upload both raw photo and it's thumbs . and save thire relationship into PhotoThumbMap.
   * @param files 
   */
  const uploadPhotos = async(files:{file:File,filename:string}[])=>{
    const uploadfiles:Record<string,File> = {}
    for (const file of files) {
      uploadfiles[file.filename] = file.file
    }
    
    // 1. first upload the raw pictures
    const ret = await dispatch(uploadPhotosToS3({caseId,files:uploadfiles}))

    // 2. after get the s3 filename then upload thumbs
    const uploadPhotoNamelist = (ret.payload as any).casePhotos;
    const fileList:{file:File}[] = Object.keys(uploadPhotoNamelist).map(filename=>{
      const file = uploadPhotoNamelist[filename];
      if (file) {
        return {file:new File([file],filename)};
      }
    })

    // generate thumbs
    const thumbsToUpload = fileList.map(item=>{
      return compressImageByCanvas(item.file, { 
            quality: 0.8, 
            width: 120,
          })
    })
    

    const thumbFiles =await Promise.all(thumbsToUpload);
    // console.log('11111>>>>',thumbFiles)
    const thumbFilesToUpload:Record<string,File> ={}; 
    thumbFiles.forEach(item=>{
      thumbFilesToUpload[item.name]=item;
    })

    const thumbsUploadRet = await dispatch(uploadPhotosToS3({caseId,files:thumbFilesToUpload}));
    const thumbsListOfRet = (thumbsUploadRet as any).payload.casePhotos;
    if (!thumbsListOfRet) {
      throw Error('upload thumbs false.')
    }


    //3. A map of photo name to thumb name , used to save the name relation in the json. 
    
    const thumbsKeys = Object.keys(thumbsListOfRet);
    for (const filename of thumbsKeys) {
      const thumbFile = thumbsListOfRet[filename];
      PhotoThumbMap[thumbFile.name]=filename; 
    }
    const thumbFileList:{file:File}[] = Object.keys(thumbsListOfRet).map(filename=>{
      const file = thumbsListOfRet[filename];
      if (file) {
        return {file:new File([file],filename)};
      }
    })

    // console.log("3333>>>>>>>",uploadPhotoNamelist,fileList,JSON.stringify(PhotoThumbMap))

    // if no data then create a new json
    if (isNoData) {
      setadditional([...thumbFileList]);
      setIsSave(true);
      setIsNoData(false);
    }else{
      setadditional([...additional,...thumbFileList]);
      setIsSave(true);
    }
  }

  const getPhotoListFromS3 = async ({ caseId })=>{
    const ret = await dispatch(downloadPhotosfromS3({caseId,photos:[
        {      
          category: "photographs",
          filename: "type.json",
        }
      ]}))
    const retData = (ret.payload);
    
    if (retData['type.json']) {
      const typeJson = retData['type.json'];
      PhotoThumbMap = typeJson.thumbs ? {...typeJson.thumbs} : {};
      setPhotosFromJson(typeJson);
      setIsNoData(false);
    }
    console.log('Init PhotoThumbMap:::',PhotoThumbMap);
    setisLoading(false);
    
  }

  const postMessageToWebsocket = async ({ params, upload }) => {
    const renewToken = await oktaAuthClient.token.renewTokens();
    await oktaAuthClient.tokenManager.setTokens(renewToken)
    setIsAIloading(true)
    let connect = false
    ws = new WebSocket(
      `${VITE_WEBSOCKET_URL}/patient-mgmt/v1/org/${current_orgId}/patients/${patientId}/txplans/${caseId}/ai-clinical-photo`,
      "4a793be7-51f0-4d51-892f-11022e413e94",
    )
    ws.onopen = function () {
      ws.send(
        JSON.stringify({
          type: "inference",
          body: {
            case_id: Number(caseId),
            token: getAccessToken(),
            algorithm: "photo",
            input: {
              source: params,
            },
          },
        }),
      )
      connect = true
    }
    ws.onmessage = function ({ data }) {
      console.log("AIProcessOnMessage::",data)
      try{
        const {
          body: { result, output },
        } = JSON.parse(data)
        if (result !== 1) return
        // filter
        const aiResult = Object.keys(output).reduce((prev,key)=>{
          if (typeof output[key] ===  "string") {
            prev[key]=output[key]
          }
          return prev
        },{})
        // console.log('aiResult:',aiResult);
        const removeDuplicate = Object.keys(aiResult).reduce((prev,key)=>{
          const value = aiResult[key]
          if (!prev.values.has(value)) {
            prev.values.add(value);
            prev.result[key]=value;
          }else{
            prev.result[key]=""
          }
          return prev
        },{values:new Set(),result:{}})

        const jsonData = {...removeDuplicate.result};
        console.log('jsonData:',jsonData);
        setPhotosFromJson(jsonData)
        ws.close()
        uploadTypeJson({ AIresult: aiResult })
        setIsSave(true);
        setIsAIloading(false);
      }catch(err){
        console.error('handle AI message error:',err,data)
      }
    }
    ws.onclose = function (e) {
      console.log("websocket close********")
      if(e.code === 1006 && !connect){
        console.error('无法建立连接,尝试重新连接');
        setTimeout(async () => {
          await postMessageToWebsocket({params, upload})
        }, 1000);
      }
    }
  }

  const onFilesAdd = async (files) => {
    const casePhoto = [],
      photos = []
    for (const iterator of files) {
      casePhoto.push({
        category: "photographs",
        filename: iterator.name,
      })
      photos.push({ file: iterator, filename: iterator.name })
    }

    //upload
    await uploadPhotos(photos);

  }

  const UploadImageComponent = () => {
    return (
      <Box
        sx={{
          width: "100%",
          background: "#FFF",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: 2,
          marginBottom: 2,
        }}
      >
        <UFileBox
          boxSize={{
            height: 200,
            width: 472,
            marginBottom: 2,
            marginTop: 0,
          }}
          allowedFileExtensions={["png", "jpg", "jpeg", "bmp"]}
          fileAcceptType={"image/*"}
          id="photo"
          selectedFile={async (files) => {
            const casePhoto = [],
              photos = []
            for (const iterator of files) {
              casePhoto.push({
                category: "photographs",
                filename: iterator.name,
              })
              photos.push({ file: iterator, filename: iterator.name })
            }
            PhotoThumbMap={}
            await uploadPhotos(photos);

          }}
          fileLimit={10000}
          isRequired={false}
          isMultiple={true}
          messages={{
            fileNote: t("records.file.filenote", {
              fileTypes: "JPG, PNG, BMP",
              fileSize: 10,
            }),
            uploadButton: t("records.file.btnclicktoupload"),
            uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
            invalidfileFormat: (
              <Trans components={{ newLine: <br /> }}>
                {"records.photograph.invalidfileformat"}
              </Trans>
            ),
            invalidFileSize: t("records.file.invalidfilesize", {
              fileSize: 10,
            }),
            invalidFileLimit: t("userProfile.uploadLimitError", {
              noOfFiles: 1,
            }),
          }}
        />
        <Box sx={{ width: 472 }}>
          <Typography
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
              fontSize: 13,
              fontWeight: 400,
              letterSpacing: 0.4,
            }}
          >
            Uploading photos and x-rays help to create the best treatment plan
            as quickly as possible
          </Typography>
          <br />
          <Typography
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <span style={{ fontWeight: 700 }}>Photos:</span> 1 composite OR 3
            Extraoral (Front, Smile, Profile), 5 Intraoral (Left, Anterior,
            Right, Upper Occlusal, Lower Occlusal)
          </Typography>
          <Typography
            component={"div"}
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <span style={{ fontWeight: 700 }}>X-Rays:</span> Pano recommended,
            Ceph optional
          </Typography>
          <Typography
            component={"div"}
            variant="caption"
            sx={{
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <span style={{ fontWeight: 700 }}>Additional:</span> Any additional
            photos or x-rays can also be uploaded at this time
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <LeftPage
      id="leftpage"
      sx={{
        width: 536,
        Height: "auto",
      }}
      title="Photos and X-rays"
      onClose={props.onClose}
      headersx={{
        height: 72,
      }}
      contentsx={{ padding: "8px 32px" }}
      content={
        <Box id="PhotosContainer">
          {isAlert && <Alert>{isAlert}</Alert>}
          {isLoading? 
          <Box sx={{margin:'20px 0px',display:'flex',justifyContent:'center'}}>
            <CircularProgress/>
          </Box>: 
          (isNoData ? (
            <UploadImageComponent />
          ) : (
            <ImageListComponent 
              setopenZoom={setopenZoom} 
              openZoom={openZoom} 
              tab={tab} 
              settab={settab} 
              photos={photos} 
              xRays={xRays} 
              additional={additional}
              onFilesAdd={onFilesAdd
              }
              downloadPhotosOrGetFromCache={downloadPhotosOrGetFromCache}
              onDragEnd={
                (result) => {
                  // TODO
                  if (!result.destination) {
                    return
                  }
                  // console.log("Drag result:", result);
                  if (
                    result.source.droppableId.includes("type") &&
                    result.destination.droppableId.includes("type")
                  ) {
                    const items = reorder(
                      tab === 0 ? photos : xRays,
                      Number(result.source.droppableId.substring(8)),
                      Number(result.destination.droppableId.substring(8)),
                    )
                    tab === 0 ? setphotos([...items]) : setxRays([...items])
                  } else if (
                    result.source.droppableId.includes("addition") &&
                    result.destination.droppableId.includes("type")
                  ) {
                    const typePhoto = tab === 0 ? [...photos] : [...xRays]
                    const destanationIndex = Number(
                      result.destination.droppableId.substring(8),
                    )
                    const destinationPhoto =
                      tab === 0 ? photos[destanationIndex] : xRays[destanationIndex]
                    typePhoto.splice(destanationIndex, 1, {
                      type: typePhoto[destanationIndex].type,
                      file: additional[result.source.index].file,
                    })
                    const addiResult = [...additional]
                    destinationPhoto.file === ""
                      ? addiResult.splice(result.source.index, 1)
                      : addiResult.splice(result.source.index, 1, {
                          file: destinationPhoto.file,
                          filename: destinationPhoto.file.name,
                        })
                    tab === 0 ? setphotos([...typePhoto]) : setxRays([...typePhoto])
                    setadditional([...addiResult])
                    } else if (
                      result.source.droppableId.includes("type") &&
                      result.destination.droppableId.includes("addition")
                    ) {
                      const typeIndex = Number(result.source.droppableId.substring(8))
                      const typePhoto = tab === 0 ? [...photos] : [...xRays]
                      const additionalResult = [...additional]
                      additionalResult.unshift({
                        file: typePhoto[typeIndex].file,
                        filename: typePhoto[typeIndex].file.name,
                      })
                      setadditional([...additionalResult])
                      typePhoto.splice(typeIndex, 1, {
                        type: typePhoto[typeIndex].type,
                        file: "",
                      })
                      tab === 0 ? setphotos([...typePhoto]) : setxRays([...typePhoto])
                    }
      
                  setIsSave(true);
                }
              }
              setIsSave={setIsSave}
              onDelete={async (index:number,isAdditional:boolean)=>{
                setIsShowMessageBox({isShow:true,onOk:()=>{
                  if (isAdditional) {
                    const datas = additional;
                    // delete the related thumbs
                    const fileName = findFileNameFromThumbs(datas[index].file.name)
                    const {[fileName]:deletkey,...newMap} = PhotoThumbMap;
                    PhotoThumbMap = newMap;
                    datas.splice(index, 1)

                  }else{
                    const datas = (tab === 0) ? photos : xRays;
                    const file = datas[index].file as any
                    if (file.name) {
                      // delete the related thumbs
                      const fileName = findFileNameFromThumbs(file.name)
                      const {[fileName]:deletkey,...newMap} = PhotoThumbMap;
                      PhotoThumbMap = newMap;
                    }

                    datas[index].file=""
                  }
                  setIsSave(true);
                  setIsShowMessageBox({isShow:false})
                }});
              }}
            />
          ))}
        </Box>
      }
      actions={
        !isEmpty(additional) && (
          <LoadingButton
            onClick={async () => {
              const { upload, params } = isAI
              console.log('upload to AI:',upload,params)
              await postMessageToWebsocket({ params, upload })
              
            }}
            loading={isAILoading}
            disabled={!isAI.open}
            variant="contained"
            sx={{ margin: 2 }}
          >
            {"AI"}
          </LoadingButton>
        )
      }
    >
      {showAlert()}
    </LeftPage>
  )
}
