import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import ipr1 from "@/assets/images/clinical/report/ipr/ipr1.svg"
import ipr2 from "@/assets/images/clinical/report/ipr/ipr2.svg"
import ipr3 from "@/assets/images/clinical/report/ipr/ipr3.svg"
import ipr4 from "@/assets/images/clinical/report/ipr/ipr4.svg"
import ipr5 from "@/assets/images/clinical/report/ipr/ipr5.svg"
import ipr6 from "@/assets/images/clinical/report/ipr/ipr6.svg"
import ipr7 from "@/assets/images/clinical/report/ipr/ipr7.svg"
import ipr8 from "@/assets/images/clinical/report/ipr/ipr8.svg"
import ipr9 from "@/assets/images/clinical/report/ipr/ipr9.svg"
import ipr10 from "@/assets/images/clinical/report/ipr/ipr10.svg"
import ipr11 from "@/assets/images/clinical/report/ipr/ipr11.svg"
import ipr12 from "@/assets/images/clinical/report/ipr/ipr12.svg"
import ipr13 from "@/assets/images/clinical/report/ipr/ipr13.svg"
import ipr14 from "@/assets/images/clinical/report/ipr/ipr14.svg"
import ipr15 from "@/assets/images/clinical/report/ipr/ipr15.svg"
import ipr17 from "@/assets/images/clinical/report/ipr/ipr17.svg"
import ipr18 from "@/assets/images/clinical/report/ipr/ipr18.svg"
import ipr19 from "@/assets/images/clinical/report/ipr/ipr19.svg"
import ipr20 from "@/assets/images/clinical/report/ipr/ipr20.svg"
import ipr21 from "@/assets/images/clinical/report/ipr/ipr21.svg"
import ipr22 from "@/assets/images/clinical/report/ipr/ipr22.svg"
import ipr23 from "@/assets/images/clinical/report/ipr/ipr23.svg"
import ipr24 from "@/assets/images/clinical/report/ipr/ipr24.svg"
import ipr25 from "@/assets/images/clinical/report/ipr/ipr25.svg"
import ipr26 from "@/assets/images/clinical/report/ipr/ipr26.svg"
import ipr27 from "@/assets/images/clinical/report/ipr/ipr27.svg"
import ipr28 from "@/assets/images/clinical/report/ipr/ipr28.svg"
import ipr29 from "@/assets/images/clinical/report/ipr/ipr29.svg"
import ipr30 from "@/assets/images/clinical/report/ipr/ipr30.svg"
import ipr31 from "@/assets/images/clinical/report/ipr/ipr31.svg"
import fdiBg from "@/assets/images/clinical/report/ipr/iprFDIbg.svg"
import generalBg from "@/assets/images/clinical/report/ipr/iprGeneralBg.svg"
import palmerBg from "@/assets/images/clinical/report/ipr/iprPalmerBg.svg"
import pedroBg from "@/assets/images/clinical/report/ipr/iprPedroBg.svg"
import iprMiss from "@/assets/images/clinical/report/ipr/miss.svg"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { toothIdTypes } from "../../column"

import { iprProps } from "./type"

import "./ipr.scss"
const iprBg = {
  // Pedro: palmerBg,
  Pedro: pedroBg,
  Palmer: pedroBg,
  FDI: fdiBg,
  General: generalBg,
}
const IPR: FC<iprProps> = ({ toothlist, iprData }) => {
  const { t } = useTranslation("common")
  const {
    clinicalSettings: { NumberSystem },
  } = useAppSelector((state: RootState) => state.userService)
  // text render function
  const renderData = (slotNum: number) => {
    let dataTmp = []
    // Reassemble the data in order
    if (iprData[slotNum] && iprData[slotNum].length) {
      const len = iprData[slotNum].length
      if (len === 1) {
        dataTmp = [{ empty: true }, { empty: true }, iprData[slotNum][0]]
      } else if (len === 2) {
        dataTmp = [{ empty: true }, iprData[slotNum][1], iprData[slotNum][0]]
      } else if (len >= 3) {
        dataTmp = [
          iprData[slotNum][2],
          iprData[slotNum][1],
          iprData[slotNum][0],
        ]
      }
    }
    const box: any[] = []
    dataTmp.map((i, j) => {
      if (j === 0) {
        if (i.empty) {
          box.push(
            <p key={`${slotNum}line01`} className={"iprLine iprLine1"}></p>,
          )
        } else {
          box.push(
            <p
              key={`${slotNum}line02`}
              className={"iprLine iprLine1"}
              style={{
                marginLeft:
                  slotNum === 23 ? "-15px" : slotNum === 25 ? "14px" : "unset",
              }}
            >
              <span className={"iprValue"}>{`${i.value}mm`}</span>
              <span className={"iprStage"}>{`| stage${i.stage}`}</span>
            </p>,
          )
        }
      } else if (j === 1) {
        if (i.empty) {
          box.push(
            <p key={`${slotNum}line11`} className={"iprLine iprLine2"}></p>,
          )
        } else {
          box.push(
            <p
              key={`${slotNum}line12`}
              className={"iprLine iprLine2"}
              style={{
                marginLeft:
                  slotNum === 23 ? "-8px" : slotNum === 25 ? "7px" : "unset",
              }}
            >
              <span className={"iprValue"}>{`${i.value}mm`}</span>
              <span className={"iprStage"}>{`| stage${i.stage}`}</span>
            </p>,
          )
        }
      } else if (j === 2) {
        if (i.empty) {
          box.push(
            <p key={`${slotNum}line21`} className={"iprLine iprLine3"}></p>,
          )
        } else {
          box.push(
            <p key={`${slotNum}line22`} className={"iprLine iprLine3"}>
              <span className={"iprValue"}>{`${i.value}mm`}</span>
              <span className={"iprStage"}>{`| stage${i.stage}`}</span>
            </p>,
          )
        }
      }
    })
    return box
  }

  return (
    <div className="iprWrap">
      <div className={"tips"}>{t("treat.reportIPR.tip")}</div>
      {Object.keys(iprData).length && (
        <div className={"iprBox"}>
          <div
            className={"iprBg"}
            style={{ backgroundImage: `url(${iprBg[NumberSystem]})` }}
          ></div>
          {/* 缺牙图片定位及数据1-32 */}
          <>
            {/* 1-8 */}
            {!toothIdTypes[1].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "220px", top: "260px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[2].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "224px", top: "228px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[3].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "230px", top: "194px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[4].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "239px", top: "162px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[5].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "249px", top: "137px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[6].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "264px", top: "114px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[7].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "286px", top: "98px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[8].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "312px", top: "89px" }}
                className={"missTooth"}
              />
            )}

            {/* 9-16 */}

            {!toothIdTypes[9].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "344px", top: "89px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[10].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "372px", top: "97px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[11].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "394px", top: "114px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[12].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "409px", top: "137px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[13].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "419px", top: "163px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[14].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "427px", top: "195px" }}
                className={"missTooth"}
              />
            )}

            {!toothIdTypes[15].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "433px", top: "229px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[16].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "436px", top: "261px" }}
                className={"missTooth"}
              />
            )}

            {/* 17-24 */}
            {!toothIdTypes[17].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "435px", top: "318px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[18].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "430px", top: "348px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[19].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "425px", top: "382px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[20].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "413px", top: "410px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[21].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "401px", top: "435px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[22].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "384px", top: "456px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[23].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "363px", top: "467px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[24].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "342px", top: "474px" }}
                className={"missTooth"}
              />
            )}
            {/* 25-32 */}
            {!toothIdTypes[25].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "318px", top: "474px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[26].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "296px", top: "467px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[27].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "273px", top: "455px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[28].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "257px", top: "435px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[29].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "245px", top: "410px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[30].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "232px", top: "381px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[31].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "227px", top: "350px" }}
                className={"missTooth"}
              />
            )}
            {!toothIdTypes[32].some((item) => toothlist.includes(item)) && (
              <img
                src={iprMiss}
                style={{ left: "222px", top: "318px" }}
                className={"missTooth"}
              />
            )}
          </>
          {/* 1 */}
          <div style={{ display: iprData[1].length ? "block" : "none" }}>
            <img
              src={ipr1}
              style={{ top: "253px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "242px", marginLeft: "-289px" }}
            >
              <>{renderData(1)}</>
            </div>
          </div>

          {/* 2 */}
          <div style={{ display: iprData[2].length ? "block" : "none" }}>
            <img
              src={ipr2}
              style={{ top: "216px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "195px", marginLeft: "-289px" }}
            >
              <>{renderData(2)}</>
            </div>
          </div>
          {/* 3 */}
          <div style={{ display: iprData[3].length ? "block" : "none" }}>
            <img
              src={ipr3}
              style={{ top: "180px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "148px", marginLeft: "-289px" }}
            >
              <>{renderData(3)}</>
            </div>
          </div>
          {/* 4 */}
          <div style={{ display: iprData[4].length ? "block" : "none" }}>
            <img
              src={ipr4}
              style={{ top: "148px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "100px", marginLeft: "-289px" }}
            >
              <>{renderData(4)}</>
            </div>
          </div>
          {/* 5 */}
          <div style={{ display: iprData[5].length ? "block" : "none" }}>
            <img
              src={ipr5}
              style={{ top: "99px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "52px", marginLeft: "-289px" }}
            >
              <>{renderData(5)}</>
            </div>
          </div>
          {/* 6 */}
          <div style={{ display: iprData[6].length ? "block" : "none" }}>
            <img
              src={ipr6}
              style={{ top: "51px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "3px", marginLeft: "-289px" }}
            >
              <>{renderData(6)}</>
            </div>
          </div>
          {/* 7 */}
          <div style={{ display: iprData[7].length ? "block" : "none" }}>
            <img
              src={ipr7}
              style={{ top: "43px", marginLeft: "-167px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "-5px", marginLeft: "-168px" }}
            >
              <>{renderData(7)}</>
            </div>
          </div>
          {/* 8 */}
          <div style={{ display: iprData[8].length ? "block" : "none" }}>
            <img
              src={ipr8}
              style={{ top: "49px", marginLeft: "-54px" }}
              className={"iprImg"}
            />
            <div className={"wrap"} style={{ top: "1px", marginLeft: "-55px" }}>
              <>{renderData(8)}</>
            </div>
          </div>
          {/* 9 */}
          <div style={{ display: iprData[9].length ? "block" : "none" }}>
            <img
              src={ipr9}
              style={{ top: "44px", marginLeft: "33px" }}
              className={"iprImg"}
            />
            <div className={"wrap"} style={{ top: "-3px", marginLeft: "58px" }}>
              <>{renderData(9)}</>
            </div>
          </div>
          {/* 10 */}
          <div style={{ display: iprData[10].length ? "block" : "none" }}>
            <img
              src={ipr10}
              style={{ top: "51px", marginLeft: "56px" }}
              className={"iprImg"}
            />
            <div className={"wrap"} style={{ top: "4px", marginLeft: "170px" }}>
              <>{renderData(10)}</>
            </div>
          </div>
          {/* 11 */}
          <div style={{ display: iprData[11].length ? "block" : "none" }}>
            <img
              src={ipr11}
              style={{ top: "101px", marginLeft: "80px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "53px", marginLeft: "170px" }}
            >
              <>{renderData(11)}</>
            </div>
          </div>
          {/* 12 */}
          <div style={{ display: iprData[12].length ? "block" : "none" }}>
            <img
              src={ipr12}
              style={{ top: "149px", marginLeft: "94px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "102px", marginLeft: "170px" }}
            >
              <>{renderData(12)}</>
            </div>
          </div>
          {/* 13 */}
          <div style={{ display: iprData[13].length ? "block" : "none" }}>
            <img
              src={ipr13}
              style={{ top: "178px", marginLeft: "102px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "150px", marginLeft: "170px" }}
            >
              <>{renderData(13)}</>
            </div>
          </div>
          {/* 14 */}
          <div style={{ display: iprData[14].length ? "block" : "none" }}>
            <img
              src={ipr14}
              style={{ top: "218px", marginLeft: "113px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "196px", marginLeft: "170px" }}
            >
              <>{renderData(14)}</>
            </div>
          </div>
          {/* 15 */}
          <div style={{ display: iprData[15].length ? "block" : "none" }}>
            <img
              src={ipr15}
              style={{ top: "254px", marginLeft: "120px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "244px", marginLeft: "170px" }}
            >
              <>{renderData(15)}</>
            </div>
          </div>

          {/* 17 */}
          <div style={{ display: iprData[17].length ? "block" : "none" }}>
            <img
              src={ipr17}
              style={{ top: "342px", marginLeft: "112px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "295px", marginLeft: "170px" }}
            >
              <>{renderData(17)}</>
            </div>
          </div>
          {/* 18 */}
          <div style={{ display: iprData[18].length ? "block" : "none" }}>
            <img
              src={ipr18}
              style={{ top: "375px", marginLeft: "108px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "344px", marginLeft: "170px" }}
            >
              <>{renderData(18)}</>
            </div>
          </div>
          {/* 19 */}
          <div style={{ display: iprData[19].length ? "block" : "none" }}>
            <img
              src={ipr19}
              style={{ top: "410px", marginLeft: "96px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "391px", marginLeft: "170px" }}
            >
              <>{renderData(19)}</>
            </div>
          </div>
          {/* 20 */}
          <div style={{ display: iprData[20].length ? "block" : "none" }}>
            <img
              src={ipr20}
              style={{ top: "435px", marginLeft: "88px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "438px", marginLeft: "170px" }}
            >
              <>{renderData(20)}</>
            </div>
          </div>
          {/* 21 */}
          <div style={{ display: iprData[21].length ? "block" : "none" }}>
            <img
              src={ipr21}
              style={{ top: "458px", marginLeft: "65px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "485px", marginLeft: "170px" }}
            >
              <>{renderData(21)}</>
            </div>
          </div>
          {/* 22 */}
          <div style={{ display: iprData[22].length ? "block" : "none" }}>
            <img
              src={ipr22}
              style={{ top: "476px", marginLeft: "47px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "530px", marginLeft: "170px" }}
            >
              <>{renderData(22)}</>
            </div>
          </div>
          {/* 23 */}
          <div style={{ display: iprData[23].length ? "block" : "none" }}>
            <img
              src={ipr23}
              style={{ top: "491px", marginLeft: "21px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "549px", marginLeft: "63px" }}
            >
              <>{renderData(23)}</>
            </div>
          </div>
          {/* 24 */}
          <div style={{ display: iprData[24].length ? "block" : "none" }}>
            <img
              src={ipr24}
              style={{ top: "491px", marginLeft: "-48px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "549px", marginLeft: "-53px" }}
            >
              {iprData[24].length &&
                iprData[24].map((i, j) => {
                  if (j === 0) {
                    return (
                      <p className={"iprLine iprLine3"} key={j}>
                        <span className={"iprValue"}>{`${i.value}mm`}</span>
                        <span
                          className={"iprStage"}
                        >{`| stage${i.stage}`}</span>
                      </p>
                    )
                  }
                  if (j === 1) {
                    return (
                      <p className={"iprLine iprLine2"} key={j}>
                        <span className={"iprValue"}>{`${i.value}mm`}</span>
                        <span
                          className={"iprStage"}
                        >{`| stage${i.stage}`}</span>
                      </p>
                    )
                  }
                  if (j === 2) {
                    return (
                      <p className={"iprLine iprLine1"} key={j}>
                        <span className={"iprValue"}>{`${i.value}mm`}</span>
                        <span
                          className={"iprStage"}
                        >{`| stage${i.stage}`}</span>
                      </p>
                    )
                  }
                })}
            </div>
          </div>

          {/* 25 */}
          <div style={{ display: iprData[25].length ? "block" : "none" }}>
            <img
              src={ipr25}
              style={{ top: "491px", marginLeft: "-166px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "549px", marginLeft: "-171px" }}
            >
              <>{renderData(25)}</>
            </div>
          </div>
          {/* 26 */}
          <div style={{ display: iprData[26].length ? "block" : "none" }}>
            <img
              src={ipr26}
              style={{ top: "478px", marginLeft: "-283px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "531px", marginLeft: "-289px" }}
            >
              <>{renderData(26)}</>
            </div>
          </div>
          {/* 27 */}
          <div style={{ display: iprData[27].length ? "block" : "none" }}>
            <img
              src={ipr27}
              style={{ top: "459px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "484px", marginLeft: "-289px" }}
            >
              <>{renderData(27)}</>
            </div>
          </div>
          {/* 28 */}
          <div style={{ display: iprData[28].length ? "block" : "none" }}>
            <img
              src={ipr28}
              style={{ top: "434px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "437px", marginLeft: "-289px" }}
            >
              <>{renderData(28)}</>
            </div>
          </div>
          {/* 29 */}
          <div style={{ display: iprData[29].length ? "block" : "none" }}>
            <img
              src={ipr29}
              style={{ top: "411px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "391px", marginLeft: "-289px" }}
            >
              <>{renderData(29)}</>
            </div>
          </div>
          {/* 30 */}
          <div style={{ display: iprData[30].length ? "block" : "none" }}>
            <img
              src={ipr30}
              style={{ top: "375px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "343px", marginLeft: "-289px" }}
            >
              <>{renderData(30)}</>
            </div>
          </div>
          {/* 31 */}
          <div style={{ display: iprData[31].length ? "block" : "none" }}>
            <img
              src={ipr31}
              style={{ top: "341px", marginLeft: "-284px" }}
              className={"iprImg"}
            />
            <div
              className={"wrap"}
              style={{ top: "294px", marginLeft: "-289px" }}
            >
              <>{renderData(31)}</>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default IPR
