import { MouseEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { TablePagination } from "@mui/material"

import { UText } from "../../../../../ui-component"

import CustomPaginationAction from "./UTablePaginationAction"

export interface ITableFooterContentProps {
  options: number[] | undefined
  count: number
  page: number
  rowsPerPage: number
  handleChangePage: (event, newPage) => void | undefined
  handleRowsPerPageChange: (event) => void | undefined
}

const UTableFooter = ({
  options,
  count,
  page,
  rowsPerPage,
  handleChangePage,
  handleRowsPerPageChange,
}: ITableFooterContentProps) => {
  const [newPage, setNewPage] = useState<number>(0)
  const { t } = useTranslation("common")

  useEffect(() => {
    setNewPage(page - 1)
  }, [page])

  return (
    <TablePagination
      data-testid={"patientListFooter"}
      sx={{
        ".MuiToolbar-root": {
          padding: "0 32px",
          gap: "8px",
        },
        borderBottom: 0,
        marginTop: "10px",
        ".MuiSelect-select": {
          lineHeight: "24px",
          paddingRight: "32px !important",
          paddingLeft: "12px !important",
          textAlignLast: "left !important",
        },
        ".MuiSelect-icon": {
          marginRight: "12px",
        },
        ".MuiInputBase-root": {
          flexBasis: "5%",
          order: 1,
          border: "1px solid rgba(0, 0, 0, 0.23)",
          borderRadius: "4px",
          minHeight: "40px",
          minWidth: "80px",
          margin: 0,
        },
        ".MuiTablePagination-displayedRows": {
          color: "text.secondary",
        },
      }}
      component="div"
      rowsPerPageOptions={options}
      count={count}
      page={newPage}
      rowsPerPage={rowsPerPage}
      onPageChange={(
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number,
      ) => {
        setNewPage(newPage - 1)
        handleChangePage(event, newPage)
      }}
      onRowsPerPageChange={handleRowsPerPageChange}
      showFirstButton
      showLastButton
      ActionsComponent={CustomPaginationAction}
      labelRowsPerPage={
        <UText variant={"body2"} color={"text.secondary"}>
          {t("patientList.pagination.selectLabel")}
        </UText>
      }
    />
  )
}

export default UTableFooter
