import React, {
  FC,
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"

import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "../../../../../components/mui.components"
import { PatientFilterQueryParams } from "../../../../../core/app/slices/patients/patient.types"
import { IPatientList } from "../../../../../core/model/interface/IPatient"
import { UText } from "../../../../../ui-component"

import EmptyTableContent from "./EmptyTableContent"
import { IColumnDef, ISort, IUTable } from "./table"
import UFilterChips from "./UFilterChips"
import UTableActionCell from "./UTableActionCell"
import UTableCellRenderer from "./UTableCellRenderer"
import UTableFilter from "./UTableFilter"
import UTableFooter from "./UTableFooter"
import UTableSearch from "./UTableSearch"

const UTable: FC<IUTable> = ({
  columnData,
  columnDef,
  name,
  isFetching,
  onRowClick,
  onFilterUpdate,
  paging,
  enableSearch,
  onSearch,
  onPageChange,
  onRowsPerPageChange,
  sorting,
  sortHandler,
  hidePagination = false,
  enableFilters = true,
  sxTableProps,
  search,
  filterType,
  filter,
  onClearAllFilter,
  onDeleteChip,
  onMenuBtnClick,
  hideMenu,
}) => {
  const tableRef = useRef(null)
  const [height, setHeight] = useState<number>(1)
  const [hoverKey, setHoverKey] = useState<number | null>(null)
  const [sortKey, setSortKey] = useState<ISort>()
  const [tableFilter, setTableFilter] = useState<PatientFilterQueryParams>(
    {} as PatientFilterQueryParams,
  )
  columnDef = columnDef.filter((column) => column.visible)
  useLayoutEffect(() => {
    if (tableRef?.current) {
      setHeight(tableRef.current.clientHeight)
    }
  }, [tableRef])

  useEffect(() => {
    setSortKey(sorting)
  }, [sorting])

  useEffect(() => {
    if (!filter) return
    setTableFilter(filter.fields)
  }, [filter?.fields])

  const handleMouseOver = (
    event: React.MouseEvent<HTMLElement>,
    key: number,
  ) => {
    setHoverKey(key)
  }

  const handleMouseOut = () => {
    setHoverKey(null)
  }

  return (
    <Box
      component={"div"}
      sx={{ width: "inherit", mb: 3, position: "relative" }}
    >
      {(enableSearch || enableFilters) && (
        <>
          <Box
            component={"div"}
            sx={{
              justifyContent: "start",
              flexWrap: "wrap",
              "> div": { alignSelf: "center" },
              pb: 2,
            }}
            id="tableSection"
          >
            <Grid
              container
              gap={1}
              direction={"row"}
              justifyContent={{ lg: "space-between", md: "space-between" }}
            >
              {enableSearch && (
                <Grid
                  item
                  direction={"column"}
                  flexGrow={{ md: 1, lg: 0, sm: 1 }}
                  mr={{ md: 2, lg: 2 }}
                  minWidth={{ lg: "400px" }}
                >
                  <UTableSearch onSearch={onSearch} searchText={search} />
                </Grid>
              )}
              {enableFilters && (
                <Grid
                  item
                  direction={"column"}
                  flexGrow={{ sm: 1, md: 0, lg: 0 }}
                >
                  <UTableFilter
                    onFilterUpdate={onFilterUpdate}
                    filterQuery={
                      tableFilter || ({} as PatientFilterQueryParams)
                    }
                    onClearAllFilter={onClearAllFilter}
                    actionNeededCount={
                      (paging.actionNeededCount && paging?.actionNeededCount) ||
                      0
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          <Box data-testid={"filterChips"} component={"div"} id="filterChips">
            <UFilterChips
              filterQuery={tableFilter || ({} as PatientFilterQueryParams)}
              onDeleteChip={onDeleteChip}
              onClearAllChips={onClearAllFilter}
            />
          </Box>
        </>
      )}

      <Box component={"div"}>
        <TableContainer
          component={Paper}
          sx={{
            minHeight: height,
            borderRadius: 3,
          }}
        >
          <Table
            sx={{ padding: 2, tableLayout: "fixed" }}
            stickyHeader
            aria-label={`${name}table`}
          >
            <TableHead>
              <TableRow>
                {columnDef.map((column: IColumnDef) => (
                  <Fragment key={column.id}>
                    <TableCell
                      key={column.id}
                      sx={{
                        lineHeight: 0,
                        color: "text.primary",
                        width:
                          column.cell?.style?.maxWidth ||
                          column.cell?.style?.width,
                        paddingRight:
                          column.dataKey === "statusUpdated"
                            ? "2px"
                            : column.dataKey === "firstName"
                            ? "10px"
                            : "",
                      }}
                    >
                      {sortKey ? (
                        <TableSortLabel
                          data-testid={"sortLabel"}
                          sx={{
                            lineHeight: "171.429%",
                            display: "flex",
                            alignItems: "flex-start",
                            ".MuiSvgIcon-root": {
                              m: 0,
                              p: "0 4px",
                              width: "1.4em",
                              mt: "1.5px",
                            },
                          }}
                          active={
                            column.id === sortKey.key ||
                            column.sorty_by === sortKey.key
                          }
                          direction={sortKey.direction}
                          onClick={() => {
                            sortHandler(column.id)
                          }}
                        >
                          {column.name}
                        </TableSortLabel>
                      ) : (
                        <UText
                          variant="body2"
                          sxProp={{ fontWeight: 500, lineHeight: "171.429%" }}
                        >
                          {column.name}
                        </UText>
                      )}
                    </TableCell>
                  </Fragment>
                ))}
                {!hideMenu && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                ".MuiTableRow-root": {
                  height: 72,
                },
              }}
            >
              {isFetching === "succeeded" &&
                columnData?.map((row: IPatientList, index) => (
                  <TableRow
                    data-testid={row?.caseId}
                    key={row?.caseId}
                    hover
                    sx={{
                      cursor: onRowClick ? "pointer" : "initial",
                      ...sxTableProps.body,
                      ":last-child": {
                        ".MuiTableCell-root": { border: 0 },
                      },
                    }}
                    onClick={() => {
                      onRowClick && onRowClick(row)
                    }}
                    onMouseOver={(event) => {
                      handleMouseOver(event, row.patientId)
                    }}
                    onMouseLeave={handleMouseOut}
                  >
                    {columnDef.map((column: IColumnDef) => (
                      <TableCell key={column.id}>
                        <UTableCellRenderer
                          row={row}
                          dataKey={column.dataKey}
                        />
                      </TableCell>
                    ))}
                    {!hideMenu && (
                      <TableCell>
                        <UTableActionCell
                          row={row}
                          hoverKey={hoverKey}
                          handleMouseOut={handleMouseOut}
                          tableName={name}
                          onMenuBtnClick={onMenuBtnClick}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              {isFetching === "pending" &&
                Array(10)
                  .fill({})
                  .map((row, index) => ({ ...row, key: index }))
                  .map((row) => (
                    <TableRow
                      key={row?.key}
                      onClick={() => {
                        onRowClick && onRowClick(row)
                      }}
                    >
                      {columnDef.map((col: IColumnDef, index) => {
                        return (
                          <TableCell
                            key={col?.dataKey}
                            component="th"
                            scope="row"
                          >
                            <Skeleton
                              data-testid="skeleton"
                              height={10}
                              animation="wave"
                            />
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))}
              {paging &&
                paging.totalRecords === 0 &&
                columnData.length === 0 &&
                isFetching !== "pending" && (
                  <EmptyTableContent
                    isEmpty={!filterType?.length}
                    clearFilterHandler={() => {
                      onClearAllFilter()
                    }}
                  />
                )}
            </TableBody>
          </Table>
        </TableContainer>
        {!hidePagination &&
          (isFetching === "succeeded" ||
            (!!paging.totalRecords && isFetching !== "pending")) && (
            <UTableFooter
              page={paging?.currentPage}
              rowsPerPage={paging?.pageSize}
              count={paging?.totalRecords}
              options={[10, 20, 50, 100]}
              handleChangePage={(event, newPage) => onPageChange(newPage)}
              handleRowsPerPageChange={(event) =>
                onRowsPerPageChange(event.target.value)
              }
            />
          )}
      </Box>
    </Box>
  )
}

UTable.defaultProps = {
  onRowClick: undefined,
  onSearch: undefined,
  isFetching: "idle",
  enableSearch: false,
}

export default UTable
