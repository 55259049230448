import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import React from "react";
import { 
  stagingManager,
  stageControl,
  EBiteJumpLocationType,
 } from "@/gluelayer"

import TriangleIcon from "./triangleIcon";
import StageAddIcon from "./stageAddIcon";
import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";
import ReactDOM from 'react-dom';
import StageItem from "./stageItem";
import StageFoldItem from "./stageFoldItem";
import StageGmButton from "./stageGmButton";

const ButtonItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StageArch = (props) => {

  const [stageCurrentStep, setStageCurrentStep] = useState(props.currentStep);
  const [stageCurrentNodeType, setStageCurrentNodeType] = useState([]);
  const [stageList, setStageList] = useState([]);
  const [isCurrentStepChangedByClick, setIsCurrentStepChangedByClick] = useState(false);
  const [isGmClicked, setIsGmClicked] = useState(false);
  const [gmPercent, setGmPercent] = useState(0.0);
  const stepRef = useRef({})
  const singleStageWidth = 26
  const StageDiv = 2
  const singleStageHeight = 32
  const [actualStageWidth, setActualStageWidth] = useState<number>(singleStageWidth)
  const [gmUiLocation, setGmUiLocation] = useState("end")//start end
  
  const isDownArchBiteMoved = () => {
    return stageControl.isDownArchBiteMoved()
  }

  useImperativeHandle(props.onRef, () => ({
    getStagePositionX,
    getStageStepByPositionX,
    setCurrentStep,
    setGmPercent,
  }))

  const callBackStepRef = (e: HTMLElement, stepIndex: number) => {
    stepRef.current["step_" + stepIndex] = e
  }

  const setCurrentStep = (step: number) => {
    if (step > stageList.length - 1) {
      step = stageList.length - 1
    }

    //if(step !== stageCurrentStep) {
    setStageCurrentStep(step)
    //}
  }

  const isNodeContains = (nodeTypes, nodeType) => {
    //console.info("=====nodeTypes: " + nodeTypes.length)
    if (nodeTypes.length > 0) {
      //console.info(nodeTypes[0])
    }
    let index = nodeTypes.indexOf(nodeType)
    //console.info("index:" + index)
    return index >= 0
  }

  const changeCurrentStep = (iStep) => {
    //if(isCurrentStepChangedByClick) {
    props.stepChangeHandler(props.archType, iStep)
    //}
    //setStageCurrentStep(iStep);
  }

  const stageButtonClickHandle = (item, index, itemArray) => {
    //getStagePosition(index)
    setIsCurrentStepChangedByClick(true)
    console.info(
      "=====isCurrentStepChangedByClick: ",
      isCurrentStepChangedByClick,
      props.archName,
    )
    changeCurrentStep(index)
  }

  const getStagePositionX = (stepIndex: number) => {
    //var rect = element.getBoundingClientRect();
    //return { x: rect.left, y: rect.top };
    const e = stepRef.current["step_" + stepIndex]
    if (e === undefined || e === null) {
      return 0
    }
    const rect = e.getBoundingClientRect()
    const middleX = rect.left + rect.width / 2

    const parentE = e.offsetParent
    let parentELeft = 0
    if (parentE !== undefined && parentE !== null) {
      const parentRect = parentE.getBoundingClientRect()
      parentELeft = parentRect.left
    }

    //console.info("=====getStagePositionX: ", middleX);
    return middleX - parentELeft
  }

  const getStageStepByPositionX = (positionX: number) => {
    for (let i = 0; i < props.nodes.length; i++) {
      const xMiddle = getStagePositionX(i)
      if (
        xMiddle - (singleStageWidth + StageDiv) / 2 <= positionX &&
        positionX <= xMiddle + (singleStageWidth + StageDiv) / 2
      ) {
        return i
      }
    }
    return -1
  }

  const addNodeHandler = () => {
    console.info(
      props.archName + " arch addNodeHandler, index:" + stageCurrentStep,
    )
    if (!isNodeContains(stageList[stageCurrentStep].nodeTypes, 0)) {
      console.info(
        "=====add node: ",
        props.archName,
        stageCurrentStep,
        props.nodes.length - 1,
      )
      stagingManager.addNormalNode(
        props.archType === 0,
        stageCurrentStep,
        stageCurrentStep === props.nodes.length - 1,
      )
    } else {
      console.info("=====delete node: ", props.archName, stageCurrentStep)
      stagingManager.deleteKeyNode(props.archType === 0, stageCurrentStep)
    }
  }

  const judgeWhichItem = (item, index, itemArray) => {
    if (props.countOfFoldGroup === 1) {
      return (
        <StageItem
          archType={props.archType}
          index={index}
          stageCurrentStep={stageCurrentStep}
          isNodeContains={isNodeContains}
          item={item}
          itemArray={itemArray}
          addNodeHandler={addNodeHandler}
          stageButtonClickHandle={stageButtonClickHandle}
          stageDiv={StageDiv}
          singleStageWidth={singleStageWidth}
          singleStageHeight={singleStageHeight}
          countOfFoldGroup={props.countOfFoldGroup}
          actualStageWidth={actualStageWidth}
        />
      )
    } else if (props.countOfFoldGroup > 1) {
      const remainder = index % props.countOfFoldGroup
      const quotient = Math.floor(index / props.countOfFoldGroup)
      if (
        index === 0 ||
        index === stageList.length - 1 ||
        (remainder === 0 &&
          quotient * props.countOfFoldGroup + 1 !== stageList.length - 1)
      ) {
        return (
          <StageItem
            archType={props.archType}
            index={index}
            stageCurrentStep={stageCurrentStep}
            isNodeContains={isNodeContains}
            item={item}
            itemArray={itemArray}
            addNodeHandler={addNodeHandler}
            stageButtonClickHandle={stageButtonClickHandle}
            stageDiv={StageDiv}
            singleStageWidth={singleStageWidth}
            singleStageHeight={singleStageHeight}
            countOfFoldGroup={props.countOfFoldGroup}
            actualStageWidth={actualStageWidth}
          />
        )
      }
      // else if(remainder === 0 && (quotient*props.countOfFoldGroup + 1) === (stageList.length - 1)) {
      //   return <StageFoldItem
      //     archType={props.archType}
      //     index={index}
      //     stageCurrentStep={stageCurrentStep}
      //     isNodeContains={isNodeContains}
      //     item={item}
      //     itemArray={itemArray}
      //     addNodeHandler={addNodeHandler}
      //     stageButtonClickHandle={stageButtonClickHandle}
      //     stageDiv={StageDiv}
      //     singleStageWidth={singleStageWidth}
      //     singleStageHeight={singleStageHeight}
      //     scaleHeight={4}
      //     countOfFoldGroup={props.countOfFoldGroup}
      //     isBigNode={true} />
      // }
      else {
        return (
          <StageFoldItem
            archType={props.archType}
            index={index}
            stageCurrentStep={stageCurrentStep}
            isNodeContains={isNodeContains}
            item={item}
            itemArray={itemArray}
            addNodeHandler={addNodeHandler}
            stageButtonClickHandle={stageButtonClickHandle}
            stageDiv={StageDiv}
            singleStageWidth={singleStageWidth}
            singleStageHeight={singleStageHeight}
            scaleHeight={4}
            countOfFoldGroup={props.countOfFoldGroup}
            actualStageWidth={actualStageWidth}
            isBigNode={false}
          />
        )
      }
    }
  }

  const loadStageUi = () => {
    return stageList.map((item, index, itemArray) => {
      return (
        <Stack
          ref={(e: HTMLElement) => {
            callBackStepRef(e, index)
          }}
          direction="row"
          spacing={0}
          key={item.indexStr}
        >
          {judgeWhichItem(item, index, itemArray)}
        </Stack>
      )
    })
  }

  const loadStageData = () => {
    console.info(
      "loadStageData=====nodes count",
      props.nodes.length,
      props.archName,
    )
    var newStageList = []
    for (var i = 0; i < props.nodes.length; i++) {
      //console.info("=====", props.nodes[i].stepindex, props.nodes[i].name, props.nodes[i].nodeType);
      newStageList.push({
        indexStr: props.nodes[i].name,
        nodeTypes: props.nodes[i].nodeType,
      })
    }

    console.info("=====set stageList", props.archName, newStageList.length)
    setStageList(newStageList)

    console.info("=====stageCurrentStep: ", props.archName, stageCurrentStep)
    if (props.currentStep !== stageCurrentStep) {
      setIsCurrentStepChangedByClick(false)
      console.info(
        "=====isCurrentStepChangedByClick: ",
        isCurrentStepChangedByClick,
        props.archName,
      )
      const step =
        props.currentStep > props.nodes.length - 1
          ? props.nodes.length - 1
          : props.currentStep
      //setStageCurrentStep(step);
      setStageCurrentStep(step)
    }
  }

  const gmFrontButtonClickHandle = () => {
    if (!isGmClicked) {
      setIsGmClicked(true)
      stageControl.setBiteJumpTypePlayGM(
        EBiteJumpLocationType["EBiteJumpLocation_Start"],
      )
    } else {
      setIsGmClicked(false)
      setGmPercent(0.0)
      stageControl.setBiteJumpTypePlayGM(
        EBiteJumpLocationType["EBiteJumpLocation_All"],
      )
    }
  }

  const gmBackButtonClickHandle = () => {
    if (!isGmClicked) {
      setIsGmClicked(true)
      stageControl.setBiteJumpTypePlayGM(
        EBiteJumpLocationType["EBiteJumpLocation_End"],
      )
    } else {
      setIsGmClicked(false)
      setGmPercent(0.0)
      stageControl.setBiteJumpTypePlayGM(
        EBiteJumpLocationType["EBiteJumpLocation_All"],
      )
    }
  }

  const updateGMProgress = (percent: number) => {
    console.info("updateGMProgress: " + percent)
    setGmPercent(percent)
  }

  const calcStageWidth = () => {
    if (props.countOfFoldGroup === 1) {
      setActualStageWidth(singleStageWidth)
    } else if (props.countOfFoldGroup === 5) {
      setActualStageWidth(singleStageWidth - 6)
    } else if (props.countOfFoldGroup === 10) {
      setActualStageWidth(singleStageWidth - 10)
    } else if (props.countOfFoldGroup === 20) {
      setActualStageWidth(singleStageWidth - 10)
    }
  }

  const loadGmButton = (isFront) => {
    if (stageList.length <= 0 || !isDownArchBiteMoved()) {
      return <></>
    }

    if (props.archType === 0) {
      if (
        (isFront && gmUiLocation === "start") ||
        (!isFront && gmUiLocation === "end")
      ) {
        return <div style={{ width: 60 }} />
      } else {
        return <></>
      }
    } else {
      if (isFront && gmUiLocation === "start") {
        return (
          <div style={{ marginTop: 9, height: 9 + 32 }}>
            <StageGmButton
              width={60}
              height={singleStageHeight}
              percent={gmPercent}
              onClick={gmFrontButtonClickHandle}
            ></StageGmButton>
          </div>
        )
      } else if (!isFront && gmUiLocation === "end") {
        return (
          <div style={{ marginTop: 9, height: 9 + 32 }}>
            <StageGmButton
              width={60}
              height={singleStageHeight}
              percent={gmPercent}
              onClick={gmBackButtonClickHandle}
            ></StageGmButton>
          </div>
        )
      }
    }
  }

  useEffect(() => {
    calcStageWidth()
  }, [props.countOfFoldGroup])

  useEffect(() => {
    stagingManager.setGMProgressCallBack = updateGMProgress
    loadStageData()
  },
    []
  );

  // useEffect(() => {
  //   stepChangeHandler(archType, stageCurrentStep)
  // }, [stageCurrentStep]);

  useEffect(() => {
    loadStageData()
  },
    [props.nodes]
  );

  useEffect(() => {
    props.loadFinished(props.archType)
  },
    [stageList]
  );

  return (
    <Box>
      <Stack direction="row" spacing={0}>
        {loadGmButton(true)}
        
        <Box sx={{width: 3 + ((singleStageWidth - actualStageWidth) / 2), height: 56}}></Box>
        {loadStageUi()}
        <Box sx={{width: 3 + ((singleStageWidth - actualStageWidth) / 2), height: 56}}></Box>

        {loadGmButton(false)}
        
      </Stack>
    </Box>
  );
}

export default StageArch;