import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Check from "@mui/icons-material/Check"
import PersonIcon from "@mui/icons-material/Person"
import OrderrIcon from "@mui/icons-material/ShoppingCart"
import { Avatar, Box, IconButton, Stack, Typography } from "@mui/material"
import { Divider, ListItemIcon, ToggleButton, Tooltip } from "@mui/material"
import { Badge, Button, Menu, MenuItem, Paper, Switch } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import { uploadCaseZips } from "@/core/app/slices/clinical/clinicalThunkApi"
import { resetOrder } from "@/core/app/slices/order/orderSlice"
import { RootState } from "@/core/app/store"
import {
  initialfinal,
  preview,
  viewControlInTreatment,
  viewEdit,
  wasmModule,
} from "@/gluelayer"
import { caseManagement } from "@/gluelayer"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"

import { orderButtonPopUpTmp } from "../bottom/Toggle/column"
import {
  setCurArch,
  setCurCaseMode,
  setCurRightPage,
  setSmartRxShow,
  setSwitchViewEdit,
  setUcloud1_1,
  setWorkflowMode,
} from "../udTreatSlice"
import CloseIcon from "@mui/icons-material/Close"
import {
  BackIcon,
  FrontIcon,
  LeftIcon,
  LowerIcon,
  RightIcon,
  SplitIcon,
  UpperIcon,
} from "./TopToolBarIcon"
import {
  BiteCheckIcon,
  CollisionIcon,
  GridIcon,
  InitFinalIcon,
  LiteModeIcon,
  StatusIcon,
  SuperImposeIcon,
  WorkflowIcon,
} from "./TopToolBarIcon"
import { ZoomBar } from "./ZoomBar"

import { PatientNameID } from "./PatientNameID"
import { CaseStatusBox } from "./CaseStatusBox"
import { setDisable } from "../udSetupSlice"

export function TopToolBar(props: any) {
  const theme = useTheme()
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()
  const {
    hasUploadSTLs,
    workflowMode,
    curCaseMode,
    smartRxShow,
    curArch,
    ucloud1_1,
    isCasePreview,
    isNewCaseHasUpload,
    switchViewEdit,
  } = useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [curStatusString, setCurStatusString] = useState("")
  // tools button status (selected or not)
  const [openCollisionCheck, setOpenCollisionCheck] = useState<boolean>(false)
  const [openBiteCheck, setOpenBiteCheck] = useState<boolean>(false)
  const [openInitialFinal, setOpenInitialFinal] = useState<boolean>(false)
  const [openSuperimpos, setOpenSuperimpos] = useState<boolean>(false)
  const [openGrid, setOpenGrid] = useState<boolean>(false)
  const [openLiteMode, setOpenLiteMode] = useState<boolean>(false)
  const {
    patientData: { firstName = "", lastName = "" },
  } = useAppSelector((state: RootState) => {
    return state.patientService
  })

  const newCaseUDPlanStatus = [
    CASE_STATUS.DEFAULT,
    CASE_STATUS.COMPLETE_PATIENT_RECORDS,
    CASE_STATUS.PRESETUP_IN_PROGRESS,
    CASE_STATUS.FILL_OUT_RX_FORM,
    CASE_STATUS.SUBMIT_TO_UASSIST,
    CASE_STATUS.SUBMITTED_TO_UASSIST_REFINEMENT,
    CASE_STATUS.ATTENTION_NEEDED,
    CASE_STATUS.ATTENTION_NEEDED_REFINEMENT,
  ]
  const uDModiStatus = [
    CASE_STATUS.REVIEW_PLAN,
    CASE_STATUS.REVIEW_MODIFICATION,
  ]
  const uDViewStatus = [
    CASE_STATUS.SUBMITTED_TO_UASSIST,
    CASE_STATUS.MODIFICATION_REQUESTED,
    CASE_STATUS.PLANNING_IN_PROGRESS,
    CASE_STATUS.ORDER_SUBMITTED,
    CASE_STATUS.IN_PRODUCTION,
    CASE_STATUS.SHIPPED,
    CASE_STATUS.TREATMENT_IN_PROGRESS,
    CASE_STATUS.DELIVERED,
    CASE_STATUS.ARCHIVED,
    CASE_STATUS.CANCELLED,
  ]

  function getCaseMode(caseDisposition: string) {
    let caseMode = "NewCase"
    if (newCaseUDPlanStatus.includes(caseDisposition)) {
      if (isCasePreview) {
        caseMode = "NewCase"
      } else if (!ucloud1_1) {
        // use AI plan
        caseMode = "UDPlan"
      } else {
        caseMode = "NewCase"
      }
    } else if (uDModiStatus.includes(caseDisposition)) {
      caseMode = "UDModi"
    } else if (uDViewStatus.includes(caseDisposition)) {
      caseMode = "UDView"
    } else {
      caseMode = "UDView"
    }
    return caseMode
  }

  // useEffect(() => {
  //   const { VITE_APP_TYPE } = import.meta.env
  //   dispatch(setUcloud1_1(VITE_APP_TYPE === "ucloud"))
  // }, [])

  useEffect(() => {
    const caseDisposition = caseDetail.caseDisposition
    console.log("Current caseDisposition" + caseDisposition)
    dispatch(setCurCaseMode(getCaseMode(caseDisposition)))
    setCurStatusString(caseDisposition)
  }, [caseDetail, ucloud1_1, isCasePreview])

  useEffect(() => {
    if (!wasmModule.isInit) {
      if (preview.isInitPreview()) {
        if (curArch === "all") preview.changeArchMode("both")
        else if (curArch === "up") preview.changeArchMode("up")
        else if (curArch === "low") preview.changeArchMode("low")
      }
      return
    }
    if (curArch === "all") viewControlInTreatment.showArchMode("all")
    else if (curArch === "up") viewControlInTreatment.showArchMode("up")
    else if (curArch === "low") viewControlInTreatment.showArchMode("low")
  }, [curArch])
  const onClickUpperLower = (upper: boolean) => {
    const nextArch = upper
      ? curArch === "up"
        ? "all"
        : "up"
      : curArch === "low"
      ? "all"
      : "low"
    dispatch(setCurArch(nextArch))
  }

  const handleEditChange = (e, val) => {
    if (!wasmModule.isInit) return
    dispatch(setSwitchViewEdit(val))
    viewEdit.switchViewToEdit(val)
  }

  const NewViewButtons = () => {
    const viewButtons = [
      {
        title: "Right",
        value: "right",
        disabled: false,
        icon: <RightIcon />,
      },
      {
        title: "Front",
        value: "front",
        disabled: false,
        icon: <FrontIcon />,
      },
      {
        title: "Back",
        value: "back",
        disabled: false,
        icon: <BackIcon />,
      },
      {
        title: "Left",
        value: "left",
        disabled: false,
        icon: <LeftIcon />,
      },
      {
        title: "Split",
        value: "split",
        disabled: false,
        icon: <SplitIcon />,
      },
    ]
    const handleClick = (view) => {
      viewControlInTreatment.setViewType(view)
    }
    return (
      <>
        {viewButtons.map(({ title, value, disabled, icon }, index) => {
          return (
            <Tooltip key={index} title={title} placement="bottom">
              <IconButton
                value={value}
                size="large"
                onClick={() => handleClick(value)}
              >
                {icon}
              </IconButton>
            </Tooltip>
          )
        })}
      </>
    )
  }

  const onClickSaveCase = (e) => {
    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        const params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        console.log("🚀 ~ caseManagement.getCaseFiles ~ zips:", zips)
        dispatch(setDisable(true))
        dispatch(uploadCaseZips(params))
        caseManagement.closeCase()
        navigate(`/patients`)
      }
    })
  }

  const NewToolButtons = () => {
    const toolButtons = [
      {
        title: "Collision Check",
        key: "collision",
        disabled: false,
        selected: openCollisionCheck,
        icon: <CollisionIcon />,
      },
      {
        title: "Bite check",
        key: "bitecheck",
        disabled: false,
        selected: openBiteCheck,
        icon: <BiteCheckIcon />,
      },
      {
        title: "Superimpose",
        key: "superimpose",
        disabled: false,
        selected: openSuperimpos,
        icon: <SuperImposeIcon />,
      },
      {
        title: "Initial Final",
        key: "initialfinal",
        disabled: false,
        selected: openInitialFinal,
        icon: <InitFinalIcon />,
      },
      {
        title: "Grid",
        key: "grid",
        disabled: false,
        selected: openGrid,
        icon: <GridIcon />,
      },

      {
        title: "Lite Mode",
        key: "litemode",
        disabled: false,
        selected: openLiteMode,
        icon: <LiteModeIcon />,
      },
    ]

    const handleClick = (toolName) => {
      switch (toolName) {
        case "collision":
          setOpenCollisionCheck(!openCollisionCheck)
          break
        case "bitecheck":
          setOpenBiteCheck(!openBiteCheck)
          break
        case "superimpose":
          setOpenSuperimpos(!openSuperimpos)
          break
        case "initialfinal":
          setOpenInitialFinal(!openInitialFinal)
          break
        case "grid":
          setOpenGrid(!openGrid)
          break
        case "litemode":
          setOpenLiteMode(!openLiteMode)
          break
      }
    }

    useEffect(() => {
      if (!wasmModule.isInit) return
      viewControlInTreatment.setSpacecheckVisibility(openCollisionCheck)
      viewControlInTreatment.setOcclusionVisibility(openBiteCheck)
      viewControlInTreatment.setSuperimposeVisibility(openSuperimpos)
      initialfinal.setInitialFinalEnabled(openInitialFinal)
    }, [openCollisionCheck, openBiteCheck, openSuperimpos, openInitialFinal])

    // Need to control grid switches individually
    useEffect(() => {
      if (!wasmModule.isInit) return
      viewControlInTreatment.setGridVisibility(openGrid)
    }, [openGrid])

    useEffect(() => {
      if (!wasmModule.isInit) return
      viewControlInTreatment.setAttachmentVisibility(!openLiteMode)
      viewControlInTreatment.specialToothColorToggle(!openLiteMode)
    }, [openLiteMode])

    return (
      <>
        {toolButtons.map(({ title, key, disabled, icon, selected }, index) => {
          return (
            <Tooltip
              style={{ backgroundColor: selected ? "lightgray" : "" }}
              key={index}
              title={title}
              placement="bottom"
            >
              <ToggleButton
                value={key}
                size="large"
                onClick={() => handleClick(key)}
              >
                {icon}
              </ToggleButton>
            </Tooltip>
          )
        })}
      </>
    )
  }

  const checkShowUpperLowerIcons = () => {
    if (curCaseMode === "NewCase") {
      if (hasUploadSTLs[0] && hasUploadSTLs[1]) return true
      else return false
    } else return true
  }
  function UDTopTools(props: any) {
    return (
      <>
        {checkShowUpperLowerIcons() && (
          <Stack
            sx={{
              height: "48px",
              borderRadius: "8px",
              backgroundColor: theme.transBkColor.light,
              boxShadow: 3,
            }}
            direction={"row"}
          >
            <Tooltip
              style={{
                backgroundColor: curArch === "up" ? "lightgray" : "",
              }}
              title="Upper"
              placement="bottom"
            >
              <ToggleButton
                value="showUpper"
                size="large"
                onClick={() => onClickUpperLower(true)}
              >
                <UpperIcon />
              </ToggleButton>
            </Tooltip>
            <Tooltip
              style={{
                backgroundColor: curArch === "low" ? "lightgray" : "",
              }}
              title="Lower"
              placement="bottom"
            >
              <ToggleButton
                value="showLower"
                size="large"
                onClick={() => onClickUpperLower(false)}
              >
                <LowerIcon />
              </ToggleButton>
            </Tooltip>
            {workflowMode === "aidesign" &&
              curCaseMode !== "NewCase" &&
              !isCasePreview && <NewViewButtons />}
          </Stack>
        )}
        {workflowMode === "aidesign" &&
          curCaseMode !== "NewCase" &&
          !isCasePreview && (
            <Stack
              sx={{
                height: 48,
                borderRadius: 2,
                backgroundColor: theme.transBkColor.light,
                boxShadow: 3,
              }}
              direction={"row"}
            >
              <NewToolButtons />
            </Stack>
          )}
      </>
    )
  }

  const CaseModeBtn = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleSelect = (e: any, statusType: string) => {
      dispatch(setCurCaseMode(statusType))
      dispatch(setSwitchViewEdit(statusType === "WeDesign"))
    }
    const handleClose = () => {
      setAnchorEl(null)
    }
    return (
      <>
        <Tooltip title="Status" placement="bottom">
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <StatusIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleSelect(e, "NewCase")}>
            New Case
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "UDPlan")}>
            uDesign
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "UDModi")}>
            Reivew & Modi
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "UDView")}>
            View Only
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "WeDesign")}>
            WeDesign
          </MenuItem>
          <Divider />
          <MenuItem>Case_status: {curStatusString}</MenuItem>
        </Menu>
      </>
    )
  }

  const WorkflowBtn = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleSelect = (e: any, caseType: string) => {
      dispatch(setWorkflowMode(caseType))
    }
    const handleClose = () => {
      dispatch(setWorkflowMode("aidesign"))
      setAnchorEl(null)
    }
    return (
      <>
        {/* 
        <Tooltip title="Workflow" placement="bottom">
          <IconButton
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <WorkflowIcon />
          </IconButton>
        </Tooltip>*/}
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={(e) => handleSelect(e, "aidesign")}>
            AI Design
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "smartrx")}>
            Smart Rx
          </MenuItem>
          <MenuItem onClick={(e) => handleSelect(e, "retainerrx")}>
            Retainer Rx
          </MenuItem>
        </Menu>
      </>
    )
  }

  return (
    <Paper
      sx={{
        left: 16,
        top: 16,
        height: "58px",
        width: "99%",
        backgroundColor: "transparent",
        justifyContent: "center",
        position: "absolute",
      }}
      elevation={0}
    >
      <Stack
        direction="row"
        sx={{ height: "100%" }}
        alignItems={"center"}
        spacing={1}
      >
        <PatientNameID
          firstName={firstName}
          lastName={lastName}
          caseId={caseId}
        />
        <Box sx={{ flexGrow: 1 }} />
        <UDTopTools />
        <ZoomBar />
        <Box sx={{ flexGrow: 1 }} />
        <WorkflowBtn />
        {!ucloud1_1 && <CaseModeBtn />}
        {(curCaseMode === "UDPlan" ||
          curCaseMode === "UDModi" ||
          curCaseMode === "WeDesign") && (
          <>
            <Typography variant="body2">View</Typography>
            <Switch value={switchViewEdit} onChange={handleEditChange}></Switch>
            <Typography variant="body2">Edit</Typography>{" "}
          </>
        )}
        {curCaseMode === "UDModi" && (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              dispatch(resetOrder())
              navigate(`/order/patient/${patientId}/case/${caseId}/bundle`)
            }}
          >
            <OrderrIcon />
            Order
          </Button>
        )}
        {(curCaseMode === "NewCase" || curCaseMode === "UDPlan") &&
          (isNewCaseHasUpload ||
            caseDetail?.udesign_json?.includes("upperUploaded")) && (
            <Button
              sx={{
                background: "#fff",
                borderRadius: "100px",
                padding: "12px 16px",
                height: "50px",
              }}
              variant="contained"
              onClick={() => {
                dispatch(setSmartRxShow(!smartRxShow))
              }}
            >
              <>
                <span
                  style={{
                    color: smartRxShow ? "#215ECD" : "rgba(0, 0, 0, 0.87)",
                  }}
                >
                  {"smartrx"}
                </span>
                <PersonIcon
                  sx={{
                    color: smartRxShow ? "#215ECD" : "rgba(0, 0, 0, 0.87)",
                    marginLeft: "8px",
                  }}
                />
                {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 10,
                top: 10,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
              </>
            </Button>
          )}
        <IconButton
          sx={{
            opacity: 0.5,
            backgroundColor: "#E0E0E0",
          }}
          aria-label="settings"
          onClick={onClickSaveCase}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Paper>
  )
}
