import { FC } from "react"
import { useTranslation } from "react-i18next"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { Invoice } from "../../../core/model/billing"
import { formatCurrency } from "../../../core/utils/formatters"
import { UText } from "../../../ui-component"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "../../../ui-component/mui.components"
import {
  CreditTable,
  ShippingTable,
  UsmileOrderTable,
} from "../../Patient/PatientList/patientTable/table.config"

import { BillingTable } from "./billingTable"

export const InvoiceDetails: FC<{ invoice: Invoice }> = ({ invoice }) => {
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const { t } = useTranslation("common")
  console.log(invoice, "...invoice Details")
  const getInvoiceSummary = (
    item: string,
    value: string | number,
    isLineBreak?: boolean,
  ) => {
    return (
      <>
        <Grid item xs={8} />
        <Grid item xs={4}>
          {isLineBreak ? <Divider /> : ""}
          <Grid container sx={{ py: "3px" }}>
            <Grid item xs={7}>
              <UText
                variant="caption"
                sxProp={{ fontWeight: isLineBreak ? 600 : "" }}
              >
                {item}
              </UText>
            </Grid>
            <Grid item xs={5} display={"flex"} justifyContent={"flex-end"}>
              <UText
                variant="caption"
                sxProp={{ fontWeight: isLineBreak ? 600 : "" }}
              >
                {value}
              </UText>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  const getStlTotal = () => {
    return invoice.details.in_office_outputs.lines.reduce(
      (a, b) => a + b.total,
      0,
    )
  }
  const getUsmileTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.usmile_orders.total,
      0,
    )
  }
  const getUassistTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.uassist_orders.total,
      0,
    )
  }
  const getShippingTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.shipping.total,
      0,
    )
  }
  const getcreditsTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.credits.total,
      0,
    )
  }
  const promotionsOutputTotal = () => {
    return invoice.credit_provided
  }
  const getSubTotal = () => {
    return (
      getStlTotal() +
      getUsmileTotal() +
      getUassistTotal() +
      getcreditsTotal() -
      promotionsOutputTotal()
    )
  }
  const getinvoiceTotal = () => {
    return invoice.total_amount
  }
  const getAmountPaidTotal = () => {
    return invoice.paid_amount
  }
  const getBalancedueTotal = () => {
    return invoice.remaining_amount
  }
  const getSalesTaxTotal = () => {
    return invoice.details.details_by_address.reduce(
      (a, b) => a + b.shipping.tax + b.usmile_orders.tax,
      0,
    )
  }

  return (
    <>
      {invoice?.details?.details_by_address?.length &&
        invoice.details.details_by_address.map((detail) => (
          <Accordion key={detail.address} sx={{ mt: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{
                backgroundColor: "#EEEEEE",
              }}
            >
              {detail.address}
            </AccordionSummary>
            <AccordionDetails>
              <Grid item container>
                <UText variant="h6" sxProp={{ my: 1 }}>
                  {t("billingHistory.invoices.usmile")}
                </UText>
                <BillingTable
                  tableHeader={UsmileOrderTable.columnDef}
                  rows={detail.usmile_orders.lines}
                  currencyCode={invoice.currency.code}
                  showPagination={true}
                />
                <UText variant="h6" sxProp={{ my: 1 }}>
                  {t("billingHistory.invoices.uassist")}
                </UText>
                <BillingTable
                  tableHeader={UsmileOrderTable.columnDef}
                  rows={detail.uassist_orders.lines}
                  showPagination={true}
                  currencyCode={invoice.currency.code}
                ></BillingTable>
                <UText variant="h6" sxProp={{ my: 1 }}>
                  {t("billingHistory.invoices.shipping")}
                </UText>
                <BillingTable
                  tableHeader={ShippingTable.columnDef}
                  rows={detail.shipping.lines}
                  currencyCode={invoice.currency.code}
                  showPagination={false}
                ></BillingTable>
                <UText variant="h6" sxProp={{ my: 1 }}>
                  {t("billingHistory.invoices.credits")}
                </UText>
                <BillingTable
                  tableHeader={CreditTable.columnDef}
                  rows={detail.credits.lines}
                  currencyCode={invoice.currency.code}
                  showPagination={false}
                ></BillingTable>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      <UText
        variant="h6"
        sxProp={{ mt: 3, mb: 1, display: "flex", justifyContent: "start" }}
      >
        {t("billingHistory.invoices.totalforInvoice")}
      </UText>
      <Divider sx={{ mb: 1 }} />
      <Grid container sx={{ pb: 3 }}>
        {getInvoiceSummary(
          t("billingHistory.invoices.stlExports"),
          formatCurrency(getStlTotal(), invoice.currency.code, countryCode),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.usmile"),
          formatCurrency(getUsmileTotal(), invoice.currency.code, countryCode),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.uassist"),
          formatCurrency(getUassistTotal(), invoice.currency.code, countryCode),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.credits"),
          formatCurrency(getcreditsTotal(), invoice.currency.code, countryCode),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.subTotal"),
          formatCurrency(getSubTotal(), invoice.currency.code, countryCode),
          true,
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.shipping"),
          formatCurrency(
            getShippingTotal(),
            invoice.currency.code,
            countryCode,
          ),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.salesTax"),
          formatCurrency(
            getSalesTaxTotal(),
            invoice.currency.code,
            countryCode,
          ),
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.total", {
            currency: invoice.currency.code,
          }),
          formatCurrency(getinvoiceTotal(), invoice.currency.code, countryCode),
          true,
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.amountPaid", {
            currency: invoice.currency.code,
          }),
          formatCurrency(
            getAmountPaidTotal(),
            invoice.currency.code,
            countryCode,
          ),
          true,
        )}
        {getInvoiceSummary(
          t("billingHistory.invoices.balanceDueTotal", {
            currency: invoice.currency.code,
          }),
          formatCurrency(
            getBalancedueTotal(),
            invoice.currency.code,
            countryCode,
          ),
          true,
        )}
      </Grid>
    </>
  )
}
