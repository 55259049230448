import { createAsyncThunk } from "@reduxjs/toolkit"

import { getCustomPackagingListService } from "./customPackaging.service"

export const getCustomPackagingList = createAsyncThunk(
  "customPackaging/getCustomPackagingList",
  async (_, { rejectWithValue, getState }) => {
    console.log("dillan")
    const orgId = getState().userService.user.current_orgId
    try {
      return await getCustomPackagingListService(orgId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
