export const MY_CUSTOM_PACKAGING_TABLE_CONFIG = [
  {
    name: "Account number",
    id: "accNo",
    dataKey: "accNo",
    visible: true,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Company name",
    id: "companyName",
    dataKey: "companyName",
    visible: true,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Created on",
    id: "createdOn",
    dataKey: "createdOn",
    visible: true,
    cell: {
      style: {
        width: 281,
      },
    },
  },
  {
    name: "Status",
    id: "status",
    dataKey: "packagingStatus",
    visible: true,
  },
]

export const PACKAGING_STATUS = [
  {
    status: "Approved",
    color: "#4caf50",
  },
  {
    status: "Uploaded",
    color: "#333333",
  },
]
