import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import DownloadIcon from "@mui/icons-material/Download"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { Invoice } from "../../../core/model/billing"
import { formatCurrency } from "../../../core/utils/formatters"
import { UButton, UText } from "../../../ui-component"
import { Box, Chip, Grid, Paper } from "../../../ui-component/mui.components"
import { STLTable } from "../../Patient/PatientList/patientTable/table.config"
import { formatDate } from "../../Patient/PatientList/util/commonUtil"

import { BillingTable } from "./billingTable"
import { InvoiceDetails } from "./invoiceDetails"

const Invoices: FC<{ invoice: Invoice }> = ({ invoice }) => {
  const [showDetail, setShowDetail] = useState(false)
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const { t } = useTranslation("common")

  const renderInvoice = (
    label: string,
    value: string | number,
    fontweight?: number,
  ) => {
    return (
      <Box>
        <UText
          variant={"caption"}
          component={fontweight ? "div" : "span"}
          sxProp={{
            color: "text.secondary",
            pb: "4px",
            pt: 2,
            fontWeight: fontweight,
          }}
        >
          {label}
        </UText>
        <UText
          variant={"caption"}
          color="text.secondary"
          component={fontweight ? "div" : "span"}
        >
          {value}
        </UText>
      </Box>
    )
  }
  console.log(invoice, "...invoice")

  return (
    <>
      {invoice.invoice_number && countryCode && (
        <Paper
          elevation={0}
          sx={{ borderRadius: 4, mx: 4, minHeight: "100px", px: 4 }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 2,
              pb: 1,
              ml: "-24px",
            }}
          >
            {invoice.status && (
              <Chip
                label={invoice.status}
                sx={{
                  color:
                    invoice.status === "Paid" ? "success.main" : "warning.main",
                  border: "1px solid",
                  fontWeight: 600,
                  borderColor:
                    invoice.status === "Paid" ? "success.main" : "warning.main",
                  ml: "-24px",
                  borderRadius: "6px",
                  backgroundColor: "background.default",
                }}
                variant={"outlined"}
              />
            )}
            {invoice.invoice_url && (
              <UButton
                sxProp={{
                  height: "44px",
                  textTransform: "unset",
                }}
                btnType={"button"}
                startIcon={<DownloadIcon />}
                variant={"contained"}
                btnText={t("billingHistory.downloadInvoice")}
                onClickHandler={() => {
                  window.open(invoice.invoice_url, "_blank")
                }}
              />
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} style={{ paddingTop: 0 }}>
              {renderInvoice(
                t("billingHistory.invoices.invoice"),
                invoice.invoice_number,
                700,
              )}
              {renderInvoice(
                t("billingHistory.invoices.dateRange"),
                t("billingHistory.invoices.dateRangefrom", {
                  from: formatDate(invoice.start_date),
                  to: formatDate(invoice.end_date),
                }),
                700,
              )}
              {renderInvoice(
                t("billingHistory.invoices.dueDate"),
                formatDate(invoice.due_date),
                700,
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {renderInvoice(
                t("billingHistory.invoices.aligners"),
                invoice.total_aligners,
              )}
              {renderInvoice(
                t("billingHistory.invoices.retainers"),
                invoice.total_retainers,
              )}
              {renderInvoice(
                t("billingHistory.invoices.promoLevel"),
                invoice.member_tiers,
              )}
            </Grid>
            <Grid item xs={12} sm={4}>
              {renderInvoice(
                t("billingHistory.invoices.totalAmt"),
                formatCurrency(
                  invoice.total_amount,
                  invoice.currency.code,
                  countryCode,
                ),
              )}
              {renderInvoice(
                t("billingHistory.invoices.amtPaid"),
                formatCurrency(
                  invoice.paid_amount,
                  invoice.currency.code,
                  countryCode,
                ),
              )}
              {renderInvoice(
                t("billingHistory.invoices.creditProvided"),
                formatCurrency(
                  invoice.credit_provided,
                  invoice.currency.code,
                  countryCode,
                ),
              )}
              <Box>
                <UText
                  variant={"body2"}
                  component={"div"}
                  sxProp={{ color: "text.secondary", pb: "4px", pt: 2 }}
                >
                  {t("billingHistory.invoices.balanceDue")}
                </UText>
                <UText
                  variant={"body2"}
                  component={"div"}
                  color="text.secondary"
                  sxProp={{ fontWeight: 700 }}
                >
                  {formatCurrency(
                    invoice.remaining_amount,
                    invoice.currency.code,
                    countryCode,
                  )}
                </UText>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <UButton
              sxProp={{
                fontSize: "11px",
                lineHeight: "169.231%",
                letterSpacing: "0.46px",
                height: "30px",
                color: "primary.main",
                mb: 2,
              }}
              size={"small"}
              variant={"text"}
              btnText={
                !showDetail
                  ? t("billingHistory.invoices.showInvoice")
                  : t("billingHistory.invoices.hideInvoice")
              }
              onClickHandler={() => {
                setShowDetail(!showDetail)
              }}
              endIcon={showDetail ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            />
            {showDetail && (
              <Box>
                {invoice?.details?.in_office_outputs?.lines?.length ? (
                  <>
                    <UText variant="h6" sxProp={{ mb: 2 }}>
                      {t("billingHistory.invoices.stlExports")}
                    </UText>
                    <BillingTable
                      tableHeader={STLTable.columnDef}
                      rows={invoice.details.in_office_outputs.lines}
                      showPagination={false}
                      currencyCode={invoice.currency.code}
                    ></BillingTable>
                  </>
                ) : (
                  ""
                )}
                <InvoiceDetails invoice={invoice} />
              </Box>
            )}
          </Box>
        </Paper>
      )}
    </>
  )
}

export default Invoices
