import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import CloseIcon from "@mui/icons-material/Close"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, IconButton, Link } from "@mui/material"

import { UButton, UModalPopup, UText } from "../../../ui-component"

import UFileTextField from "./UFileTextField"

const NewPackackingModal = ({ showAddModal, setShowAddModal }) => {
  const { t } = useTranslation("common")
  const [showAdditionalNote, setShowAdditionalNote] = useState(false)
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [topImage, setTopImage] = useState(undefined)
  const [sideImage, setSideImage] = useState(undefined)

  useEffect(() => {
    if (topImage || sideImage) {
      setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [topImage, sideImage])

  return (
    <UModalPopup
      sxModalProps={{
        ".MuiDialog-paper": {
          maxWidth: "500px",
          overflowY: "scroll",
        },
        ".MuiDialogTitle-root": {
          width: "100%",
        },
        ".MuiDialogContent-root": {
          px: "20px",
          overflowY: "unset",
        },
        "#btnListCntr": {
          ">.MuiGrid-item": {
            display: "flex",
          },
          flexDirection: "column",
          gap: 1,
        },
      }}
      title={<ModalTitle setShowAddModal={setShowAddModal} />}
      btnList={[
        <UButton
          disabled={isBtnDisabled}
          sxProp={{
            width: "100%",
            textTransform: "capitalize",
            marginX: "20px",
            backgroundColor: "success.light",
            "&:hover": {
              backgroundColor: "success.main",
            },
          }}
          btnText="Upload"
          variant="contained"
          key={t("customPackaging.newPackagingModal.uploadBtn")}
        />,
        <UButton
          sxProp={{
            width: "100%",
            textTransform: "capitalize",
            marginX: "20px",
            color: "text.secondary",
            "&:hover": {
              color: "text.primary",
              backgroundColor: "#F6F6F6",
            },
          }}
          btnText="Cancel"
          variant="text"
          onClickHandler={() => setShowAddModal(false)}
          key={t("customPackaging.newPackagingModal.cancelBtn")}
        />,
        <Box
          key={"statement"}
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: 3,
            width: "100%",
          }}
        >
          <UButton
            sxProp={{
              width: "100%",
              justifyContent: "space-between",
              color: "text.primary",
              fontSize: "12px",
              fontWeight: "400",
              padding: "16px 24px",
            }}
            disableRipple
            onClickHandler={() => {
              setShowAdditionalNote(!showAdditionalNote)
            }}
            endIcon={
              showAdditionalNote ? <ExpandLessIcon /> : <ExpandMoreIcon />
            }
            variant={"text"}
            btnText={t(
              "customPackaging.newPackagingModal.statementOnBranding.title",
            )}
          />
          {showAdditionalNote && (
            <Box sx={{ padding: "0px 24px 16px 24px" }}>
              <UText variant={"caption"}>
                {t(
                  "customPackaging.newPackagingModal.statementOnBranding.content",
                )}
              </UText>
            </Box>
          )}
        </Box>,
      ]}
      content={
        <ModalContent setTopImage={setTopImage} setSideImage={setSideImage} />
      }
      isAlert={showAddModal}
    />
  )
}

const ModalTitle = ({ setShowAddModal }) => {
  const { t } = useTranslation("common")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
        marginBottom: "12px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        <IconButton onClick={() => setShowAddModal(false)} size={"small"}>
          <CloseIcon sx={{ height: "24px", width: "24px" }} />
        </IconButton>
      </Box>
      <UText sxProp={{ p: 2, fontWeight: 600 }} variant={"h5"}>
        {t("customPackaging.newPackagingModal.title")}
      </UText>
    </Box>
  )
}

const ModalContent = ({ setTopImage, setSideImage }) => {
  const { t } = useTranslation("common")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        marginBottom: 2,
      }}
    >
      <UText variant={"body2"}>
        {t("customPackaging.newPackagingModal.instruction1")}
      </UText>
      <UText variant={"body2"} sxProp={{ fontWeight: 700 }}>
        {t("customPackaging.newPackagingModal.fileFormat.title")}
      </UText>
      <UText variant={"body2"}>
        {t("customPackaging.newPackagingModal.fileFormat.text1")}
      </UText>
      <UText variant={"body2"}>
        {t("customPackaging.newPackagingModal.fileFormat.text2")}
      </UText>
      <UText variant={"body2"}>
        {t("customPackaging.newPackagingModal.fileFormat.text3")}
      </UText>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <IconButton sx={{ p: 0 }} size={"small"}>
          <AttachFileIcon />
        </IconButton>
        <UFileTextField
          handleOnClickClose={() => {
            setTopImage(undefined)
          }}
          label={"Top image"}
          fieldName={"topImage"}
          onInputchange={(files) => {
            setTopImage(files[0])
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: 1,
        }}
      >
        <IconButton sx={{ p: 0 }}>
          <AttachFileIcon />
        </IconButton>
        <UFileTextField
          handleOnClickClose={() => {
            setSideImage(undefined)
          }}
          label={"Side image"}
          fieldName={"sideImage"}
          onInputchange={(files) => {
            setSideImage(files[0])
          }}
        />
      </Box>
      <UText variant={"body2"}>
        <Trans
          components={{
            MailLink: <Link href="mailto: artwork@ulabsystems.com" />,
          }}
        >
          {"customPackaging.newPackagingModal.instruction2"}
        </Trans>
      </UText>
    </Box>
  )
}

export default NewPackackingModal
