import React, { useCallback, useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { Typography, Button, Box, Stack } from "@mui/material"
import Divider from "@mui/material/Divider"
import {
  EArchTreatmentType,
  EArchType,
  caseManagement,
  preview,
  wasmModule,
} from "@/gluelayer"
import { UButton, UFileBox, UFileProcessingCard, UText } from "@/ui-component"
import {
  uploadFiles,
  downloadFiles,
  requestCloudPresetup,
  testForLoading,
} from "@/core/app/slices/v2/v2apis"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import store from "@/core/app/store"
import {
  fetchFileList,
  getCaseInfoApi,
  getTreatmentInfos,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { LeftPage } from "../left/LeftPage"
import { RootState } from "@/core/app/store"
import { updateCaseById } from "@/core/app/slices/case/caseThunkApi"
import oktaAuthClient from "@/core/config/okta.config"
import {
  setShowBottomStatusBar,
  setBottomStatusMsg,
  setBottomProgress,
  setHasUploadSTLs,
  setIsNewCaseHasUpload,
} from "../udTreatSlice"

import { ReopenCaseDlg } from "./ReopenCaseDlg"
import { UploadCaseDlg } from "./UploadCaseDlg"
import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import { setDisable } from "../udSetupSlice"

export function UploadSTL({ onClose }) {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const [caseType, setCaseType] = useState<string>(null)
  const { caseId, patientId } = useParams()
  const orgId = store.getState().userService.user.current_orgId
  const [reopenDlg, setReopenDlg] = useState(false)
  const [openSaveCaseDlg, setOpenSaveCaseDlg] = useState(false)

  const { isCasePreview, ucloud1_1, caseRAtype } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const UploadSTLBox2 = () => {
    return isCasePreview ? (
      <Box
        sx={{
          width: "410px",
          height: "100%",
          background: "#fff",
          padding: 2,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            width: "410px",
            height: "100%",
            background: "#fff",
            padding: 2,
            borderRadius: 1,
          }}
        >
          <>
            <Box id="upper">
              <UText variant={"caption"}>Upper</UText>
              {upperStl.action === "stl" || upperStl.action === "download" ? (
                <UFileProcessingCard
                  fileName={
                    upperStl.action === "stl"
                      ? upperStl.data?.name
                      : "Uploaded Scan"
                  }
                  fileSize={upperStl.data?.size}
                  progressValue={100}
                  progressstatus={"complete"}
                  onRemove={() => {
                    setUpperStl({ data: null, action: "delete" })
                  }}
                ></UFileProcessingCard>
              ) : (
                <UFileBox
                  id={"Upper"}
                  allowedFileExtensions={["stl", "meshinspector"]}
                  allowedfileSize={101200}
                  selectedFile={(file) => {
                    setUpperStl({ data: file[0], action: "stl" })
                  }}
                  fileAcceptType={".stl"}
                  boxSize={{
                    width: "100%",
                    height: 88,
                    padding: "24px 16px",
                  }}
                  fileBoxContent={{
                    flexDirection: "row",
                    gap: 2,
                  }}
                  isRequired={false}
                  messages={{
                    fileNote: t("records.scans.fileNote"),
                    uploadButton: t("records.scans.uploadButton"),
                    uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                    invalidfileFormat: t("records.scans.invalidfileFormat"),
                    invalidFileSize: t("records.scans.invalidSTLFileSize"),
                  }}
                  fileLimit={1}
                ></UFileBox>
              )}
            </Box>
            <Box id="lower">
              <UText variant={"caption"}>Lower</UText>
              {lowerStl.action === "stl" || lowerStl.action === "download" ? (
                <UFileProcessingCard
                  fileName={
                    lowerStl.action === "stl"
                      ? lowerStl.data?.name
                      : "Uploaded Scan"
                  }
                  fileSize={lowerStl.data?.size}
                  progressValue={100}
                  progressstatus={"complete"}
                  onRemove={() => {
                    setLowerStl({ data: null, action: "delete" })
                  }}
                ></UFileProcessingCard>
              ) : (
                <UFileBox
                  id={"Lower"}
                  allowedFileExtensions={["stl", "meshinspector"]}
                  allowedfileSize={101200}
                  selectedFile={(file) => {
                    setLowerStl({ data: file[0], action: "stl" })
                  }}
                  fileAcceptType={".stl"}
                  boxSize={{
                    width: "100%",
                    height: 88,
                    padding: "24px 16px",
                  }}
                  fileBoxContent={{
                    flexDirection: "row",
                    gap: 2,
                  }}
                  isRequired={false}
                  messages={{
                    fileNote: t("records.scans.fileNote"),
                    uploadButton: t("records.scans.uploadButton"),
                    uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                    invalidfileFormat: t("records.scans.invalidfileFormat"),
                    invalidFileSize: t("records.scans.invalidSTLFileSize"),
                  }}
                  fileLimit={1}
                ></UFileBox>
              )}
            </Box>
          </>
          {showNext()}
        </Box>
      </Box>
    ) : (
      <Box>
        <p>Presetup has been done for this case.</p>
        <p>Can not upload new STLs.</p>
        <p>Please continue photo & Rx form!</p>
      </Box>
    )
  }

  useEffect(() => {
    dispatch(resetCase())
    caseManagement.closeCase()
    if (isCasePreview) {
      dispatch(setShowBottomStatusBar(true))
      dispatch(setBottomStatusMsg("Create new case."))
      dispatch(setBottomProgress(false))
    }
    preview.drawSTL({
      // Must called before upload real stl. Else the 1st STL is NOT shown.
      upperArch: upperStl.data,
      lowerArch: lowerStl.data,
      canvas: document.getElementById("canvas") as HTMLCanvasElement,
      zoomRange: [0.25, 2],
    })
    return () => {
      dispatch(setShowBottomStatusBar(false))
      // preview.clearPreview()
    }
  }, [])

  useEffect(() => {
    dispatch(setHasUploadSTLs([upperStl.data !== null, lowerStl.data !== null]))
    preview.drawSTL({
      upperArch: upperStl.data,
      lowerArch: lowerStl.data,
      canvas: document.getElementById("canvas") as HTMLCanvasElement,
      zoomRange: [0.25, 2],
    })
  }, [upperStl, lowerStl])

  const SelCaseType = () => {
    return (
      <Box
        sx={{
          margin: "10px 0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Divider></Divider>
        <UText sxProp={{ margin: "10px 0px" }} variant={"subtitle2"}>
          What type of case is this?
        </UText>
        <UButton
          sxProp={{ margin: "10px 0px", color: "#ffffff" }}
          variant={"contained"}
          btnText={"ALIGNER"}
          onClickHandler={() => {
            setCaseType("Aligner")
          }}
        ></UButton>
        <UButton
          sxProp={{ color: "#ffffff" }}
          variant={"contained"}
          btnText={"RETAINER"}
          onClickHandler={() => {
            setCaseType("Retainer")
          }}
        ></UButton>
      </Box>
    )
  }

  const SelAlignerType = () => {
    const onClickSmartRx = async () => {
      dispatch(setBottomStatusMsg("Renew token."))
      const renewToken = await oktaAuthClient.token.renewTokens()
      await oktaAuthClient.tokenManager.setTokens(renewToken)
      // Upload raw data and do presetup
      dispatch(setBottomStatusMsg("Save STL to MTC."))
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      // fill holes
      dispatch(setBottomStatusMsg("Fill hole."))
      await preview.fillHole()
      dispatch(setBottomStatusMsg("Get Case Files."))
      const files = await caseManagement.getCaseFiles()
      // download for testing
      // const keys = Object.keys(files)
      // for (let index = 0; index < keys.length; index++) {
      //   const key = keys[index]
      //   const file = files[key]
      //   resourcesSynchronization.download(file.name,file)
      // }

      // upload
      dispatch(setBottomStatusMsg("Upload STL to AWS."))
      dispatch(setBottomProgress(true))
      const isOk = await dispatch(uploadFiles({ caseId, zipFiles: files }))
      // const downloadUrl = await dispatch(getS3DownloadUrl({caseId,files:['bulk0']}))
      console.log("上传成功了吗", isOk.payload)

      // update udesign_json
      if (caseDetail) {
        const udesign_json = caseDetail.udesign_json
          ? JSON.parse(caseDetail.udesign_json)
          : {}
        const newUdesignJsonObj = {
          ...udesign_json,
          upperUploaded: upperStl.data ? true : false,
          lowerUploaded: lowerStl.data ? true : false,
        }
        dispatch(setBottomStatusMsg("Update case by Id."))

        dispatch(
          updateCaseById({
            patientId,
            caseId,
            payload: {
              case_extra_attrs: JSON.stringify(newUdesignJsonObj),
            },
          }),
        )
      }
      dispatch(setBottomProgress(false))
      //invoke presetup
      const hasUpper = upperStl.data ? true : false
      const hasLower = lowerStl.data ? true : false
      console.log("do presetup::", hasUpper, hasLower)
      dispatch(setBottomStatusMsg("Running AI presetup."))
      dispatch(setBottomProgress(true))
      const isPresetupOK = await dispatch(
        requestCloudPresetup({
          orgId,
          upper: hasUpper,
          lower: hasLower,
          patientId,
          caseId,
        }),
      )
      dispatch(
        setBottomStatusMsg(
          "AI presetup result:" + isPresetupOK.payload ? "Success" : "Failed",
        ),
      )
      dispatch(setBottomProgress(false))

      console.log("presetup finished?", isPresetupOK)
      if (isPresetupOK.payload === false) {
        console.warn("Presetup is false.")
        return
      }
      setReopenDlg(true)

      /* 
      dispatch(setBottomStatusMsg("Reopen case."))
      // when presetup has finished then reopen case
      await dispatch(resetCase())
      caseManagement.closeCase()
      dispatch(getTreatmentInfos({ patientId }))
      await dispatch(
        fetchFileList({
          patientId,
          caseId,
        }),
      )
      dispatch(setBottomStatusMsg("Done!"))
      dispatch(setBottomProgress(false)) */
    }
    return (
      <Box
        id="other"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Divider sx={{ margin: "20px 0px 0px 0px" }}></Divider>
        <UText sxProp={{ margin: "10px 0px" }} variant={"subtitle2"}>
          What type of case is this?
        </UText>
        <UText
          sxProp={{ margin: "0px 10px" }}
          variant={"body2"}
          color={"text.secondary"}
        >
          {caseType}
        </UText>
        <Divider sx={{ margin: "20px 0px 0px 0px" }}></Divider>
        <UText
          sxProp={{ margin: "10px 0px" }}
          color={"text.primary"}
          variant={"subtitle2"}
        >
          How Would you like to plan your case?
        </UText>
        <UButton
          sxProp={{ margin: "10px 0px", color: "#ffffff" }}
          variant={"contained"}
          btnText={"SMARTRX"}
          onClickHandler={onClickSmartRx}
        ></UButton>
        <UButton
          sxProp={{ color: "#ffffff" }}
          variant={"contained"}
          btnText={"AI PLAN"}
          onClickHandler={async () => {
            // download zip files for test
            // const files = await dispatch(downloadFiles({caseId,files:['raw','bulk0','bulk1','bulk2','bulk10','photo']}))
            // console.log('>>>>>',files)
            // test for loading bar
            dispatch(testForLoading())
          }}
        ></UButton>
        <UText
          sxProp={{ margin: "10px 0px" }}
          color={"text.secondary"}
          variant={"subtitle2"}
        >
          Our AI will work in the background to create your case with either
          choice.final text tbd.
        </UText>
      </Box>
    )
  }

  const ConfirmButton = () => {
    return (
      <Stack marginTop={2} direction="row-reverse">
        <Button variant="contained" onClick={() => setOpenSaveCaseDlg(true)}>
          Confirm
        </Button>
      </Stack>
    )
  }

  const showNext = () => {
    if (upperStl.data && lowerStl.data) {
      if (ucloud1_1) return <ConfirmButton />
      if (caseType) {
        return <SelAlignerType />
      } else {
        return <SelCaseType />
      }
    }
    return <></>
  }

  const onReopenOK = async () => {
    setReopenDlg(false)
    dispatch(setBottomStatusMsg("Reopen case."))
    // when presetup has finished then reopen case
    await dispatch(resetCase())
    caseManagement.closeCase()
    dispatch(getTreatmentInfos({ patientId }))
    await dispatch(
      fetchFileList({
        patientId,
        caseId,
      }),
    )
    dispatch(setShowBottomStatusBar(false))
  }

  const onClickSaveCaseOK = () => {
    preview.saveStlToMtc(upperStl.data, lowerStl.data)
    const caseType =
      caseRAtype === "alinger"
        ? EArchTreatmentType.Aligner
        : EArchTreatmentType.Retainer
    preview.saveArchType(caseType, caseType)

    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        const params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        console.log("🚀 ~ caseManagement.getCaseFiles ~ zips:", zips)
        dispatch(setDisable(true))
        dispatch(uploadCaseZips(params)).then((r) => {
          if (r.payload) {
            // upload zips success
            dispatch(setIsNewCaseHasUpload(true))
          }
        })
        // update udesign_json
        if (caseDetail) {
          const udesign_json = caseDetail.udesign_json
            ? JSON.parse(caseDetail.udesign_json)
            : {}
          const newUdesignJsonObj = {
            ...udesign_json,
            upperUploaded: upperStl.data ? true : false,
            lowerUploaded: lowerStl.data ? true : false,
          }
          dispatch(
            updateCaseById({
              patientId,
              caseId,
              payload: {
                case_extra_attrs: JSON.stringify(newUdesignJsonObj),
              },
            }),
          )
        }
        setOpenSaveCaseDlg(false)
      }
    })
  }

  return (
    <>
      <LeftPage
        title="Upload STL and start case"
        onClose={onClose}
        content={<UploadSTLBox2 />}
      />
      <ReopenCaseDlg />
      <UploadCaseDlg
        open={openSaveCaseDlg}
        onCancel={() => {
          setOpenSaveCaseDlg(false)
        }}
        onOK={onClickSaveCaseOK}
      />
    </>
  )
}
