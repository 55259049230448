import { createAsyncThunk } from "@reduxjs/toolkit"

import { QUERY_PARAMS_STORAGE_KEY } from "../../../../modules/Patient/PatientList/patientTable/table.config"

import {
  addNewCaseService,
  createPatient,
  fetchPatientByIdService,
  getExistingPatientList,
  getPatientList,
  updatePatient,
  addPatientServiceV2,
  addCaseServiceV2,
} from "./patient.service"
import {
  AddNewPatientParams,
  CreateCaseParams,
  ExistingPatientQueryParams,
  PatientQueryparams,
  updatePatientParams,
} from "./patient.types"

export const fetchPatientList = createAsyncThunk(
  "patientService/fetchPatientList",
  async (
    {
      patientQueryparams,
    }: { patientQueryparams: PatientQueryparams; filterType: string },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      localStorage.setItem(
        QUERY_PARAMS_STORAGE_KEY,
        JSON.stringify(patientQueryparams),
      )
      return await getPatientList(orgId, patientQueryparams)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const addNewPatient = createAsyncThunk(
  "patientService/addNewPatient",
  async (
    addNewPatientParams: AddNewPatientParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await createPatient(orgId, addNewPatientParams)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const addNewCase = createAsyncThunk(
  "patientService/addNewCaseService",
  async (
    { patientId, payload }: CreateCaseParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await addNewCaseService(orgId, patientId, payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchPatientById = createAsyncThunk(
  "patientService/fetchPatientById",
  async (
    { patientId }: { patientId: string },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await fetchPatientByIdService(orgId, patientId)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchExistingPatientList = createAsyncThunk(
  "patientService/fetchExistingPatientList",
  async (
    {
      existingPatientQueryparams,
    }: { existingPatientQueryparams: ExistingPatientQueryParams },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getExistingPatientList(orgId, existingPatientQueryparams)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updatePatientData = createAsyncThunk(
  "patientService/updatePatientData",
  async (
    { patientId, payload }: { patientId: string; payload: updatePatientParams },
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await updatePatient(orgId, patientId, payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

// add patient---version2
export const addPatientV2 = createAsyncThunk(
  "patientService/addPatientV2",
  async (payload: AddNewPatientParams, { rejectWithValue }) => {
    try {
      return await addPatientServiceV2(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
// add case---version2
export const addCaseV2 = createAsyncThunk(
  "patientService/addCaseV2",
  async (payload: AddNewPatientParams, { rejectWithValue }) => {
    try {
      return await addCaseServiceV2(payload)
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
