import axios, { AxiosProgressEvent } from "axios"

import httpIT from "@/core/global/httpIT"
import HttpS3 from "@/core/global/https3"

import { getResizedFile } from "../../../../../modules/Records/Photograph/photographUtil"
import http from "../../../../global/http"

export const uploadPhotographFiles = (
  orgId: string,
  patientId: string,
  caseId: string,
  fileName: string,
  formData: FormData,
  callback: (progress: AxiosProgressEvent) => void,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try {
    return getResizedFile(formData.get("attachment")).then(
      (resizedFile: File) => {
        formData.set("attachment", resizedFile)
        const res = http.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: callback,
        })
        return res
      },
    )
  } catch (err) {
    return err
  }
}

export const getS3FileUrl = ({ caseId, casePhoto }) => {
  return httpIT.post(`/ulab/case/api/v2/case/s3url`, {
    caseId,
    urlType: "upload",
    casePhoto,
  })
}

export const uploadPhoto = ({ url, file }) => {
  const res = HttpS3({ url }).put("", file)
  return res
}

export const downloadPhotographFiles = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  fileName: string,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try {
    const res = http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}

export const fetchFileList = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/files`
  try {
    const res = http.get(url)
    return res
  } catch (err) {
    return err
  }
}

export const updatePhotoPositionAPI = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  payload: {
    photo_position: string
  },
) => {
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs`
  try {
    const res = http.patch(url, payload)
    return res
  } catch (err) {
    return err
  }
}
