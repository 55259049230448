import { FC, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import { LoadingButton } from "@mui/lab"
import { caseManagement } from "@/gluelayer"

import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import PatientInfoHeader from "../../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import {
  unlockCaseApi,
  uploadCaseZips,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { resetPayload } from "../../../core/app/slices/order/orderSlice"
import { placeManufacturingOrders } from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { UButton, UText } from "../../../ui-component"
import { Box, Grid, Link, Paper } from "../../../ui-component/mui.components"
import OrderSummary from "../Confirmation/OrderSummary"
import ExitOrderAlertPopup from "../ExitOrderAlertPopup"
import DeliveryService from "../Shipping/components/DeliveryService"
import PackageType from "../Shipping/components/PackageType"
import ShippingAddress from "../Shipping/components/ShippingAddress"

const ReviewOrder: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const navigate = useMyNavigation()
  const { patientId, caseId } = useParams()
  const [showPlaceOrderBtn, setShowPlaceOrderBtn] = useState<boolean>(false)
  const [placingOrder, setPlacingOrder] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const { currentOrder, isAlignerCase } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )

  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  const LANG_CODE = "en"
  const termsHref = useMemo(() => {
    if (countryCode) {
      return `https://hub.ulabsystems.net/templates/terms/${countryCode.toLowerCase()}_${LANG_CODE}.html`
    }
  }, [countryCode])

  const policyHref = useMemo(() => {
    if (countryCode) {
      return `https://hub.ulabsystems.net/templates/privacy/${countryCode.toLowerCase()}_${LANG_CODE}.html`
    }
  }, [countryCode])

  const handlePlaceManufacturingOrder = () => {
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    const subOrdersArray = currentOrder.subOrderId
      ? [currentOrder.subOrderId]
      : []
    dispatch(
      placeManufacturingOrders({
        orderId: currentOrder.orderId + "",
        payload: {
          txplan_name: caseDetail.txplanName || "",
          txplan_subname: caseDetail.txplanSubname || "",
          sub_orders: subOrdersArray,
          status: 20,
        },
      }),
    ).then((res) => {
      setPlacingOrder(false)
      if (res.payload?.status === 200) {
        dispatch(resetPayload())
        dispatch(unlockCaseApi({ patientId, caseId }))
        navigate(
          `/order/patient/${patientId}/case/${caseId}/order/${currentOrder.orderId}/confirmation`,
        )
      } else {
        dispatch(
          setAlert({
            message: t("overview.order.orderfailed"),
            isError: true,
          }),
        )
      }
    })
  }

  // when you click next ,please upload zips
  // sync zips to backend server
  const uploadzipsAndPlaceOrder = () => {
    caseManagement.getCaseFiles(["bulk2"]).then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        dispatch(
          uploadCaseZips({
            zips,
            patientId,
            caseId,
          }),
        ).then((res) => {
          handlePlaceManufacturingOrder()
        })
      }
    })
  }

  useEffect(() => {
    if (placingOrder) {
      uploadzipsAndPlaceOrder()
    }
  }, [placingOrder])

  useEffect(() => {
    if (currentOrder.orderId) {
      dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
    }
  }, [dispatch])

  return (
    <>
      <Grid
        sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}
      >
        <Grid item minWidth={{ lg: "1152px" }} md={11} sm={10}>
          <PatientInfoHeader
            chipLabel={"Place Order"}
            cancelBtnText={t("button.close")}
            onBtnCancelClick={() => {
              dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
              setShowAlert(true)
            }}
            chipSx={{
              background: "#0288D1",
              fontSize: "13px",
              fontWeight: "400px",
              color: "primary.contrastText",
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <UText component={"div"} variant="h4">
              {t("overview.order.review")}
            </UText>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        direction={"row"}
        gap={"24px"}
        sx={{
          display: "flex",
          pt: 6,
          justifyContent: "center",
        }}
      >
        <Grid item lg={7} md={7} sm={10}>
          <ShippingAddress isReviewOrder={true} />

          <Box sx={{ mt: 2 }}>
            <DeliveryService isReviewOrder={true} />
          </Box>
          <Box sx={{ mt: 2 }}>
            <PackageType isReviewOrder={true} />
          </Box>
          {currentOrder?.orderId && !!isAlignerCase ? (
            <Paper
              elevation={0}
              sx={{
                py: 5,
                px: 2,
                mt: 3,
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <UText variant="body1" sxProp={{ px: 2, py: "2px" }}>
                  {"Additional aligner storage case"}
                </UText>
              </Box>
              <Box
                sx={{
                  px: "12px",
                }}
              >
                <UText sxProp={{ p: "12px" }} variant={"subtitle2"}>
                  {"Included"}
                </UText>
              </Box>
            </Paper>
          ) : null}
        </Grid>
        <Grid
          item
          justifyContent={"centre"}
          lg={4}
          md={4}
          sm={10}
          minWidth={{ lg: "446px" }}
        >
          <Paper elevation={0} sx={{ p: 2 }}>
            <OrderSummary
              isreview={true}
              setShowPlaceOrderBtn={setShowPlaceOrderBtn}
            />
            <LoadingButton
              loading={placingOrder}
              loadingPosition="start"
              variant="contained"
              disabled={showPlaceOrderBtn}
              size="large"
              onClick={() => setPlacingOrder(true)}
              sx={{
                width: "100%",
                mt: 4,
                mb: 2,
                ".MuiLoadingButton-loadingIndicator": {
                  left: { lg: "30%", md: "20%" },
                },
              }}
            >
              {placingOrder
                ? t("overview.order.placingorder")
                : t("overview.order.placeorder")}
            </LoadingButton>
            <Box sx={{ mb: 4 }}>
              <UText component={"div"} variant={"caption"}>
                {t("overview.order.tnc.0")}
                <Link href={termsHref} target={"_blank"}>
                  {t("overview.order.tnc.1")}
                </Link>
                {t("overview.order.tnc.2")}
                <Link href={policyHref} target={"_blank"}>
                  {t("overview.order.tnc.3")}
                </Link>
              </UText>
            </Box>
          </Paper>
        </Grid>
        <Grid item minWidth={{ lg: "1152px" }} md={11} sm={10}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pb: 6,
              pt: 3,
            }}
          >
            <UButton
              variant="outlined"
              onClickHandler={() => {
                dispatch(
                  setIsRouteChangeBlocked({ isRouteChangeBlocked: false }),
                )
                navigate(`/order/patient/${patientId}/case/${caseId}/shipping`)
              }}
              sxProp={{ width: "96px", height: "36px" }}
              startIcon={
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
              }
              btnText={t("button.back")}
            />
          </Box>
        </Grid>
      </Grid>
      <ExitOrderAlertPopup
        showAlert={showAlert}
        closeAlert={() => {
          dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
          setShowAlert(false)
        }}
        buttonTxt={t("button.cancel")}
      />
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default ReviewOrder
