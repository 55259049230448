import { createSlice } from "@reduxjs/toolkit"

import { Invoice } from "../../../model/billing"

import { fetchInvoiceHistory, getOutstandingBalancebyOrg } from "./billingThunk"

interface Billing {
  outstandingBalance: null | number
  invoices: Invoice[]
  loading: "idle" | "pending" | "succeeded" | "failed"
}
const initialState: Billing = {
  loading: "idle",
  outstandingBalance: null,
  invoices: null,
}

export const BillingServiceSlice = createSlice({
  name: "billingService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOutstandingBalancebyOrg.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getOutstandingBalancebyOrg.fulfilled, (state, action) => {
      state.outstandingBalance = action?.payload?.data?.result?.balance
      state.loading = "succeeded"
    })
    builder.addCase(getOutstandingBalancebyOrg.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(fetchInvoiceHistory.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchInvoiceHistory.fulfilled, (state, action) => {
      if (state?.invoices?.length) {
        state.invoices.push(...action.payload)
      } else {
        state.invoices = action.payload
      }
      state.loading = "succeeded"
    })

    builder.addCase(fetchInvoiceHistory.rejected, (state) => {
      state.loading = "failed"
    })
  },
})
