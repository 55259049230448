import { FC, ReactElement, useEffect, useState } from "react"

import { UButton } from "../../../../../components"

export interface IActionButtonProps {
  btnText: string
  variant: "text" | "contained" | "outlined" | "shade"
  startIcon: ReactElement
  bgColor: string
  isActive: boolean
}

const UActionButton: FC<IActionButtonProps> = ({
  btnText,
  variant,
  startIcon,
  bgColor,
  isActive,
}) => {
  const [isButtonActive, setIsButtonActive] = useState<boolean>(false)

  useEffect(() => {
    setIsButtonActive(isActive)
  }, [isActive])

  return (
    <UButton
      size={"medium"}
      sxProp={{
        width: "172px",
        flexGrow: { sm: 1 },
        height: "38px",
        padding: "6px 16px",
        border: !isButtonActive ? "1px solid rgba(0, 0, 0, 0.87)" : "",
        color: !isButtonActive ? "text.primary" : "#FFF",
        backgroundColor: bgColor,
        "&:hover": {
          backgroundColor: "#616161",
          color: "#FFF",
          border: "none",
        },
        "&:active": {
          backgroundColor: "success.main",
          color: "#FFF",
          border: "none",
        },
      }}
      btnText={btnText}
      variant={variant}
      startIcon={startIcon}
    />
  )
}

export default UActionButton
