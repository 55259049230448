import { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { throttle } from "lodash"

import { UText } from "../components"
import { Grid } from "../components/mui.components"
import { useAppDispatch, useAppSelector } from "../core/app/hooks"
import { getActiveOrdersAPI } from "../core/app/slices/order/orderThunkApi"
import { OrderQueryparams } from "../core/app/slices/patients/patient.types"
import { RootState } from "../core/app/store"
import { useMyNavigation } from "../hooks/useMyNavigation"
import UTable from "../modules/Patient/PatientList/components/UTable/UTable"
import {
  defaultOrderParams,
  orderTableData,
  QUERY_PARAMS_STORAGE_KEY_ORDER,
} from "../modules/Patient/PatientList/patientTable/table.config"

const ActiveOrders: FC = () => {
  let queryParams: any = localStorage.getItem(QUERY_PARAMS_STORAGE_KEY_ORDER)
  if (queryParams) {
    queryParams = JSON.parse(queryParams)
  }
  const [tableQueryParams, setTableQueryParams] = useState<OrderQueryparams>(
    queryParams || defaultOrderParams,
  )
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const { orderData, loading, paging } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  useEffect(() => {
    if (tableQueryParams) {
      dispatch(getActiveOrdersAPI(tableQueryParams))
    }
  }, [tableQueryParams])

  const onSearch = (value: string) => {
    throttleOnSearch(value)
  }

  const handleChangePage = (newpage: number) => {
    setTableQueryParams({
      ...tableQueryParams,
      page: newpage,
    })
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    setTableQueryParams({
      ...tableQueryParams,
      page_size: newPageSize,
      page: 1,
    })
  }

  const sortHandler = (value: string) => {
    setTableQueryParams({
      ...tableQueryParams,
      sort_by: value,
      sort_type:
        tableQueryParams.sort_by === value &&
          tableQueryParams.sort_type === "asc"
          ? "desc"
          : "asc",
    })
  }

  const onClearAllFilter = () => {
    setTableQueryParams({
      ...defaultOrderParams,
      search_key: "",
    })
  }

  const throttleOnSearch = useCallback(
    throttle((value) => {
      setTableQueryParams({
        ...tableQueryParams,
        page: defaultOrderParams.page,
        page_size: defaultOrderParams.page_size,
        search_key: value,
      })
    }, 500),
    [],
  )

  const onRowClick = (row) => {
    navigate(`/overview/patient/${row.patientId}`)
  }

  return (
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        minWidth={"lg"}
        sx={{ minHeight: "85vh" }}
      >
        <Grid container maxWidth={"lg"} flexDirection={"column"}>
          <Grid container item display={"flex"} sx={{ mt: 3, px: 1 }} py={"16px"}>
            <Grid item xs={12}>
              <UText variant={"h4"}>{t("overview.ordersOverviewTitle")}</UText>
            </Grid>
          </Grid>

          <Grid item container justifyContent={"center"} sx={{ px: 1 }}>
            <UTable
              name={"Active orders"}
              columnDef={orderTableData.columnDef}
              columnData={orderData}
              enableFilters={false}
              onRowClick={onRowClick}
              enableSearch={true}
              onSearch={onSearch}
              hideMenu={true}
              paging={paging}
              isFetching={loading}
              hidePagination={false}
              onPageChange={handleChangePage}
              onClearAllFilter={onClearAllFilter}
              filterType={"filter"}
              onRowsPerPageChange={handleRowsPerPageChange}
              search={tableQueryParams?.search_key || ""}
              sortHandler={sortHandler}
              sorting={{
                key: tableQueryParams.sort_by,
                direction: tableQueryParams.sort_type,
              }}
              sxTableProps={{
                body: {
                  "&.MuiTableRow-root:hover": {
                    backgroundColor: "#2196F30A",
                  },
                },
              }}
            ></UTable>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default ActiveOrders
