import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined"

import { UText } from "../../components"
import {
  Box,
  Button,
  Container,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "../../components/mui.components"
import PatientInfoHeader from "../../containers/LandingLayout/PatientInfoHeader/PatientInfoHeader"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import {
  resetCase,
  setClinicalData,
  setIsScansRendered,
} from "../../core/app/slices/clinical/clinicalSlice"
import { setIsRouteChangeBlocked } from "../../core/app/slices/navigationPopup/navigationPopupSlice"
import { fetchFilesList } from "../../core/app/slices/records/photograph"
import { resetPhotoLists } from "../../core/app/slices/records/photograph/photographSlice"
import { resetXrays } from "../../core/app/slices/records/xrays/xraysSlice"
import { RootState } from "../../core/app/store"
import { useMyNavigation } from "../../hooks/useMyNavigation"

import Photograph from "./Photograph/Photograph"
import Scans from "./Scans/Scans"
import Xrays from "./Xrays/Xray"

export interface IRecordRoutesProps {
  showError: boolean
  xrayErrors: boolean
  scanErrors: boolean
  photographErrors: boolean
  setScanErrors: (value: boolean) => void
  setPhotographErrors: (value: boolean) => void
  setXrayErrors: (value: boolean) => void
  flagNextBtn: boolean
  steps?: { id: string; lable: string; to: string }[]
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  isDragOpen: boolean
  setIsDragOpen: (value: boolean) => void
  handleSkip: () => void
}

const RecordRoutes: FC<IRecordRoutesProps> = ({
  showError,
  xrayErrors,
  scanErrors,
  photographErrors,
  setScanErrors,
  setPhotographErrors,
  setXrayErrors,
  flagNextBtn,
  steps,
  setIsDragEmpty,
  isAlertOpen,
  setIsAlertOpen,
  isDragOpen,
  setIsDragOpen,
  handleSkip,
}) => {
  const { t } = useTranslation("common")
  return (
    <Routes>
      <Route
        path={"scans"}
        element={
          <Scans
            setScanErrors={setScanErrors}
            flagClickNext={flagNextBtn}
            steps={steps}
            handleSkip={handleSkip}
            caseType={"new_scan"}
          />
        }
      />
      <Route
        path={"photograph"}
        element={
          <Photograph
            title={t("records.photograph.title")}
            setPhotographErrors={setPhotographErrors}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            setIsDragOpen={setIsDragOpen}
            isDragOpen={isDragOpen}
          />
        }
      />
      <Route
        path={"xray"}
        element={
          <Xrays
            title={t("records.xray.title")}
            showError={showError}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            xrayErrors={xrayErrors}
            setXrayErrors={setXrayErrors}
          />
        }
      />
      <Route path={"*"} element={<Navigate to={"scans"} replace />} />
    </Routes>
  )
}

const RecordModule: FC = () => {
  const { patientId, caseId } = useParams()
  const navigate = useMyNavigation()
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()

  const steps = [
    {
      id: "scans",
      label: "Scans",
      to: `/records/patient/${patientId}/case/${caseId}/scans`,
    },
    {
      id: "photographs",
      label: "Photographs",
      to: `/records/patient/${patientId}/case/${caseId}/photograph`,
    },
    {
      id: "x-rays",
      label: "X-Rays",
      to: `/records/patient/${patientId}/case/${caseId}/xray`,
    },
  ]
  const [activeStep, setActiveStep] = useState<number>(0)
  const {
    isPhotoUploading,
    fileList,
    compositePhoto,
    individualPhotos,
    isDownloading,
    count,
    individualPhotosCount,
  } = useAppSelector((state: RootState) => state.PhotosService)
  const { pano } = useAppSelector((state: RootState) => state.xrayService)
  const { loading: xrayLoading, xrayUploadLoading } = useAppSelector(
    (state: RootState) => state.xrayService,
  )
  const { preferences } = useAppSelector(
    (state: RootState) => state.userService?.user,
  )
  const { isScansRendered } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const [skipped, setSkipped] = useState(new Set<number>())

  const [flagNextBtn, setFlagNextBtn] = useState()
  const [isDragOpen, setIsDragOpen] = useState<boolean>(false)

  const location = useLocation()
  useEffect(() => {
    setFlagNextBtn(undefined)
    if (location?.pathname.includes("/scans")) {
      setActiveStep(0)
    } else if (location?.pathname.includes("/photograph")) {
      setActiveStep(1)
    } else if (location?.pathname.includes("/xray")) {
      setActiveStep(2)
    } else {
      setActiveStep(0)
    }
  }, [location])

  useEffect(() => {
    dispatch(resetCase())
    dispatch(resetXrays())
    dispatch(resetPhotoLists())
    dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])

  const [scanErrors, setScanErrors] = useState<boolean>(false)
  const [photographErrors, setPhotographErrors] = useState<boolean>(false)
  const [xrayErrors, setXrayErrors] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [isDragEmpty, setIsDragEmpty] = useState<boolean>(false)
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false)

  const isStepOptional = (step: number) => {
    if (scanErrors && step === 0) {
      return true
    }
    if (photographErrors && step === 1) {
      return true
    }
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    if (
      isDragEmpty &&
      isDragOpen &&
      preferences &&
      !preferences?.on_show_sort_warning
    ) {
      setIsAlertOpen(true)
    } else if (individualPhotos.length > 0 && !isDragOpen) {
      setIsDragOpen(true)
    } else if (activeStep === 2) {
      handleError()
    } else {
      let newSkipped = skipped
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values())
        newSkipped.delete(activeStep)
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1)
      setSkipped(newSkipped)
      navigate(steps[activeStep + 1].to)
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    navigate(steps[activeStep - 1].to)
  }

  const handleSkip = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    navigate(steps[activeStep + 1].to)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const isStepFailed = (step: number) => {
    if (showError) {
      if (photographErrors && step === 1) {
        return true
      }
      if (scanErrors && step === 0) {
        return true
      }
      if (xrayErrors && step === 2) {
        return true
      }
    }
  }

  const handleError = () => {
    if (photographErrors || scanErrors || xrayErrors) {
      setShowError(true)
    } else {
      navigate(`/uassist/patient/${patientId}/case/${caseId}/dentalchart`)
    }
  }

  useEffect(() => {
    if (
      compositePhoto.length === 0 &&
      individualPhotos.length === 0 &&
      !fileList.find((file) => file.file_name.includes("composite")) &&
      !fileList.find((file) => file.file_name.includes("individual"))
    ) {
      setPhotographErrors(true)
    } else {
      setPhotographErrors(false)
    }
    if (pano.length === 0) {
      setXrayErrors(true)
    } else {
      setXrayErrors(false)
    }
  }, [compositePhoto, individualPhotos, pano, fileList])
  //Clean up clincal zipNames, zipList, and other data to avoid entering clincal with errors
  useEffect(() => {
    return () => {
      dispatch(resetCase())
    }
  }, [])
  useEffect(() => {
    dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])
  useEffect(() => {
    if (isScansRendered) return
    if (location.pathname.includes("/scans")) {
      dispatch(setIsScansRendered(true))
    } else if (!isScansRendered) {
      navigate(`/records/patient/${patientId}/case/${caseId}/scans`)
    }
  }, [location.pathname])

  return (
    <>
      <PatientInfoHeader
        chipLabel={t("records.description")}
        onBtnCancelClick={() => {
          navigate("/patients")
        }}
        cancelBtnText={t("button.close")}
        chipSx={{
          background: "#0288D1",
          fontSize: "13px",
          fontWeight: "400px",
          color: "primary.contrastText",
        }}
      />
      <Container component="main" maxWidth={"lg"} sx={{ mb: 4 }}>
        <UText
          sxProp={{ display: "flex", justifyContent: "center" }}
          variant={"h4"}
        >
          {t("records.title")}
        </UText>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ pt: 2, pb: 3 }}>
          {steps.map((s, index) => {
            const stepProps: { completed?: boolean } = {}
            const labelProps: {
              optional?: React.ReactNode
              error?: boolean
            } = {}
            if (showError && isStepFailed(index)) {
              labelProps.error = true
            }
            if (isStepSkipped(index)) {
              stepProps.completed = false
            }

            return (
              <Step key={s.label} {...stepProps}>
                <StepLabel
                  icon={
                    labelProps.error && (
                      <ErrorOutlinedIcon sx={{ color: "error.main" }} />
                    )
                  }
                  {...labelProps}
                >
                  {s.label}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <Paper
          elevation={0}
          sx={{
            my: { xs: 3, md: 6 },
            p: "40px",
            borderRadius: "16px",
            marginTop: "10px !important",
            margin: "0 auto",
            width: "max-content",
          }}
        >
          <RecordRoutes
            xrayErrors={xrayErrors}
            scanErrors={scanErrors}
            photographErrors={photographErrors}
            showError={showError}
            setScanErrors={setScanErrors}
            setPhotographErrors={setPhotographErrors}
            setXrayErrors={setXrayErrors}
            flagNextBtn={flagNextBtn}
            steps={steps}
            setIsDragEmpty={setIsDragEmpty}
            isAlertOpen={isAlertOpen}
            setIsAlertOpen={setIsAlertOpen}
            isDragOpen={isDragOpen}
            setIsDragOpen={setIsDragOpen}
            handleSkip={handleSkip}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 1,
            }}
          >
            {activeStep !== 0 && (
              <Button
                variant="outlined"
                onClick={handleBack}
                sx={{ width: "96px", height: "36px" }}
              >
                <ChevronLeftIcon
                  fontSize={"small"}
                  sx={{ marginRight: "3px" }}
                />
                {t("button.back")}
              </Button>
            )}
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                dispatch(
                  setIsRouteChangeBlocked({ isRouteChangeBlocked: false }),
                )
                if (activeStep === 0) {
                  dispatch(setClinicalData({ isLoading: "pending" }))
                  setFlagNextBtn(!flagNextBtn)
                } else if (isStepOptional(activeStep)) {
                  handleSkip()
                } else {
                  handleNext()
                }
              }}
              variant="contained"
              sx={{ width: "92px", height: "36px" }}
              disabled={
                isPhotoUploading === "pending" ||
                xrayLoading === "pending" ||
                xrayUploadLoading === "pending" ||
                isDownloading === "pending" ||
                individualPhotosCount !== count
              }
            >
              {t("button.next")}
              <ChevronRightIcon sx={{ marginLeft: "3px" }} fontSize={"small"} />
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export default RecordModule
