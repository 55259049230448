import http from "../../../global/http"

export const getCustomPackagingListService = async (orgId: string) => {
  try {
    // const { data } = await http.get(
    //   `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}`,
    // )
    // if (data && data.response_code === 200 && data.result) {
    //   caseObj = new Case().Deserialize(data.result)
    // }
    console.log("dillan")
    return [
      {
        accNo: 23,
        companyName: "Dr. Dumore & Team Orthodontics",
        createdOn: "Oct 28, 2022, 5:37 PM",
        packagingStatus: "Approved",
      },
      {
        accNo: 23,
        companyName: "Dr. Dumore & Team Orthodontics",
        createdOn: "Oct 28, 2022, 5:37 PM",
        packagingStatus: "Approved",
      },
    ]
  } catch (err) {
    return err
  }
}
