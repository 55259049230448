import { FC, useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { AxiosProgressEvent } from "axios"

import {
  UFileBox,
  UFileProcessingCard,
  UImagePreview,
  UText,
} from "../../../components"
import { Container, Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { uploadPhotographsInv_1 } from "../../../core/app/slices/records/photograph"
import { PhotoObject } from "../../../core/app/slices/records/photograph/photograph.type"
import { removeCompositePhoto } from "../../../core/app/slices/records/photograph/photographSlice"
import { deleteFiles } from "../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
import AdditionalPhotograph from "../AdditionalPhotograph"

const FILE_ALLOWED_TYPES = ["png", "jpg", "jpeg", "bmp"]
const FILE_ALLOWED_SIZE = 50 //in MB
const FILE_ALLOWED_LIMIT = 1

const CompositePhotgraph: FC<{
  selectedFileSection: (str: "composite" | "both") => void
  isRefinement?: boolean
}> = ({ selectedFileSection, isRefinement }) => {
  const { t } = useTranslation("common")
  const [files, setFiles] = useState<File[]>([])
  const [progressValue, setProgressValue] = useState<number>(0)
  const [progressStatus, setProgressStatus] = useState<
    "loading" | "complete" | "failed"
  >("loading")
  const selectedCompositeFile = (files: FileList): void => {
    setFiles([...files])
    selectedFileSection("composite")
  }
  const [showAdditionalFilesSection, setShowAdditionalFilesSection] =
    useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [additionalPhotosArray, setAdditionalPhotosArray] = useState<
    PhotoObject[]
  >([])
  const [uploadError, setUploadError] = useState<string>()

  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()

  const { compositePhoto, additionalPhotos } =
    useAppSelector((state: RootState) => state.PhotosService) || {}

  useEffect(() => {
    if (compositePhoto.length > 0 || additionalPhotos.length > 0) {
      selectedFileSection("composite")
      setShowAdditionalFilesSection(true)
    } else {
      selectedFileSection("both")
      setShowAdditionalFilesSection(false)
    }
  }, [compositePhoto, additionalPhotos])

  useEffect(() => {
    setAdditionalPhotosArray(additionalPhotos)
  }, [additionalPhotos])

  useEffect(() => {
    if (files.length === 0) return
    const newFile = new File(
      [files[0]],
      `composite.${files[0].type.split("/")[1]}`,
      { type: files[0].type },
    )
    const formPayload = new FormData()
    formPayload.append("attachment", newFile)
    formPayload.append("original_file_name", files[0].name)
    if (isRefinement) formPayload.append("is_refinement", "1")
    dispatch(
      uploadPhotographsInv_1({
        patientId: patientId,
        caseId: caseId,
        formData: formPayload,
        fileName: newFile.name,
        file: newFile,
        orgId: "",
        onFileProcesscallback: function (progress: AxiosProgressEvent): void {
          const { loaded, total } = progress
          setProgressStatus("loading")
          const percentageProgress = Math.floor((loaded / total) * 100)
          setProgressValue(percentageProgress)
        },
      }),
    )
      .then((action) => {
        if (action.payload.status === 200) {
          setProgressStatus("complete")
          setShowAdditionalFilesSection(true)
          setFiles([])
        } else {
          setUploadError(t("records.photograph.uploadError"))
          setProgressStatus("failed")
        }
      })
      .catch((error) => {
        setProgressStatus("failed")
      })
  }, [files])

  return (
    <>
      <UText
        color={"text.primary"}
        sxProp={{ display: "flex", justifyContent: "center" }}
        variant={"h6"}
      >
        {t("records.photograph.composite")}
      </UText>
      <Grid container justifyContent={"center"}>
        {files.length === 1 || compositePhoto.length === 1 ? (
          <>
            {(progressStatus === "loading" || progressStatus === "failed") &&
              compositePhoto.length === 0 && (
                <Container
                  sx={{
                    padding: "0 !important",
                  }}
                >
                  <UFileProcessingCard
                    fileName={files[0].name}
                    fileSize={files[0].size}
                    progressValue={progressValue}
                    filleErrorMessage={uploadError}
                    onRemove={() => {
                      if (progressStatus === "failed") {
                        setFiles([])
                        selectedFileSection("both")
                      }
                    }}
                    progressstatus={progressStatus}
                  ></UFileProcessingCard>
                </Container>
              )}
            {compositePhoto.length === 1 && (
              <UImagePreview
                imageHeight={400}
                imageWidth={400}
                files={[
                  {
                    src: compositePhoto[0].src,
                    name: compositePhoto[0].name,
                  },
                ]}
                deleteImage={() => {
                  dispatch(
                    deleteFiles({
                      caseId,
                      patientId,
                      fileName: compositePhoto[0].fileName,
                      fileType: "photographs",
                      version: "v2",
                    }),
                  ).then((action) => {
                    if (action.payload.data.status === "Success") {
                      dispatch(removeCompositePhoto())
                    }
                  })
                }}
                showFileName
                sxImageProp={{ mt: 0 }}
              />
            )}
          </>
        ) : (
          <Container
            maxWidth={"md"}
            sx={{
              padding: "0 !important",
            }}
          >
            <UFileBox
              boxSize={{ height: "216px", mt: "0 !important" }}
              allowedFileExtensions={FILE_ALLOWED_TYPES}
              allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE)}
              fileAcceptType={"image/*"}
              id="composite"
              selectedFile={selectedCompositeFile}
              fileLimit={FILE_ALLOWED_LIMIT}
              isRequired={false}
              messages={{
                fileNote: t("records.file.filenote", {
                  fileTypes: "JPG, PNG, BMP",
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                uploadButton: t("records.file.btnclicktoupload"),
                uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
                invalidfileFormat: (
                  <Trans components={{ newLine: <br /> }}>
                    {"records.photograph.invalidfileformat"}
                  </Trans>
                ),
                invalidFileSize: t("records.file.invalidfilesize", {
                  fileSize: FILE_ALLOWED_SIZE,
                }),
                invalidFileLimit: t("records.file.invalidfilelimitComposite", {
                  noOfFiles: FILE_ALLOWED_LIMIT,
                }),
              }}
            />
          </Container>
        )}
      </Grid>
      <AdditionalPhotograph
        title={t("records.photograph.modalPopupTitle")}
        openModal={openModal}
        setOpenModal={setOpenModal}
        linkText={t("records.photograph.additionalPhotos") || ""}
        showAdditionalFilesSection={showAdditionalFilesSection}
        version="v2"
        fileType="photographs"
        downloadedFiles={additionalPhotosArray}
        photographType={"composite"}
        isRefinement={isRefinement}
      />
    </>
  )
}

export default CompositePhotgraph
