import { useState } from "react"
import { Box, Stack, LinearProgress, Typography } from "@mui/material"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { useTheme } from "@mui/material/styles"

export const BottomStatusBar = () => {
  const theme = useTheme()
  const { bottomStatusMsg, bottomPrgress } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  return (
    <Stack
      sx={{
        bottom: 0,
        right: 0,
        position: "absolute",
        backgroundColor: theme.transBkColor.light,
      }}
      direction="row"
    >
      <Box display="flex" justifyContent="center">
        <Typography variant="body2">{bottomStatusMsg}</Typography>
      </Box>
      {bottomPrgress && (
        <LinearProgress sx={{ width: "100px", height: "10px" }} />
      )}
    </Stack>
  )
}
