import { CapitalizeString } from "../../../src/modules/Patient/PatientList/util/commonUtil"

import {
  IAlignerOrder,
  PACKAGETYPE,
  SHIPPINGMETHOD,
  ShipToAddress,
} from "./interface/AlignerOrder"
import Deserialize from "./interface/Deserialize"
import { IActiveOrders, IOrder } from "./interface/IOrder"
import { IShippingAddress } from "./interface/IShippingAddress"

export class ShippingAddress implements IShippingAddress, Deserialize {
  id: null
  line_1 = ""
  line_2 = ""
  city = ""
  region_id = null
  state: { id: null; short_name: "" }
  zip_code = ""
  country_id = null
  country = { id: null, code_1: "" }
  Deserialize(input: any): this {
    if (input) {
      this.id = input.id
      this.line_1 = input.line_1
      this.line_2 = input.line_2
      this.city = input.city
      this.zip_code = input.zip_code
      if (input.region_id && input.state) {
        this.region_id = input.region_id
        this.state = {
          id: input.state.id,
          short_name: input.state.short_name,
        }
      }
      if (input.country_id && input.state) {
        this.country_id = input.country_id
        this.country = {
          id: input.country.id,
          code_1: input.country.code_1,
        }
      }
    }
  }
}

// Temporary hard-coded values will be removed after API fix
export class Order implements IOrder, Deserialize {
  txplanName = ""
  txplanSubname = ""
  alignerCount = null
  retainerCount = null
  alignerMaterial = ""
  upperStageRange = ""
  lowerStageRange = ""
  templateCount = null
  upperTemplateStage = ""
  lowerTemplateStage = ""
  upperRetainerCount = ""
  lowerRetainerCount = ""
  isCustomPackaging = false
  shippingMethod = ""
  orderType = null
  orderDate = ""
  orderId = null
  caseId = null
  orderStatus = ""
  trackingNo = ""
  shippingDate = ""
  updateDate = ""

  Deserialize(input: any): this {
    if (input) {
      this.txplanName = input.txplan_name
      this.txplanSubname = input.txplan_subname
      this.alignerCount = input.aligner_count
      this.alignerMaterial = input.aligner_material

      this.retainerCount = input.retainer_count
      this.upperRetainerCount = input.upper_retainer_stage
      this.lowerRetainerCount = input.lower_retainer_stage

      this.upperStageRange = input.upper_stage_range
      this.lowerStageRange = input.lower_stage_range
      this.templateCount = input.template_count
      this.upperTemplateStage = input.upper_template_stage
      this.lowerTemplateStage = input.lower_template_stage
      this.isCustomPackaging = input.custom_packaging
      if (input.shipping_method) {
        this.shippingMethod = input.shipping_method.name
      }
      if (input.status) {
        this.orderStatus = input.status.name
      }
      this.trackingNo = input.shipment_tracking_no
      this.shippingDate = input.shipping_date
      this.updateDate = input.update_date
      if (input.pricing_option) {
        const str = input.pricing_option.toLowerCase()
        this.orderType = str.charAt(0).toUpperCase() + str.slice(1)
      }
      this.orderDate = input.order_date
      this.orderId = input.order_id
      this.caseId = input.case_id
    }
    return this
  }
}

export class AlignerOrder implements IAlignerOrder, Deserialize {
  shipAddress: ShipToAddress
  bundle: {
    pricing_option: string
    aligner: { remaining: null; requested: null; included: null }
    retainer: { remaining: null; requested: null; included: null }
    alignercase: { cost: null; count: null }
    previous_uassist_credit: null
    shipping_price: { type: null; item_total_value: null }
    tax_amt: number
    sub_total: number
    custom_packaging: number
  }
  alacarte: {
    pricing_option: string
    aligner: { cost: null; count: null }
    custom_package: { cost: null; count: null }
    uassist_service: { cost: null; count: null }
    alignercase: { cost: null; count: null }
    retainer: { cost: null; count: null }
    template: { cost: null; count: null }
    shipping_price: { type: null; item_total_value: null }
    tax_amt: number
    sub_total: number
    custom_packaging: number
  }
  packageType: PACKAGETYPE
  shippingMethod: SHIPPINGMETHOD
  total: number
  currency: string
  orderId: number
  subOrderId: number

  Deserialize(input: any): this {
    if (input) {
      let bundle_type = ""
      let val: any
      let bundlekey: any

      switch (input.pricing_option) {
        case "MILD":
          bundle_type = "Mild"
          break
        case "MODERATE":
          bundle_type = "Moderate"
          break
        case "COMPREHENSIVE":
          bundle_type = "Comprehensive"
          break
        case "FINISHING":
          bundle_type = "Finishing"
          break
        case "2 RETAINER":
          bundle_type = "2 Retainer"
          break
        case "4 RETAINER":
          bundle_type = "4 Retainer"
          break
        case "ALACARTE":
          bundle_type = "alacarte"
          break
        default:
          break
      }
      if (bundle_type === "alacarte") {
        const bundleTypes = [
          "Mild",
          "Moderate",
          "Comprehensive",
          "Finishing",
          "2 Retainer",
          "4 Retainer",
        ]
        for (const type of bundleTypes) {
          if (input.quote?.bundles?.[type]?.description) {
            bundle_type = type
            break
          }
        }
      }

      if (bundle_type !== "alacarte") {
        val = input.quote?.bundles?.[bundle_type]
        bundlekey = input.quote?.bundles?.[bundle_type]?.description
      } else {
        val = input.quote
        bundlekey = input.quote?.[bundle_type]
      }
      if (val?.name && bundle_type !== "alacarte") {
        this.bundle = {
          pricing_option: val?.name ?? "",
          aligner: {
            requested: bundlekey?.aligner?.requested,
            remaining: bundlekey?.aligner?.leftAfterOrder,
            included: bundlekey?.aligner?.current,
          } ?? {
            remaining: null,
            requested: null,
            included: null,
          },
          alignercase: {
            cost: bundlekey.aligner_case?.item_per_cost,
            count: bundlekey.aligner_case?.item_count,
          } ?? {
            cost: null,
            count: null,
          },
          retainer: {
            requested: bundlekey?.retainer?.requested,
            remaining: bundlekey?.retainer?.leftAfterOrder,
            included: bundlekey?.retainer?.current,
          } ?? {
            remaining: null,
            requested: null,
            included: null,
          },
          previous_uassist_credit: input.quote?.previous_uassist_credit ?? null,
          shipping_price: bundlekey?.shipping_price ?? {
            type: null,
            item_total_value: null,
          },
          tax_amt: bundlekey?.tax?.item_total_value ?? 0,
          sub_total: bundlekey?.total_value ?? 0,
          custom_packaging: input.custom_packaging ?? 0,
        }
      }
      if (val?.alacarte) {
        this.alacarte = {
          pricing_option: "A La Carte",
          alignercase: {
            cost: val?.alacarte?.aligner_case?.item_per_cost,
            count: val?.alacarte?.aligner_case?.item_count,
          } ?? {
            cost: null,
            count: null,
          },
          aligner: {
            cost: val?.alacarte?.aligner?.item_per_cost,
            count: val?.alacarte?.aligner?.item_count,
          } ?? {
            cost: null,
            count: null,
          },
          retainer: {
            cost: val?.alacarte?.retainer?.item_per_cost,
            count: val?.alacarte?.retainer?.item_count,
          } ?? {
            cost: null,
            count: null,
          },
          shipping_price: val?.alacarte?.shipping_price ?? {
            type: null,
            item_total_value: null,
          },
          tax_amt: val?.alacarte?.tax?.item_total_value ?? 0,
          sub_total: val?.alacarte?.total_value ?? 0,
          custom_packaging: input.custom_packaging ?? 0,
          custom_package: {
            cost: val?.alacarte?.custom_packaging?.item_per_cost,
            count: val?.alacarte?.custom_packaging?.item_count,
          },
          uassist_service: {
            cost: val?.alacarte?.uassist?.item_per_cost,
            count: val?.alacarte?.uassist?.item_count,
          },
          template: {
            cost: val?.alacarte?.template?.item_per_cost,
            count: val?.alacarte?.template?.item_count,
          },
        }
      }
      const shipVal = input.address?.ship_to_address
      this.shipAddress = {
        id: shipVal?.id ?? null,
        business_name: shipVal?.business_name ?? null,
        line_1: shipVal?.line_1 ?? null,
        line_2: shipVal?.line_2 ?? null,
        country: {
          id: null,
          code_1: shipVal?.country_name ?? null,
        },
        zip_code: shipVal?.zip_code ?? null,
        phone: shipVal?.phone ?? null,
        city: shipVal?.city ?? null,
        region_id: shipVal?.region_id ?? null,
        state: {
          id: null,
          short_name: shipVal?.region_name ?? null,
        },
      }

      this.packageType = input.package_type

      if (input.shipping_method) {
        this.shippingMethod = input.shipping_method.name
      }

      this.total = input.quote?.grand_total ?? 0
      this.currency = input.quote?.currency ?? ""
      this.orderId = input.id
      this.subOrderId = input.sub_order_id ?? null
    }

    return this
  }
}

export class ActiveOrders implements IActiveOrders, Deserialize {
  shipmentTrackingNo: string
  createDate: string
  caseDisposition: string
  first: string
  last: string
  orderNumber: string
  orderTypeName: string
  lowerLasttoothtocover: string
  upperLasttoothtocover: string
  shippingMethod: string
  shippingValue: number
  shippingDate: string
  orderDate: string
  updateDate: string
  upperStageRange: string
  lowerStageRange: string
  upperRetainerStage: string
  lowerRetainerStage: string
  upperTemplateStage: string
  lowerTemplateStage: string
  patientId: number
  pricingOption: string

  Deserialize(input: any): this {
    if (input) {
      this.shipmentTrackingNo = input.shipment_tracking_no
      this.createDate = input.order_date
      this.caseDisposition = input.status.name
        .toUpperCase()
        .replace(/\s+/g, "_")
      this.first = CapitalizeString(input.patient_first_name)
      this.last = CapitalizeString(input.patient_last_name)
      this.patientId = input.patient_id
      if (input.pricing_option) {
        this.pricingOption =
          input.pricing_option === "ALACARTE"
            ? "A La Carte"
            : CapitalizeString(input.pricing_option)
      }
      if (input.order_no && input.order_no_2) {
        this.orderNumber = input.order_no + "/" + input.order_no_2
      } else if (input.order_no && !input.order_no_2) {
        this.orderNumber = input.order_no
      } else {
        this.orderNumber = input.order_no_2
      }
      if (input.type) {
        this.orderTypeName = input.type?.name || ""
      }
      this.lowerLasttoothtocover =
        input.lower_left_lasttoothtocover +
        "-" +
        input.lower_right_lasttoothtocover
      this.upperLasttoothtocover =
        input.upper_left_lasttoothtocover +
        "-" +
        input.upper_right_lasttoothtocover
      this.shippingMethod = input?.shipping_method?.name
      this.shippingValue = input?.shipping_method?.value || null
      this.shippingDate = input.shipping_date
      this.orderDate = input.order_date
      this.updateDate = input.update_date
      this.upperStageRange = input.upper_stage_range
      this.lowerStageRange = input.lower_stage_range
      this.upperRetainerStage = input.upper_retainer_stage
      this.lowerRetainerStage = input.lower_retainer_stage
      this.upperTemplateStage = input.upper_template_stage
      this.lowerTemplateStage = input.lower_template_stage
    }
    return this
  }
}
