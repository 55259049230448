import { FC, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useParams } from "react-router"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Box } from "@mui/system"

import { FormAutoComplete } from "../../../components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { updateCaseById } from "../../../core/app/slices/case"
import { setAssignedTo } from "../../../core/app/slices/patients/patientSlice"
import { RootState } from "../../../core/app/store"
import { ICase } from "../../../core/model/interface/ICASE"
import {
  INewPatientData,
  IPatient,
} from "../../../core/model/interface/IPatient"
import { CENETER_COLUMN_ALIGN } from "../../../theme/theme.util"
import { UText } from "../../../ui-component"
import { Chip, Divider, Grid } from "../../../ui-component/mui.components"

import PatientTooltipContent from "./PatientTooltipContent"
import UTooltip from "./UTootip"

const PatientBasicInfo: FC<{
  patientData: INewPatientData | IPatient
  caseDetail: ICase
}> = ({ patientData, caseDetail }) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useForm()
  const dispatch = useAppDispatch()
  const { patientId } = useParams()
  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )
  const formValue = watch("doctor", caseDetail ? caseDetail.assigned_to : "")

  const formLabel = doctorList?.find((list) => list.id === formValue)

  useEffect(() => {
    if (caseDetail && caseDetail.assigned_to === formValue) return
    if (caseDetail) {
      dispatch(setAssignedTo(formValue))
      dispatch(
        updateCaseById({
          patientId: patientId,
          caseId: "" + caseDetail.id,
          payload: {
            assigned_to: formValue,
          },
        }),
      )
    }
  }, [formValue, caseDetail])

  return (
    <Box component={"div"}>
      <UTooltip
        titleContent={<PatientTooltipContent patientData={patientData} />}
      >
        <UText
          variant={"h5"}
          component={"div"}
          sxProp={{
            display: "inline-block",
            verticalAlign: "middle",
            maxWidth: "500px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginBottom: "4px",
          }}
        >
          {patientData?.firstName + " " + patientData?.lastName}
        </UText>
      </UTooltip>

      <Box
        component={"div"}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
        }}
      >
        {patientData.status === "ARCHIVED" && (
          <Chip
            label="Archived"
            sx={{
              height: 24,
              width: 79,
              ".MuiChip-label": {
                padding: "0 6px",
              },
            }}
          />
        )}
        {patientData.status === "ARCHIVED" && (
          <Divider
            sx={{ height: 28, width: "1px", backgroundColor: "divider" }}
          />
        )}

        {formLabel?.label && (
          <Grid container direction={"row"} wrap={"nowrap"}>
            <Box
              component={"div"}
              sx={{
                ...CENETER_COLUMN_ALIGN,
                height: "24px",
                width: "28px",
                backgroundColor: "success.dark",
                borderRadius: "50px",
                margin: "2px 8px 2px 0",
                fontSize: "10px",
                justifyContent: "center",
                fontWeight: 500,
                color: "#ffffff",
              }}
            >
              {formLabel?.label
                .charAt(0)
                .concat(formLabel?.label.split(" ")[1].charAt(0))
                .toUpperCase()}
            </Box>
            <FormAutoComplete
              inputLabel={"formfields.alldoctors"}
              fieldName={"doctor"}
              rules={{
                required: "formfieldErrors.assigntorequired",
              }}
              defaultValue={
                doctorList?.find((list) => list.id === caseDetail?.assigned_to)
                  ?.label
              }
              isHeader
              options={doctorList}
              errors={false}
              control={control}
              formSxprops={{ my: 0, display: "block" }}
              customPopupIcon={<KeyboardArrowDownIcon fontSize={"medium"} />}
              sxProp={{
                "& .MuiOutlinedInput-root": {
                  padding: "0 !important",
                  width: "200px",
                },
                "& .MuiOutlinedInput-root:hover:not(.Mui-focused )": {
                  "& fieldset": {
                    border: "none !important",
                  },
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "inline-flex",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    marginRight: "-10px",
                  },
                },
                "& .MuiOutlinedInput-root:focus-within": {
                  "& .MuiAutocomplete-popupIndicator": {
                    display: "inline-flex !important",
                    backgroundColor: "rgba(0, 0, 0, 0.12)",
                    marginRight: "-10px",
                  },
                },
                "& input": {
                  padding: "0 !important",
                  fontSize: "14px",
                },
                "& .MuiAutocomplete-clearIndicator": {
                  display: "none",
                },
                "& svg": {
                  width: "18px",
                  height: "18px",
                },
                "& fieldset": {
                  border: "none !important",
                  padding: "0 !important",
                },
                "& .MuiFormControl-root": {
                  display: "block",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  display: "none",
                },
              }}
            />
          </Grid>
        )}
      </Box>
    </Box>
  )
}

export default PatientBasicInfo
