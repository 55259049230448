import { createSlice } from "@reduxjs/toolkit"

import { getCustomPackagingList } from "./customPackagingThunkApi"

const initialState: any = {
  loading: "idle",
  myCustomPackagingList: [],
}

export const customPackagingSlice = createSlice({
  name: "customPackagingService",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomPackagingList.pending, (state) => {
      console.log(state, "pending")
    })
    builder.addCase(getCustomPackagingList.fulfilled, (state, action) => {
      console.log(action, "dillan")
    })
    builder.addCase(getCustomPackagingList.rejected, (state) => {
      console.log(state, "rejected")
    })
  },
})

// export const {} = customPackagingSlice.actions
