export function dataURItoFile(dataURI, fileName) {
    const [dataDescription, base64Data] = dataURI.split(',')
    // 文件类型
    const mimetype = dataDescription.match(/:(.*?);/)[1]

    // 解码 base64 数据
    const decodedData = atob(base64Data)
    let n = decodedData.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = decodedData.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: mimetype });
}

export async function compressImageByCanvas(file, options:{quality?:number,width?:number,height?:number,filename?:string} = {}) {
    const { quality } = options;
    let { width, height } = options

    const filename = options.filename ?? file.name;

    let _resolve, _reject
    const promise = new Promise<File>((resolve, reject) => {
        _resolve = resolve
        _reject = reject
    })

    const img = new Image();
    img.onload = function () {

        // 如果只指定了宽度或高度，则另一个按比例缩放
        if (width && !height) {
            height = Math.round(img.height * (width / img.width))
        } else if (!width && height) {
            width = Math.round(img.width * (height / img.height))
        }
        
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // 设置 canvas 的宽高与图片一致
        canvas.width = width || img.width;
        canvas.height = height || img.height;

        // 在 canvas 上绘制图片
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        
        // 获取压缩后的图片数据
        const compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
        _resolve(dataURItoFile(compressedDataUrl, filename))
    };

    img.src = URL.createObjectURL(file);
    return promise
}
