import { useEffect, useState } from "react"
import { Box, ThemeProvider, Stack } from "@mui/material"
import { lightTheme, darkTheme } from "./theme/UDThemes"

import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"

import WasmCanvas from "@/wasm3d/WasmCanvas"
import { TopToolBar } from "./top/TopToolBar"
import { LeftToolBar } from "./left/LeftToolBar"
import { LeftPages } from "./left/LeftPages"
import { RightToolBar } from "./right/RightToolBar"
import { RightPages } from "./right/RightPages"
import { UDOpenCase } from "./UDOpenCase"
import { UDLockCaseForEdit } from "./UDLockCaseForEdit"

import { setCurRightPage } from "./udTreatSlice"
import { BottomToolBar } from "./bottom/BottomToolBar"
import { BottomStatusBar } from "./bottom/BottomStatusBar"
import { UDSmartRxWorkflow } from "./workflow/smartrx/UDSmartRxWorkflow"
import { UDRetainerRxWorkflow } from "./workflow/retainer/UDRetainerWorkflow"
import { UploadSTL } from "./components/UploadSTL"
import { UDChairsideWorkflow } from "./workflow/chairside/UDChairsideWorkflow"
import UDToothInfo from "./UDToothInfo/UDToothInfo"
import SmartRx from "./components/SmartRx"
import { ThemeSwitch } from "./top/ThemeSwitch"
import { useParams } from "react-router-dom"
import { WeStageBar } from "./WeDesign/WeStageBar"
import { KFEditor } from "./WeDesign/KFEditor"
import BiteRampButtons from "./UDesign/Attachments/BiteRampButtons"
import { UDBanner } from "./Banner/UDBanner"
import { ReopenCaseDlg } from "./components/ReopenCaseDlg"

export const UDTreat = () => {
  const dispatch = useAppDispatch()
  const { curCaseMode, workflowMode, isCasePreview, showBottomStatusBar } =
    useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { caseId, patientId } = useParams()
  const [openCase, setOpenCase] = useState(true)
  const [darkMode, setDarkMode] = useState(false)
  const [openWarning, setOpenWarning] = useState(true)

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box
        sx={{
          display: "flex",
          top: 60,
          left: 0,
          bottom: 0,
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Box sx={{ flexGrow: 1 }} position="relative">
          <WasmCanvas />
          <TopToolBar />
          <ThemeSwitch
            sx={{
              position: "absolute",
              right: "180px",
              top: "-45px",
            }}
            value={darkMode}
            onChange={(e) => {
              setDarkMode(e.target.checked)
            }}
          />
          {workflowMode === "aidesign" && <LeftToolBar />}
          <LeftPages />
          <UDToothInfo isOpenCase={openCase} />
          {workflowMode === "aidesign" && <RightToolBar />}
          <RightPages />
          <BiteRampButtons />
          <SmartRx />
          <UDOpenCase
            open={openCase}
            onClose={() => {
              setOpenCase(false)
            }}
          />
          <UDLockCaseForEdit />
          <UDBanner
            open={openWarning}
            onOK={() => {
              setOpenWarning(false)
            }}
          />
          {workflowMode === "smartrx" && <UDSmartRxWorkflow />}
          {workflowMode === "retainerrx" && <UDRetainerRxWorkflow />}
          {showBottomStatusBar && <BottomStatusBar />}
          {/* curCaseMode === "WeDesign" && <WeStageBar /> */}
          {curCaseMode === "WeDesign" && <KFEditor />}
          <ReopenCaseDlg />
        </Box>
        {!isCasePreview && <BottomToolBar />}
      </Box>
    </ThemeProvider>
  )
}
