import { FC, useEffect, useState } from "react"

import { Menu, MenuItem } from "../../../../../components/mui.components"
import { useAppDispatch } from "../../../../../core/app/hooks"
import { getCaseById } from "../../../../../core/app/slices/case"
import {
  getBtnActionsByStatus,
  UNARCHIVEPATIENT,
} from "../../../config/menuAction.config"

import MenuIconButton from "./MenuIconButton"

const UTableActionCell: FC<{
  row: any
  hoverKey: number
  handleMouseOut: () => void
  tableName: string
  onMenuBtnClick: (actionType: string, row: any) => void
}> = ({ row, hoverKey, handleMouseOut, tableName, onMenuBtnClick }) => {
  const dispatch = useAppDispatch()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [menuKey, setMenuKey] = useState<boolean>(false)
  const [actions, setActions] = useState([])

  const handleOnMenuIconClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
    setMenuKey(false)
    handleMouseOut()
  }

  useEffect(() => {
    if (menuKey) {
      if (row.objtype === "CASE") {
        const caseStatus = getBtnActionsByStatus(
          row.caseStatus?.status.key || row.caseDisposition,
          row.isInitialuAssist,
          row.isRefinementReady,
        )
        if (caseStatus?.actions) {
          setActions([...caseStatus.actions])
        }
      } else {
        dispatch(
          getCaseById({ patientId: row.patientId, caseId: row.caseId }),
        ).then((res) => {
          if (res.payload) {
            const caseStatus = getBtnActionsByStatus(
              res.payload.caseDisposition,
              res.payload.isInitialuAssist,
              res.payload.isRefinementReady,
            )
            if (caseStatus?.actions) {
              setActions([...caseStatus.actions])
            }
          }
        })
      }
    }
  }, [row, menuKey])

  return (
    <>
      {((row.caseDisposition && hoverKey === row.patientId) || menuKey) && (
        <MenuIconButton
          onClickHandler={(event) => {
            event.stopPropagation()
            setAnchorEl(event.currentTarget)
            setMenuKey(true)
          }}
          id={row.patientId}
          sxProps={{ justifyContent: "end" }}
          isSelected={!!menuKey}
        />
      )}
      {menuKey && (row.status === "ARCHIVED" || actions.length > 0) && (
        <Menu
          id={`${row.patientId}-content`}
          aria-labelledby={`${row.patientId}-button`}
          sx={{
            ".MuiPaper-root": {
              minWidth: "220px",
            },
          }}
          open={menuKey}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleOnMenuIconClose}
        >
          {row.status === "ARCHIVED" ? (
            <MenuItem
              onClick={(event) => {
                event.stopPropagation()
                onMenuBtnClick &&
                  onMenuBtnClick(UNARCHIVEPATIENT.btnAction, row)
              }}
            >
              {UNARCHIVEPATIENT.btnText}
            </MenuItem>
          ) : (
            actions.map((btn, index) => (
              <MenuItem
                key={btn?.btnAction + "" + index}
                onClick={(event) => {
                  event.stopPropagation()
                  onMenuBtnClick && onMenuBtnClick(btn.btnAction, row)
                }}
              >
                {btn.btnText}
              </MenuItem>
            ))
          )}
        </Menu>
      )}
    </>
  )
}

export default UTableActionCell
