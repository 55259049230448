import { FC, useCallback, useEffect, useRef, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
  FormAutoComplete,
  FormInput,
  UButton,
  UText,
} from "../../../components"
import { Box, Button } from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { useAppDispatch } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import {
  IBillPayload,
  ICreditCard,
  IUpdateCardPayload,
} from "../../../core/app/slices/user/user.type"
import {
  fetchCountryList,
  fetchStateLists,
  getCreditCardDetails,
  saveCreditCard,
  updateCreditCard,
} from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"

import { ICardDetails } from "./adyen.type"
import { PaymentContainer } from "./AdyenForm"
import { renderUserInfoSection } from "./UserProfile"

const PaymentDetails: FC = () => {
  const { t } = useTranslation("common")
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({ mode: "onSubmit" })
  const [editPayment, setEditPayment] = useState<boolean>(false)
  const [cardInfo, setCardInfo] = useState<ICardDetails>({} as ICardDetails)
  const [selectedState, setSelectedState] = useState<string>("")
  const dispatch = useAppDispatch()
  const { countries, states, paymentInfo, saveOrUpdatecreditCardStatus } =
    useAppSelector((state: RootState) => state.userService)

  const setAddressDetails = () => {
    if (paymentInfo && paymentInfo.billing_address) {
      setValue("country", paymentInfo.billing_address.country)
      setValue("street", paymentInfo.billing_address.address_line)
      setValue("city", paymentInfo.billing_address.city)
      setValue("state", paymentInfo.billing_address.state)
      setSelectedState(paymentInfo.billing_address.state)
      setValue("zip", paymentInfo.billing_address.zip_code)
    }
  }

  useEffect(() => {
    paymentInfo && setAddressDetails()

    if (
      paymentInfo?.billing_address?.country &&
      countries &&
      countries.length
    ) {
      const countryItem = countries.find(
        (item) => item.label === paymentInfo?.billing_address?.country,
      )
      dispatch(fetchStateLists({ countryid: countryItem.id }))
    }
  }, [paymentInfo, countries])

  useEffect(() => {
    dispatch(getCreditCardDetails())
    dispatch(fetchCountryList())
  }, [])

  const updateCardInfo = (cardDetails: ICardDetails) => {
    setCardInfo(cardDetails)
  }

  const alert = useCallback(
    (res: any) => {
      const { payload } = res
      if (payload.response_code === 201) {
        dispatch(getCreditCardDetails())
        setEditPayment(false)
        dispatch(
          setAlert({
            message: t("userProfile.paymentSuccess"),
          }),
        )
      } else {
        dispatch(
          setAlert({
            message: t("userProfile.paymentFailed"),
            isError: true,
          }),
        )
      }
    },
    [saveOrUpdatecreditCardStatus],
  )

  const onSubmit: SubmitHandler<FieldValues> = (data: any) => {
    const comp = window.checkout.components[0]
    if (!comp.state.isValid) {
      return comp.showValidation()
    }
    if (comp.state.isValid && cardInfo) {
      const address: IBillPayload = {
        name: cardInfo.holderName,
        address_line: data.street,
        city: data.city,
        state: data.state,
        zip_code: data.zip,
        country: data.country,
      }
      const card: ICreditCard = {
        card_number: cardInfo.encryptedCardNumber,
        expiration_date: `${cardInfo.encryptedExpiryMonth}/${cardInfo.encryptedExpiryYear}`,
        card_code: cardInfo.encryptedSecurityCode,
      }
      //update card
      if (paymentInfo && paymentInfo.customer_payment_profile_id) {
        const obj: IUpdateCardPayload = {
          customer_profile_id: paymentInfo.customer_profile_id,
          customer_payment_profile_id: paymentInfo.customer_payment_profile_id,
          payment_profile: {
            bill_to: address,
            payment: {
              credit_card: card,
            },
          },
        }
        dispatch(updateCreditCard(obj)).then((res) => {
          alert(res)
        })
      } else {
        //save card
        const obj: IUpdateCardPayload = {
          payment_profile: {
            bill_to: address,
            payment: {
              credit_card: card,
            },
          },
        }
        dispatch(saveCreditCard(obj)).then((res) => {
          alert(res)
        })
      }
    }
  }

  const getAddress = useCallback(() => {
    if (paymentInfo && paymentInfo.billing_address) {
      return (
        <>
          {paymentInfo.billing_address.address_line || ""}
          <br />
          {paymentInfo.billing_address.address_line_1 || ""}
          <br />
          {paymentInfo.billing_address.city || ""}
          {paymentInfo.billing_address.city ? ", " : ""}
          {paymentInfo.billing_address.state || ""}
          {" " + (paymentInfo.billing_address.zip_code || "")}
          <br />
          {paymentInfo.billing_address.country || ""}
        </>
      )
    }
  }, [paymentInfo])

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <UText
          variant={"h6"}
          component={"div"}
          sxProp={{ textAlign: "center" }}
        >
          {t("userProfile.payment")}
        </UText>
        {!editPayment && (
          <Button
            variant={"text"}
            sx={{
              minWidth: "auto",
              height: "auto",
              width: "auto",
              lineHeight: "22px",
              py: "4px",
              px: "5px",
            }}
            onClick={() => setEditPayment(true)}
          >
            {t("userProfile.edit")}
          </Button>
        )}
      </Box>
      {editPayment ? (
        <Box sx={{ mt: 2 }}>
          <PaymentContainer updateCardInfo={updateCardInfo} />
        </Box>
      ) : (
        <>
          {renderUserInfoSection(
            t("userProfile.addressOnFile.cardDetails.cardNum"),
            paymentInfo && paymentInfo.card_details
              ? "**** **** **** " + paymentInfo.card_details.card_number || ""
              : "--",
          )}
          {renderUserInfoSection(
            t("userProfile.addressOnFile.cardDetails.expDate"),
            paymentInfo && paymentInfo.card_details
              ? `${paymentInfo.card_details.expiry_month}/${paymentInfo.card_details.expiry_year}` ||
                  ""
              : "--",
          )}
          {renderUserInfoSection(
            t("userProfile.addressOnFile.cardDetails.nameOnCard"),
            paymentInfo && paymentInfo.card_details
              ? paymentInfo.card_details.holder_name || ""
              : "--",
          )}
        </>
      )}
      <UText
        variant={"body1"}
        component={"div"}
        sxProp={{ color: "text.secondary", pt: 3 }}
      >
        {t("userProfile.addressTitle")}
      </UText>
      {editPayment ? (
        <>
          <Box>
            <FormAutoComplete
              formSxprops={{ mt: 2, mb: 0 }}
              inputLabel={"userProfile.cn"}
              fieldName={"country"}
              isInputLabel={true}
              rules={{
                required: "userProfile.addressOnFile.countryRequired",
              }}
              options={countries}
              errors={errors}
              callback={(e) => {
                setValue("country", e.label)
                setValue("state", "")
                setSelectedState(null)
                const countryItem = countries.find(
                  (item) => item.label === e.label,
                )
                countryItem &&
                  dispatch(fetchStateLists({ countryid: countryItem.id }))
              }}
              // TODO: get country from card details api and pass to defaultvalue
              defaultValue={
                paymentInfo && paymentInfo.billing_address
                  ? paymentInfo.billing_address.country
                  : ""
              }
              control={control}
            />
            <FormInput
              inputLabel={"userProfile.addressOnFile.streetaddress"}
              fieldName={"street"}
              fieldType={"text"}
              rules={{
                required: "userProfile.addressOnFile.streetRequired",
              }}
              errors={errors}
              control={control}
              formSxprops={{ mt: 2 }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <FormInput
              inputLabel={"userProfile.addressOnFile.city"}
              fieldName={"city"}
              fieldType={"text"}
              rules={{ required: "userProfile.addressOnFile.cityRequired" }}
              errors={errors}
              control={control}
            />
            <FormAutoComplete
              inputLabel={"userProfile.addressOnFile.state"}
              fieldName={"state"}
              isInputLabel={true}
              rules={{
                required: "userProfile.addressOnFile.stateRequired",
              }}
              options={states}
              errors={errors}
              callback={(e) => {
                e && setValue("state", e.label)
              }}
              control={control}
              // TODO: get state value from card details api and assign it to default value
              defaultValue={selectedState}
            />
            <FormInput
              inputLabel={"userProfile.addressOnFile.zip"}
              fieldName={"zip"}
              fieldType={"text"}
              rules={{ required: "userProfile.addressOnFile.zipRequired" }}
              errors={errors}
              control={control}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <UButton
              variant={"outlined"}
              btnText={t("button.cancel")}
              key={"yes"}
              sxProp={{ minWidth: 58, height: 36 }}
              onClickHandler={() => {
                setAddressDetails()
                setEditPayment(false)
              }}
            />
            <UButton
              variant={"contained"}
              btnText={t("button.save")}
              key={"no"}
              btnType="submit"
              sxProp={{ minWidth: 54, height: 36 }}
            />
          </Box>
        </>
      ) : (
        <Box>
          <UText variant={"body1"} component={"div"} sxProp={{ color: "#000" }}>
            {getAddress() || "--"}
          </UText>
        </Box>
      )}
    </Box>
  )
}

export default PaymentDetails
