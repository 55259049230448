import React, { FC, useState } from "react"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import FirstPageIcon from "@mui/icons-material/FirstPage"
import LastPageIcon from "@mui/icons-material/LastPage"
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  useTheme,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { formatCurrency } from "../../../core/utils/formatters"
import { UText } from "../../../ui-component"

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}
interface TableHeader {
  name: string
  id: string
  dataKey: string
  isCurrency?: boolean
}
export const BillingTable: FC<{
  tableHeader: TableHeader[]
  rows: any[]
  currencyCode: string
  showPagination?: boolean
}> = ({ tableHeader, rows, currencyCode, showPagination }) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const { countryCode } = useAppSelector(
    (state: RootState) => state?.userService,
  )
  const cellStyle = {
    maxWidth: 250,
  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <TableContainer component={Paper} sx={{ mb: 2, boxShadow: "none" }}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow key={"header"}>
            {tableHeader.map((item) => (
              <TableCell key={item.id} component="th" scope="row">
                {item.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {countryCode &&
            (rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row.order_number}>
                {tableHeader.map((column) => (
                  <TableCell
                    key={row[column.dataKey]}
                    component="th"
                    scope="row"
                    sx={column.dataKey === "desc" ? cellStyle : {}}
                  >
                    {column.isCurrency
                      ? formatCurrency(
                          row[column.dataKey],
                          currencyCode,
                          countryCode,
                        )
                      : row[column.dataKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          {rows.length === 0 && (
            <TableRow>
              <TableCell
                colSpan={12}
                sx={{ textAlign: "center", borderBottom: "none" }}
              >
                <UText variant={"body1"} component={"div"}>
                  {"No records found"}
                </UText>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {showPagination && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={12} sx={{ p: 0, pr: 2 }}>
                <TablePagination
                  sx={{
                    ".MuiTablePagination-toolbar": {
                      display: "flex",
                      justifyContent: "flex-end",
                    },
                    ".MuiTablePagination-selectLabel": {
                      flexBasis: "16%",
                    },
                  }}
                  component="div"
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
