import { FC, useEffect, useState } from "react"
import {
  generatePath,
  matchPath,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import { ULoading } from "../components"
import { useAppDispatch, useAppSelector } from "../core/app/hooks"
import { getCaseById } from "../core/app/slices/case/caseThunkApi"
import { getQuotation } from "../core/app/slices/order/orderThunkApi"
import { fetchPatientById } from "../core/app/slices/patients/patientThunkApi"
import { updateCurrentOrgId } from "../core/app/slices/user/userSlice"
import { RootState } from "../core/app/store"
import { useMyNavigation } from "../hooks/useMyNavigation"
import { getQuoteRequestObj } from "../modules/Patient/PatientList/util/commonUtil"

import {
  ROUTE_BY_MODULE,
  shouldNavigateToPatients,
  verifyRoutAllowedByStatus,
} from "./status.route.config"

const RouteByStatus: FC = () => {
  const location = useLocation()
  const navigate = useMyNavigation()
  const [loading, setLoading] = useState<boolean>(true)
  const { patientId, caseId, orgId } = useParams()
  const dispatch = useAppDispatch()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { user } = useAppSelector((state: RootState) => state.userService)
  const { selectedBundle } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const { rxPrescription } = useAppSelector(
    (state: RootState) => state.formService,
  )

  const callGetQuotation = () => {
    if (caseDetail.udesign_json) {
      const requestObj = getQuoteRequestObj(caseDetail)
      dispatch(getQuotation(requestObj))
    }
  }

  useEffect(() => {
    if (!orgId) return
    dispatch(updateCurrentOrgId(orgId))
  }, [dispatch, orgId])

  // route permission based on  caseDetail?.caseDisposition
  useEffect(() => {
    if (patientId) {
      dispatch(
        fetchPatientById({
          patientId,
        }),
      )
    }
    if (caseId && patientId) {
      dispatch(getCaseById({ patientId, caseId })).then((res) => {
        if (
          !matchPath(
            user.isCustomerSupport
              ? "org/:orgId/" + ROUTE_BY_MODULE.clinicalPath
              : ROUTE_BY_MODULE.clinicalPath,
            location.pathname,
          ) &&
          res?.payload?.caseDisposition
        ) {
          const x = verifyRoutAllowedByStatus(
            res?.payload?.caseDisposition,
            location.pathname,
            user.isCustomerSupport,
          )
          if (!x) {
            if (
              shouldNavigateToPatients(
                location.pathname,
                res?.payload?.caseDisposition,
              )
            ) {
              navigate("/patients")
              return
            }
            setLoading(false)
            navigate(-1)
          }
        }
      })
    }

    setLoading(false)
  }, [location.pathname])

  //Order Module
  /**
   * if selected bundle not present we should redirect to /bundle selection stage
   */
  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.id &&
      !!matchPath(ROUTE_BY_MODULE.order, location.pathname)
    ) {
      const isMatchBundle = matchPath(
        ROUTE_BY_MODULE.orderBundle,
        location.pathname,
      )
      if (!selectedBundle || (selectedBundle && !selectedBundle.sku_name)) {
        if (!isMatchBundle) {
          const newPath = generatePath(ROUTE_BY_MODULE.orderBundle, {
            patientId,
            caseId,
          })
          callGetQuotation()
          navigate(newPath)
        }

        if (isMatchBundle) {
          callGetQuotation()
        }
      }
    }
    setLoading(false)
  }, [selectedBundle, location.pathname, caseDetail])

  if (loading) {
    return <ULoading isLoading={true} />
  }

  return <Outlet />
}

export default RouteByStatus
