import { wasmModule } from "./modules/wasm/wasmModule"
import "./modules/wasm/setwasmcallback"

import {
  setToothMoveForbidCB,
  setShowModificationButtonCB,
  onModificationClicked,
  setMoveToothLimitCB,
  modificationLogic,
  toothMoveEvent,
  clearUndoOrRedoState,
  setShowToothInfoDialogCB,
  setToothClickedInViewModeCB,
  EToothMoveEventType,
  resetCaseType,
  setReadonly,
  disPatchPanelDataCallback,
  disPatchButton,
  setRootControl,
  TypeInNumber,
  moveWithInputValue,
  toothModule,
  IToothProperty,
  setIsExistIPROrPAPromptCallback,
  dealWithExistIprOrPAPrompt,
} from "./modules/toothmanager"
import {
  resourceManager,
  ResourceManagerEventType,
  resourcesSynchronization,
} from "./modules/resourcemanager"
import {
  doPresetupWorkflow,
  redoPresetupFlow,
  requestAICloudPresetup,
} from "./modules/sagemaker/presetup"
import { displayModule } from "./modules/displaymodule/displaymodule"
import { stagingManager } from "./modules/stagingdatamanager/stagingmanager"
import {
  attachmentModule,
  IAttachmentIPRVisible,
} from "./modules/attachment/attachmentModule"
import { initialfinalModule } from "./modules/initialfinal/initialfinalmodule"
import {
  drawSTL,
  changeArchMode,
  zoomWithValue,
  setZoomCallback,
  clearPreview,
  saveStlToMtc,
  isInitPreview,
  drawScanMtc,
  drawMtcFromZips,
  initScanView,
  initRefinementView,
  testInitRefinementView,
  drawRefinementSTL,
  drawRefinementMtcFromZips,
  saveArchType,
  saveDoRefinementStage,
  fillHole,
} from "./modules/preview/preview"
import { treatmentPlan } from "./modules/treatmentplan"
import { boltonModule } from "./modules/bolton"
import {
  CaseZipType,
  ICaseExtInfo,
  ICaseExtraAttr,
  ZipFileName,
  ZipFileList,
} from "./common/types"
import { refinementModule } from "./modules/refinement"

import { IAuxullartiesShowInfo } from "./modules/attachment/attachmentModule"
import { PanelDataProps, ESetupType } from "./common/commontypes"
import { getArrayFromCPlusArray } from "./common"

import type {
  IInitialFinal,
  ICaseManagement,
  IStageControl,
  IPreView,
  ITreatmentViewportControl,
  IToothMovement,
  Ihistory,
  IViewEdit,
  IRefinement,
  HoverToothData,
  EBiteJumpLocationType,
  IOrderView,
  IToothModule,
  IBiteRamp,
} from "./typesofinterface"
import { iprModule } from "./modules/ipr/iprModule"
import { superimposeModule } from "./modules/superimpose/superimposemodule"
import { EToothDirection } from "./modules/toothmanager/toothdirection"
import { globalEvents, GlobalEvents } from "../utils"
import { WASMLifeCircleEventType, report } from "./modules"
import {
  isCaninesTeeth,
  isDeciduous,
  isIncisorTeeth,
  isMolarTeeth,
  isPremolarsTeeth,
  isWisdom,
} from "./modules/toothmanager/toothmodule"

export * from "./typesofinterface"

function printMetaInfo() {
  // console.log(`WeSmileClinical Version:[${glueLayerPackageJson.version}]`);
}

export const caseManagement: ICaseManagement = {
  async initWasm(canvas:HTMLCanvasElement){
    await wasmModule.initWASM(canvas)
  },
  async openCaseOrSwitchCanvas(
    canvas: HTMLCanvasElement,
    zipFiles: Record<string, Blob> | File | string,
    callback: () => void,
  ) {
    if (!caseManagement.isCaseInit()) {
      caseManagement.setCallbackAfterOpencase(() => {
        callback()
      })
      if (zipFiles instanceof File) {
        caseManagement.openCaseFromZip(canvas, zipFiles)
      } else if (typeof zipFiles === "string") {
        caseManagement.openCaseFromLocalZipFileUrl(canvas, zipFiles)
      } else {
        caseManagement.openCase(canvas, zipFiles)
      }
    } else {
      wasmModule.changeCanvasForWasm(canvas, () => {
        console.log("change canvas!!!")
        callback()
        wasmModule.moduleManager.SwitchBiteRampModule(true)
      })
      globalEvents.fire(GlobalEvents.WASM_CANVAS_CHANGED)
    }
  },
  async openCase(canvas: HTMLCanvasElement, zipFiles: Record<string, Blob>) {
    printMetaInfo()
    if (!canvas) {
      console.warn("cant opencase, canvas is null ")
      return
    }

    await new Promise((resolve, reject) => {
      wasmModule.initWASM(canvas, async (module) => {
        console.debug("wasm init over!!!!", canvas, module)
        resolve(true)
      })
    })

    await resourcesSynchronization.writeCaseFilesToVirtualFS(zipFiles)
    wasmModule.cancelemloop()
    wasmModule.isPresetup = false
    const windowTitle = document.title
    wasmModule.module._setup(wasmModule.module.allocateUTF8(windowTitle))
  },
  async openEmptyCase(canvas: HTMLCanvasElement) {
    printMetaInfo()
    if (!canvas) {
      console.warn("cant opencase, canvas is null ")
      return
    }

    await new Promise((resolve, reject) => {
      wasmModule.initWASM(canvas, async (module) => {
        console.debug("wasm init over!!!!", canvas, module)
        resolve(true)
      })
    })
    wasmModule.cancelemloop()
    wasmModule.isPresetup = false
    const windowTitle = document.title
    wasmModule.module._setup(wasmModule.module.allocateUTF8(windowTitle))
  },
  async openCaseFromZip(canvas: HTMLCanvasElement, zipfile: File) {
    printMetaInfo()
    if (!canvas) {
      console.warn("cant opencase, canvas is null ")
      return
    }

    try {
      await new Promise((resolve, reject) => {
        wasmModule.initWASM(canvas, async (module) => {
          console.log("wasm init over!!!!", canvas, module)
          resolve(true)
        })
      })
      wasmModule.cancelemloop()
      const resFileList = await resourceManager.parseZipFilesFromAZipPacket(
        zipfile,
      )
      const writeFileList = await resourcesSynchronization.makeVirtualDirectory(
        resFileList,
      )
      await resourcesSynchronization.moduleFS(writeFileList)
      caseManagement.setupCase()
    } catch (excp) {
      console.warn("openCaseFromZip::", excp)
    }
  },
  async openCaseFromLocalZipFileUrl(
    canvas: HTMLCanvasElement,
    fileUrl: string,
  ) {
    const fetchRes = async (url) => {
      const resp: Response = await fetch(url, {
        method: "get",
        responseType: "arraybuffer",
      } as any)

      const blob = await resp.blob()
      return blob
    }
    const zipBlob = await fetchRes(fileUrl)
    Object.assign(zipBlob, { lastModifiedDate: new Date(), name: "zipfile" })

    await caseManagement.openCaseFromZip(canvas, zipBlob as File)
  },
  setCallbackAfterOpencase(callback: () => void) {
    wasmModule.moduleOpenCaseReady = () => {
      if (callback) {
        callback()
      }
    }
  },
  enableTreatmentModules() {
    wasmModule.moduleManager.SwitchModuleToothEruption(true)
    wasmModule.moduleManager.SwitchModuleMoveTooth(true)
    wasmModule.moduleManager.SwitchUndoOrRedoModule(true)
    wasmModule.moduleManager.SwitchMainWindowModule(true)
    attachmentModule.openAttachmentModule(true)
    wasmModule.moduleManager.SwitchBiteRampModule(true)
  },
  closeCase() {
    disPatchPanelDataCallback()
    wasmModule.delete()
  },
  setupCase() {
    const windowTitle = document.title
    console.log("start setup........")
    wasmModule.module._setup(wasmModule.module.allocateUTF8(windowTitle))
  },
  isCaseInit() {
    return wasmModule.isInit
  },
  getCaseExtraInfomation() {
    try {
      // get attachment information
      const attachmentReport = attachmentModule.getAttachmentReport()

      let upperTemplate = false
      let lowerTemplate = false
      const attachmentInfo = attachmentReport.attachmentReport
      for (let index = 1; index <= 16; index++) {
        const attachment = attachmentInfo[index]
        if (attachment && attachment.attachment.length > 0) {
          upperTemplate = true
        }
      }
      for (let index = 17; index <= 32; index++) {
        const attachment = attachmentInfo[index]
        if (attachment && attachment.attachment.length > 0) {
          lowerTemplate = true
        }
      }

      // check if there is Ap Attachment
      let apAttachment = false
      const attToothKeys = Object.keys(attachmentReport.attachmentReport)
      const bingoKeys = [6, 5, 4]
      outerLoop: for (const key of attToothKeys) {
        const numKey = Number.parseInt(key, 10)
        const { attachment } = attachmentReport.attachmentReport[numKey]
        if (attachment && attachment.length > 0) {
          for (let n = 0; n < attachment.length; n++) {
            const element = attachment[n]
            if (bingoKeys.includes(element.attachmentId)) {
              apAttachment = true
              break outerLoop
            }
          }
        }
      }
      // get all tooth properties
      const toothList = itoothModule.getToothList()
      const toothProperties: Record<number, IToothProperty> = {}
      for (let index = 0, len = toothList.uplist.length; index < len; index++) {
        const toothId = toothList.uplist[index]
        toothProperties[toothId] = itoothModule.getToothProperties(toothId)
      }
      for (
        let index = 0, len = toothList.lowlist.length;
        index < len;
        index++
      ) {
        const toothId = toothList.lowlist[index]
        toothProperties[toothId] = itoothModule.getToothProperties(toothId)
      }

      let isImplant = false,
        isMissTooth = false,
        isPullout = false,
        isBridge = false,
        isErup = false,
        isBigSpace = false
      const keys = Object.keys(toothProperties)

      for (const key of keys) {
        const numKey = Number.parseInt(key)
        const properties = toothProperties[numKey]
        if (!properties) {
          continue
        }
        if (properties.isBridge) {
          isBridge = true
        }
        if (properties.isImplant) {
          isImplant = true
        }
        if (properties.isErupting) {
          isErup = true
        }
        if (properties.isExtract) {
          isPullout = true
        }
      }

      // check is there a missing tooth?
      // find the tooth id range
      let upperMinToothId = 16
      let upperMaxToothId = 1
      let lowerMinToothId = 32
      let lowerMaxToothId = 16
      for (const key of keys) {
        const toothID = Number.parseInt(key, 10)
        if (toothID <= 16) {
          if (toothID < upperMinToothId) {
            upperMinToothId = toothID
          }
          if (toothID > upperMaxToothId) {
            upperMaxToothId = toothID
          }
        } else {
          if (toothID < lowerMinToothId) {
            lowerMinToothId = toothID
          }
          if (toothID > lowerMaxToothId) {
            lowerMaxToothId = toothID
          }
        }
      }

      for (let i = upperMinToothId; i <= upperMaxToothId; i++) {
        const isExist = keys.includes(i.toString())
        if (!isExist) {
          isMissTooth = true
          break
        }
      }
      for (let i = lowerMinToothId; i <= lowerMaxToothId; i++) {
        const isExist = keys.includes(i.toString())
        if (!isExist) {
          isMissTooth = true
          break
        }
      }

      // check is setup
      let isSetup = false
      let file = resourcesSynchronization.getFileDirectly(
        "teethProperty_u.data",
        "Setting Data2",
      )
      if (!file) {
        file = resourcesSynchronization.getFileDirectly(
          "teethProperty_l.data",
          "Setting Data2",
        )
      }

      if (file) {
        isSetup = true
      }

      const stageData = stagingManager.wasmStageData
      let upperMaxStageNum = 0
      if (stageData.jointUpKeypoints.length > 0) {
        upperMaxStageNum = Number.parseInt(
          stageData.jointUpKeypoints[stagingManager.stageUpnNumber].name,
        )
      }

      let lowerMaxStageNum = 0
      if (stageData.jointLowerKeypoints.length > 0) {
        lowerMaxStageNum = Number.parseInt(
          stageData.jointLowerKeypoints[stagingManager.stageLowNumber].name,
        )
      }

      isBigSpace = wasmModule.mouthModel.GetBigSpace()
      const ret = {
        ApAttachment: apAttachment, // ???判断条件是否是只要有6，5，4号附件存在就为true  在UD中会提示是否有“Button”
        MissTooth: isMissTooth, //  建病例之前没有或者被拔掉就是缺牙
        BigSpace: isBigSpace, // 相邻牙齿间距大于5mm
        Implant: isImplant,
        Pullout: isPullout,
        EruptionDome: isErup ? 1 : 0,
        SetUp: isSetup,
        UpperMaxStageNum: upperMaxStageNum,
        LowerMaxStageNum: lowerMaxStageNum,
        UpperTemplate: upperTemplate ? 1 : 0,
        LowerTemplate: lowerTemplate ? 1 : 0,
        // UpperRetainer: 0, // ??? 魏扬说需要暴露新的接口
        // LowerRetainer: 0, // ??? 魏扬说需要暴露新的接口
        // UpperType: "A", // ??? 需要接口 UD有A/R/I/U WASM只有三种类型
        // LowerType: "A", // ??? 需要接口
        // TimeBeforeSetUp: 0, // ???
        // TimeOnManualSetUp: 0, // ???? 不知道怎么获取
      } as ICaseExtraAttr

      return ret
    } catch (excp) {
      console.error("getCaseExtraInfomation", excp)
      return null
    }
  },
  presetupCase(presetupOps) {
    doPresetupWorkflow(
      presetupOps.upperArch,
      presetupOps.lowerArch,
      presetupOps.canvas,
      () => {},
      presetupOps.donecallback,
    )
  },
  redoPresetup(canvas, donecallback) {
    redoPresetupFlow(canvas, () => {}, donecallback)
  },
  async requestCloudPresetup(
    upper,
    lower,
    orientation,
    orgId,
    patientId,
    caseId,
  ) {
    return await requestAICloudPresetup(
      upper,
      lower,
      orientation,
      orgId,
      patientId,
      caseId,
    )
  },
  getScansPhoto() {
    return resourcesSynchronization.getScansPhoto()
  },
  getInitialFinalScreenshot() {
    return resourcesSynchronization.getInitialFinalScreenshot()
  },
  /**
   * 保存后获取所有文件用以上传
   * @param fileName
   * @returns
   */
  async getCaseFiles(fileName?: ZipFileList | ZipFileName) {
    return await resourcesSynchronization.saveAndGetFileZips(fileName)
  },
  getAttachmentIPRReport() {
    const attachment = attachmentModule.getAttachmentReport()
    const iprReport = iprModule.getIPRReport()
    const reportData = {
      attachment: attachment.attachmentReport,
      toothlist: attachment.toothList,
      firstStage: attachment.firstStage,
      ipr: iprReport,
    }
    return reportData
  },
  getBoltonReport() {
    boltonModule.computeBolton()
    return boltonModule.getBoltonReport()
  },

  getAttachmentIPRData() {
    const visible: IAttachmentIPRVisible = {
      isHasAttachmentData: wasmModule.statusController.IsAddAttachment(),
      isHasIPRData: wasmModule.statusController.HasCollision(),
    }
    return visible
  },
  getCaseSetupType() {
    return wasmModule.caseSetupType
  },
  setCaseSetupType(type: ESetupType) {
    wasmModule.caseSetupType = type
  },
  setcalsetupfinishCallback(callback: () => void) {
    wasmModule.calsetupfinishCallback = callback
  },

  //------------------ Treatment plan ---------------------
  setCaseExtInfo(caseExtInfo: ICaseExtInfo) {
    treatmentPlan.setCaseExtInfo(caseExtInfo)
  },
  getCaseExtInfo() {
    return treatmentPlan.caseExtInfo
  },
  getTreatmentPlanList() {
    return treatmentPlan.getTxNameList()
  },

  changeTreatment(txIndex: 1 | 2) {
    return treatmentPlan.changeTx(txIndex)
  },

  renameTreatment(txIndex: 1 | 2, newName: string) {
    return treatmentPlan.renameTx(txIndex, newName)
  },

  saveCurrentTx() {
    return treatmentPlan.saveCurrentTx()
  },
  delTx(txIndex: 1 | 2) {
    return treatmentPlan.delTx(txIndex)
  },
  getToothReports(isUpper: boolean): Map<number, any> {
    report.update()
    return isUpper ? report.UpReportArrays : report.LowReportArrays
  },
  setBackgroundColor(r: number, g: number, b: number) {
    if (!wasmModule.isInit) return
    wasmModule.ulabwinIns.setBackgroundColor(r, g, b)
  },

  setBackgroundPic(url: string) {
    if (!wasmModule.isInit) return
    wasmModule.ulabwinIns.setBackgroundPic(url)
  },

  SetBackgroundGradient(color1: string, color2: string) {
    if (!wasmModule.isInit) return
    wasmModule.ulabwinIns.SetBackgroundGradient(color1, color2)
  },

  getHasArchType() {
    const archsType = {
      hasUpperArch: false,
      hasLowerArch: false,
    }
    const jsonFile = resourcesSynchronization.getFile(
      "/test/case/Setting Data2/ArchType.json",
    )
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (event) => {
        const result = event.target.result as string
        const archJson = JSON.parse(result)
        if ("upArch" in archJson) archsType.hasUpperArch = true
        if ("downArch" in archJson) archsType.hasLowerArch = true

        resolve(archsType)
      }

      reader.onerror = (event) => {
        reject(event.target.error)
      }

      reader.readAsText(jsonFile)
    })
  },
  saveJsonDataToZip: async (
    data: string,
    fileName: string,
    path: CaseZipType,
  ) => {
    if (!wasmModule.module) return false

    const saveRes = await resourcesSynchronization.saveJsonDataToZip(
      data,
      fileName,
      path,
    )

    return saveRes
  },

  savePhotoToZip: async (photo: File, fileName: string) => {
    if (!wasmModule.module) return false
    const saveRes = await resourcesSynchronization.saveFileToZip(
      photo,
      fileName,
      "Photo",
    )
    return saveRes
  },

  saveStlToZip: async (stl: File, isUpper: boolean) => {
    if (!wasmModule.module) return
    let stlFilePath: Record<string, any> = {}
    const stlPath = isUpper ? "test/pre/arch_u.stl" : "test/pre/arch_l.stl"
    stlFilePath[stlPath] = stl
    let result = await resourcesSynchronization.moduleFS(stlFilePath)
    const mtcPath = isUpper ? "Raw/arch_o_u.mtc" : "Raw/arch_o_l.mtc"
    wasmModule.module.SaveMtcFileToFolder(stlPath, mtcPath)
    return result
  },

  getPhotoFromZip: async (fileName: string) => {
    if (!wasmModule.module) return false
    const blob: Blob = await resourcesSynchronization.getFileFromZip(
      fileName,
      "Photo",
    )
    const file: File = new File([blob], fileName, {
      type: "image/png",
    })
    return file
  },

  getPhotoListFromZip: () => {
    if (!wasmModule.module) return []
    return resourcesSynchronization.getFileListFromZip("Photo")
  },

  setOnReportUpdateCB(callback: (reportsData) => void) {
    globalEvents.on(GlobalEvents.ON_REPORT_UPDATE, () => {
      const data = this.getAttachmentIPRReport()
      callback(data)
    })
  },
}

export const stageControl: IStageControl = {
  getWasmStageData() {
    return stagingManager.wasmStageData
  },

  setStageStep(upstep, lowstep) {
    stagingManager.setStageStep(upstep, lowstep)
  },
  setStageCallback(callback) {
    stagingManager.setStageCallback = callback
  },
  setSetupTypeCaseCallback(callback) {
    stagingManager.setupTypeAndStage = callback
    // attachmentModule.iprVisibility = callback;
  },
  // setCallbackOnStageDataChanged(callback) {
  //   attachmentModule.attachmentiprVisibility = callback;
  // },
  // setEndMessageCallback(callback) {
  //   attachmentModule.attachmentiprVisibility = callback;
  //   // attachmentModule.attachmentVisibility = callback;
  //   // attachmentModule.iprVisibility = callback;
  // },
  getMaxStageNodeIndex(): number {
    return Math.max(
      stagingManager.wasmStageData.keypointsUpMaxStep,
      stagingManager.wasmStageData.keypointsDownMaxStep,
    )
  },
  getCurrentStageIndex(): { upper: number; lower: number } {
    const ret = stagingManager.getCurrentStageIndex()
    return ret
  },
  switchIC() {
    let upIndex = 0
    let lowerIndex = 0
    if (
      stagingManager.wasmStageData.curUpNodeIndex === 0 &&
      stagingManager.wasmStageData.curLowerNodeIndex === 0
    ) {
      if (stagingManager.lastNoZeroUpIndex !== 0) {
        upIndex = stagingManager.lastNoZeroUpIndex
      }
      if (stagingManager.lastNoZeroLowerIndex !== 0) {
        lowerIndex = stagingManager.lastNoZeroLowerIndex
      }
    }

    stagingManager.setStageStep(upIndex, lowerIndex)
  },
  setBiteJumpType(biteJumpType: EBiteJumpLocationType) {
    stagingManager.SetBiteJumpType(biteJumpType)
  },

  isDownArchBiteMoved() {
    if (!wasmModule.isInit) return false

    return wasmModule.stagingcontoler.IsDownArchBiteMoved()
  },

  playGMAnimation() {
    if (!wasmModule.isInit) return 0
    return wasmModule.stagingcontoler.playGMAnimation()
  },

  setBiteJumpTypePlayGM(biteJumpType: EBiteJumpLocationType) {
    if (!wasmModule.isInit) return
    wasmModule.stagingcontoler.setBiteJumpTypePlayGM(biteJumpType)
  },

  getBiteJumpType() {
    if (!wasmModule.isInit) return 0
    return wasmModule.stagingcontoler.getBiteJumpType()
  },
}

export const initialfinal: IInitialFinal = {
  setInitialFinalEnabled: (isOpen) => {
    initialfinalModule.switchInitialFinalDisplayModule(isOpen)
  },
  zoomByScale(scaleFac) {
    initialfinalModule.zoomByUI(scaleFac)
  },
  setZoomInitialFinalCallback(callback) {
    initialfinalModule.setUpdateSliderCB(callback)
  },
  openOcclusalView() {
    initialfinalModule.openOcclusalView()
  },
}

export const preview: IPreView = {
  drawSTL,
  changeArchMode,
  zoomWithValue,
  setZoomCallback,
  clearPreview,
  saveStlToMtc,
  isInitPreview,
  drawScanMtc,
  drawMtcFromZips,
  initScanView,
  initRefinementView,
  testInitRefinementView,
  drawRefinementSTL,
  drawRefinementMtcFromZips,
  saveArchType,
  saveDoRefinementStage,
  fillHole,
}

export const toothTools = {
  isIncisorTeeth,
  isCaninesTeeth,
  isPremolarsTeeth,
  isMolarTeeth,
  /**
   * 是否是智齿
   */
  isWisdom,
  /**
   * 是否是多生牙
   */
  isDeciduous,
}

export const viewControlInTreatment: ITreatmentViewportControl = {
  setAttachmentVisibility(isVisible) {
    if (!wasmModule.isInit) return
    wasmModule.statusController.DisplayAttachment(isVisible)
    wasmModule.moduleManager.GetBiteRampModule().SetBiteRampVisiable(isVisible)
    //  if (!this.wasmAttachmentModule) {
    attachmentModule.wasmAttachmentModule =
      wasmModule.moduleManager.GetAttachmentModule()
    //   }
  },
  drawAuxullarties(
    canvasElement: HTMLElement,
    auxinfoList: IAuxullartiesShowInfo[],
  ) {
    attachmentModule.drawAuxullarties(canvasElement, auxinfoList)
  },
  setZoomCallbackInTreatment(callback) {
    displayModule.setZoomCallback([0.25, 4.0], callback)
  },
  zoomWithValueInTreatment(val) {
    displayModule.zoomWithValue(val)
  },
  showArchMode(mode) {
    displayModule.showArchMode(mode)
  },
  setViewType(type) {
    switch (type) {
      case "left":
        displayModule.splitScreenDisplay(false)
        displayModule.setToLeftBuccalView()
        break
      case "right":
        displayModule.splitScreenDisplay(false)
        displayModule.setToRightBuccalView()
        break
      case "back":
        displayModule.splitScreenDisplay(false)
        wasmModule.statusController.SetToBackView()
        break
      case "front":
        displayModule.splitScreenDisplay(false)
        displayModule.setToAnteriorView()
        break
      case "split":
        displayModule.splitScreenDisplay(true)
        // 处理inside视窗ipr显示问题
        iprModule.updateIPRVisibility()
        break
      default:
        break
    }
    displayModule.setShowType(type)
  },
  setIPRVisibility(isVisible, stageIndexForShow = 0) {
    if (!wasmModule.isInit) return
    iprModule.setIPRVisibility(isVisible, stageIndexForShow)
  },
  /**
   * set Grid is visibile
   * @param val
   * @returns
   */
  setGridVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return
    // wasmModule.moduleManager.SetGridActorsColor(
    //   120 / 255,
    //   144 / 255,
    //   156 / 255,
    //   0.5,
    //   120 / 255,
    //   144 / 255,
    //   156 / 255,
    //   0.5,
    //   120 / 255,
    //   144 / 255,
    //   156 / 255,
    //   1
    // );
    wasmModule.wrapInstance.ShowGridLayer(isVisible)
  },

  /**
   * set Super-impose Stage
   * @param val
   * @returns
   */
  setSuperimposeStage(isUpper: boolean, step: number) {
    if (!wasmModule.isInit) return
    superimposeModule.SetSuperimposeStage(isUpper ? 0 : 1, step)
  },

  /**
   * set Super-impose visibility
   * @param isVisible
   * @returns
   */
  setSuperimposeVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return
    superimposeModule.ShowSuperimpose(isVisible)
  },

  /**
   * set Super-impose Opacity
   * @param isVisible
   * @returns
   */
  setSuperimposeOpacity(opacityVal: number) {
    if (!wasmModule.isInit) return
    superimposeModule.SetSuperimposeOpacity(opacityVal)
  },

  /**
   * set Occlusion is Visible
   * @param isVisible
   */
  setOcclusionVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return
    if (isVisible) wasmModule.statusController.TurnOnBiteCheck()
    else wasmModule.statusController.TurnOffBiteCheck()
  },

  /**
   * set space check is Visible
   * @param isVisible
   */
  setSpacecheckVisibility(isVisible: boolean) {
    if (!wasmModule.isInit) return
    wasmModule.wrapInstance.ShowCollision(isVisible)
  },
  setOverlayVisibility(isVisible: boolean) {
    displayModule.switchOverlayModule(isVisible)
  },
  setOverlayOpacity(opacity: number) {
    displayModule.opacityOverlay(opacity)
  },

  /**
   * 特殊牙齿颜色开关
   * @param isEnable
   * @returns
   */
  specialToothColorToggle(isEnable: boolean) {
    if (!wasmModule.isInit) return

    wasmModule.statusController.SetEnableToothColorSetting(isEnable)
  },

  /**
   * 设置 Dome/Tooth display switch
   */
  setDomeDisplayCallback(
    cb: (
      isVisible: boolean,
      toothID: number,
      x: number,
      y: number,
      isRight: boolean,
      isUpper: boolean,
    ) => void,
  ) {
    const mteModule = wasmModule.moduleManager.GetModuleToothEruption()
    ;(window as any).showDomeDisplayCB = cb
    if (mteModule) {
      mteModule.SetShowPickModeDialogCB("showDomeDisplayCB")
    }
  },

  /**
   * 调用Dome Sync button 功能
   * 在最后一步，第一步，移动dome后，正常播放dome会动。点击这个按钮后，再播放，dome位置不变。
   * @returns
   */
  makeDomeStatic() {
    const mteModule = wasmModule.moduleManager.GetModuleToothEruption()
    if (mteModule) {
      mteModule.OnSyncButtonDown()
    }
  },

  /**
   * to change between Picking demo or picking tooth
   * @param isPickDome
   */
  changePickDomeMode(isPickDome: boolean) {
    const mteModule = wasmModule.moduleManager.GetModuleToothEruption()
    if (mteModule) {
      mteModule.ChangePickDomeMode(isPickDome)
    }
  },
  setHoverToothCallback: (callback: (hoverData: HoverToothData) => void) => {
    displayModule.setHoverToothcallback(callback)
  },
  hoverToothToggle: (isEnable: boolean) => {
    displayModule.setHoverToothToggle(isEnable)
  },
}

export const toothMovement: IToothMovement = {
  setIsExistIPROrPAPromptCallback(
    callback: (hasIPR: 0 | 1, hasPA: 0 | 1) => void,
  ) {
    setIsExistIPROrPAPromptCallback(callback)
  },
  dealWithExistIprOrPAPrompt(comfirmOrCancel: boolean) {
    dealWithExistIprOrPAPrompt(comfirmOrCancel)
  },
  setIsEnableMoveTooth(isEnabled: boolean) {
    wasmModule.moduleManager.SwitchModuleMoveTooth(isEnabled)
  },
  cancelPickTooth() {
    if (wasmModule.wrapInstance) {
      const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule()
      moveToothModule.CancelPickTooth()
    }
  },
  setCallbackForMovingMolar(callback) {
    setToothMoveForbidCB(callback)
  },

  setCallbackOutOfRange(callback: () => void) {
    setMoveToothLimitCB(callback)
  },

  setCallbackModifyTooth(callback: (isShow: boolean) => void) {
    setShowModificationButtonCB(callback)
  },

  modifyButtonClicked(callback: () => void, popupCallback: () => void) {
    if (!wasmModule.isInit) return
    onModificationClicked(callback, popupCallback)
  },

  async modificationSchemeSimple() {
    modificationLogic(false)
    // true 变为简单
    const showType = displayModule.getShowType()
    viewControlInTreatment.showArchMode("all")
    viewControlInTreatment.showArchMode(showType)

    resetCaseType(ESetupType.COPASimple)
  },

  async modificationSchemeSecondary() {
    // false  变为中等
    modificationLogic(true)
    const showType = displayModule.getShowType()
    viewControlInTreatment.showArchMode("all")
    viewControlInTreatment.showArchMode(showType)

    resetCaseType(ESetupType.COPAModerate)
  },

  setCallbackCannotMoveTooth(callback: () => void) {
    setToothClickedInViewModeCB(callback)
  },

  setCallbackShowToothInfo(callback: (panelDataP: PanelDataProps) => void) {
    setShowToothInfoDialogCB(callback)
  },
  /**
   * readonly :true  不可移  false :可以移
   */
  setToothReadonly(bReadonly: boolean) {
    setReadonly(bReadonly)
    if (!wasmModule.isInit) return false
    const moveToothModule = wasmModule.wrapInstance.GetMoveToothModule()
    if (moveToothModule) {
      moveToothModule.SetViewMode(bReadonly)
    }
  },
  disPatchButton(dir: EToothDirection, quick: boolean) {
    disPatchButton(dir, quick)
  },

  setRootControl(isRoot) {
    setRootControl(isRoot)
  },
  moveWithInputValue: (val: number, dir: TypeInNumber) => {
    if (!wasmModule.isInit) return false
    moveWithInputValue(val, dir)
  },
  closeArchAdjustment: () => {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.SwitchModuleFastSmartCircle(false)
    wasmModule.moduleManager.SwitchModuleFineTuneCircle(false)
  },
  switchModuleFastSmartCircle: (onOff: boolean) => {
    if (!wasmModule.isInit) return
    if (onOff) wasmModule.moduleManager.SwitchModuleFineTuneCircle(false)
    wasmModule.moduleManager.SwitchModuleFastSmartCircle(onOff)
  },
  switchModuleFineTuneCircle: (onOff: boolean) => {
    if (!wasmModule.isInit) return
    if (onOff) wasmModule.moduleManager.SwitchModuleFastSmartCircle(false)
    wasmModule.moduleManager.SwitchModuleFineTuneCircle(onOff)
  },
}

export const itoothModule: IToothModule = {
  getToothProperties(toothId: number) {
    if (!wasmModule.wrapInstance) {
      console.warn("cant find wasmModule.wrapInstance, wasmModule need init.")
      return null
    }

    return toothModule.getToothProperties(toothId)
  },
  /**
   * 获取已有牙齿牙号列表（不包括missing,包括其他特殊牙齿和拔牙）
   * @returns
   */
  getToothList() {
    return toothModule.getToothList()
  },
  getPrimaryToothMark(toothId: number) {
    return toothModule.getPrimaryToothMark(toothId)
  },
}

export const history: Ihistory = {
  undo() {
    if (!wasmModule.isInit) return false
    const module = wasmModule.ulabwinIns.getModuleManager()
    if (module) {
      module.GetUndoOrRedoModule().GetLastState()
    }
    // close panel
    disPatchPanelDataCallback()
    return true
  },

  /**
   * redo one step
   * @returns
   */
  redo() {
    if (!wasmModule.isInit) return false
    const module = wasmModule.ulabwinIns.getModuleManager()
    if (module) {
      module.GetUndoOrRedoModule().GetNextState()
    }
    // close panel
    disPatchPanelDataCallback()
    return true
  },

  /**
   * clear all steps
   * @returns
   */
  clearAll() {
    if (!wasmModule.isInit) return false
    const module = wasmModule.ulabwinIns.getStageContoller()
    // const undo = undefined;
    if (module) {
      caseManagement.getAttachmentIPRData()
      module.ResetAutoSetUpResult()
      clearUndoOrRedoState()

      toothMoveEvent.fire(EToothMoveEventType.RESETCLICKED_EVENT)
      // close panel
      disPatchPanelDataCallback()
    }
    return true
  },
}

export const viewEdit: IViewEdit = {
  switchViewToEdit(isEdit: boolean) {
    if (!wasmModule.isInit) return 0
    stagingManager.setEndNodeIndex()
    return wasmModule.statusController.SwitchViewAndEdit(isEdit)
  },
}

export const refinement: IRefinement = {
  openRefinementModule(isOpen: boolean) {
    return refinementModule.openRefinementModule(isOpen)
  },

  setRefinementDisplayRange(displayRange: string) {
    return refinementModule.setRefinementDisplayRange(displayRange)
  },

  getRefinementInfo(archTpye: number) {
    return refinementModule.getRefinementInfo(archTpye)
  },

  changeDisplayStageValue(index: number, archTpye: number) {
    refinementModule.changeDisplayStageValue(index, archTpye)
  },

  getNumberOfRefinement(archTpye: number) {
    return refinementModule.getNumberOfRefinement(archTpye)
  },

  undoRefinement(archTpye: number) {
    refinementModule.undoRefinement(archTpye)
  },
}

export const orderView: IOrderView = {
  getElasticJson(teethIdArray: number[]): string {
    return attachmentModule.getElasticJsonContent(teethIdArray)
  },
  initAlignerCoverageViewInOrderPage(bgcolor: [number, number, number]) {
    caseManagement.setBackgroundColor(bgcolor[0], bgcolor[1], bgcolor[2])
    wasmModule.stagingcontoler.OpenOrClosetoothMoveModule(false)
    wasmModule.moduleManager.SwitchModuleSelectToothInOrder(false)
  },
  initAndClearOrderView(bgcolor: [number, number, number]) {
    caseManagement.setBackgroundColor(bgcolor[0], bgcolor[1], bgcolor[2])
    wasmModule.stagingcontoler.OpenOrClosetoothMoveModule(false)
    wasmModule.moduleManager.SwitchModuleSelectToothInOrder(false)
    wasmModule.moduleManager.SwitchModuleSelectToothInOrder(true)
  },
  setTeethSelectedColor(toothSelectedColor: [number, number, number]) {
    const toothInOrderModule =
      wasmModule.moduleManager.GetModuleSelectToothInOrder()
    toothInOrderModule.setPickedToothColor(
      toothSelectedColor[0],
      toothSelectedColor[1],
      toothSelectedColor[2],
    )
  },
  setDefaultTeethSelected(toothIds: number[]) {
    const toothInOrderModule =
      wasmModule.moduleManager.GetModuleSelectToothInOrder()
    for (let index = 0; index < toothIds.length; index++) {
      const toothId = toothIds[index]
      toothInOrderModule.setPickedTooth(toothId)
    }
  },
  getTeethSelected() {
    const toothInOrderModule =
      wasmModule.moduleManager.GetModuleSelectToothInOrder()
    const teethIdCArray = toothInOrderModule.getSelectedToothIds()
    const teethIds = getArrayFromCPlusArray<number>(teethIdCArray)
    return teethIds
  },
  // async saveScanButtonsInfoToElasticJson(teethIds: number[]) {
  //   const jsonStr = resourcesSynchronization.getFileDirectly(
  //     "elasticControlPoints.json",
  //     "Setting Data2",
  //     "utf8"
  //   );

  //   let jsonObj = {};
  //   if (!jsonStr) {
  //     // 没有json则创建
  //   } else {
  //     try {
  //       jsonObj = JSON.parse(jsonStr);
  //     } catch (excp) {
  //       console.warn("The content of elasticControlPoints.json parse false!");
  //     }
  //   }
  //   // 保存jsonObj
  //   const report = caseManagement.getAttachmentIPRReport();
  //   const traverseAllTeeth = toothId => {
  //     const index = teethIds.findIndex(key => {
  //       return key === toothId;
  //     });
  //     if (index === -1) {
  //       if (jsonObj[toothId]) {
  //         jsonObj[toothId].isScanButton = false;
  //       } else {
  //         jsonObj[toothId] = { isScanButton: false };
  //       }
  //     } else {
  //       if (jsonObj[toothId]) {
  //         jsonObj[toothId].isScanButton = true;
  //       } else {
  //         jsonObj[toothId] = { isScanButton: true };
  //       }
  //     }
  //   };
  //   report.toothlist.uplist.forEach(traverseAllTeeth);
  //   report.toothlist.lowlist.forEach(traverseAllTeeth);
  //   await caseManagement.saveJsonDataToZip(
  //     JSON.stringify(jsonObj),
  //     "elasticControlPoints.json",
  //     "Setting Data2"
  //   );
  // },
}

export const biteRampModule: IBiteRamp = {
  setOperationEnable(enable: boolean): void {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.GetBiteRampModule().SetOperationEnable(enable)
  },
  autoAddBiteRampToTooth(toothID: number): boolean {
    if (!wasmModule.isInit) return false
    const isAdd = wasmModule.moduleManager
      .GetBiteRampModule()
      .AutoAddBiteRampToTooth(toothID)
    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
    return isAdd
  },
  deleteBiteRamp(toothID: number): void {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.GetBiteRampModule().DeleteBiteRamp(toothID)
    globalEvents.fire(GlobalEvents.ON_REPORT_UPDATE)
  },
  isToothHasBiteRamp(toothid: number): boolean {
    if (!wasmModule.isInit) return false
    const isHas = wasmModule.moduleManager
      .GetBiteRampModule()
      .IsToothHasBiteRamp(toothid)
    return isHas
  },

  isHaveBiteRampOnArch(isUpper): boolean {
    if (!wasmModule.isInit) return false

    const archType = isUpper
      ? wasmModule.module.ArchType.UpArch
      : wasmModule.module.ArchType.DownArch
    return wasmModule.statusController.IsHaveBiteRampOnArch(archType)
  },
  changeStartStage(startStage: number): void {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.GetBiteRampModule().ChangeStartStage(startStage)
  },
  changeEndStage(endStage: number): void {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.GetBiteRampModule().ChangeEndStage(endStage)
  },
  getStartStage(): number {
    if (!wasmModule.isInit) return -1

    const startStage = wasmModule.moduleManager
      .GetBiteRampModule()
      .GetStartStage()

    return startStage
  },
  getEndStage(): number {
    if (!wasmModule.isInit) return -1

    const endStage = wasmModule.moduleManager.GetBiteRampModule().GetEndStage()

    return endStage
  },
  setBiteRampVisiable(bVisiable: boolean): void {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.GetBiteRampModule().SetBiteRampVisiable(bVisiable)
  },

  syncBiteRampHeightToPlane(): void {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager.GetBiteRampModule().SyncBiteRampHeightToPlane()
  },

  onShowDownTeethIn3to3Range(visible) {
    if (!wasmModule.isInit) return
    wasmModule.moduleManager
      .GetBiteRampModule()
      .OnShowDownTeethIn3to3Range(visible)
  },

  onButtonPosChangeCallback(callback) {
    const windowObj = window as any
    windowObj.OnBiteRampBtnPosChange = callback
  },
}
