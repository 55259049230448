import React from 'react';
import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import Draggable from 'react-draggable';

const StageDragBar = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragOffsetX, setDragOffsetX] = useState(0)
  const [dragStartPositionX, setDragStartPositionX] = useState(0)
  const divRef = useRef(null)

  const getRectanglePositionX = () => {
    //var rect = element.getBoundingClientRect();
    //return { x: rect.left, y: rect.top };
    const e = divRef.current
    if(e === undefined) {
      return 0
    }
    const rect = e.getBoundingClientRect()
    const middleX = rect.left + (rect.width / 2)

    const parentE = e.offsetParent
    let parentELeft = 0
    if(parentE !== undefined) {
      const parentRect = parentE.getBoundingClientRect()
      parentELeft = parentRect.left
    }
    
    //console.info("=====getRectanglePosition: ", middleX);
    return (middleX - parentELeft)
  }

  return (
    <Draggable 
      bounds={props.bounds}
      position={position}
      onStart={(e, data) => {
        setDragStartPositionX(getRectanglePositionX())
      }}
      onDrag={(e, data) => {
        props.positionXChanged(dragStartPositionX + data.x)
        setDragOffsetX(data.x)
      }}
      onStop={(e, data) => {
        setDragOffsetX(0)
        props.dragStoped()
      }}>
      <div ref={divRef} style={{
        width: props.width,
        height: props.height,
        borderRadius: "2px",
        backgroundColor: props.visibility === "hidden" ? "transparent" : "#4B4B4B",
        position: "relative",
        left: props.x - dragOffsetX
      }}>
      
      </div>
    </Draggable>
    
  );
};
 
export default StageDragBar;