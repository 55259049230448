import OktaAuth, {
  AuthState,
  EVENT_EXPIRED,
  OktaAuthCoreInterface,
} from "@okta/okta-auth-js"

import { QUERY_PARAMS_STORAGE_KEY, QUERY_PARAMS_STORAGE_KEY_ORDER } from "../../modules/Patient/PatientList/patientTable/table.config"
import { updateLoggedInUser } from "../app/slices/user/userSlice"
import store from "../app/store"
import { setAccessToken } from "../global/interceptors"

const REDIRECT_URI = `${window.location.origin}/login/callback`

const { VITE_OKTA_ISSUER, VITE_OKTA_CLIENT_ID } = import.meta.env

// eslint-disable-next-line import/no-anonymous-default-export
const oktaConfig = {
  clientId: VITE_OKTA_CLIENT_ID,
  issuer: VITE_OKTA_ISSUER, // REACT_APP_OKTA_BASE_URL,
  redirectUri: REDIRECT_URI,
  postLogoutRedirectUri: window.location.origin,
  scopes: ["openid", "profile", "email", "offline_access"],
  pkce: true,
  maxAge: 10,
  autoRemove: true,
  cookies: {
    secure: false,
  },
  tokenManager: {
    autoRenew: true,
    autoRemove: true,
  },
  transformAuthState: async (
    oktaAuth: OktaAuthCoreInterface,
    authState: AuthState,
  ) => {
    if (!authState.isAuthenticated) {
      console.log("transformAuthState", authState.isAuthenticated)
    }
    authState.isAuthenticated = !!authState.accessToken
    return authState
  },
}

const oktaAuthClient = new OktaAuth(oktaConfig)

oktaAuthClient.tokenManager.on("renewed", function (key, newToken) {
  console.log(`Renewed token with key ${key}`, newToken)
})

oktaAuthClient.authStateManager.subscribe((authState) => {
  console.debug("auth.js authStateManager subscription", authState)
  setAccessToken(() => authState?.accessToken?.accessToken || "")
  store.dispatch(
    updateLoggedInUser({
      ...authState?.accessToken,
    }),
  )
})

if (!oktaAuthClient.isLoginRedirect()) {
  // Trigger an initial authState change event when the app startup
  oktaAuthClient.authStateManager.updateAuthState()
}

oktaAuthClient.tokenManager.on(EVENT_EXPIRED, (key) => {
  const existingToken = oktaAuthClient.tokenManager.get(key)
  //for debugging purposes
  console.log("auth.js: authClient expiration event", existingToken)
})

export default oktaAuthClient

export const getAccessToken = () => {
  const tokenObj = localStorage.getItem("okta-token-storage")
  if (tokenObj) {
    const { accessToken } = JSON.parse(tokenObj)
    return accessToken?.accessToken || null
  }
  return null
}

export const getUserInfoFromToken = () => {
  const token: string | null = getAccessToken()
  if (token) {
    try {
      const base64Url = token.split(".")[1]
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
      const jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join(""),
      )
      return JSON.parse(jsonPayload)
    } catch (error) {
      return null
    }
  }

  return null
}

export const isTokenValid = (): boolean => {
  const token: string | null = getAccessToken()
  if (token) {
    const { exp } = getUserInfoFromToken()
    if (Date.now() >= exp * 1000) {
      clearLocalStorage()
      //Enable later if we need
      //localStorage.setItem("ERROR_TYPE", "SESSION_ERROR")
      return false
    } else {
      return true
    }
  }
  return false
}

export const clearLocalStorage = () => {
  localStorage.removeItem("okta-cache-storage")
  localStorage.removeItem("okta-token-storage")
  localStorage.removeItem("okta-original-uri-storage")
  localStorage.removeItem("okta-shared-transaction-storage")
  localStorage.removeItem("routeFrom")
  localStorage.removeItem(QUERY_PARAMS_STORAGE_KEY)
  localStorage.removeItem(QUERY_PARAMS_STORAGE_KEY_ORDER)
  localStorage.removeItem("web-uuid")
}
