import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { Box, Button, Stack } from "@mui/material"

import { useAppDispatch } from "@/core/app/hooks"
import { LeftPage } from "./LeftPage"
import UDdentalComponent from "../UDdentalComponent/UDdentalComponent"
export function UDDentalChart(props) {
  const inputFile = useRef(null)
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  return (
    <LeftPage
      title="Dental Chart"
      content={<UDdentalComponent />}
      onClose={props.onClose}
      actions={
        <Box
          sx={{
            display: "flex",
            height: "48px",
            alignItems: "center",
            justifyContent: "flex-end",
            width: "100%",
            padding: "32px 16px 32px 24px",
          }}
        >
          <Button
            sx={{
              color: "#fff",
              padding: "8px 22px",
              borderRadius: "4px",
              backgroundColor: "#215ECD",
            }}
            variant="contained"
            onClick={() => {}}
          >
            {"Done"}
          </Button>
        </Box>
      }
    />
  )
}
