import { useState, useEffect, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Backdrop, Box, LinearProgress, useTheme } from "@mui/material"

import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"

import { caseManagement, wasmModule } from "@/gluelayer"
import {
  fetchFileList,
  fetchFileZips,
  getCaseInfoApi,
  getTreatmentInfos,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { preview } from "@/gluelayer"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import {
  setCurCaseMode,
  setCurLeftPage,
  setHasUploadSTLs,
  setIsCasePreview,
} from "./udTreatSlice"
import { fetchClinicalSettings } from "@/core/app/slices/user/userApis"
import { downloadFiles } from "@/core/app/slices/v2/v2apis"
import { ZipTypes } from "@/core/app/slices/v2/v2.service"

export const UDOpenCase = ({ open, onClose }) => {
  const theme = useTheme()

  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const { fileListReady, isLoading, zipList, zipNames } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const { curCaseMode, isCasePreview } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const [loadingMsg, setLoadingMsg] = useState("")
  const [isCaseLoading, setIsCaseLoading] = useState(false)

  // Get Treatment Infos and zip file names
  useEffect(() => {
    console.log("getTreatmentInfos ::")
    dispatch(setCurCaseMode("WeDesign"))
    dispatch(setCurLeftPage(""))
    caseManagement.closeCase()
    dispatch(resetCase())

    dispatch(setIsCasePreview(true)) // default is preview only

    // Get treatment infos
    dispatch(getTreatmentInfos({ patientId }))
    // Get zip file names
    dispatch(
      fetchFileList({
        patientId,
        caseId,
      }),
    )
    dispatch(fetchClinicalSettings())

    setIsCaseLoading(true)
    setLoadingMsg("Start download case...")
    return () => {
      dispatch(resetCase())
      setIsCaseLoading(false)
    }
  }, [])

  // Get zip files (Blobs)
  useEffect(() => {
    if (zipNames === null) {
      return
    }
    if (zipNames.length) {
      const downloadNames: ZipTypes[] = zipNames.map<ZipTypes>((item) => {
        if (item === "storage.version.json") {
          return "version" as ZipTypes
        } else {
          return item as ZipTypes
        }
      })
      const envStr = import.meta.env.VITE_NODE_ENV
      if (envStr === '"local"' || envStr === '"qausa"') {
        dispatch(
          //   // old
          fetchFileZips({
            orgId: "",
            patientId,
            caseId,
            zipNames,
          }),
        )
      } else {
        const files3 = dispatch(downloadFiles({ caseId, files: downloadNames }))
        console.log("files>>>>>>>>>>000", files3)
      }
      setIsCaseLoading(true)
      setLoadingMsg("Download case files ...")
    } else {
      // zero files, so it's empty case
      console.log("No zip files, empty case! ")
      dispatch(setCurCaseMode("NewCase"))
      dispatch(setCurLeftPage("createcase"))
      setIsCaseLoading(false)
    }
  }, [zipNames])

  useEffect(() => {
    const bgColor =
      theme.palette.mode === "dark"
        ? [128 / 255, 128 / 255, 128 / 255]
        : [66 / 255, 95 / 255, 107 / 255]

    if (wasmModule.isInit)
      // caseManagement.SetBackgroundGradient("F8F8FF", "#DCDCDC")
      // caseManagement.setBackgroundColor(bgColor[0], bgColor[1], bgColor[2])
      caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
  }, [theme])

  useEffect(() => {
    if (isLoading === "failed" && isCaseLoading) {
      console.log("Something wrong for clincal service...")
      setLoadingMsg("Something is wrong while loading case")
      setIsCaseLoading(false)
      dispatch(setCurCaseMode("NewCase"))
    }
  }, [isLoading])

  const checkIsCaseFull = (zipList: Record<string, Blob>) => {
    const blob1 = zipList["bulk1"] // show contian teeth, gum & more
    if (blob1 && blob1.size >= 5000) return true
    return false
  }

  const openCasePreview = (zips: Record<string, Blob>) => {
    setLoadingMsg("Open the case ...")
    caseManagement.closeCase()
    preview
      .initScanView(
        document.getElementById("canvas") as HTMLCanvasElement,
        zips,
      )
      .then(() => {
        const res = preview.drawScanMtc([0.25, 2])
        dispatch(
          setHasUploadSTLs([
            res.get("arch_o_u.mtc") !== null,
            res.get("arch_o_l.mtc") !== null,
          ]),
        )
      })
    setIsCaseLoading(false)
  }

  const openCaseFull = (zipList: Record<string, Blob>) => {
    setLoadingMsg("Open the case ...")
    caseManagement.closeCase()
    caseManagement.setCallbackAfterOpencase(() => {
      caseManagement.setBackgroundPic("backgroundvtk.png") //背景图只需要将图片名字传入，图片存储在resource/Pictures/下
      dispatch(setHasUploadSTLs([true, true]))
    })
    caseManagement
      .openCase(document.getElementById("canvas") as HTMLCanvasElement, zipList)
      .then((val) => {
        setLoadingMsg("Open case in 3D.")
        setIsCaseLoading(false)
      })
      .catch((error) => {
        setLoadingMsg("Error to open case.")
        setIsCaseLoading(false)
      })
  }

  // Open case with zipList
  useEffect(() => {
    if (Object.keys(zipList).length > 0) {
      setLoadingMsg("Check case type...")
      const zips = zipList as Record<string, Blob>
      const fullCase = checkIsCaseFull(zips)
      dispatch(setIsCasePreview(!fullCase))
      if (fullCase) {
        setLoadingMsg("Open full case ...")
        openCaseFull(zips)
      } else {
        setLoadingMsg("Open preview case ...")
        openCasePreview(zips)
      }
    }
  }, [zipList])

  return (
    open && (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isCaseLoading}
      >
        <Box sx={{ width: "40%" }}>
          {loadingMsg}
          <LinearProgress />
        </Box>
      </Backdrop>
    )
  )
}
