import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { UDTabs } from "@/UDTreat/custom/UDTabs"
import WarnIcon from "@mui/icons-material/PriorityHigh"
import {
  EToothDirection,
  toothMovement,
  wasmModule,
  gdsPlusSetup,
} from "@/gluelayer"
import { KeyboardEvent, useEffect, useState } from "react"
import { panelDataProps } from "./toothinfoprops"
import { LeftArrow, RightArrow } from "./InfoIcons"
import { getSettingLabel } from "@/core/utils/commonMethod"
// import Drag from "@/components/Drag/Drag"
import { Rnd } from "react-rnd"
import { UDToothAutoSetup } from "./UDToothAutoSetup"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setSelTooth } from "../udTreatSlice"
import { updateSelTooth } from "../udSetupSlice"

const panelButtons = [
  {
    index: 0,
    leftLabel: "Distal",
    rightLabel: "Mesial",
    leftDir: EToothDirection.Distal,
    rightDir: EToothDirection.Mesial,
    unit: "mm",
    key: "",
  },
  {
    index: 1,
    leftLabel: "Buccal",
    rightLabel: "Lingual",
    leftDir: EToothDirection.Buccal,
    rightDir: EToothDirection.Lingual,
    unit: "mm",
    key: "",
  },
  {
    index: 2,
    leftLabel: "Extrusion",
    rightLabel: "Intrusion",
    leftDir: EToothDirection.Extrusion,
    rightDir: EToothDirection.Intrusion,
    unit: "mm",
    key: "",
  },

  {
    index: 3,
    leftLabel: "Torque(L)",
    rightLabel: "Torque(B)",
    leftDir: EToothDirection.TorqueSub,
    rightDir: EToothDirection.TorqueAdd,
    unit: "°",
    key: "",
  },
  {
    index: 4,
    leftLabel: "Tipping(M)",
    rightLabel: "Tipping(D)",
    leftDir: EToothDirection.TippingSub,
    rightDir: EToothDirection.TippingAdd,
    unit: "°",
    key: "",
  },
  {
    index: 5,
    leftLabel: "Rotation(M)",
    rightLabel: "Rotation(D)",
    leftDir: EToothDirection.RotationSub,
    rightDir: EToothDirection.RotationAdd,
    unit: "°",
    key: "",
  },
]

const UDToothInfo = ({ isOpenCase }) => {
  const theme = useTheme()
  const [isShow, setIsShow] = useState(false)
  const { disabled } = useAppSelector(
    (state: RootState) => state.udSetupService,
  )
  const dispatch = useAppDispatch()
  const [dragxy, setDragxy] = useState({ x: 100, y: 200 })

  const [dataSource, setDataSource] = useState<panelDataProps>({
    data: [0, 0, 0, 0, 0, 0],
    flag: false,
    isBridge: false,
    isCrown: false,
    isImplant: false,
    isNoMovement: false,
    isRoot: false,
    isShow: false,
    isErupting: false,
    isPontic: false,
    isPrimary: false,
    toothId: 0,
    toothWidth: 0,
    isCanEdit: false,
  })
  const [textFieldValus, setTextFieldValues] = useState([
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
    ["", ""],
  ])

  useEffect(() => {
    toothMovement.setCallbackShowToothInfo((data) => {
      const copyData = JSON.parse(JSON.stringify(data))
      //toothWidth
      let valWidth = (Math.round(copyData.toothWidth * 10) / 10).toString()
      if (!valWidth.includes(".")) {
        valWidth += ".0"
      }
      copyData.toothWidth = valWidth
      // handle data to fixed 2
      for (let i = 0; i < copyData.data.length; i++) {
        const val = Math.round(copyData.data[i] * 10) / 10
        copyData.data[i] = i === 1 || i === 2 ? -val : val
      }
      setDataSource(copyData)
      let selT = 0
      if (copyData.toothId > 0 && copyData.isShow) selT = copyData.toothId
      dispatch(setSelTooth(selT))
      dispatch(updateSelTooth(selT))
    })
  }, [])

  useEffect(() => {
    setIsShow(dataSource.isShow)
    const newTextFieldValus = [...textFieldValus]
    for (let i = 0; i < dataSource.data.length; i++) {
      const data = dataSource.data[i]
      if (data === 0) newTextFieldValus[i] = ["", ""]
      else
        newTextFieldValus[i] =
          data < 0
            ? [`${Math.abs(data)}${panelButtons[i].unit}`, ""]
            : ["", `${Math.abs(data)}${panelButtons[i].unit}`]
    }
    setTextFieldValues(newTextFieldValus)
  }, [dataSource])

  const handleArrowClick = (dir: number, quick: boolean) => {
    toothMovement.disPatchButton(dir, quick)
  }

  //focus input
  const focusInput = (
    value: string,
    textFieldIndex: 0 | 1,
    index: number,
    unit: string,
  ) => {
    const newValue = Number.parseFloat(value.replace(unit, ""))
    if (!newValue && newValue !== 0) return

    const newTextFieldValus = [...textFieldValus]
    newTextFieldValus[index][textFieldIndex] = newValue.toString()
    setTextFieldValues(newTextFieldValus)
  }
  // blur input
  const blurInput = (
    value: string,
    dir: -1 | 1,
    index: number,
    unit: string,
  ) => {
    value = value.replace(unit, "")
    let numberValue = Number.parseFloat(value)
    if (!numberValue && numberValue !== 0) return
    numberValue *= dir
    if (index < 3) numberValue *= -1
    toothMovement.moveWithInputValue(numberValue, index)
  }
  // enter input
  const enterInput = (
    event: KeyboardEvent<HTMLInputElement>,
    value: string,
    dir: -1 | 1,
    index: number,
    unit: string,
  ) => {
    if (event.code === "Enter") {
      value = value.replace(unit, "")
      let numberValue = Number.parseFloat(value)
      if (!numberValue && numberValue !== 0) return
      numberValue *= dir
      if (index < 3) numberValue *= -1
      toothMovement.moveWithInputValue(numberValue, index)
      event.target.blur()
    }
  }
  // change value
  const changeInput = (value: string, dir: -1 | 1, index: number) => {
    const regex = /^(?!\.?$)[0-9]*\.?[0-9]*$/
    if (regex.test(value) || value === "") {
      // console.log("ashuaizzz🚀 ~regex value:", value)
      const newTextFieldValus = [...textFieldValus]
      newTextFieldValus[index][dir] = value
      setTextFieldValues(newTextFieldValus)
    }
  }
  const RemoveOccContact = () => {
    return (
      <Button
        onClick={() => {
          if (!wasmModule.isInit) return
          gdsPlusSetup.ResolveOccColl(true, true, () => ({}))
        }}
      >
        Remove Occ Contact
      </Button>
    )
  }
  const ToothMoveInfo = () => {
    return (
      <Stack
        paddingTop={1.5}
        marginLeft={2}
        marginRight={2}
        // marginTop={1}
        sx={{
          height: "90%",
        }}
        direction="column"
        spacing={1}
      >
        {panelButtons.map((arrowProps) => {
          return (
            <Stack key={arrowProps.index} spacing={-0.5}>
              <Stack direction="row">
                <IconButton
                  onClick={() => handleArrowClick(arrowProps.leftDir, true)}
                >
                  <LeftArrow sx={{ width: 24, height: 24 }} />
                </IconButton>
                <IconButton
                  onClick={() => handleArrowClick(arrowProps.leftDir, false)}
                >
                  <LeftArrow sx={{ width: 12, height: 12 }} />
                </IconButton>
                <TextField
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: "center",
                    },
                  }}
                  value={textFieldValus[arrowProps.index][0]}
                  onFocus={(e) => {
                    focusInput(
                      e.target.value,
                      0,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onBlur={(e) => {
                    blurInput(
                      e.target.value,
                      -1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onKeyUp={(e) => {
                    enterInput(
                      e,
                      textFieldValus[arrowProps.index][0],
                      -1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onChange={(e) => {
                    changeInput(e.target.value, 0, arrowProps.index)
                  }}
                />
                <TextField
                  variant="standard"
                  inputProps={{
                    style: {
                      textAlign: "center",
                    },
                  }}
                  value={textFieldValus[arrowProps.index][1]}
                  onFocus={(e) => {
                    focusInput(
                      e.target.value,
                      1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onBlur={(e) => {
                    blurInput(
                      e.target.value,
                      1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onKeyUp={(e) => {
                    enterInput(
                      e,
                      textFieldValus[arrowProps.index][1],
                      1,
                      arrowProps.index,
                      arrowProps.unit,
                    )
                  }}
                  onChange={(e) => {
                    changeInput(e.target.value, 1, arrowProps.index)
                  }}
                />
                <IconButton
                  onClick={() => handleArrowClick(arrowProps.rightDir, false)}
                >
                  <RightArrow sx={{ width: 12, height: 12 }} />
                </IconButton>
                <IconButton
                  onClick={() => handleArrowClick(arrowProps.rightDir, true)}
                >
                  <RightArrow sx={{ width: 24, height: 24 }} />
                </IconButton>
              </Stack>
              <Stack
                direction="row"
                divider={
                  <Divider variant="middle" orientation="vertical" flexItem />
                }
                alignItems="center"
                justifyContent={"center"}
                spacing={1}
              >
                <Typography variant="caption">
                  {arrowProps.leftLabel}
                </Typography>
                <Typography variant="caption">
                  {arrowProps.rightLabel}
                </Typography>
              </Stack>
            </Stack>
          )
        })}
        {!disabled ? <RemoveOccContact /> : <></>}
      </Stack>
    )
  }
  return (
    isShow && (
      <>
        <Rnd
          position={{ x: dragxy.x, y: dragxy.y }}
          onDragStop={(e, d) => {
            setDragxy({ x: d.x, y: d.y })
          }}
          dragHandleClassName="dragarea"
        >
          <Box
            className="toothinfo"
            sx={{
              width: "300px",
              backgroundColor: theme.palette.background.paper,
              position: "absolute",
              borderRadius: 2,
            }}
          >
            <Stack
              className="dragarea"
              paddingLeft={4}
              paddingRight={2}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                height: "10%",
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.background.default
                    : "whitesmoke",
                borderRadius: 2,
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {`${getSettingLabel("Pedro", dataSource.toothId)}`}
              </Typography>
              <Stack alignItems="center" direction="row">
                <Button
                  onClick={() => {
                    wasmModule.wrapInstance
                      .GetMoveToothModule()
                      .ResetToothMovement()
                  }}
                >
                  Reset
                </Button>
                <IconButton
                  sx={{
                    backgroundColor: "lightskyblue",
                    width: "24px",
                    height: "24px",
                  }}
                >
                  <WarnIcon />
                </IconButton>
              </Stack>
            </Stack>
            {!disabled ? (
              <UDTabs labels={["Tooth", "AutoSetup"]}>
                <ToothMoveInfo />
                <UDToothAutoSetup />
              </UDTabs>
            ) : (
              <ToothMoveInfo />
            )}
          </Box>
        </Rnd>
      </>
    )
  )
}
export default UDToothInfo
