import { ChangeEvent, useState } from "react"
import CloseIcon from "@mui/icons-material/Close"
import { TextField } from "@mui/material"

const UFileTextField = ({
  label,
  fieldName,
  onInputchange,
  handleOnClickClose,
}) => {
  const [imgName, setImgName] = useState("")
  const [showCloseIcon, setShowCloseIcon] = useState(false)
  const [isHover, setIsHover] = useState(false)

  return (
    <TextField
      fullWidth
      sx={{
        ".MuiInputBase-root": {
          height: "40px",
        },
        ".MuiFormLabel-root": {
          top: "-6px",
        },
        ".MuiInputLabel-shrink": {
          top: 0,
        },
        "input[type=file]::file-selector-button": {
          display: "none",
        },
        "input[type=file]": {
          color: imgName.length ? "unset" : "rgba(0, 0, 0, 0)",
        },
      }}
      InputProps={{
        endAdornment: ((imgName && showCloseIcon) || (imgName && isHover)) && (
          <CloseIcon
            onClick={() => {
              setImgName("")
              handleOnClickClose()
            }}
            sx={{
              height: "24px",
              width: "24px",
              cursor: "pointer",
              color:
                isHover && !showCloseIcon ? "text.secondary" : "primary.main",
            }}
          />
        ),
      }}
      inputProps={{
        accept: "image/*",
      }}
      value={imgName}
      onChange={(e: ChangeEvent<HTMLInputElement>): void => {
        onInputchange && onInputchange(e.target.files)
        setImgName(e.target.value)
      }}
      onMouseOver={() => {
        setIsHover(true)
      }}
      onMouseOut={() => {
        setIsHover(false)
      }}
      onFocus={() => {
        setShowCloseIcon(true)
      }}
      onBlur={() => {
        setShowCloseIcon(false)
      }}
      type={"file"}
      label={label}
      name={fieldName}
    />
  )
}

export default UFileTextField
