import { createSlice } from "@reduxjs/toolkit"

import { FileItem, PhotoObject } from "./photograph.type"
import {
  downloadPhotographs,
  fetchFilesList,
  getS3FileUrlAPI,
  updatePhotoPosition,
  uploadPhotographsInv_1,
  uploadPhotographsInv_2,
  downloadPhotosfromS3,
  uploadPhotosToS3,
} from "./photographThunkApi"

interface IPhotographState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  isPhotoUploading: "idle" | "pending" | "succeeded" | "failed"
  fileList: FileItem[]
  compositePhoto: PhotoObject[]
  individualPhotos: PhotoObject[]
  additionalPhotos: PhotoObject[]
  isDownloading: "idle" | "pending" | "succeeded" | "failed"
  count: number
  individualPhotosCount: number
  individualAdditional: PhotoObject[]
  uploadPhotoS3URL: {
    url: string
    origFilename: string
    destFilename: string
  }[]
  photoTypeJson?: File
  cachePhotos: {
    caseId: string
    records: { file: File; filename: string }[]
    map: File
  }[]
  /**
   * caseID <-> Record<filename,{data:File}>[]
   */
  photosCache: Record<string, Record<string, File>>
}

const initialState: IPhotographState = {
  loading: "idle",
  isPhotoUploading: "idle",
  fileList: [],
  compositePhoto: [],
  individualPhotos: [],
  additionalPhotos: [],
  isDownloading: "idle",
  count: 0,
  individualPhotosCount: 0,
  individualAdditional: [],
  uploadPhotoS3URL: [],
  cachePhotos: [],
  photosCache: {},
}

export const photographServiceSlice = createSlice({
  name: "photographService",
  initialState,
  reducers: {
    resetPhotoLists: (state) => {
      state.compositePhoto = []
      state.individualPhotos = []
      state.additionalPhotos = []
      state.fileList = []
      state.individualAdditional = []
    },
    resetPhotographs: (state) => {
      state.compositePhoto = []
      state.individualPhotos = []
      state.additionalPhotos = []
      state.individualAdditional = []
    },
    resetCount: (state) => {
      state.count = 0
      state.individualPhotosCount = 0
    },
    addAdditionalPhotoCount: (state, action) => {
      state.individualPhotosCount = action.payload
    },
    setDownloadStatus: (state, action) => {
      state.isDownloading = action.payload
    },
    setIndividualPhotos: (state) => {
      const newData = [
        ...state.individualPhotos,
        ...state.individualAdditional,
      ].sort((a, b) => Number(a.sequence) - Number(b.sequence))
      state.individualPhotos = [...newData]
      state.individualAdditional = []
    },
    setAdditionalPhotos: (state, action) => {
      const newData = [...state.additionalPhotos, ...action.payload.fileObj]
      state.additionalPhotos = [...newData]
    },
    removeCompositePhoto: (state) => {
      state.compositePhoto = []
    },
    removeAdditionalPhoto: (state, action) => {
      state.additionalPhotos = [...action.payload.newArray]
    },
    removeIndividualPhotos: (state, action) => {
      state.individualPhotos = [...action.payload.newArray]
    },
    removeIndividualAdditional: (state, action) => {
      const newArrray = state.individualAdditional.filter(
        (obj) => obj.fileName !== action.payload.fileName,
      )
      state.individualAdditional = [...newArrray]
    },
  },
  extraReducers: (builder) => {
    // uploadPhotosToS3
    builder.addCase(uploadPhotosToS3.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(uploadPhotosToS3.fulfilled, (state, action) => {
      // const payload = action.payload;
      // const { caseId } = action.meta.arg
      // state.photosCache[caseId] = {...state.photosCache[caseId],...payload.casePhotos} as any
      //console.log("upload photos::",state.photosCache[caseId],payload)
    })
    builder.addCase(uploadPhotosToS3.rejected, (state, action) => {
      console.log("rejected")
    })

    // downloadPhotosfromS3
    builder.addCase(downloadPhotosfromS3.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(downloadPhotosfromS3.fulfilled, (state, action) => {
      const payload = action.payload
      const { caseId } = action.meta.arg
      if (payload) {
        const fileList = Object.keys(payload).reduce((obj, key) => {
          obj[key] = payload[key] as any
          return obj
        }, {})
        state.photosCache[caseId] = {
          ...state.photosCache[caseId],
          ...fileList,
        } as any
        console.log("download photos::", state.photosCache[caseId], fileList)
      }
    })
    builder.addCase(downloadPhotosfromS3.rejected, (state, action) => {
      console.log("rejected")
    })

    // Upload Photos reducers
    builder.addCase(uploadPhotographsInv_1.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(uploadPhotographsInv_1.fulfilled, (state, action) => {
      const result = action.payload.data.result
      const { file, isAdditional } = action.meta.arg
      if (file && !isAdditional) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          sequence: result.sequence,
          position: result.photo_position,
          fileName: result.file_name,
          size: file.size,
        }
        if (fileObj.fileName.includes("composite")) {
          state.compositePhoto.push(fileObj)
        } else if (fileObj.fileName.includes("individual")) {
          state.count++
          const newData = [...state.individualPhotos, fileObj].sort(
            (a, b) => Number(a.sequence) - Number(b.sequence),
          )
          state.individualPhotos = [...newData]
        }
      }
      if (file && isAdditional) {
        const fileObj = {
          src: URL.createObjectURL(file),
          name: result.original_file_name,
          sequence: result.sequence,
          position: result.photo_position,
          fileName: result.file_name,
          size: file.size,
        }
        if (fileObj.fileName.includes("individual")) {
          state.individualAdditional.push(fileObj)
        }
      }
      state.isPhotoUploading = "succeeded"
      isAdditional && state.count++
    })
    builder.addCase(uploadPhotographsInv_1.rejected, (state, action) => {
      const { isAdditional } = action.meta.arg
      console.log("rejected")
      isAdditional && state.count++

      state.isPhotoUploading = "failed"
    })
    builder.addCase(uploadPhotographsInv_2.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(uploadPhotographsInv_2.fulfilled, (state, action) => {
      const file = action.payload.config.data
      const { cachePhotos } = state
      const { caseId } = action.meta.arg
      let res = []
      if (file?.name === "type") {
        res = cachePhotos.map((element) => {
          if (element.caseId === caseId) {
            element.map = file
          }
          return element
        })
      } else {
        let list = []
        cachePhotos.forEach((element) => {
          if (element.caseId === caseId) {
            list = element.records
          }
        })
        list.push({ file: file, filename: file.name })

        if (list.length === 1) {
          res = [...cachePhotos, { caseId, records: [...list] }]
        } else {
          res = cachePhotos.map((element) => {
            if (element.caseId === caseId) {
              element.records = list
            }
            return element
          })
        }
      }
      state.cachePhotos = [...res]
    })
    builder.addCase(uploadPhotographsInv_2.rejected, (state, action) => {
      console.log("rejected")
    })
    builder.addCase(getS3FileUrlAPI.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(
      getS3FileUrlAPI.fulfilled,
      (
        state,
        {
          payload: {
            data: {
              data: { casePhoto },
            },
          },
        },
      ) => {
        state.uploadPhotoS3URL = casePhoto
      },
    )
    builder.addCase(getS3FileUrlAPI.rejected, (state, action) => {
      console.log("rejected")
    })
    // Download Photos reducers
    builder.addCase(downloadPhotographs.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(downloadPhotographs.fulfilled, (state, action) => {
      const photographType = action.meta.arg.photographType
      const result = action.payload.data
      if (result) {
        const file = new File(
          [result],
          `${action.meta.arg.original_file_name}`,
          { type: "image/jpg" },
        )
        const obj: PhotoObject = {
          src: URL.createObjectURL(file),
          sequence: action.meta.arg.sequence,
          position: action.meta.arg.position,
          name: action.meta.arg.original_file_name,
          fileName: action.meta.arg.fileName,
          size: file.size,
        }
        switch (photographType) {
          case "composite":
            state.compositePhoto = [obj]
            break
          case "individual":
            state.individualPhotos = [
              ...[...state.individualPhotos, obj].sort(
                (a, b) => Number(a.sequence) - Number(b.sequence),
              ),
            ]
            break
          case "others":
            state.additionalPhotos.push(obj)
            break
          default:
            break
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(downloadPhotographs.rejected, (state, action) => {
      state.loading = "failed"
    })

    // Fetch File list reducers
    builder.addCase(fetchFilesList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchFilesList.fulfilled, (state, action) => {
      const { result } = action.payload.data
      if (result && result.length > 0) {
        state.fileList = [...result]
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchFilesList.rejected, (state) => {
      state.loading = "failed"
    })

    //Update Photo position reducers
    builder.addCase(updatePhotoPosition.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updatePhotoPosition.fulfilled, (state, action) => {
      state.loading = "succeeded"
      const {
        payload: { data },
      } = action
      state.individualPhotos.map((photo) => {
        data.result.map((res) => {
          if (res.file_name === photo.fileName) {
            photo.position = res.photo_position
          }
        })
      })
    })
    builder.addCase(updatePhotoPosition.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export const {
  resetPhotoLists,
  resetPhotographs,
  setIndividualPhotos,
  setAdditionalPhotos,
  removeCompositePhoto,
  removeAdditionalPhoto,
  removeIndividualPhotos,
  setDownloadStatus,
  resetCount,
  addAdditionalPhotoCount,
  removeIndividualAdditional,
} = photographServiceSlice.actions
