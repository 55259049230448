import { useState } from "react"
import { Box, Button, Hidden, Paper } from "@mui/material"
import Toggle from "./Toggle/toggle"

import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
} from "@/gluelayer"

const {
  setcalsetupfinishCallback,
  getBoltonReport,
  getAttachmentIPRReport,
  getHasArchType,
  getTreatmentPlanList,
  changeTreatment,
  renameTreatment,
  delTx,
} = caseManagement

export const BottomToolBar = () => {
  const [initalShow, setInitalShow] = useState(true)
  const [reportShow, setReportShow] = useState(true)
  const [openMovement, setopenMovement] = useState(true)
  const [stageObj, setStageObj] = useState({
    upList: [],
    downList: [],
    up_current: 0,
    down_current: 0,
  })
  const [planList, setPlanList] = useState<{ txName: string; index: 1 | 2 }[]>(
    [],
  )
  const [currentPlan, setCurrentPlan] = useState<{
    txName: string
    index: 2 | 1
  }>({})

  // function to reNamePlan
  const renamePlanFunction = (newName: string) => {
    // renameTreatment(currentPlan.index, newName)
    // const txList = getTreatmentPlanList()
    // const txArr = assemblyPlanData(txList)
    // txArr.map((i) => {
    //   if (i.index === currentPlan.index) {
    //     setCurrentPlan(i)
    //   }
    // })
    // setPlanList(txArr)
  }
  // function to deletePlan
  const deletePlanFunction = (plan = currentPlan.index) => {
    // const isDel = delTx(plan)
    // const txList = getTreatmentPlanList()
    // const txArr = assemblyPlanData(txList)
    // setCurrentPlan(txArr[0])
    // setPlanList(txArr)
    // return isDel
  }
  const [attachmentObj, setAttachmentObj] = useState<{
    up: boolean
    down: boolean
  }>({ up: false, down: false })

  //stage bar是否处于折叠状态
  const [isStageFold, setIsStageFold] = useState(true)

  // which refinement key
  const [refinementKey, setRefinementKey] = useState<string>("")

  // Availability of upper and lower jaw
  const [isHasUpAndlow, setIsHasUpAndLow] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState(true)

  // up\low arch about view show icon bool(aligner and retainer)
  const [arLimitShow, setArLimitShow] = useState<boolean>(false)

  const [stagebarHeight, setStagebarHeight] = useState(0)

  //max refine
  const [maxRefine, setMaxRefine] = useState<number>(0)

  // up and low attachment stage
  const [attachStage, setAttachStage] = useState<{
    upStageIndex: number
    lowerStageIndex: number
  }>({ upStageIndex: 0, lowerStageIndex: 0 })

  // if have bite-jump
  const [hasBite, setHasBite] = useState<boolean>(true)

  // if refienment case and not last refinement
  const [isRANL, setIsRANL] = useState<boolean>(false)

  // Assembly plan data
  const assemblyPlanData = (planData: string[]) => {
    const txArr = []
    planData.map((i, j) => {
      txArr.push({
        txName: i.trim(),
        index: j + 1,
      })
    })
    return txArr
  }

  // get txPlan data
  const geTxPlanFunc = () => {
    const txList = getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setPlanList(txArr)
    setCurrentPlan(txArr[0])
  }

  return (
    <Box sx={{ left: 0, width: "100%", height: isStageFold ? "48px" : "200px", overflow: "hidden"}}>
      <Toggle
        preparation={true}
        setReportShow={setReportShow}
        setopenMovement={setopenMovement}
        setInitalShow={setInitalShow}
        initalShow={initalShow}
        openMovement={openMovement}
        isReady={true}
        setStageObj={setStageObj}
        planList={planList}
        setCurrentPlan={setCurrentPlan}
        currentPlan={currentPlan}
        renamePlanFunction={renamePlanFunction}
        deletePlanFunction={deletePlanFunction}
        attachmentObj={attachmentObj}
        setIsStageFold={setIsStageFold}
        refinementKey={refinementKey}
        setRefinementKey={setRefinementKey}
        isHasUpAndlow={isHasUpAndlow}
        isEdit={isEdit}
        arLimitShow={arLimitShow}
        setStagebarHeight={setStagebarHeight}
        maxRefine={maxRefine}
        setMaxRefine={setMaxRefine}
        attachStage={attachStage}
        hasBite={hasBite}
        isRANL={isRANL}
      >

      </Toggle>
    </Box>
  )
}
