import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { useAppDispatch } from "../../core/app/hooks"
import { getAccountDetail } from "../../core/app/slices/user/userApis"
import { UText } from "../../ui-component"

import ULabCompanyProfile from "./components/ULabCompanyProfile"
import ULabContactInfo from "./components/ULabContactInfo"
import ULabPriceList from "./components/ULabPriceList"

const ULabAccountContent = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getAccountDetail())
  }, [dispatch])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 8,
      }}
    >
      <UText
        sxProp={{
          padding: "48px 8px 0px 8px",
        }}
        variant={"h4"}
      >
        {t("uLabAccount.title")}
      </UText>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
          width: "100%",
        }}
      >
        <ULabContactInfo />
        {/* <ULabPriceList /> */}
      </Box>
      <ULabCompanyProfile />
    </Box>
  )
}

export default ULabAccountContent
