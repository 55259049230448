import { FC, useEffect, useRef, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { Link, useLocation, useParams } from "react-router-dom"
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen"
import OpenInFullIcon from "@mui/icons-material/OpenInFull"
import { useOktaAuth } from "@okta/okta-react"

import { caseManagement, wasmModule } from "@/gluelayer"

import faq from "../../../assets/images/faq.svg"
import profile from "../../../assets/images/profile.svg"
import profileunfilled from "../../../assets/images/profile-unfilled.svg"
import logo from "../../../assets/images/uLab-Logo.svg"
import { UButton, UText } from "../../../components"
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
} from "../../../components/mui.components"
import { useAppDispatch } from "../../../core/app/hooks"
import { useAppSelector } from "../../../core/app/hooks"
import { fetchDoctorsList } from "../../../core/app/slices/doctors/doctorThunkApi"
import { fetchUserPreferences } from "../../../core/app/slices/user/userApis"
import { updateUserPreferences } from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import {
  QUERY_PARAMS_STORAGE_KEY,
  QUERY_PARAMS_STORAGE_KEY_ORDER,
} from "../../../modules/Patient/PatientList/patientTable/table.config"
import { setWeSmileUI } from "../../../wasm3d/Wasm3DServiceSlice"

import ProfileOptions from "./ProfileOptions"

const Header: FC = () => {
  const navigate = useMyNavigation()
  const location = useLocation()
  const refElement = useRef(null)
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()

  const { t } = useTranslation("common")
  const { oktaAuth, authState } = useOktaAuth()
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const [isHelpOpen, setIsHelpOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const logOutRedirect = async () => oktaAuth.signOut()
  const isCsUser = location.pathname === "/organization"
  const { user } = useAppSelector((state: RootState) => state.userService)
  const {
    VITE_CUSTOMPACKAGE,
    VITE_ULABACCOUNT,
    VITE_BILLINGHISTORY,
    VITE_UDAPP,
  } = import.meta.env
  useEffect(() => {
    //Remove filter from local storge on page refresh
    localStorage.removeItem(QUERY_PARAMS_STORAGE_KEY)
    localStorage.removeItem(QUERY_PARAMS_STORAGE_KEY_ORDER)
    dispatch(fetchDoctorsList())
    dispatch(fetchUserPreferences())
  }, [dispatch, user.current_orgId])

  const tabProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) homePageRedirect()
  }

  const homePageRedirect = () => {
    if (wasmModule.isInit) caseManagement.closeCase()
    navigate(isCustomerSupport ? "/organization" : "/patients")
  }
  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )

  const menuItems = [
    {
      name: "Settings",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/settings/")
      },
    },
    {
      name: "Billing History",
      onClick: () => {
        setIsProfileOpen(false)
        window.open(VITE_BILLINGHISTORY)
      },
    },
    {
      name: "uLab Account",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/uLabAccount")
      },
    },
    {
      name: "Custom Packaging",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/customPackaging/")
      },
    },
    {
      name: "uDesign App",
      onClick: () => {
        setIsProfileOpen(false)
        navigate("/UDesignApp")
      },
    },
    {
      name: "Logout",
      onClick: logOutRedirect,
    },
    {
      name: "Show/Hide weSmile",
      onClick: () => dispatch(setWeSmileUI(!weSmileUI)),
    },
  ]

  const helpMenuItems = [
    {
      name: t("header.helpMenu.help"),
      onClick: () => {
        setIsHelpOpen(false)
        window.open("/help/help")
      },
    },
    {
      name: t("header.helpMenu.shortcuts"),
      onClick: () => {
        window.open("/help/shortcuts")
        setIsHelpOpen(false)
      },
    },
    {
      name: t("header.helpMenu.releaseNotes"),
      onClick: () => {
        window.open("/help/releaseNotes")
        setIsHelpOpen(false)
      },
    },
  ]

  const onClickHandler = () => {
    navigate("/")
  }

  const { preferences, isCustomerSupport } = useAppSelector(
    (state: RootState) => state.userService.user,
  )

  const [openSkipTooltip, setOpenSkipTooltip] = useState<boolean>(false)

  useEffect(() => {
    if (preferences) {
      setOpenSkipTooltip(
        !preferences?.on_show_setting_tooltip &&
          preferences?.on_show_welcome_modal,
      )
    }
  }, [preferences])

  const handleMenuClose = () => {
    setAnchorEl(null)
    setIsProfileOpen(false)
    setIsHelpOpen(false)
  }
  // full-screen
  const [full, setFull] = useState<boolean>(false)
  const clickFull = () => {
    if (!full) {
      const docElm = document.documentElement
      /*W3C*/
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } /*FireFox */ else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } /*Chrome等 */ else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      } /*IE11*/ else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
    setFull(!full)
  }
  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        position: "relative",
        backgroundColor: "#ffffff !important",
        boxShadow: "0px 0px 0px 1px #E0E0E0",
        height: "63px",
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Box
          component={"img"}
          src={logo}
          onClick={homePageRedirect}
          alt={"Logo"}
          loading="lazy"
          sx={{ cursor: "pointer" }}
        />
        <Box
          component="div"
          sx={{
            borderLeft: "1px solid #000000",
            opacity: "0.12",
            height: "35px",
            mx: 2,
          }}
        ></Box>
        <UText
          variant="h6"
          color="#757575"
          sxProp={{ mr: 2 }}
          onClick={onClickHandler}
        >
          {t("header.company")}
        </UText>
        <Box sx={{ flexGrow: 1, ml: 4 }}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
              <Tabs
                value={
                  [
                    "/patients",
                    "/billing",
                    "/orders",
                    "/organization",
                  ].includes(location.pathname)
                    ? location.pathname
                    : false
                }
                onChange={handleChange}
                aria-label="tabs"
              >
                {!isCustomerSupport && (
                  <Tab
                    label={t("header.patientTabtitle")}
                    value="/patients"
                    component={Link}
                    onClick={() => {
                      if (wasmModule.isInit) caseManagement.closeCase()
                    }}
                    to="/patients"
                    sx={{
                      height: "64px",
                      letterSpacing: "0.4px",
                      mr: "16px",
                    }}
                  />
                )}
                {!isCustomerSupport && (
                  <Tab
                    label={t("header.orderTabtitle")}
                    value="/orders"
                    component={Link}
                    to="/orders"
                    sx={{
                      height: "64px",
                      letterSpacing: "0.4px",
                      mr: "16px",
                    }}
                  />
                )}
                <Tab
                  label={t("header.billing")}
                  value="/billing"
                  component={Link}
                  to="/billing"
                  sx={{
                    height: "64px",
                    letterSpacing: "0.4px",
                    mr: "16px",
                  }}
                />
                {isCustomerSupport && (
                  <Tab
                    label={t("header.organization")}
                    value="/organization"
                    component={Link}
                    to="/organization"
                    sx={{
                      height: "64px",
                      letterSpacing: "0.4px",
                      mr: "16px",
                    }}
                  />
                )}
              </Tabs>
            </Box>
          </Box>
        </Box>
        <OpenInFullIcon
          onClick={() => {
            clickFull()
          }}
          sx={{
            color: "#757575",
            mr: 2,
            cursor: "pointer",
          }}
        />
        <Box
          data-testid={"faqIcon"}
          component={"img"}
          src={faq}
          alt={"Logo"}
          onClick={(e) => {
            setIsHelpOpen(!isHelpOpen)
            setAnchorEl(e.currentTarget)
          }}
          loading="lazy"
          sx={{ cursor: "pointer", mr: 2 }}
        />
        <Box component={"div"} ref={refElement} sx={{ display: "flex" }}>
          <Tooltip
            open={openSkipTooltip}
            arrow
            placement={"bottom-start"}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [10, 0],
                  },
                },
              ],
            }}
            slotProps={{
              tooltip: {
                style: {
                  padding: 14,
                  backgroundColor: "#17AADD",
                  maxWidth: "282px",
                  borderRadius: "16px",
                  marginTop: "8px",
                  boxShadow:
                    "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                },
              },
              arrow: {
                style: {
                  color: "#17AADD",
                  width: "24px",
                  height: "17px",
                  marginTop: "-1em",
                },
              },
            }}
            title={
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <UText
                  variant={"subtitle1"}
                  sxProp={{
                    fontWeight: 500,
                    lineHeight: "131.25%",
                    letterSpacing: "normal",
                  }}
                >
                  <Trans
                    components={{
                      bold: <b />,
                    }}
                  >
                    {"header.skipTooltip.content"}
                  </Trans>
                </UText>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <UButton
                    sxProp={{
                      boxShadow:
                        "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                      fontSize: "13px",
                      lineHeight: "169.231%",
                      letterSpacing: "0.46px",
                      minHeight: "30px",
                      height: "auto",
                      padding: "4px 10px",
                      backgroundColor: "#E0E0E0",
                      color: "black",
                    }}
                    size={"small"}
                    variant={"shade"}
                    onClickHandler={() => {
                      dispatch(
                        updateUserPreferences({
                          on_show_setting_tooltip: true,
                        }),
                      )
                    }}
                    btnText={t("header.skipTooltip.gotItBtn")}
                  />
                </Box>
              </Box>
            }
          >
            <Box
              component={"img"}
              onClick={(e) => {
                setAnchorEl(e.currentTarget)
                setIsProfileOpen(!isProfileOpen)
              }}
              src={isProfileOpen ? profile : profileunfilled}
              alt={"userLogo"}
              sx={{ cursor: "pointer", padding: !isProfileOpen ? "4px" : "" }}
            />
          </Tooltip>
          <ProfileOptions
            menuItems={menuItems}
            anchorEl={anchorEl}
            isOpen={isProfileOpen}
            handleOnClose={handleMenuClose}
            menuProps={{
              ".MuiPaper-root": {
                minWidth: "200px",
                top: "58px !important",
              },
            }}
          />
          <ProfileOptions
            handleOnClose={handleMenuClose}
            menuProps={{
              ".MuiPaper-root": {
                minWidth: "220px",
                top: "58px !important",
              },
            }}
            menuItems={helpMenuItems}
            anchorEl={anchorEl}
            isOpen={isHelpOpen}
          />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default Header
