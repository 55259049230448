import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { getCustomPackagingList } from "../../../core/app/slices/customPackaging/customPackagingThunkApi"
import { RootState } from "../../../core/app/store"
import { UText } from "../../../ui-component"
import { Box, Grid, Paper } from "../../../ui-component/mui.components"
import UTable from "../../Patient/PatientList/components/UTable/UTable"

import { MY_CUSTOM_PACKAGING_TABLE_CONFIG } from "./config"

export const MyCustomPackaging: FC = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { patients, loading, paging } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  useEffect(() => {
    console.log("dillan")
    dispatch(getCustomPackagingList())
  }, [])

  return (
    <Grid item container justifyContent={"center"}>
      <Paper
        elevation={0}
        sx={{ borderRadius: 4, width: "100%", minHeight: "100px" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          pb={2}
          ml={4}
          mr={4}
        >
          <UText variant={"h6"} sxProp={{ pt: 2, pb: 2 }}>
            {"My custom packaging"}
          </UText>
          <UTable
            columnData={[
              {
                accNo: 23,
                companyName: "Dr. Dumore & Team Orthodontics",
                createdOn: "Oct 28, 2022, 5:37 PM",
                packagingStatus: "Approved",
              },
              {
                accNo: 23,
                companyName: "Dr. Dumore & Team Orthodontics",
                createdOn: "Oct 28, 2022, 5:37 PM",
                packagingStatus: "Approved",
              },
            ]}
            isFetching="succeeded"
            columnDef={MY_CUSTOM_PACKAGING_TABLE_CONFIG}
            name={"My Packaging"}
            sxTableProps={{
              body: {
                "&.MuiTableRow-root:hover": {
                  backgroundColor: "#2196F30A",
                },
              },
            }}
            enableFilters={false}
            enableSearch={false}
            hidePagination
          ></UTable>
        </Box>
      </Paper>
    </Grid>
  )
}
