import { FC, useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useParams } from "react-router"
import { useLocation, useNavigate } from "react-router-dom"
import { Box, Checkbox, FormControlLabel, Grid } from "@mui/material"

import welcomeTitle from "../../../../assets/images/welcomeTitle.svg"
import { FormAutoComplete } from "../../../../components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { setAlert } from "../../../../core/app/slices/alert/alertSlice"
import {
  getCaseByPatient,
  updateCaseById,
} from "../../../../core/app/slices/case"
import {
  updateCaseStatus,
  updateCaseUnarchive,
} from "../../../../core/app/slices/case/caseThunkApi"
import { updatePatientData } from "../../../../core/app/slices/patients"
import { setAssignedTo } from "../../../../core/app/slices/patients/patientSlice"
import { updateUserPreferences } from "../../../../core/app/slices/user/userApis"
import { RootState } from "../../../../core/app/store"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"
import { UButton, UModalPopup, UText } from "../../../../ui-component"
import { getContactInfo } from "../../../contact.config"
import { CASE_STATUS } from "../../config/status.config"

const CaseActionPopup: FC<{
  isAlertOpen: string
  setIsAlertOpen: (type: "") => void
  rowPatientId?: string
  caseId?: string
}> = ({ isAlertOpen, setIsAlertOpen, rowPatientId, caseId }) => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const location = useLocation()
  const { patientId } = useParams()

  const { doctorList } = useAppSelector(
    (state: RootState) => state.doctorService,
  )
  const { caseList, loading, caseDetail } = useAppSelector(
    (state: RootState) => state.caseService,
  )
  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm()

  const { countryCode } = useAppSelector((state) => state.userService)
  const contactInfo = useMemo(() => getContactInfo(countryCode), [countryCode])

  const handleAssign = (data) => {
    if (data && data.doctor) {
      dispatch(setAssignedTo(data.doctor))
      dispatch(
        updateCaseById({
          patientId: patientId,
          caseId: caseList[0]?.id?.toString(),
          payload: {
            assigned_to: data?.doctor,
          },
        }),
      )
    }
  }

  const updatePatientArchiveStatus = (status: string) => {
    dispatch(
      updatePatientData({
        patientId: patientId || rowPatientId,
        payload: { status },
      }),
    ).then((res) => {
      if (res.payload.status === 200) {
        setIsAlertOpen("")
        dispatch(
          setAlert({
            message: t(
              `overview.${
                status === "ARCHIVED" ? "archivePatient" : "unarchivePatient"
              }.updatedSuccessfully`,
            ),
          }),
        )
        if (status === "ARCHIVED" && location.pathname.includes("patients"))
          navigate("/")
      } else {
        dispatch(
          setAlert({
            message: t(
              `overview.${
                status === "ARCHIVED" ? "archivePatient" : "unarchivePatient"
              }.updateFailure`,
            ),
            isError: true,
          }),
        )
      }
    })
  }

  useEffect(() => {
    if (loading === "succeeded") {
      patientId && dispatch(getCaseByPatient({ patientId }))
      setIsAlertOpen("")
    }
  }, [caseDetail])

  return (
    <>
      {isAlertOpen === "OPEN_ARCHIVE_MODEL" && (
        <UModalPopup
          isAlert={!!isAlertOpen}
          title={
            <UText
              variant={"h6"}
              sxProp={{
                width: "396px",
              }}
            >
              {t(`overview.archiveCase.title`)}
            </UText>
          }
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={
            <>
              <Box component={"div"}>
                <UText
                  variant={"body1"}
                  sxProp={{ display: "block", height: "32px" }}
                >
                  {t(`overview.archiveCase.subtitle`)}
                </UText>
              </Box>
            </>
          }
          btnList={[
            <UButton
              key={t("button.cancel")}
              variant="text"
              btnText={t("button.cancel")}
              onClickHandler={() => setIsAlertOpen("")}
              sxProp={{
                height: 36,
              }}
            ></UButton>,
            <UButton
              key={t("overview.popup.archiveBtn")}
              variant="contained"
              btnText={t("overview.archiveCase.archiveBtn")}
              sxProp={{
                width: 133,
                height: 36,
              }}
              onClickHandler={() => {
                dispatch(
                  updateCaseStatus({
                    patientId: patientId || rowPatientId,
                    caseId: caseId || caseList[0]?.id?.toString(),
                    payload: {
                      case_disposition: CASE_STATUS.ARCHIVED,
                    },
                  }),
                ).then((res) => {
                  if (res.payload.status === "Success") {
                    setIsAlertOpen("")
                    if (location.pathname.includes("overview")) {
                      dispatch(getCaseByPatient({ patientId }))
                    } else {
                      navigate("/")
                    }
                  }
                })
              }}
            ></UButton>,
          ]}
        ></UModalPopup>
      )}
      {isAlertOpen === "OPEN_ARCHIVE_PATIENT_MODEL" && (
        <UModalPopup
          isAlert={!!isAlertOpen}
          title={
            <UText
              variant={"h6"}
              sxProp={{
                width: "396px",
              }}
            >
              {t(`overview.archivePatient.title`)}
            </UText>
          }
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={
            <Box component={"div"}>
              <UText
                variant={"body1"}
                sxProp={{ display: "block", height: "32px" }}
              >
                {t(`overview.archivePatient.subtitle`)}
              </UText>
            </Box>
          }
          btnList={[
            <UButton
              key={t("button.cancel")}
              variant="text"
              btnText={t("button.cancel")}
              onClickHandler={() => setIsAlertOpen("")}
              sxProp={{
                height: 36,
              }}
            ></UButton>,
            <UButton
              key={t("overview.archivePatient.archiveBtn")}
              variant="contained"
              btnText={t("overview.archivePatient.archiveBtn")}
              sxProp={{
                width: 106,
                height: 36,
              }}
              onClickHandler={() => updatePatientArchiveStatus("ARCHIVED")}
            ></UButton>,
          ]}
        ></UModalPopup>
      )}
      {isAlertOpen === "OPEN_UNARCHIVE_PATIENT_MODEL" && (
        <UModalPopup
          isAlert={!!isAlertOpen}
          title={
            <UText
              variant={"h6"}
              sxProp={{
                width: "396px",
              }}
            >
              {t(`overview.unarchivePatient.title`)}
            </UText>
          }
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
              minHeight: "fit-content",
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={<></>}
          btnList={[
            <UButton
              key={t("button.cancel")}
              variant="text"
              btnText={t("button.cancel")}
              onClickHandler={() => setIsAlertOpen("")}
              sxProp={{
                height: 36,
              }}
            ></UButton>,
            <UButton
              key={t("overview.archivePatient.archiveBtn")}
              variant="contained"
              btnText={t("overview.unarchivePatient.unarchiveBtn")}
              sxProp={{
                width: 106,
                height: 36,
              }}
              onClickHandler={() => updatePatientArchiveStatus("ACTIVE")}
            ></UButton>,
          ]}
        ></UModalPopup>
      )}
      {isAlertOpen === "OPEN_UNARCHIVE_MODEL" && (
        <UModalPopup
          isAlert={!!isAlertOpen}
          title={
            <UText
              variant={"h6"}
              sxProp={{
                width: "396px",
              }}
            >
              {t(`overview.unarchiveCase.title`)}
            </UText>
          }
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
              minHeight: "fit-content",
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={<></>}
          btnList={[
            <UButton
              key={t("button.cancel")}
              variant="text"
              btnText={t("button.cancel")}
              onClickHandler={() => setIsAlertOpen("")}
              sxProp={{
                height: 36,
              }}
            ></UButton>,
            <UButton
              key={t("overview.archivePatient.archiveBtn")}
              variant="contained"
              btnText={t("overview.unarchiveCase.unarchiveBtn")}
              sxProp={{
                width: 152,
                height: 36,
              }}
              onClickHandler={() => {
                dispatch(
                  updateCaseUnarchive({
                    patientId: patientId || rowPatientId,
                    caseId: caseId || caseList[0]?.id?.toString(),
                  }),
                ).then((res) => {
                  if (res.payload.status === "Success") {
                    setIsAlertOpen("")
                    if (location.pathname.includes("overview")) {
                      dispatch(getCaseByPatient({ patientId }))
                    } else {
                      navigate("/")
                    }
                  }
                })
                setIsAlertOpen("")
              }}
            ></UButton>,
          ]}
        ></UModalPopup>
      )}
      {isAlertOpen === "OPEN_CANCEL_MODEL" && (
        <UModalPopup
          isAlert={!!isAlertOpen}
          title={
            <UText
              variant={"h6"}
              sxProp={{
                width: "396px",
              }}
            >
              {t(`overview.cancel.title`)}
            </UText>
          }
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={
            <>
              <Box component={"div"}>
                <UText
                  variant={"body1"}
                  sxProp={{ display: "block", height: "32px" }}
                >
                  <Trans
                    values={{
                      phone: contactInfo.phone,
                    }}
                  >
                    {t(`overview.cancel.subtitle`)}
                  </Trans>
                </UText>
              </Box>
            </>
          }
          btnList={[
            <UButton
              key={t("overview.cancel.ok")}
              variant="contained"
              btnText={t("overview.cancel.ok")}
              sxProp={{
                width: 53,
                height: 36,
              }}
              onClickHandler={() => setIsAlertOpen("")}
            ></UButton>,
          ]}
        ></UModalPopup>
      )}
      {isAlertOpen === "addNewCase" && (
        <UModalPopup
          isAlert={!!isAlertOpen}
          title={
            <UText
              variant={"h6"}
              sxProp={{
                width: "396px",
              }}
            >
              {t(`overview.addNewCase.title`)}
            </UText>
          }
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          content={
            <>
              <Box component={"div"}>
                <UText variant={"body1"} sxProp={{ display: "block" }}>
                  {t(`overview.addNewCase.subtitle`)}
                </UText>
              </Box>
            </>
          }
          btnList={[
            <UButton
              key={t("overview.addNewCase.ok")}
              variant="contained"
              btnText={t("overview.addNewCase.ok")}
              sxProp={{
                width: 53,
                height: 36,
              }}
              onClickHandler={() => setIsAlertOpen("")}
            ></UButton>,
          ]}
        ></UModalPopup>
      )}
      {isAlertOpen === "OPEN_ASSIGN_MODEL" && (
        <UModalPopup
          title={t("overview.assignUserPopup.title")}
          isAlert={true}
          content={
            <>
              <UText variant={"body1"}>
                {t("overview.assignUserPopup.subtitle")}
              </UText>
              <Grid container>
                <Grid item xs={12}>
                  <FormAutoComplete
                    formSxprops={{
                      marginY: 1,
                    }}
                    inputLabel={"formfields.alldoctors"}
                    fieldName={"doctor"}
                    rules={{
                      required: "formfieldErrors.assigntorequired",
                    }}
                    options={doctorList}
                    errors={errors}
                    control={control}
                  />
                </Grid>
              </Grid>
            </>
          }
          btnList={[
            <UButton
              key={"assignPopupBtn"}
              btnText={t("overview.assignUserPopup.assignBtn")}
              btnType={"submit"}
              onClickHandler={handleSubmit(handleAssign)}
              sxProp={{
                minHeight: "36px",
                height: "auto",
                letterSpacing: "0.4px",
                lineHeight: "24px",
              }}
              variant={"contained"}
            />,
          ]}
          sxModalProps={{
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "8px 24px",
            },
            "#btnListCntr": {
              padding: 1,
            },
          }}
        />
      )}
      {isAlertOpen === "OPEN_WELCOME_MODAL" && (
        <UModalPopup
          isAlert={true}
          title={<Box component={"img"} src={welcomeTitle} />}
          content={
            <>
              <UText variant={"body1"}>
                <Trans
                  components={{
                    newLine: <br />,
                  }}
                >
                  {"patientList.onBoardPopup.content"}
                </Trans>
              </UText>
            </>
          }
          btnList={[
            <UButton
              sxProp={{
                lineHeight: "24px",
                letterSpacing: "0.4px",
              }}
              key={"started"}
              btnText={t("patientList.onBoardPopup.getStartedBtn")}
              variant={"contained"}
              onClickHandler={() => {
                dispatch(
                  updateUserPreferences({
                    on_show_welcome_modal: true,
                  }),
                )
                setIsAlertOpen("")
              }}
            />,
          ]}
          sxModalProps={{
            ".MuiPaper-root": {
              padding: 2,
              maxWidth: "640px",
            },
            "#contentCntr": {
              display: "flex",
              flexDirection: "column",
              padding: "28px 24px",
              paddingBottom: "36px",
            },
            "#btnListCntr": {
              padding: "8px 16px 16px 8px",
            },
          }}
        />
      )}
      {isAlertOpen === "CASE_CANNOT_OPEN" && (
        <UModalPopup
          isAlert={true}
          title={
            <UText variant={"h6"}>{t("overview.caseCannotOpen.title")}</UText>
          }
          content={
            <UText variant={"body2"}>
              {t("overview.caseCannotOpen.subtitle")}
            </UText>
          }
          btnList={[
            <UButton
              key={"got-it"}
              sxProp={{
                lineHeight: "171.492%",
                letterSpacing: "0.4px",
                textTransform: "uppercase",
              }}
              onClickHandler={() => {
                setIsAlertOpen("")
              }}
              btnText={t("overview.caseCannotOpen.gotIt")}
              variant={"contained"}
            />,
          ]}
          sxModalProps={{
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "0 24px 20px 24px",
            },
            "#btnListCntr": {
              padding: "8px",
            },
            "& .MuiDialog-paper": {
              maxWidth: "444px",
              boxShadow: 24,
            },
          }}
        />
      )}
    </>
  )
}

export default CaseActionPopup
