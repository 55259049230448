import { FC, useEffect } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Grid } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { useNavigate, useParams } from "react-router-dom"
import { fetchPatientById } from "@/core/app/slices/patients/patientThunkApi"
import { RootState } from "@/core/app/store"
import { setCaseRAtype } from "@/UDTreat/udTreatSlice"
import alignerSvg from "../../../assets/svgs2/chooseType/aligner.svg"
import retainerSvg from "../../../assets/svgs2/chooseType/retainer.svg"
import smartRxSvg from "../../../assets/svgs2/chooseType/smartRx.svg"
import "./caseType.scss"
const CaseType: FC = () => {
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation("common")
  const {
    patientData: { firstName = "", lastName = "" },
    versionNum,
  } = useAppSelector((state: RootState) => {
    return state.patientService
  })
  useEffect(() => {
    // get patient name
    dispatch(fetchPatientById({ patientId }))
  }, [])
  const goTreat = (type: string) => {
    if (versionNum === 1.1) {
      dispatch(setCaseRAtype(type))
    }
    navigate(`/clinical/patient/${patientId}/case/${caseId}/treat`)
  }
  return (
    <>
      <Grid container item sx={{ mb: 3, mt: 5 }}>
        <Grid item sm={6} display={"flex"}>
          <div className={"nameBox"}>{`${firstName.substring(
            0,
            1,
          )}${lastName.substring(0, 1)}`}</div>
          <div className={"nameText"}>
            <p>{`${firstName.substring(0, 1)}.${lastName}`}</p>
            <p>{`#${caseId}`}</p>
          </div>
        </Grid>
        <Grid item sm={6} display={"flex"} justifyContent={"end"}>
          <Button
            sx={{
              padding: "4px 10px",
              borderRadius: "4px",
              background: "#E0E0E0",
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: "13px",
              fontWeight: "500",
              height: "30px",
            }}
          >
            {"close"}
          </Button>
        </Grid>
      </Grid>
      <Grid
        className={"questionTitle"}
        container
        item
        sx={{ mb: 5 }}
        justifyContent={"center"}
      >
        What type of case would you like to create?
      </Grid>
      {versionNum === 1.1 && (
        <Grid container item justifyContent={"center"}>
          <div
            className={"chooseBox"}
            onClick={() => {
              goTreat("alinger")
            }}
            style={{ marginRight: "24px" }}
          >
            <p className={"chooseTitle"}>{"Aligner"}</p>
            <img src={alignerSvg} />
            <span
              className={"des"}
            >{`Our AI will help setup and segment your case for easy treatment planning with great outcomes.`}</span>
          </div>
          <div
            className={"chooseBox"}
            onClick={() => {
              goTreat("retainer")
            }}
          >
            <p className={"chooseTitle"}>{"Retainer"}</p>
            <img src={retainerSvg} />
            <span
              className={"des"}
            >{`Quickly and easily order retainers or replacement retainers for your patient in a few simple steps.`}</span>
          </div>
        </Grid>
      )}
      {versionNum === 2 && (
        <Grid container item justifyContent={"center"}>
          <div
            className={"chooseBox"}
            onClick={() => {
              goTreat()
            }}
            style={{ marginRight: "24px" }}
          >
            <p className={"chooseTitle"}>{"AI Plan"}</p>
            <img src={alignerSvg} />
            <span
              className={"des"}
            >{`Our AI will help setup and segment your case for easy treatment planning with great outcomes.`}</span>
          </div>
          <div
            className={"chooseBox"}
            onClick={() => {
              goTreat()
            }}
            style={{ marginRight: "24px" }}
          >
            <p className={"chooseTitle"}>{"SmartRX"}</p>
            <img src={smartRxSvg} />
            <span
              className={"des"}
            >{`Quickly and easily order retainers or replacement retainers for your patient in a few simple steps.`}</span>
          </div>
          <div
            className={"chooseBox"}
            onClick={() => {
              goTreat()
            }}
          >
            <p className={"chooseTitle"}>{"Retainer"}</p>
            <img src={retainerSvg} />
            <span
              className={"des"}
            >{`Quickly and easily order retainers or replacement retainers for your patient in a few simple steps.`}</span>
          </div>
        </Grid>
      )}
    </>
  )
}
export default CaseType
