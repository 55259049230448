import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom"
import { LoginCallback } from "@okta/okta-react"

import LandingLayout from "../containers/LandingLayout/LandingLayout"
import MainLayout from "../containers/MainLayout/MainLayout"
import { useAppSelector } from "../core/app/hooks"
import { RootState } from "../core/app/store"
import { ErrorProvider } from "../Error.context"
import AuthModule from "../modules"
import ClinicalModule from "../modules/Clinical/Clinical.module"
import HelpModule from "../modules/Help/Help.module"
import OrderConfirmation from "../modules/Order/Confirmation/Confirmation"
import OrderModule from "../modules/Order/Order.module"
import ReviewOrder from "../modules/Order/ReviewOrder/ReviewOrder"
import OrganizationModule from "../modules/Organization/Organization.module"
import OverviewModule from "../modules/Patient/Overview.module"
import PatientModule from "../modules/Patient/Patient.module"
import Pricing from "../modules/Pricing/Pricing"
import RecordsModule from "../modules/Records/Records.module"
import Billing from "../modules/Settings/BillingHistory/Billing"
import { BillingModule } from "../modules/Settings/BillingHistory/BillingModule"
import CustomPackaging from "../modules/Settings/CustomPackaging/CustomPackaging"
import { CustomPackagingModule } from "../modules/Settings/CustomPackaging/CustomPackagingModule"
import SettingsModule from "../modules/Settings/Settings.module"
import UDesignApp from "../modules/Settings/uDesignApp/uDesign"
import Confirmation from "../modules/Uassist/Summary/Confirmation"
import UassistModule from "../modules/Uassist/Uassist.module"
import ActiveOrders from "../pages/ActiveOrders"
import Auth from "../pages/Auth"
import Clinical from "../pages/Clinical"
import ErrorPage from "../pages/ErrorPage"
import Help from "../pages/Help"
import Order from "../pages/Order"
import Organization from "../pages/Organization"
import PageNotFound from "../pages/PageNotFound"
import Patients from "../pages/Patient"
import Records from "../pages/Records"
import Settings from "../pages/Settings"
import Uassist from "../pages/Uassist"
import ULabAccount from "../pages/ULabAccount"
import { USmileUIcomponents } from "../ui-component"

import RouteByStatus from "./RouteByStatus"
import SecureRoute from "./SecureRoute"

const AppRoutes: FC = () => {
  const { user } = useAppSelector((state: RootState) => state.userService)

  const getPathName = (path: string) => {
    if (user?.isCustomerSupport) return "org/:orgId/" + path
    return path
  }

  return (
    <ErrorProvider>
      <Routes>
        {/* Patient routes */}
        <Route path={"/"} element={<MainLayout />}>
          <Route element={<SecureRoute />}>
            <Route path={"/error"} element={<ErrorPage />} />
            <Route element={<Organization />}>
              <Route path={"organization/*"} element={<OrganizationModule />} />
            </Route>
            <Route element={<Billing />}>
              <Route path={"billing/*"} element={<BillingModule />} />
              <Route path={"billing/pricing"} element={<Pricing />} />
            </Route>
            <Route element={<Patients />}>
              <Route
                path={getPathName(`patients/*`)}
                element={<PatientModule />}
              />
              <Route
                path={getPathName("overview/patient/:patientId/*")}
                element={<OverviewModule />}
              />
            </Route>
            <Route path={"orders"} element={<ActiveOrders />} />
            <Route element={<Settings />}>
              <Route path={"settings/*"} element={<SettingsModule />} />
            </Route>
            <Route element={<CustomPackaging />}>
              <Route
                path={"customPackaging/*"}
                element={<CustomPackagingModule />}
              />
            </Route>
            <Route element={<Help />}>
              <Route path={"help/*"} element={<HelpModule />} />
            </Route>
            <Route path={"uLabAccount"} element={<ULabAccount />} />
            <Route path={"UDesignApp"} element={<UDesignApp />} />
            <Route path={getPathName("/")} element={<RouteByStatus />}>
              <Route element={<Records />}>
                <Route
                  path={"records/patient/:patientId/case/:caseId/*"}
                  element={<RecordsModule />}
                />
              </Route>
              <Route element={<Clinical />}>
                <Route
                  path={"clinical/patient/:patientId/case/:caseId/*"}
                  element={<ClinicalModule />}
                />
              </Route>
              <Route element={<Order />}>
                <Route
                  path={"order/patient/:patientId/case/:caseId/*"}
                  element={<OrderModule />}
                />
                <Route
                  path={
                    "order/patient/:patientId/case/:caseId/order/:orderId/confirmation"
                  }
                  element={<OrderConfirmation />}
                />
                <Route
                  path={"order/patient/:patientId/case/:caseId/revieworder"}
                  element={<ReviewOrder />}
                />
              </Route>

              <Route element={<Uassist />}>
                <Route
                  path={"uassist/patient/:patientId/case/:caseId/*"}
                  element={<UassistModule />}
                />
                <Route
                  path={
                    "uassist/patient/:patientId/case/:caseId/summary/confirmation"
                  }
                  element={<Confirmation />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            path={"/"}
            element={
              <Navigate
                to={user?.isCustomerSupport ? "/organization" : "/patients"}
                replace
              />
            }
          />
        </Route>

        {/* login routes */}
        <Route element={<LandingLayout />}>
          <Route element={<Auth />}>
            <Route path={"auth/*"} element={<AuthModule />} />
          </Route>
          <Route path={"*"} element={<PageNotFound />} />
        </Route>
        <Route path={"login/callback"} element={<LoginCallback />} />
        <Route path={"/ex"} element={<USmileUIcomponents />} />
        <Route />
      </Routes>
    </ErrorProvider>
  )
}

export default AppRoutes
