import { createAsyncThunk } from "@reduxjs/toolkit"
import * as v2Apis from "./v2.service"
import {FS_FILEZIP} from "@/gluelayer"
import { caseManagement,resourcesSynchronization } from "@/gluelayer"

export interface UploadFileS3Options{
  caseId:string,
  zipFiles?:Record<string, File>,
  otherFiles?:Record<string, File>,
}

async function downloadZipFiles(caseId:string,files:v2Apis.ZipTypes[]){
  // get file url info of S3
  const response = await v2Apis.requestS3Url({
    caseId:caseId,
    urlType:'download',
    casePhoto:[],
    caseZip:files,
  });
  const result:{status:string,files:File[]} = {status:"",files:[]};
  console.log("Get S3 URL >>>",response)
  if(response.code === 0 && response.msg === 'success'){
    const respData = response.data;
    for (let index = 0; index < respData.caseZip.length; index++) {
      const res = respData.caseZip[index];
      const ret = await v2Apis.downloadS3Resource(res)
      if (ret.status === 200 && ret.data.constructor.name === 'Blob') {
        const file = new File([ret.data],res.destFilename);
        result.files.push(file as any);
      }
    }
    result.status ='Success';
    console.log("Download finished>>>",respData,result);
    return result;
  }
}



export const uploadFiles = createAsyncThunk(
  "utils/uploadFiles",
  async (params:UploadFileS3Options, { rejectWithValue, getState }) => {
    try {
      console.log("uploadFiles000")
      const response = await v2Apis.uploadFilesToS3(params.caseId,params.zipFiles,params.otherFiles)
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const testForLoading = createAsyncThunk(
  "utils/testForLoading",
  async (params,{ rejectWithValue, getState })=>{
    const testFunc = async ()=>{
      return new Promise((resolve)=>{
        setTimeout(()=>{
          resolve(true);
        },5000)
      })
    }
    try{
      const ret = await testFunc()
      return ret;
    }catch(err){
      return rejectWithValue(err)
    }
  }
)

export const requestCloudPresetup = createAsyncThunk(
  "utils/requestCloudPresetup",
  async (params:{caseId:string,upper:boolean,lower:boolean,orgId:string, patientId:string},{ rejectWithValue, getState })=>{
    const reqFunc = async ()=>{
      return caseManagement.requestCloudPresetup(params.upper, params.lower, true, params.orgId,params.patientId,Number(params.caseId))
    }
    try{
      const ret = await reqFunc()
      return ret;
    }catch(err){
      return rejectWithValue(err)
    }
  }
)


export const downloadFiles = createAsyncThunk(
  "utils/downloadFiles",
  async (params:{caseId:string,files:v2Apis.ZipTypes[]}, { rejectWithValue, getState }) => {

    try {
      const filesRet = await downloadZipFiles(params.caseId,params.files)
      
      // for testing
      //const files = filesRet.files;
      // for (let index = 0; index < files.length; index++) {
      //   const file = files[index];
      //   resourcesSynchronization.download(file.name,file)
      // }
      return filesRet
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)


export const getS3DownloadUrl = createAsyncThunk(
  "",
  async (params:{caseId:string,files:v2Apis.ZipTypes[]}, { rejectWithValue, getState })=>{
    try {
      const response = await v2Apis.requestS3Url({
        caseId:params.caseId,
        urlType:'download',
        casePhoto:[],
        caseZip:params.files,
      });
      return response.data.caseZip
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

