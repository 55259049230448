import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom"

import placeHolder from "../../../../assets/images/placeholder.png"
import { UButton } from "../../../../components"
import { Box, Grid } from "../../../../components/mui.components"
import PatientBasicInfo from "../../../../containers/LandingLayout/PatientInfoHeader/PatientBasicInfo"
import { useAppSelector } from "../../../../core/app/hooks"
import { useAppDispatch } from "../../../../core/app/hooks"
import { resetProfilePhoto } from "../../../../core/app/slices/records/xrays/xraysSlice"
import { downloadProfilePhoto } from "../../../../core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "../../../../core/app/store"
import { ICase } from "../../../../core/model/interface/ICASE"
import { IPatient } from "../../../../core/model/interface/IPatient"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"

const RefinementHeader: FC<{
  status: string
  patientData: IPatient
  caseDetail: ICase
}> = ({ patientData, caseDetail }) => {
  const { t } = useTranslation()
  const navigate = useMyNavigation()
  const { profile } = useAppSelector((state: RootState) => state.xrayService)
  const { routeFrom } = useAppSelector((state: RootState) => state.config)
  const dispatch = useAppDispatch()
  const { patientId } = useParams()
  const location = useLocation()

  useEffect(() => {
    dispatch(resetProfilePhoto())
    dispatch(
      downloadProfilePhoto({
        patientId: patientId,
        fileName: `${patientId}.png`,
      }),
    )
  }, [])

  const handleCloseRefinement = () => {
    if (routeFrom === "PATIENT_OVERVIEW_MODULE") {
      navigate(`/overview/patient/${patientId}/`)
    } else {
      navigate("/")
    }
  }

  return (
    <>
      <Grid
        container
        direction={"row"}
        spacing={0}
        justifyContent={"center"}
        sx={{ mb: 2 }}
      >
        <Grid item sm={6} sx={{ display: "flex", alignItems: "center" }}>
          <Box
            component={"img"}
            src={profile[0]?.src || placeHolder}
            alt={"Logo"}
            loading="lazy"
            sx={{ mr: 2, height: "96px", width: "96px" }}
          />
          <PatientBasicInfo patientData={patientData} caseDetail={caseDetail} />
        </Grid>
        <Grid
          item
          sm={6}
          sx={{ display: "flex", justifyContent: "end", pl: 3 }}
        >
          {!matchPath(
            "/overview/patient/:patientId/case/:caseId/refinement/confirmation",
            location.pathname,
          ) && (
            <UButton
              variant={"shade"}
              btnText={t("button.close")}
              sxProp={{ height: "30px", fontSize: "13px", p: "4px 10px" }}
              onClickHandler={() => handleCloseRefinement()}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

export default RefinementHeader
