export const navigateTo = (
  page: string,
  navigate: (url: string) => void,
  patientId: string | number,
  caseId: string | number,
) => {
  switch (page) {
    case "RECORDS":
      navigate(`/records/patient/${patientId}/case/${caseId}/scans`)
      break
    case "PRESCRIPTION":
      navigate(`/uassist/patient/${patientId}/case/${caseId}/ordertype`)
      break
    case "SUMMARY":
      navigate(`/uassist/patient/${patientId}/case/${caseId}/summary`)
      break
    case "RETAINER":
      navigate(`/overview/patient/${patientId}/case/${caseId}/form/retainer`)
      break
    case "TREAT":
      navigate(`/clinical/patient/${patientId}/case/${caseId}/treat`)
      break
    case "REFINEMENT":
      navigate(
        `/overview/patient/${patientId}/case/${caseId}/courserefinement/scans`,
      )
      break
    default:
      console.log("action", caseId)
  }
}

export const getAlignerRange = (alignerStageRange: string) => {
  if (!alignerStageRange) return ""
  const rangeArray = alignerStageRange.split(",")
  return rangeArray.length === 1
    ? rangeArray[0]
    : rangeArray[0] + "-" + rangeArray[rangeArray.length - 1]
}
