import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { Tooltip } from "@mui/material"

import { UButton, UText } from "../../../../../components"
import { Box, Grid, Link } from "../../../../../components/mui.components"
import { useAppDispatch } from "../../../../../core/app/hooks"
import { updateCurrentOrgId } from "../../../../../core/app/slices/user/userSlice"
import { dateFormat } from "../../../../../core/utils/formatters"
import { useMyNavigation } from "../../../../../hooks/useMyNavigation"
import { PACKAGING_STATUS } from "../../../../Settings/CustomPackaging/config"
import { CASE_STATUS, STATUS } from "../../../config/status.config"
import ProgressBar from "../../../Overview/components/ProgressBar"
import { getAlignerRange } from "../../../utils"

import AssignedUser from "./AssignedUser"
import StatusButton from "./StatusButton"

const CASE_TABLE_COLUMNS = [
  "casePlan",
  "alignersToOrder",
  "createDate",
  "caseStatus",
]

const BUNDLE_TABLE_COLUMNS = [
  "bundleType",
  "remainingBundleAllotment",
  "purchaseDate",
  "expiryDate",
]

const ORDER_TABLE_COLUMNS = [
  "treatmentPlan",
  "order",
  "orderType",
  "orderDate",
  "orderStatus",
  "orderDetails",
  "orderShippingStatus",
  "shipmentTrackingNo",
  "orderId",
]

const ORDER_TYPE = {
  MILD: "Mild Bundle",
  MODERATE: "Moderate Bundle",
  COMPREHENSIVE: "Comprehensive Bundle",
  FINISHING: "Finishing Bundle",
  ALACARTE: "A La Carte",
  "2 RETAINER": "2 RETAINER Bundle",
  "3 RETAINER": "3 RETAINER Bundle",
}

const SHOW_AS_LINK_STATUS = [
  CASE_STATUS.PLACE_ORDER,
  CASE_STATUS.ORDER_SUBMITTED,
]

const UTableCellRenderer: FC<{ dataKey: string; row: any }> = ({
  dataKey,
  row,
}) => {
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { patientId } = useParams()
  const { t } = useTranslation("common")
  const data = row[dataKey]

  const renderDate = () => {
    return (
      <UText
        sxProp={{
          display: "block",
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant={"body2"}
      >
        {dateFormat(data, "MM/DD/YYYY", "YYYY-MM-DDTh:mm:ss")}
      </UText>
    )
  }

  const firstName = () => {
    return row.firstName ? (
      <Tooltip title={row.firstName}>
        <UText variant={"body2"}>{row.firstName[0].toUpperCase()}</UText>
      </Tooltip>
    ) : (
      <></>
    )
  }

  const sortStageValue = (stageValue) => {
    return stageValue ? stageValue.split(",").sort((a, b) => a - b) : []
  }

  const renderOrderDetails = () => {
    let upper = ""
    let lower = ""

    if (
      row.upperStageRange ||
      row.upperRetainerStage ||
      row.upperTemplateStage
    ) {
      if (row.upperStageRange) {
        const arrayStage = sortStageValue(row.upperStageRange)
        if (arrayStage.length === 1) {
          upper += arrayStage[0]
        } else if (arrayStage.length > 1) {
          upper += arrayStage[0] + "-" + arrayStage[arrayStage.length - 1]
        }
        upper += "\xa0\xa0\xa0"
      }
      if (row.upperRetainerStage) {
        const arrayRetainer = sortStageValue(row.upperRetainerStage)
        if (arrayRetainer.length === 1) {
          upper += "R, "
        } else if (arrayRetainer.length > 1) {
          upper += "Rx" + arrayRetainer.length + ", "
        }
      }

      if (row.upperTemplateStage) {
        const arrayTemplate = sortStageValue(row.upperTemplateStage)
        if (arrayTemplate.length > 0) {
          upper += "T"
        }
      }
      upper = upper.replace(/,\s$/g, "")
    }

    if (
      row.lowerStageRange ||
      row.lowerRetainerStage ||
      row.lowerTemplateStage
    ) {
      if (row.lowerStageRange) {
        const arrayStage = sortStageValue(row.lowerStageRange)
        if (arrayStage.length === 1) {
          lower += arrayStage[0]
        } else if (arrayStage.length > 1) {
          lower += arrayStage[0] + "-" + arrayStage[arrayStage.length - 1]
        }
        lower += "\xa0\xa0\xa0"
      }
      if (row.lowerRetainerStage) {
        const arrayRetainer = sortStageValue(row.lowerRetainerStage)
        if (arrayRetainer.length === 1) {
          lower += "R, "
        } else if (arrayRetainer.length > 1) {
          lower += "Rx" + arrayRetainer.length + ", "
        }
      }
      if (row.lowerTemplateStage) {
        const arrayTemplate = sortStageValue(row.lowerTemplateStage)
        if (arrayTemplate.length > 0) {
          lower += "T"
        }
      }
      lower = lower.replace(/,\s$/g, "")
    }

    return (
      <>
        <Box>
          <span>U&nbsp;&nbsp;&nbsp;</span>
          <span>{upper}</span>
        </Box>
        <Box>
          <span>L&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span>{lower}</span>
        </Box>
      </>
    )
  }

  const renderOrderStatus = () => {
    return (
      <>
        {row.caseDisposition && (
          <Box sx={{ marginBottom: "3px" }}>
            <StatusButton {...row.order} caseStatus={row.caseDisposition} />
          </Box>
        )}
        {row.updateDate && (
          <UText variant="caption" sxProp={{ color: "text.secondary", ml: 1 }}>
            {dateFormat(row.updateDate, "MM/DD/YYYY", "YYYY-MM-DDTh:mm:ss")}
          </UText>
        )}
      </>
    )
  }

  const renderShippingTrackingNo = () => {
    return (
      <>
        {row.shipmentTrackingNo ? (
          <Link
            target={"_blank"}
            onClick={(event) => event.stopPropagation()}
            href={`https://www.fedex.com/fedextrack/?trknbr=${row.shipmentTrackingNo}`}
          >
            {row.shipmentTrackingNo}
          </Link>
        ) : (
          <>
            <UText
              variant="body2"
              sxProp={{ color: "text.primary", wordBreak: "break-word" }}
            >
              {row.shippingMethod}
            </UText>
            <UText
              variant="body2"
              component={"div"}
              sxProp={{ color: "text.primary", wordBreak: "break-word" }}
            >
              {t("overview.order.confirmation.estShippingDate")}
            </UText>
            <UText
              variant="body2"
              component={"div"}
              sxProp={{ color: "text.primary", wordBreak: "break-word" }}
            >
              {dateFormat(
                row.orderDate,
                "MM/DD/YYYY",
                "YYYY-MM-DDTh:mm:ss",
                row.shippingValue,
              )}
            </UText>
          </>
        )}
      </>
    )
  }

  const renderTxPlan = () => {
    return (
      <Box>
        <UText
          sxProp={{ wordBreak: "break-word" }}
          variant="body2"
          component={"div"}
        >
          {row.txplanName}
        </UText>
        {row.txplanSubname && (
          <UText
            variant="caption"
            sxProp={{ color: "text.secondary", wordBreak: "break-word" }}
          >
            {row.txplanSubname}
          </UText>
        )}
      </Box>
    )
  }

  const renderText = (value?: string, isWrappable?: boolean) => {
    return (
      <UText
        sxProp={{
          display: "block",
          width: "100%",
          whiteSpace: isWrappable ? "pre-wrap" : "nowrap",
          wordBreak: isWrappable ? "break-word" : "keep-all",
          overflow: isWrappable ? "" : "hidden",
          textOverflow: isWrappable ? "" : "ellipsis",
        }}
        variant="body2"
      >
        {value || data}
      </UText>
    )
  }

  const renderStatus = (
    status: string,
    date?: string,
    trackingNo?: string,
    showAsLink = false,
  ) => {
    return (
      <Box>
        <Box>
          <>
            {showAsLink ? (
              <Link
                sx={{
                  cursor: "pointer",
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  navigate(
                    `/clinical/patient/${patientId}/case/${row.id}/treat`,
                  )
                }}
              >
                {status}
              </Link>
            ) : (
              <UText sxProp={{ wordBreak: "break-word" }} variant="body2">
                {status}
              </UText>
            )}
            {trackingNo && (
              <>
                {" - "}
                <Link
                  target={"_blank"}
                  onClick={(event) => event.stopPropagation()}
                  href={`https://www.fedex.com/fedextrack/?trknbr=${trackingNo}`}
                >
                  {trackingNo}
                </Link>
              </>
            )}
          </>
        </Box>
        {date && (
          <UText variant="caption" sxProp={{ color: "text.secondary" }}>
            {dateFormat(date, "MM/DD/YYYY", "YYYY-MM-DDTh:mm:ss")}
          </UText>
        )}
      </Box>
    )
  }

  const renderProgressBar = (colorCode: string, bundle: any) => {
    return (
      <Grid container gap={1}>
        <ProgressBar
          colorCode={colorCode}
          total={bundle.total}
          completed={bundle.completed}
        />
      </Grid>
    )
  }

  const renderCaseTableData = (key: string) => {
    const alignerOrder = row.remainingAlignerOrder
    switch (key) {
      case "casePlan":
        return renderTxPlan()
      case "alignersToOrder": {
        const remainindAligners = [
          {
            title: "Upper",
            total: alignerOrder.upper.total_count || 0,
            completed: alignerOrder.upper.remaining_count || 0,
          },
          {
            title: "Lower",
            total: alignerOrder.lower.total_count || 0,
            completed: alignerOrder.lower.remaining_count || 0,
          },
        ]
        return (
          <>
            {remainindAligners.map((aligner, index: number) => (
              <Grid container direction={"column"} mb={1} key={index}>
                <UText variant="body2">{aligner.title}</UText>
                <UText
                  variant="caption"
                  sxProp={{ color: "text.secondary" }}
                >{`Aligners: ${aligner.completed} of ${aligner.total}`}</UText>
                {renderProgressBar("primary.light", aligner)}
              </Grid>
            ))}
          </>
        )
      }
      case "createDate":
        return renderDate()
      case "caseStatus": {
        const status = STATUS.find(
          (obj) => obj.key === row["caseDisposition"],
        ).displayText
        let date = row.updateDate
        if (row["caseDisposition"] === CASE_STATUS.SUBMITTED_TO_UASSIST)
          date = row.submittedDate
        if (row["caseDisposition"] === CASE_STATUS.ORDER_SUBMITTED)
          date = row.orderDate
        return renderStatus(
          status,
          date,
          "",
          SHOW_AS_LINK_STATUS.includes(row["caseDisposition"]),
        )
      }
      default:
        return renderText()
    }
  }

  const renderOrderTableData = (key: string) => {
    switch (key) {
      case "orderDate":
        return renderDate()
      case "treatmentPlan":
        return renderTxPlan()
      case "orderDetails":
        return renderOrderDetails()
      case "orderShippingStatus":
        return renderOrderStatus()
      case "shipmentTrackingNo":
        return renderShippingTrackingNo()
      case "order":
        return (
          <Grid container direction={"column"} gap={1}>
            {!!row.alignerCount && (
              <Grid item display={"flex"} flexDirection={"column"}>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.alignerCount} Aligners`}</UText>
                {row.upperStageRange && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`Upper ${getAlignerRange(row.upperStageRange)}`}</UText>
                )}
                {row.lowerStageRange && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`Lower ${getAlignerRange(row.lowerStageRange)}`}</UText>
                )}
                {row.alignerMaterial && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.alignerMaterial}`}</UText>
                )}
              </Grid>
            )}

            {!!row.templateCount && (
              <Grid item display={"flex"} flexDirection={"column"}>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.templateCount} Templates`}</UText>
                {row.upperTemplateStage && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.upperTemplateStage.split(",").length} Upper`}</UText>
                )}
                {row.lowerTemplateStage && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.lowerTemplateStage.split(",").length} Lower`}</UText>
                )}
              </Grid>
            )}

            {!!row.retainerCount && (
              <Grid item display={"flex"} flexDirection={"column"}>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.retainerCount} Retainers`}</UText>
                {row.upperRetainerCount && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.upperRetainerCount.split(",").length} Upper`}</UText>
                )}
                {row.lowerRetainerCount && (
                  <UText
                    variant="caption"
                    sxProp={{
                      color: "text.secondary",
                      wordBreak: "break-word",
                    }}
                  >{`${row.lowerRetainerCount.split(",").length} Lower`}</UText>
                )}
              </Grid>
            )}

            {row.isCustomPackaging && (
              <Grid item>
                <UText sxProp={{ wordBreak: "break-word" }} variant="body2">
                  Custom Packaging
                </UText>
              </Grid>
            )}

            {row.shippingMethod && (
              <Grid item>
                <UText
                  sxProp={{ wordBreak: "break-word" }}
                  variant="body2"
                >{`${row.shippingMethod} Shipping`}</UText>
              </Grid>
            )}
          </Grid>
        )
      case "orderStatus": {
        const date =
          row[key] === "Shipped" || row[key] === "Delivered"
            ? row.shippingDate
            : row.updateDate
        let status
        if (row[key]?.toUpperCase()?.replace(" ", "_") === "IN_PRODUCTION") {
          status = "In Production"
        } else {
          status = row[key]
        }
        return renderStatus(status, date, row.trackingNo)
      }
      case "orderType": {
        if (!row.orderType) {
          return renderText("A La Carte", true)
        } else if (
          row.orderType.toUpperCase().replaceAll(" ", "") === "ALACARTE"
        ) {
          return renderText(ORDER_TYPE.ALACARTE, true)
        } else {
          let orderType
          if (row.orderType.toUpperCase().replaceAll(" ", "") === "MILD") {
            orderType = ORDER_TYPE.MILD
          } else if (
            row.orderType.toUpperCase().replaceAll(" ", "") === "MODERATE"
          ) {
            orderType = ORDER_TYPE.MODERATE
          } else if (
            row.orderType.toUpperCase().replaceAll(" ", "") === "COMPREHENSIVE"
          ) {
            orderType = ORDER_TYPE.COMPREHENSIVE
          } else if (
            row.orderType.toUpperCase().replaceAll(" ", "") === "FINISHING"
          ) {
            orderType = ORDER_TYPE.FINISHING
          } else {
            orderType = row?.orderType + " Bundle"
          }
          return renderText(orderType, true)
        }
      }
      default:
        return renderText("", true)
    }
  }

  const renderBundleTableData = (key: string) => {
    switch (key) {
      case "bundleType":
        return (
          <Grid container direction={"column"}>
            <UText variant="body2">{row.name}</UText>
            <UText variant="caption">Includes up to:</UText>
            {!!row.aligner.included && (
              <UText variant="caption">{`${row.aligner.included} Aligners`}</UText>
            )}
            {!!row.template.included && (
              <UText variant="caption">{`${row.template.included} Templates`}</UText>
            )}
            {!!row.retainer.included && (
              <UText variant="caption">{`${row.retainer.included} Retainers`}</UText>
            )}
          </Grid>
        )
      case "remainingBundleAllotment": {
        const bundleAllotment = []
        if (row.aligner.included)
          bundleAllotment.push({
            type: "Aligners",
            total: row.aligner.included,
            completed: row.aligner.included - row.aligner.consumed,
          })
        if (row.template.included)
          bundleAllotment.push({
            type: "Templates",
            total: row.template.included,
            completed: row.template.included - row.template.consumed,
          })
        if (row.retainer.included)
          bundleAllotment.push({
            type: "Retainers",
            total: row.retainer.included,
            completed: row.retainer.included - row.retainer.consumed,
          })
        return (
          <>
            {bundleAllotment.map((bundle, index: number) => (
              <Grid container direction={"column"} mb={1} key={index}>
                <UText variant="body2">{`${bundle.type}: ${bundle.completed} of ${bundle.total}`}</UText>
                {renderProgressBar("secondary.main", bundle)}
              </Grid>
            ))}
          </>
        )
      }
      case "purchaseDate":
      case "expiryDate":
        return renderDate()
    }
  }

  const renderPackagingStatusData = (status: string) => {
    const color = PACKAGING_STATUS.find((obj) => obj.status === status).color
    return (
      <UButton
        btnText={status}
        variant="outlined"
        sxProp={{
          cursor: "default",
          color: color,
          borderColor: color!,
          ":hover": {
            background: "none",
            borderColor: "initial",
          },
        }}
        disableRipple
      />
    )
  }

  if (ORDER_TABLE_COLUMNS.includes(dataKey))
    return renderOrderTableData(dataKey)
  if (BUNDLE_TABLE_COLUMNS.includes(dataKey))
    return renderBundleTableData(dataKey)
  if (CASE_TABLE_COLUMNS.includes(dataKey)) return renderCaseTableData(dataKey)

  switch (dataKey) {
    case "case":
      return (
        <>
          {row &&
            row.status !== "ARCHIVED" &&
            row.caseDisposition !== "REJECTED" && (
              <Link
                sx={{
                  display: "block",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                onClick={(event) => {
                  event.stopPropagation()
                  navigate(
                    `/clinical/patient/${row.patientId}/case/${row.caseId}/treat`,
                  )
                }}
              >
                {row.caseId}
              </Link>
            )}
          {row &&
            (row.status === "ARCHIVED" || row.caseDisposition === "REJECTED") &&
            row.caseId}
        </>
      )
    case "status":
      return (
        <>
          {row.caseDisposition && (
            <StatusButton {...row.order} caseStatus={row.caseDisposition} />
          )}
        </>
      )
    case "firstName":
      return firstName()
    case "assignedTo":
      return row.user ? <AssignedUser user={row.user} /> : <></>
    case "updatedDate":
      return renderDate()
    case "packagingStatus":
      return renderPackagingStatusData(row.packagingStatus)
    case "patient_list":
      return (
        <Link
          sx={{
            display: "block",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer",
          }}
          onClick={(event) => {
            event.stopPropagation()
            dispatch(updateCurrentOrgId(row.org_id))
            navigate(`/patients`, {}, row.org_id)
          }}
        >
          {row.patient_list}
        </Link>
      )
    default:
      return renderText()
  }
}

export default UTableCellRenderer
