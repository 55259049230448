import { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { getQuotation } from "../../../core/app/slices/order/orderThunkApi"
import { RootState } from "../../../core/app/store"
import { UText } from "../../../ui-component"
import { Box, Container } from "../../../ui-component/mui.components"
import { getQuoteRequestObj } from "../../Patient/PatientList/util/commonUtil"
import { AddNewPackaging } from "../CustomPackaging/AddNewPackaging"

import { MyCustomPackaging } from "./MyCustomPackaging"

export const CustomPackagingModule: FC = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  useEffect(() => {
    if (caseDetail.udesign_json) {
      const requestObj = getQuoteRequestObj(caseDetail)
      dispatch(getQuotation(requestObj))
    }
  }, [])

  return (
    <Container
      component="main"
      maxWidth={"lg"}
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "16px 0",
        padding: "0 !important",
      }}
    >
      <Box component={"div"} display={"flex"} justifyContent={"center"}>
        <UText variant={"h4"} sxProp={{ pt: 2, pb: 2, mb: 5 }}>
          {t("customPackaging.title")}
        </UText>
      </Box>
      <Box component={"div"}>{<AddNewPackaging />}</Box>
      <Box mt={4}>{<MyCustomPackaging />}</Box>
    </Container>
  )
}
