import { FC } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment-timezone"

import { UText } from "../../../ui-component"
import { Box, Grid, Paper } from "../../../ui-component/mui.components"

export const OutstandingBalance: FC<{ outstandingBalance: number }> = ({
  outstandingBalance,
}) => {
  const { t } = useTranslation("common")

  const getPreviousMonth = (): string => {
    const previousMonth = moment().subtract(1, "months").format("MMMM YYYY")
    return previousMonth
  }

  return (
    <Grid item container justifyContent={"center"}>
      <Paper
        elevation={0}
        sx={{ borderRadius: 4, width: "100%", mx: 4, minHeight: "100px" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"start"}
          pb={2}
          ml={4}
        >
          <UText variant={"h6"} sxProp={{ pt: 2, pb: 2 }}>
            {t("billingHistory.outstandingBalance")}
          </UText>
          <UText variant={"body2"}>
            {t("billingHistory.amountdue") + " "}
            {getPreviousMonth()}
          </UText>
          {outstandingBalance ? (
            <UText variant={"body2"} sxProp={{ pb: 2, pt: 1, fontWeight: 600 }}>
              {"$" + outstandingBalance}
            </UText>
          ) : null}
        </Box>
      </Paper>
    </Grid>
  )
}
