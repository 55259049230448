import { FC } from "react"
import { Box, Typography } from "@mui/material"

import ImageAvatars from "./ImageAvatars"

export interface UserDetail {
  id: string
  firstName: string
  lastName: string
  preference: string
}

export interface IAssignedUserProps {
  user: UserDetail
}

const AssignedUser: FC<IAssignedUserProps> = ({ user }) => {
  const { firstName, lastName, preference } = user
  const name = firstName + " " + lastName
  return (
    name && (
      <Box
        data-testid={"assignedUser"}
        sx={{
          display: "flex",
          alignItems: "center",
          width: "inherit",
        }}
      >
        <ImageAvatars name={name} preference={preference} />
        <Typography
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingLeft: 1,
          }}
        >
          {name}
        </Typography>
      </Box>
    )
  )
}

export default AssignedUser
