import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { IAlignerOrder } from "../../../core/model/interface/AlignerOrder"
import { formatCurrency } from "../../../core/utils/formatters"
import { UButton, UText } from "../../../ui-component"
import { Box, Chip, Divider, Grid } from "../../../ui-component/mui.components"

const showSubTotal = (currentOrder: any) => {
  if (
    (currentOrder?.alacarte?.sub_total &&
      currentOrder?.bundle?.pricing_option) ||
    (!currentOrder?.alacarte?.sub_total &&
      currentOrder?.bundle?.pricing_option &&
      currentOrder?.alacarte?.template?.count)
  ) {
    return true
  } else {
    return false
  }
}

const showAlacarteCard = (currentOrder: any) => {
  if (
    currentOrder?.alacarte?.sub_total ||
    (!currentOrder?.alacarte?.sub_total &&
      currentOrder?.alacarte?.template?.count)
  ) {
    return true
  } else {
    return false
  }
}

const getBundleItem = (item: string, cost: string, type?: string) => {
  if (item === "Delivery service") {
    if (type === "ANZ Shipping") {
      type = "Standard Shipping"
    }
    type = type.replace("Shipping", "")
  }
  return (
    <Grid
      sx={{
        px: 2,
        py: 1,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid item xs={8}>
        <UText component={"div"} variant={"body1"} sxProp={{ pt: "4px" }}>
          {item}
        </UText>
        {type && (
          <UText
            component={"div"}
            sxProp={{
              color: "text.secondary",
              pb: "4px",
            }}
            variant={"caption"}
          >
            {type}
          </UText>
        )}
      </Grid>
      <UText
        component={"div"}
        variant={"subtitle2"}
        sxProp={{ textTransform: "capitalize" }}
      >
        {cost}
      </UText>
    </Grid>
  )
}

const renderAlacarteCard = (
  currentOrder: IAlignerOrder,
  t,
  countryCode: string,
) => {
  return (
    showAlacarteCard(currentOrder) && (
      <Box
        sx={{
          backgroundColor: "#ECEFF1",
          borderRadius: "8px",
          mb: 2,
          pb: 1,
        }}
      >
        <Box
          sx={{
            px: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UText
            component={"div"}
            sxProp={{
              fontWeight: "500",
              lineHeight: "48px",
              color: "text.secondary",
            }}
            variant={"subtitle2"}
          >
            {t("overview.order.confirmation.items")}
          </UText>
          <Chip
            sx={{
              ">.MuiChip-labelMedium": { px: "6px", py: "3px" },
              color: "#607D8B",
              fontWeight: "500",
              lineHeight: "157%",
              fontSize: "14px",
            }}
            label={`${currentOrder.alacarte?.pricing_option}`}
          />
        </Box>
        {!!(
          currentOrder.alacarte?.aligner?.cost &&
          currentOrder.alacarte?.aligner?.count
        ) &&
          getBundleItem(
            "Aligners",
            `$${currentOrder.alacarte?.aligner?.cost} x ${currentOrder.alacarte?.aligner?.count}`,
          )}
        {!!(
          currentOrder.alacarte?.alignercase?.cost &&
          currentOrder.alacarte?.alignercase?.count
        ) &&
          getBundleItem(
            "Aligner storage case",
            `$${currentOrder.alacarte?.alignercase?.cost} x ${currentOrder.alacarte?.alignercase?.count}`,
          )}
        {!!(
          currentOrder.alacarte?.retainer?.cost &&
          currentOrder.alacarte?.retainer?.count
        ) &&
          getBundleItem(
            "Retainers",
            `$${currentOrder.alacarte?.retainer?.cost} x ${currentOrder.alacarte?.retainer?.count}`,
          )}
        {!!currentOrder.alacarte?.template?.count &&
          getBundleItem(
            "Templates",
            `$${currentOrder.alacarte?.template?.cost} x ${currentOrder.alacarte?.template?.count}`,
          )}
        {!!(
          currentOrder.alacarte?.uassist_service?.cost &&
          currentOrder.alacarte?.uassist_service?.count
        ) &&
          getBundleItem(
            "uAssist™ service",
            `$${currentOrder.alacarte?.uassist_service?.cost} x ${currentOrder.alacarte?.uassist_service?.count}`,
          )}
        {currentOrder.alacarte?.shipping_price?.item_total_value &&
          getBundleItem(
            "Delivery service",
            isNaN(
              Number(currentOrder.alacarte?.shipping_price?.item_total_value),
            )
              ? currentOrder.alacarte?.shipping_price?.item_total_value
              : formatCurrency(
                  Number(
                    currentOrder.alacarte?.shipping_price?.item_total_value,
                  ),
                  currentOrder.currency,
                  countryCode,
                ),
            currentOrder.alacarte?.shipping_price?.type,
          )}
        {!currentOrder.alacarte?.custom_package?.count &&
          getBundleItem("Package type", "Included", "uLab Standard")}
        {!!(
          currentOrder.alacarte?.custom_package?.cost &&
          currentOrder.alacarte?.custom_package?.count
        ) &&
          getBundleItem(
            "Package type",
            `$${currentOrder.alacarte?.custom_package?.cost} x ${currentOrder.alacarte?.custom_package?.count}`,
            "Custom Logo",
          )}
        {!!currentOrder.alacarte?.tax_amt &&
          getBundleItem(
            "Estimated tax",
            (
              formatCurrency(
                currentOrder.alacarte?.tax_amt,
                currentOrder.currency,
                countryCode,
              ) ?? ""
            ).toString(),
          )}
        {showSubTotal(currentOrder) && (
          <Box
            sx={{
              p: 2,
              pt: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <UText component={"div"} variant={"h6"}>
                {"Subtotal"}
              </UText>
            </Box>
            <UText component={"div"} variant={"h6"}>
              {`${formatCurrency(
                currentOrder.alacarte?.sub_total,
                currentOrder.currency,
                countryCode,
              )} ${currentOrder.currency}`}
            </UText>
          </Box>
        )}
      </Box>
    )
  )
}

const renderBundleCard = (
  currentOrder: IAlignerOrder,
  t,
  countryCode: string,
) => {
  return (
    <Box
      sx={{
        backgroundColor: "#ECEFF1",
        borderRadius: "8px",
        mb: 2,
        pb: 1,
      }}
    >
      <Box
        sx={{
          px: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <UText
          component={"div"}
          sxProp={{
            fontWeight: "500",
            lineHeight: "48px",
            color: "text.secondary",
          }}
          variant={"subtitle2"}
        >
          {t("overview.order.confirmation.items")}
        </UText>
        <Chip
          sx={{
            ">.MuiChip-labelMedium": { px: "6px", py: "3px" },
            color: "#304FFE",
            fontWeight: "500",
            lineHeight: "157%",
            fontSize: "14px",
          }}
          label={` ${currentOrder.bundle?.pricing_option} Bundle`}
        />
      </Box>
      {!!currentOrder.bundle?.aligner?.requested &&
        getBundleItem(
          "Aligners",
          `x ${currentOrder.bundle?.aligner?.included}`,
          `Remaining bundle allotment ${
            currentOrder.bundle.pricing_option !== "Comprehensive"
              ? currentOrder.bundle?.aligner?.remaining
              : "Unlimited"
          }`,
        )}
      {!!currentOrder.bundle?.retainer?.requested &&
        getBundleItem(
          "Retainers",
          `x ${currentOrder.bundle?.retainer?.included}`,
          `Remaining bundle allotment ${currentOrder.bundle?.retainer?.remaining}`,
        )}
      {!!currentOrder.bundle?.alignercase?.count &&
        getBundleItem(
          "Aligner storage case",
          `$${currentOrder.bundle?.alignercase?.cost} x ${currentOrder.bundle?.alignercase?.count}`,
        )}
      {!!currentOrder.bundle?.previous_uassist_credit &&
        getBundleItem(
          "Previous uAssist™ credit",
          (
            formatCurrency(
              currentOrder.bundle?.previous_uassist_credit,
              currentOrder.currency,
              countryCode,
            ) ?? ""
          ).toString(),
          ["2 Retainers", "4 Retainers"].includes(
            currentOrder.bundle.pricing_option,
          )
            ? "Retainer service"
            : "Initial",
        )}
      {getBundleItem(
        "Delivery service",
        !isNaN(currentOrder.bundle?.shipping_price?.item_total_value)
          ? formatCurrency(
              parseInt(currentOrder.bundle?.shipping_price?.item_total_value),
              currentOrder.currency,
              countryCode,
            )
          : currentOrder.bundle?.shipping_price?.item_total_value,
        currentOrder.bundle?.shipping_price?.type,
      )}
      {getBundleItem(
        "Package type",
        "Included",
        currentOrder.bundle?.custom_packaging ? "Custom Logo" : "uLab Standard",
      )}
      {getBundleItem(
        "Estimated tax",
        formatCurrency(
          currentOrder.bundle?.tax_amt,
          currentOrder.currency,
          countryCode,
        ),
      )}
      {showSubTotal(currentOrder) && (
        <Box
          sx={{
            px: 2,
            py: "12px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <UText component={"div"} variant={"h6"}>
              {"Subtotal"}
            </UText>
          </Box>
          <UText component={"div"} variant={"h6"}>
            {`${formatCurrency(
              currentOrder.bundle?.sub_total,
              currentOrder.currency,
              countryCode,
            )} ${currentOrder.currency}`}
          </UText>
        </Box>
      )}
    </Box>
  )
}

const OrderSummary: FC<{
  isreview?: boolean
  setShowPlaceOrderBtn?: (val: boolean) => void
}> = ({ isreview, setShowPlaceOrderBtn }) => {
  const { t } = useTranslation()
  const [consent, setConsent] = useState<{
    consent1: boolean
    consent2: boolean
  }>({ consent1: false, consent2: false })
  const { currentOrder } = useAppSelector(
    (state: RootState) => state.orderService,
  )
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )

  useEffect(() => {
    if (!isreview) return
    if (
      (consent.consent1 && consent.consent2) ||
      (currentOrder.alacarte?.pricing_option &&
        !currentOrder.bundle?.pricing_option) ||
      (!currentOrder.alacarte?.pricing_option &&
        currentOrder.bundle?.pricing_option)
    ) {
      setShowPlaceOrderBtn(false)
    } else {
      setShowPlaceOrderBtn(true)
    }
  }, [consent, currentOrder])

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <UText variant={"subtitle2"}>
          {t("overview.order.confirmation.summary")}
        </UText>
      </Box>
      {currentOrder?.bundle?.pricing_option &&
        renderBundleCard(currentOrder, t, countryCode)}
      {isreview && showSubTotal(currentOrder) && (
        <UButton
          variant={"contained"}
          btnText={consent.consent1 ? "Confirmed" : "Confirm"}
          size="large"
          sxProp={{
            width: "100%",
            mb: 2,
            backgroundColor: consent.consent1 ? "success.main" : "",
            ":hover": {
              backgroundColor: consent.consent1 ? "success.main" : "",
            },
          }}
          onClickHandler={() => {
            setConsent((prevConsent) => ({
              ...prevConsent,
              consent1: !prevConsent.consent1,
            }))
          }}
        />
      )}
      {showAlacarteCard(currentOrder) &&
        renderAlacarteCard(currentOrder, t, countryCode)}
      <>
        {isreview && showSubTotal(currentOrder) && (
          <UButton
            variant={"contained"}
            btnText={consent.consent2 ? "Confirmed" : "Confirm"}
            size="large"
            sxProp={{
              width: "100%",
              mb: 2,
              backgroundColor: consent.consent2 ? "success.main" : "",
              ":hover": {
                backgroundColor: consent.consent2 ? "success.main" : "",
              },
            }}
            onClickHandler={() => {
              setConsent((prevConsent) => ({
                ...prevConsent,
                consent2: !prevConsent.consent2,
              }))
            }}
          />
        )}
        <Divider />
      </>
      {currentOrder &&
        (currentOrder.alacarte || currentOrder.bundle?.pricing_option) && (
          <Box
            sx={{
              px: 2,
              py: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <UText component={"div"} variant={"h5"}>
              {t("overview.order.confirmation.total")}
            </UText>
            <UText component={"div"} variant={"h5"}>
              {currentOrder && countryCode
                ? `${formatCurrency(
                    currentOrder.total,
                    currentOrder.currency,
                    countryCode || "us",
                  )} ${currentOrder.currency}`
                : ""}
            </UText>
          </Box>
        )}
    </>
  )
}

export default OrderSummary
