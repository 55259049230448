import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { caseManagement, preview } from "@/gluelayer"

import {
  UButton,
  UFileBox,
  UFileProcessingCard,
  ULoading,
  UModalPopup,
  UText,
} from "../../../components"
import NavigationBlockPopup from "../../../components/NavigationBlockPopup/NavigationBlockPopup"
import Preview from "../../../components/Preview/preview"
import Refinement from "../../../components/RefinementCanvas/refinement"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { updateCaseById } from "../../../core/app/slices/case/caseThunkApi"
import {
  clinicalRequestBasicType,
  getZipsType,
  UploadZips,
} from "../../../core/app/slices/clinical/clinical.types"
import {
  FILEZIPS,
  resetAction,
  setClinicalData,
} from "../../../core/app/slices/clinical/clinicalSlice"
import {
  fetchFileList,
  fetchFileZips,
  uploadCaseZips,
} from "../../../core/app/slices/clinical/clinicalThunkApi"
import { setIsRouteChangeBlocked } from "../../../core/app/slices/navigationPopup/navigationPopupSlice"
import { updateUserPreferences } from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { checkIfObjectHasKeys } from "../../../core/utils/formatters"
import { useCallbackPrompt } from "../../../hooks/useCallbackPrompt"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import { ROUTE_ATTENTION_BANNER } from "../../../routes/status.route.config"
import { Box, Checkbox, Grid } from "../../../ui-component/mui.components"
import { CASE_STATUS } from "../../Patient/config/status.config"

import {
  file,
  fileBox,
  fileBoxContent,
  previewBox,
  stltitle,
  title,
  zone,
  zoneBox,
} from "./Scans.style"

/**
 * a component for upload stl & show the teeth model
 * @param downLoadZips: 下载stl文件
 * @param uploadScans: 上传stl文件
 * @param resetStoreData: 重置store数据
 * @param isClickNext: click the next button
 * @returns scans compoent
 */

interface scansType {
  downLoadZips: (payload: object) => any
  resetStoreData: () => void
  setScanErrors: any
  flagClickNext: boolean
  zipList: FileList
  uploadZips: (payload: UploadZips) => any
  current_orgId: string
  steps?: { id: string; lable: string; to: string }[]
  zipNames: []
  getFileName: () => void
  handleSkip: () => void
  handleNext?: () => void
  isRefinement: boolean
  handleLowerStl?: any
  handleUpperStl?: any
  handleShowStl?: any
  caseType: "new_scan" | "hold_scan" | "hold_refinement"
}

const Scans: FC = ({
  downLoadZips,
  resetStoreData,
  setScanErrors,
  flagClickNext,
  zipList,
  uploadZips,
  current_orgId,
  steps,
  zipNames,
  getFileName,
  isRefinement,
  handleLowerStl,
  handleUpperStl,
  handleShowStl,
  handleSkip,
  handleNext,
  caseType = "new_scan",
}: scansType) => {
  const { t } = useTranslation("common")

  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const [holePopup, setholePopup] = useState(false)
  const [nextPopup, setnextPopup] = useState(false)
  const [showStl, setShowStl] = useState({ isupper: false, islower: false })
  const [scansDownloaded, setScansDownloaded] = useState<boolean>(false)
  const navigate = useMyNavigation()
  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)

  const { caseId, patientId } = useParams()
  const [isShowBothScansPopup, setIsShowBothScansPopup] =
    useState<boolean>(true)
  const { preferences } = useAppSelector((state) => state.userService.user)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const dispatch = useAppDispatch()
  const location = useLocation()
  useEffect(() => {
    getFileName({
      patientId,
      caseId,
      caseType,
    })

    return () => {
      resetStoreData()
      setholePopup(false)
      setnextPopup(false)
    }
  }, [])

  useEffect(() => {
    if (zipNames && zipNames.length) {
      dispatch(setClinicalData({ isLoading: "pending" }))
      downLoadZips({
        patientId,
        caseId,
        zipNames,
      })
    }
  }, [zipNames])

  const gotoNextStep = () => {
    dispatch(setClinicalData({ isLoading: "pending" }))
    /**
     * 1. new case: upload all zips
     * 2. on hold new scan: upload raw
     * 3. on hold refinement: upload bulk10
     */
    const zip =
      caseType === "new_scan"
        ? [...FILEZIPS]
        : caseType === "hold_scan"
        ? ["raw"]
        : ["bulk2", "bulk10"]
    caseManagement.getCaseFiles(zip).then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        if (caseDetail) {
          const udesign_json = JSON.parse(caseDetail.udesign_json)
          const newUdesignJsonObj = {
            ...udesign_json,
            upperUploaded: upperStl.data ? true : false,
            lowerUploaded: lowerStl.data ? true : false,
          }
          dispatch(
            updateCaseById({
              patientId,
              caseId,
              payload: {
                case_extra_attrs: JSON.stringify(newUdesignJsonObj),
              },
            }),
          )
        }
        uploadZips({
          zips,
          patientId,
          caseId,
          orgId: current_orgId,
          callback: () => {
            if (
              !location?.pathname.includes(
                ROUTE_ATTENTION_BANNER.attentionBanner,
              )
            ) {
              navigate(steps[1].to)
            } else {
              handleNext && handleNext()
            }
          },
        })
      }
    })
  }

  const checkLowerFileUpload = (file) => {
    handleLowerStl && handleLowerStl(file.action)
  }

  const checkUpperFileUpload = (file) => {
    handleUpperStl && handleUpperStl(file.action)
  }

  useEffect(() => {
    if (typeof flagClickNext === "undefined") return
    if (
      (isRefinement && showStl.isupper && upperStl.data && !showStl.islower) ||
      (isRefinement && showStl.islower && lowerStl.data && !showStl.isupper)
    ) {
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      gotoNextStep()
    }

    if (
      upperStl.action === "download" &&
      lowerStl.action === "download" &&
      !location.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner)
    ) {
      setScanErrors(false)
      dispatch(setClinicalData({ isLoading: "idle" }))
      navigate(steps[1].to)
      return
    }
    if (upperStl.data && lowerStl.data) {
      setScanErrors(false)
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      gotoNextStep()
    } else if (
      (upperStl.data || lowerStl.data) &&
      !location.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner)
    ) {
      setScanErrors(false)
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      if (preferences?.on_show_both_scan_upload_warning) {
        gotoNextStep()
      } else {
        dispatch(setClinicalData({ isLoading: "idle" }))
        setnextPopup(true)
      }
    } else if (
      (location.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner) &&
        showStl.isupper &&
        upperStl.data) ||
      (location.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner) &&
        showStl.islower &&
        lowerStl.data)
    ) {
      preview.saveStlToMtc(upperStl.data, lowerStl.data)
      setScanErrors(false)
      gotoNextStep()
    } else {
      setScanErrors(!scansDownloaded)
      !scansDownloaded && handleSkip && handleSkip()
      if (!location?.pathname.includes(ROUTE_ATTENTION_BANNER.attentionBanner))
        navigate(steps[1].to)
    }
  }, [flagClickNext])

  useEffect(() => {
    if (upperStl.action === "stl" || lowerStl.action === "stl") {
      dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: true }))
    } else {
      dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    }
  }, [upperStl, lowerStl])

  useEffect(() => {
    if (caseDetail) {
      const udesignCategory = caseDetail?.udesign_category
      if (udesignCategory) {
        const arches = udesignCategory.split("/")
        setShowStl({
          isupper: arches[0].includes("R") || arches[0].includes("A"),
          islower: arches[1].includes("R") || arches[1].includes("A"),
        })
      }
    }
  }, [caseDetail])

  useEffect(() => {
    handleLowerStl && handleShowStl(showStl)
  }, [showStl])

  useEffect(() => {
    if (!checkIfObjectHasKeys(zipList)) return

    preview.drawMtcFromZips(zipList, [0.25, 2.0]).then((res) => {
      if (res.get("arch_o_u.mtc") || res.get("arch_o_l.mtc")) {
        setScansDownloaded(true)
      }
    })
  }, [zipList])

  return (
    <>
      <Grid>
        <UModalPopup
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
              height: 192,
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          title={
            <UText variant={"h6"}>{t("records.scans.holePopupTile")}</UText>
          }
          content={
            <Box>
              <span>{t("records.scans.holePopup1")}</span>
              <br />
              <br />
              <span>{t("records.scans.holePopup2")}</span>
            </Box>
          }
          isAlert={holePopup}
          btnList={[
            <UButton
              variant={"contained"}
              btnText={t("button.ok")}
              sxProp={{ width: 53, height: 36 }}
              key={"hole"}
              onClickHandler={() => setholePopup(false)}
            />,
          ]}
        />
        <UModalPopup
          sxModalProps={{
            ".MuiDialog-paper": {
              maxWidth: 444,
            },
            "#titleCntr": {
              padding: "16px 24px",
            },
            "#contentCntr": {
              padding: "20px 24px",
              // height: 182,
            },
            "#btnListCntr": {
              padding: 1,
              gap: 1,
            },
          }}
          title={
            <UText variant={"h6"}>{t("records.scans.nextPopupTile")}</UText>
          }
          content={
            <>
              <Grid container flexDirection={"column"}>
                <UText variant={"body1"}>
                  {t("records.scans.nextPopup1")}
                  <br />
                  <br />
                  {t("records.scans.nextPopup2")}
                </UText>
              </Grid>
              {/* <Grid sx={{ height: 38 }}>
              <Checkbox
                onChange={({ target: { checked } }) => {
                  if (checked) {
                    dispatch(
                      updateUserPreferences({
                        on_show_both_scan_upload_warning: true,
                      }),
                    )
                  } else {
                    dispatch(
                      updateUserPreferences({
                        on_show_both_scan_upload_warning: false,
                      }),
                    )
                  }
                }}
              />
              <UText variant={"body1"}>{t("popup.showAgain")}</UText>
            </Grid> */}
            </>
          }
          isAlert={
            (isShowBothScansPopup && nextPopup && !isRefinement) ||
            (isShowBothScansPopup &&
              nextPopup &&
              isRefinement &&
              showStl.islower &&
              showStl.isupper)
          }
          btnList={[
            <UButton
              variant={"contained"}
              btnText={"YES"}
              key={"yes"}
              sxProp={{ minWidth: 58, height: 36 }}
              onClickHandler={gotoNextStep}
            />,
            <UButton
              variant={"shade"}
              btnText={"NO"}
              key={"no"}
              onClickHandler={() => {
                setnextPopup(false)
              }}
              sxProp={{ minWidth: 54, height: 36 }}
            />,
          ]}
        />
        <UText sxProp={title} variant={"h5"}>
          {t("records.scans.title")}
        </UText>
        {isRefinement && (
          <UText
            variant="caption"
            sxProp={{
              display: "flex",
              justifyContent: "center",
              color: "#616161",
            }}
          >
            {t("records.refinement.description")}
          </UText>
        )}
        <Grid container sx={previewBox}>
          <Box style={fileBox}>
            {((isRefinement && showStl.isupper) ||
              (location.pathname.includes(
                ROUTE_ATTENTION_BANNER.attentionBanner,
              ) &&
                showStl.isupper) ||
              (!isRefinement &&
                !location.pathname.includes(
                  ROUTE_ATTENTION_BANNER.attentionBanner,
                ))) && (
              <Grid sx={{ ...zone }}>
                <UText
                  sxProp={{
                    ...stltitle,
                  }}
                  variant={"h6"}
                >
                  {"Upper"}
                </UText>
                {upperStl.action === "stl" || upperStl.action === "download" ? (
                  <UFileProcessingCard
                    fileName={
                      upperStl.action === "stl"
                        ? upperStl.data?.name
                        : "Uploaded Scan"
                    }
                    fileSize={upperStl.data?.size}
                    progressValue={100}
                    progressstatus={"complete"}
                    onRemove={() => {
                      setUpperStl({ data: null, action: "delete" })
                      checkUpperFileUpload({ data: null, action: "delete" })
                    }}
                    removable={
                      caseDetail &&
                      caseDetail.caseDisposition !== CASE_STATUS.FROM_UDESIGN
                    }
                  ></UFileProcessingCard>
                ) : (
                  <UFileBox
                    id={"Upper"}
                    allowedFileExtensions={["stl"]}
                    allowedfileSize={51200}
                    selectedFile={(file) => {
                      setScanErrors(false)

                      setUpperStl({ data: file[0], action: "stl" })
                      checkUpperFileUpload({ data: file[0], action: "stl" })
                    }}
                    fileAcceptType={".stl"}
                    boxSize={file}
                    fileBoxContent={fileBoxContent}
                    isRequired={false}
                    messages={{
                      fileNote: t("records.scans.fileNote"),
                      uploadButton: t("records.scans.uploadButton"),
                      uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                      invalidfileFormat: t("records.scans.invalidfileFormat"),
                      invalidFileSize: t("records.scans.invalidSTLFileSize"),
                    }}
                    fileLimit={1}
                  ></UFileBox>
                )}
              </Grid>
            )}
            {((isRefinement && showStl.islower) ||
              (location.pathname.includes(
                ROUTE_ATTENTION_BANNER.attentionBanner,
              ) &&
                showStl.islower) ||
              (!isRefinement &&
                !location.pathname.includes(
                  ROUTE_ATTENTION_BANNER.attentionBanner,
                ))) && (
              <Grid sx={{ ...zone }}>
                <UText sxProp={{ ...stltitle }} variant={"h6"}>
                  {"Lower"}
                </UText>
                {lowerStl.action === "stl" || lowerStl.action === "download" ? (
                  <UFileProcessingCard
                    fileName={
                      lowerStl.action === "stl"
                        ? lowerStl.data?.name
                        : "Uploaded Scan"
                    }
                    fileSize={lowerStl.data?.size}
                    progressValue={100}
                    progressstatus={"complete"}
                    onRemove={() => {
                      setLowerStl({ data: null, action: "delete" })
                      checkLowerFileUpload({ data: null, action: "delete" })
                    }}
                    removable={
                      caseDetail &&
                      caseDetail.caseDisposition !== CASE_STATUS.FROM_UDESIGN
                    }
                  ></UFileProcessingCard>
                ) : (
                  <UFileBox
                    id={"Lower"}
                    allowedFileExtensions={["stl"]}
                    allowedfileSize={51200}
                    selectedFile={(file) => {
                      setScanErrors(false)
                      setLowerStl({ data: file[0], action: "stl" })
                      checkLowerFileUpload({ data: file[0], action: "stl" })
                    }}
                    fileAcceptType={".stl"}
                    boxSize={file}
                    fileBoxContent={fileBoxContent}
                    isRequired={false}
                    messages={{
                      fileNote: t("records.scans.fileNote"),
                      uploadButton: t("records.scans.uploadButton"),
                      uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                      invalidfileFormat: t("records.scans.invalidfileFormat"),
                      invalidFileSize: t("records.scans.invalidSTLFileSize"),
                    }}
                    fileLimit={1}
                  ></UFileBox>
                )}
              </Grid>
            )}
          </Box>
          <Box style={zoneBox}>
            {isRefinement ? (
              <Refinement
                upperStl={upperStl}
                lowerStl={lowerStl}
                placeHolder={t("records.scans.previewPlaceHolder")}
                zips={zipList}
                setUpperStl={setUpperStl}
                setLowerStl={setLowerStl}
              ></Refinement>
            ) : (
              <Preview
                upperStl={upperStl}
                lowerStl={lowerStl}
                fillholeCallback={(isSuccess: boolean) => {
                  if (!isSuccess) {
                    setholePopup(true)
                  }
                }}
                placeHolder={t("records.scans.previewPlaceHolder")}
                zips={zipList}
                setUpperStl={setUpperStl}
                setLowerStl={setLowerStl}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default connect(
  ({ clinicalService, userService }) => {
    const { zipList, zipNames } = clinicalService
    const {
      user: { current_orgId },
    } = userService
    return { zipList, current_orgId, zipNames }
  },
  (dispatch) => {
    return {
      getFileName: ({
        patientId,
        caseId,
        caseType,
      }: clinicalRequestBasicType) => {
        const zip =
          caseType === "new_scan"
            ? [...FILEZIPS]
            : caseType === "hold_refinement"
            ? ["bulk2", "bulk10"]
            : ["raw"]
        dispatch(
          fetchFileList({
            patientId,
            caseId,
            fileNames: zip,
          }),
        )
      },
      downLoadZips: ({ orgId, patientId, caseId, zipNames }: getZipsType) => {
        return dispatch(
          fetchFileZips({
            orgId,
            patientId,
            caseId,
            zipNames,
          }),
        )
      },
      uploadZips: (payload: UploadZips) => {
        return dispatch(uploadCaseZips({ ...payload }))
      },
      resetStoreData: () => {
        dispatch(resetAction())
      },
    }
  },
)(Scans)
