import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, Chip, Grid } from "@mui/material"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { getBundleByCaseId } from "../../../../core/app/slices/bundle/bundleThunkApi"
import { getOrdersByCaseId } from "../../../../core/app/slices/order/orderThunkApi"
import { addNewPatient } from "../../../../core/app/slices/patients/patientThunkApi"
import { RootState } from "../../../../core/app/store"
import { IBundle } from "../../../../core/model/interface/IBundle"
import { ICase } from "../../../../core/model/interface/ICASE"
import { dateFormat } from "../../../../core/utils/formatters"
import { useMyNavigation } from "../../../../hooks/useMyNavigation"
import { UButton, UText } from "../../../../ui-component"
import {
  getBtnActionsByStatus,
  RECORDS_STATUS,
  TABLE_VIEW_STATUS,
} from "../../config/menuAction.config"
import { CASE_STATUS } from "../../config/status.config"
import {
  BUNDLE_OVERVIEW_TABLE,
  CASE_OVERVIEW_TABLE,
  ORDER_OVERVIEW_TABLE,
} from "../caseTable.config"

import OverviewTableRenderer from "./OverviewTableRenderer"
import RejectedOverview from "./RejectedOverview"

const CaseSectionHeader: FC<{
  index: number
  caseObj: ICase
  patientId: string
  setIsAlertOpen: (popupType: "addNewCase") => void
  showOverview: boolean
  setShowOverview: (show: boolean) => void
}> = ({
  index,
  caseObj,
  patientId,
  setIsAlertOpen,
  showOverview,
  setShowOverview,
}) => {
  const { t } = useTranslation()
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { caseList } = useAppSelector((state: RootState) => state.caseService)

  const checkforActiveCase = () => {
    const payload = {
      firstname: patientData.firstName,
      lastname: patientData.lastName,
      dob: patientData.dob,
      patient_id: patientData.id,
      assigned_to: caseList[0]?.assigned_to || null,
    }
    dispatch(addNewPatient(payload)).then((res) => {
      if (res.payload?.data?.status === "Success") {
        const case_id = res.payload.data.result?.case?.id
        navigate(`/records/patient/${patientId}/case/${case_id}/scans`)
      } else {
        setIsAlertOpen("addNewCase")
      }
    })
  }

  return (
    <>
      <Grid
        item
        sx={{
          mb: CASE_STATUS.REJECTED.includes(caseObj?.caseDisposition) ? 3 : 0,
        }}
      >
        <Grid container sx={{ mt: 1, mb: 1, justifyContent: "space-between" }}>
          <Grid item display={"flex"} sx={{ gap: 2, alignItems: "center" }}>
            {!CASE_STATUS.REJECTED.includes(caseObj?.caseDisposition) && (
              <UText variant="h4">{`Case #${caseObj.id}`}</UText>
            )}
            {index !== 0 &&
              (showOverview ? (
                <ExpandLessIcon
                  sx={{
                    width: 35,
                    height: 35,
                    color: "rgba(0, 0, 0, 0.56)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowOverview(false)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{
                    width: 35,
                    height: 35,
                    color: "rgba(0, 0, 0, 0.56)",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowOverview(true)}
                />
              ))}
          </Grid>
          <Grid item display={"flex"} alignItems={"flex-end"}>
            {!index && RECORDS_STATUS.includes(caseObj.caseDisposition) && (
              <UButton
                variant="text"
                disabled={patientData?.status === "ARCHIVED"}
                onClickHandler={() => {
                  checkforActiveCase()
                }}
                sxProp={{
                  height: 30,
                  width: 113,
                  padding: "5px",
                  fontSize: "13px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                btnText={t("overview.buttons.addNewCaseBtn")}
              />
            )}

            {!CASE_STATUS.REJECTED.includes(caseObj?.caseDisposition) && (
              <UButton
                variant="contained"
                disabled={patientData?.status === "ARCHIVED"}
                sxProp={{
                  height: 30,
                  width: 95,
                  padding: "4px 10px",
                  fontSize: "13px",
                  ml: 2,
                }}
                btnText={t("overview.buttons.openCaseBtn")}
                onClickHandler={() => {
                  navigate(
                    `/clinical/patient/${patientId}/case/${caseObj.id}/treat`,
                  )
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {!TABLE_VIEW_STATUS.includes(caseObj.caseDisposition) &&
        !CASE_STATUS.REJECTED.includes(caseObj.caseDisposition) && (
          <Grid sx={{ mb: 1 }}>
            <UText variant="h6">{t("overview.caseOverviewTitle")}</UText>
          </Grid>
        )}
    </>
  )
}

const CaseOverview: FC<{
  index: number
  caseObj: ICase
  setIsAlertOpen: (
    type:
      | "OPEN_ARCHIVE_MODEL"
      | "OPEN_CANCEL_MODEL"
      | "addNewCase"
      | "CASE_CANNOT_OPEN"
      | "",
  ) => void
  executeAction: (action: string, caseObj: ICase) => void
}> = ({ index, caseObj, setIsAlertOpen, executeAction }) => {
  const { patientId } = useParams()
  const { ucloud1_1 } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { orderList } = useAppSelector((state: RootState) => state.orderService)
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const { bundleList } = useAppSelector(
    (state: RootState) => state.bundleService,
  )

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const [orderData, setOrderData] = useState<any>([])
  const [bundleData, setBundleData] = useState<any>([])
  const [showOverview, setShowOverview] = useState<boolean>(!index)
  const actionList = getBtnActionsByStatus(caseObj.caseDisposition)
  useEffect(() => {
    if (!orderList[caseObj.id]) return
    setOrderData([...orderList[caseObj.id]])
  }, [orderList])

  useEffect(() => {
    if (!bundleList[caseObj.id]) return
    const bundleData = []
    bundleList[caseObj.id].map((data: IBundle) => {
      if (
        !(
          data.name &&
          data.name.toUpperCase().replaceAll(" ", "") === "ALACARTE"
        )
      ) {
        bundleData.push(data)
      }
    })
    setBundleData([...bundleData])
  }, [bundleList])

  useEffect(() => {
    if (RECORDS_STATUS.includes(caseObj.caseDisposition)) return
    dispatch(getBundleByCaseId(caseObj.id))
    dispatch(getOrdersByCaseId(caseObj.id))
  }, [])

  useEffect(() => {
    if (
      patientData?.status === "ARCHIVED" &&
      !RECORDS_STATUS?.includes(caseObj?.caseDisposition)
    ) {
      setIsAlertOpen("CASE_CANNOT_OPEN")
    }
  }, [patientData])

  return (
    <>
      {([
        ...TABLE_VIEW_STATUS,
        CASE_STATUS.SUBMITTED_TO_UASSIST,
        CASE_STATUS.PLANNING_IN_PROGRESS,
        CASE_STATUS.REJECTED,
        CASE_STATUS.ATTENTION_NEEDED,
      ].includes(caseObj.caseDisposition) ||
        !ucloud1_1) && (
        <CaseSectionHeader
          caseObj={caseObj}
          patientId={patientId}
          setIsAlertOpen={setIsAlertOpen}
          index={index}
          showOverview={showOverview}
          setShowOverview={setShowOverview}
        />
      )}
      {(RECORDS_STATUS.includes(caseObj.caseDisposition) || showOverview) && (
        <>
          {!TABLE_VIEW_STATUS.includes(caseObj.caseDisposition) && (
            <Box
              sx={{
                padding: "24px 40px",
                minHeight: "220px",
                backgroundColor: "background.default",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "16px",
                mb: 3,
              }}
            >
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {actionList && actionList.topCaption && (
                  <Grid item sx={{ mb: 2 }}>
                    <UText variant="body1" sxProp={{ color: "text.secondary" }}>
                      {actionList.topCaption.textContent}
                    </UText>
                  </Grid>
                )}
                {CASE_STATUS.REJECTED.includes(caseObj?.caseDisposition) && (
                  <RejectedOverview />
                )}
                {patientData.status !== "ARCHIVED" &&
                  actionList &&
                  actionList.actions.map((btn, index) => {
                    return (
                      <Grid
                        item
                        key={index}
                        sx={{
                          mb: CASE_STATUS.REJECTED.includes(
                            caseObj?.caseDisposition,
                          )
                            ? 0
                            : 1,
                          gap: 1,
                        }}
                      >
                        <UButton
                          variant={btn.variant}
                          sxProp={{
                            ...btn.sxProps,
                            padding: "8px 22px",
                            height: "42px",
                          }}
                          btnText={btn.btnText}
                          onClickHandler={() => {
                            executeAction(btn.btnAction, caseObj)
                          }}
                        />
                      </Grid>
                    )
                  })}
                {actionList &&
                  actionList.bottomCaption &&
                  (caseObj.submittedDate || caseObj.updateDate) && (
                    <Grid item sx={{ height: "20px" }}>
                      <UText
                        variant="caption"
                        color="rgb(0,0,0,0.38)"
                        sxProp={{ mt: 1 }}
                      >
                        {actionList.bottomCaption.textContent(
                          caseObj.submittedDate
                            ? dateFormat(
                                caseObj.submittedDate,
                                "MM/DD/YYYY",
                                "YYYY-MM-DDTh:mm:ss",
                              )
                            : dateFormat(
                                caseObj.updateDate,
                                "MM/DD/YYYY",
                                "YYYY-MM-DDTh:mm:ss",
                              ),
                        )}
                      </UText>
                    </Grid>
                  )}
              </Grid>
            </Box>
          )}
        </>
      )}

      <Box
        sx={{
          transition:
            "height 0.4s ease-out, opacity 0.4s ease-in, visibility 0.4s ease-in",
          opacity: showOverview ? 1 : 0,
          visibility: showOverview ? "visible" : "hidden",
          height: showOverview ? "100%" : 0,
        }}
      >
        {TABLE_VIEW_STATUS.includes(caseObj.caseDisposition) && (
          <>
            <Grid item>
              <OverviewTableRenderer
                id={"caseOverview"}
                tableConfig={CASE_OVERVIEW_TABLE}
                tableData={[caseObj]}
                executeAction={executeAction}
                hideMenu={patientData?.status === "ARCHIVED"}
              />
            </Grid>
            {!!bundleData.length && (
              <Grid item>
                <OverviewTableRenderer
                  id={"bundleOverview"}
                  tableConfig={BUNDLE_OVERVIEW_TABLE}
                  tableData={bundleData}
                  hideMenu
                />
              </Grid>
            )}
            {!!orderData.length && (
              <Grid item>
                <OverviewTableRenderer
                  id={"orderOverview"}
                  tableConfig={ORDER_OVERVIEW_TABLE}
                  tableData={orderData}
                  hideMenu
                />
              </Grid>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default CaseOverview
